import { FaqLoadingPlaceholder, useAnalyticsPageViewTracker, useAuthentication } from '@cp-shared-8/frontend-ui';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { FaqPrivate } from './faq-private/FaqPrivate';
import { FaqUi } from './ui';
import { useFaq } from './useFaq';
import { useMyProfile } from 'components/my-profile';

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading: faqIsLoading, loadingError: faqLoadingErr } = useFaq();
    const { data: myProfile, loadingError: myProfileLoadingError, isLoading: myProfileIsLoading } = useMyProfile();
    const { isAuthenticated } = useAuthentication();

    useAnalyticsPageViewTracker('faq', !!faq);

    if (!!faqLoadingErr) return <NoConnectionNotification />;

    return faqIsLoading || myProfileIsLoading ? (
        <FaqLoadingPlaceholder />
    ) : isAuthenticated && !myProfileLoadingError ? (
        <FaqPrivate faq={faq} myProfile={myProfile} />
    ) : (
        <FaqUi faqTeaser={faq?.teaser} faqContent={faq?.content.public} />
    );
};
