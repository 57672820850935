import { IdentificationSectionEditViewSmeValidatorErrors } from '../../types';

export const IdentificationSectionSmeEditViewError: IdentificationSectionEditViewSmeValidatorErrors = {
    companyName: {
        required: 'Company name is required',
        matches: 'Company name does not match',
    },
    kbis: {
        required: 'At least one of kbis or insee is required',
        max: 'Max one kbis',
    },
    insee: {
        required: 'At least one of kbis or insee is required',
        max: 'Max one insee',
    },
};
