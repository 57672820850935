import { Heading } from '@vwfs-bronson/bronson-react';
import { currentBrand } from 'config';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useEffect, useState } from 'react';
import { EditStatus, EditViewNames } from '../enums';
import { FileUploadView } from './file-upload-view/FileUploadView';
import { IbanView } from './iban-view/IbanView';
import {
    getInitialValues as getIbanInitialValues,
    InitialValues as IbanInitialValues,
} from './iban-view/InitialValues';

type EditViewProps = {
    iban?: string;
    onSuccess: (lastEditStatus: EditStatus, effectDate?: string) => void;
    onFail: (lastEditStatus: EditStatus) => void;
    contractId: string;
    email: string;
    attempt: number;
};

export const EditView: React.FC<EditViewProps> = ({ iban, onSuccess, onFail, contractId, email, attempt }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [currentView, setCurrentView] = useState(EditViewNames.IBAN_VIEW);

    const [ibanViewValues, setIbanViewValues] = useState<IbanInitialValues>(getIbanInitialValues(iban, email, attempt));

    useEffect(() => {
        setIbanViewValues((state: IbanInitialValues) => ({ ...state, attempt }));
    }, [attempt]);

    const onFileUploadFail = (lastEditStatus: EditStatus): void => {
        onFail(lastEditStatus);
    };

    return (
        <div data-theme={currentBrand === 'seat' ? 'alternative' : 'primary'} className={'u-mb'}>
            <Heading className="u-pt" level={'6'}>
                {t('iban-section.edit.title')}
            </Heading>
            {currentView === EditViewNames.IBAN_VIEW && (
                <IbanView
                    setCurrentView={setCurrentView}
                    ibanViewValues={ibanViewValues}
                    setIbanViewValues={setIbanViewValues}
                />
            )}
            {currentView === EditViewNames.FILE_UPLOAD_VIEW && (
                <FileUploadView
                    setCurrentView={setCurrentView}
                    onSuccess={onSuccess}
                    onFail={onFileUploadFail}
                    ibanViewValues={ibanViewValues}
                    contractId={contractId}
                />
            )}
        </div>
    );
};
