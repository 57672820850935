import { Contract, ContractDetails as TContractDetails } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import moment, { Moment } from 'moment';

export const getFirstEligibleDateForEarlyReturnPenalty = (
    contract: Contract & TContractDetails,
): Moment | undefined => {
    const collectionDate = contract.financial.collectionDate;
    const date = collectionDate && collectionDate > 0 ? collectionDate : 1;

    const firstEligibleDate = moment().date(date).isAfter(moment().add(5, 'day'))
        ? moment().date(date)
        : moment().date(date).add(1, 'month');

    const startDate = formatCpDate(contract.contractStartDate).add(9, 'month').toMoment().isBefore(firstEligibleDate)
        ? firstEligibleDate
        : formatCpDate(contract.contractStartDate).add(9, 'month').toMoment();

    const maxDate = formatCpDate(contract.contractEndDate).toMoment().add(-30, 'day');

    return startDate.isBefore(maxDate) ? startDate : undefined;
};
