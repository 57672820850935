import React from 'react';
import { Reasons } from '@cp-fr/common';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect } from '@cp-shared-8/frontend-ui';

type ReasonsSelectionProps = {
    name: string;
};

export const ReasonSelection: React.FC<ReasonsSelectionProps> = ({ name }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.tenant';
    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const reasons: Reasons[] = [
        "Cession de l'activité",
        'Changement de forme juridique',
        'Fusion/Absorption',
        'Transmission universelle de patrimoine',
        'Autre',
    ];

    return (
        <>
            <ValidatedSelect
                isMandatory={true}
                label={translate('reason.label')}
                name={name}
                selectItems={reasons.map((reason) => ({
                    value: reason,
                    label: reason,
                }))}
                emptyByDefault
            />
        </>
    );
};
