import { RequestLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useMyProfile } from '../my-profile';
import { FormView } from './form-view/FormView';
import { useRequest } from './useRequest';

const RequestWithHandlers = withLoadingAndNoConnectionHandler(FormView);

export const Request: React.FC = () => {
    const { data: contracts, isLoading: isContractsLoading, loadingError: contractsLoadingError } = useRequest();
    const { data: myProfile, isLoading: isMyProfileLoading, loadingError: myProfileLoadingError } = useMyProfile();

    useAnalyticsPageViewTracker('requests', !!contracts && !!myProfile);

    const isLoading = isContractsLoading || isMyProfileLoading;
    const loadingError = contractsLoadingError || myProfileLoadingError;
    const isSMECustomer = myProfile?.identification?.customerType
        ? myProfile.identification.customerType !== 'Retail'
        : false;

    return (
        <RequestWithHandlers
            isLoading={isLoading}
            contracts={contracts}
            hasError={!!loadingError}
            contactDetails={myProfile?.contactDetails}
            identification={myProfile?.identification}
            isSMECustomer={isSMECustomer}
            loadingPlaceholder={<RequestLoadingPlaceholder withTextArea numberOfButtons={2} />}
        />
    );
};
