import { Contract, ContractDetails, FinancialDetails, ProductTypeEN, isVDLContract } from '@cp-fr/common';
import { AccordionItem } from '@cp-shared-8/frontend-ui';
import { useMyProfile } from 'components/my-profile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CollectionDateSection } from './collection-date-section';
import { GeneralSection } from './general-section';
import { IbanSection } from './iban-section';
import { PaymentSection } from './payment-section';

type FinancialDetailsProps = {
    contract?: Contract & ContractDetails;
    financialDetails?: FinancialDetails;
    isContactInformationChangePending: boolean;
};

export const FinancialDetailsComponent: React.FC<FinancialDetailsProps> = ({
    contract,
    financialDetails,
    isContactInformationChangePending,
}) => {
    const { t } = useTranslation('financial-details');
    const { data: myProfileData } = useMyProfile();

    if (!financialDetails || !contract || contract.productTypeEN === ProductTypeEN.STAND_ALONE) {
        return null;
    }

    const { isActive, productType, productTypeEN, contractId } = contract;

    const isSMECustomer = myProfileData?.identification?.customerType
        ? myProfileData?.identification?.customerType !== 'Retail'
        : false;

    return (
        <AccordionItem title={isSMECustomer ? t('title.sme') : t('title.private')} contentClassName={'u-pr-none'}>
            <div>
                <GeneralSection
                    details={financialDetails}
                    isActive={isActive}
                    productType={productTypeEN}
                    isSMECustomer={isSMECustomer}
                />
                <CollectionDateSection
                    details={financialDetails}
                    isActive={isActive}
                    contractId={contractId}
                    isContactInformationChangePending={isContactInformationChangePending}
                    isSMECustomer={isSMECustomer}
                    isVDLContract={isVDLContract(contract)}
                />
                <IbanSection
                    details={financialDetails}
                    isActive={isActive}
                    contractId={contractId}
                    userEmail={myProfileData?.contactDetails?.email || ''}
                    isContactInformationChangePending={isContactInformationChangePending}
                />
                <PaymentSection
                    details={financialDetails}
                    isActive={isActive}
                    productType={productType}
                    isSMECustomer={isSMECustomer}
                />
            </div>
        </AccordionItem>
    );
};
