import { Base64File } from '../files';

export enum AddressType {
    POSTAL = 'POSTAL',
    INVOICE = 'INVOICE',
}

export enum HousingOccupancyStatus {
    HOSTED_FREE = 'HOSTED_FREE',
    RENT = 'RENT',
    PROPERTY_OWNERSHIP = 'PROPERTY_OWNERSHIP',
}

export enum DocumentType {
    PROOF_OF_ADDRESS = 'proof_of_address',
    REGISTRATION_CERTIFICATE = 'registration_certificate',
    IDENTITY_DOCUMENT = 'identity_document',
    CERTIFICATE_OF_ACCOMODATION = 'certificate_of_accomodation',
    HOST_IDENTITY_DOCUMENT = 'host_identity_document',
    KBIS = 'kbis',
    INSEE = 'insee',
}

export type AddressDocument = {
    file: Base64File;
    type: DocumentType;
};

export type Address = {
    houseNumber?: string;
    street?: string;
    streetAdd?: string;
    building?: string;
    door?: string;
    postalCode?: string;
    city?: string;
    area?: string;
    country?: string;
    countryCode?: string;
};

export type ChangeAddress = {
    houseNumber?: string;
    street?: string;
    streetAdd?: string;
    building?: string;
    door?: string;
    postalCode: string;
    city: string;
    country: string;
    documents: AddressDocument[];
    addressType: AddressType;
    housingOccupancyStatus: HousingOccupancyStatus;
};
