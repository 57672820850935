import { RequestChangeDurationMileageValidatorErrors } from '../../types';

export const RequestChangeDurationMileageError: RequestChangeDurationMileageValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    totalDuration: {
        required: 'totalDuration is required',
    },
    desiredMileage: {
        required: 'desiredMileage is required',
    },
};
