import React from 'react';
import { ParkingUi } from './parking-ui';
import { DefaultStaticPageContentLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { useSearchCustomerIndex } from './useSearchCustomerIndex';
import { CustomerIndexIdentifierType } from '@cp-shared-8/apis';
import { currentBrand, pbpLoginUrl } from '../../config';

const ParkingWithHandlers = withLoadingAndNoConnectionHandler(ParkingUi);

export const Parking: React.FC = () => {
    const { data: customerIndexData, isLoading, loadingError } = useSearchCustomerIndex();

    const getPayByPhoneId = (): string | undefined => {
        if (!customerIndexData?.items?.length) {
            return;
        }
        return customerIndexData.items.find(({ type }) => type === CustomerIndexIdentifierType.PAYBYPHONEID)?.id;
    };

    const handlePbpLogin = () => {
        window.location.assign(
            window.location.origin +
                `/pbp-login.html?brand=${currentBrand}&url=${encodeURIComponent(pbpLoginUrl || '')}`,
        );
    };

    return (
        <ParkingWithHandlers
            isLoading={isLoading}
            payByPhoneId={getPayByPhoneId()}
            handlePbpLogin={handlePbpLogin}
            hasError={!!loadingError}
            loadingPlaceholder={<DefaultStaticPageContentLoadingPlaceholder />}
        />
    );
};
