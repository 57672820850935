import { OriginalRegDocument } from '@cp-fr/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import React from 'react';

type OriginalRegDocumentSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
    hasTooltip?: boolean;
    tooltipText?: string | React.ReactNode;
};

export const OriginalRegDocumentSelection: React.FC<OriginalRegDocumentSelectionProps> = ({
    label,
    name,
    isMandatory = true,
    hasTooltip = false,
    tooltipText,
}) => {
    const originalRegDocumentOptions: OriginalRegDocument[] = ['Oui', 'Non'];

    const selectItems: ValidatedSelectItem[] = originalRegDocumentOptions.map(
        (originalRegDocumentOption: OriginalRegDocument) => {
            return { value: originalRegDocumentOption, label: originalRegDocumentOption };
        },
    );

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
            tooltip={hasTooltip ? tooltipText : undefined}
        />
    );
};
