import { getContactDetailsStatusEndpoint, RequestStatus } from '@cp-fr/common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';

const mockOptionsRetail: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    DONE: {
        status: 200,
        responseBody: {
            requestCustomerStatus: RequestStatus.DONE,
        },
    },
    IN_PROGRESS: {
        status: 200,
        responseBody: {
            requestCustomerStatus: RequestStatus.IN_PROGRESS,
        },
    },
    'Business Error': DefaultBusinessMarketApiError,
};

export const ContactInformationChangeStatusMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'ContactInformationChangeStatus',
    method: 'get',
    defaultMockOption: 'DONE',
    mockOptions: mockOptionsRetail,
    url: getContactDetailsStatusEndpoint(),
};

export const ContactInformationChangeStatusMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContactInformationChangeStatusMock]);

    return <div>{storyFn()}</div>;
};
