export enum LOA_PRODUCT {
    LOAN_FL = 'LOAN_FL',
    LOAN_PR = 'LOAN_PR',
    LOAO_PR = 'LOAO_PR',
    LOAB_PR = 'LOAB_PR',
    ASVO_PR = 'ASVO_PR',
}

export enum LEASING_PRODUCT {
    CBEQ_FL = 'CBEQ_FL',
    CBVO_FL = 'CBVO_FL',
    CBPO_FL = 'CBPO_FL',
    CBPN_FL = 'CBPN_FL',
    CBVE_FL = 'CBVE_FL',
}

export enum LLD_PRODUCT {
    LCDN_FL = 'LCDN_FL',
    LLDN_FL = 'LLDN_FL',
    LLDN_PR = 'LLDN_PR',
    LLDO_FL = 'LLDO_FL',
    LLDO_PR = 'LLDO_PR',
}

export enum LOUN_PRODUCT {
    LOUN_PR = 'LOUN_PR',
    LOUO_PR = 'LOUO_PR',
    LOUN_FL = 'LOUN_FL',
}

export enum CREDIT_PRODUCT {
    ACVN_PR = 'ACVN_PR',
    ACVO_PR = 'ACVO_PR',
    CCCD_FL = 'CCCD_FL',
    PPER_PR = 'PPER_PR',
    PERS_PR = 'PERS_PR',
    TRAN_PR = 'TRAN_PR',
    CRVN_FL = 'CRVN_FL',
    CRVN_PR = 'CRVN_PR',
    CRVO_FL = 'CRVO_FL',
    CRVO_PR = 'CRVO_PR',
}

export enum STANDALONE_PRODUCT {
    SEPN_PR = 'SEPN_PR',
    SENN_PR = 'SENN_PR',
    SEPO_PR = 'SEPO_PR',
    SENO_PR = 'SENO_PR',
    SEGN_FL = 'SEGN_FL',
    SEGN_PR = 'SEGN_PR',
    SMAN_FL = 'SMAN_FL',
    SVDA_FL = 'SVDA_FL',
}

export const isBalloonLeasing = (val?: string) => Object.values(LOA_PRODUCT).includes(val as LOA_PRODUCT);

export const isCredit = (val?: string) => Object.values(CREDIT_PRODUCT).includes(val as CREDIT_PRODUCT);

export const isFinancialLease = (val?: string) => Object.values(LEASING_PRODUCT).includes(val as LEASING_PRODUCT);

export const isStandalone = (val?: string) => Object.values(STANDALONE_PRODUCT).includes(val as STANDALONE_PRODUCT);

export const isLLD = (val?: string) => Object.values(LLD_PRODUCT).includes(val as LLD_PRODUCT);

export const isLoun = (val?: string) => Object.values(LOUN_PRODUCT).includes(val as LOUN_PRODUCT);

export const isLLLProduct = (val?: string) =>
    Object.values(LOA_PRODUCT).includes(val as LOA_PRODUCT) ||
    Object.values(LLD_PRODUCT).includes(val as LLD_PRODUCT) ||
    Object.values(LOUN_PRODUCT).includes(val as LOUN_PRODUCT);

export const isLine2Product = (val?: string) =>
    Object.values(LEASING_PRODUCT).includes(val as LEASING_PRODUCT) ||
    Object.values(LLD_PRODUCT).includes(val as LLD_PRODUCT) ||
    Object.values(LOUN_PRODUCT).includes(val as LOUN_PRODUCT);

/**
 * With "old" approach to EN and FR product types it is the same as:
 * - LongTermLeasing
 * - OperatingLeasing
 */
export const isLongTermOrOperatingLeasing = (val?: string) =>
    Object.values(LLD_PRODUCT).includes(val as LLD_PRODUCT) ||
    Object.values(LOUN_PRODUCT).includes(val as LOUN_PRODUCT);
