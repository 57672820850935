import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ContactDetailsCheckPendingNotification: React.FC = () => {
    const { t } = useTranslation('my-profile');

    return (
        <Notification className={'u-mb'} testId="documents-error" status={NotificationStatus.info}>
            <div dangerouslySetInnerHTML={{ __html: t('notifications.contact-information-change-pending') }}></div>
        </Notification>
    );
};
