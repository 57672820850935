import * as Yup from 'yup';

export const phoneNumberNotRequiredValidation = (error: string, matches?: string): Yup.AnySchema => {
    return matches
        ? Yup.string()
              .trim()
              .matches(/^0[6-7]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/, matches)
              .test('min', error, (value): boolean => (value ? value === '' || value.length === 10 : true))
        : Yup.string()
              .trim()
              .test('min', error, (value): boolean => (value ? value === '' || value.length === 10 : true));
};
