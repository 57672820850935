import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { SearchCustomerIndexDataSelector } from './SearchCustomerIndexDataSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { SearchCustomerIndexResponse } from '@cp-shared-8/apis';
import { fetchSearchCustomerIndex } from './SearchCustomerIndexSlice';

export function useSearchCustomerIndex(): AbstractDataState<
    SearchCustomerIndexResponse,
    DefaultBusinessMarketApiErrorCode
> {
    return useGetSimpleApiData(fetchSearchCustomerIndex, SearchCustomerIndexDataSelector, true);
}
