import { CPDate } from '@cp-shared-8/common-utilities';

export type EarlyReturnPenaltyFormValues = {
    requestDate: CPDate;
    estimatedMileage: number;
};

export const getInitialValues: () => EarlyReturnPenaltyFormValues = () => ({
    requestDate: '',
    estimatedMileage: 0,
});
