import * as Yup from 'yup';
import { RegistrationPhysicalPersonValidatorErrors } from '../types';
import { RegistrationPhysicalPersonError } from '../messages';
import { RegistrationDataPhysicalValidationSchema } from '../../apis';

export const registrationPhysicalPersonValidator = (
    isFS = false,
    errors: RegistrationPhysicalPersonValidatorErrors = RegistrationPhysicalPersonError,
): Yup.SchemaOf<RegistrationDataPhysicalValidationSchema> =>
    Yup.object().shape({
        contractNumber: Yup.string()
            .trim()
            .required(errors.contractNumber.empty)
            .min(11, errors.contractNumber.format)
            .max(12, errors.contractNumber.format)
            .test('valid format-11', errors.contractNumber.format, (contractNumber): boolean => {
                return contractNumber?.length === 11 ? !!contractNumber?.match(RegExp('^\\d{8}[a-zA-Z]{3}$')) : true;
            })
            .test('valid format-12', errors.contractNumber.format, (contractNumber): boolean => {
                return contractNumber?.length === 12
                    ? !!contractNumber?.match(RegExp('^\\d{8}[a-zA-Z]{3}\\d{1}$'))
                    : true;
            }),
        firstName: Yup.string().trim().required(errors.firstName),
        surname: Yup.string().trim().required(errors.surname),
        siren: Yup.string().when('isFS', {
            is: () => isFS,
            then: Yup.string()
                .trim()
                .required(errors.siren.empty)
                .matches(/^[0-9]*$/, errors.siren.format)
                .min(9, errors.siren.format)
                .max(9, errors.siren.format),
            otherwise: Yup.string()
                .trim()
                .test('valid siren', errors.siren.format, (siren): boolean => {
                    return siren?.length === 9 || !siren;
                }),
        }),
    });
