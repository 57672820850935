import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ProductTypeEN } from '@cp-fr/common';
import { EmailInput, MonthSelection, PhonenumberInput } from '../../simple-form-components';

type DeadlineFormProps = {
    productTypeEn?: ProductTypeEN;
};

export const DeadlineForm: React.FC<DeadlineFormProps> = ({ productTypeEn }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.deadline';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const description =
        productTypeEn === ProductTypeEN.CREDIT
            ? translate('description.credit')
            : productTypeEn === ProductTypeEN.BALLON_LEASING
            ? translate('description.balloon')
            : '';

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'deadline.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput label={translate('phonenumber')} name={'deadline.phonenumber'} />
            </Layout.Item>
            <Layout.Item>
                <MonthSelection label={t(`${translationPrefix}.deadline`)} name={'deadline.deadline'} />
            </Layout.Item>
        </>
    );
};
