import React, { useState } from 'react';
import { getCustomerHappinessIndexEndpoint } from '@cp-fr/common';
import { useAnalyticsActionTracker, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { CustomerHappinessIndexUi } from './ui/CustomerHappinessIndexUi';
import { TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { useTranslation } from 'react-i18next';

type CustomerHappinessIndexProps = {
    firstName?: string;
    lastName?: string;
    email?: string;
    customType: TrustedShopsIntegrationCustomType;
};

const CustomerHappinessIndexWithLoadingHandler = withLoadingAndNoConnectionHandler(CustomerHappinessIndexUi);

export const CustomerHappinessIndex: React.FC<CustomerHappinessIndexProps> = ({
    firstName,
    lastName,
    email,
    customType,
}) => {
    const { onAction } = useAnalyticsActionTracker('chi');
    const { t } = useTranslation('request');

    const [isOpeningSurvey, setIsOpeningSurvey] = useState(false);
    const [isSurveyError, setIsSurveyError] = useState(false);
    const [noEmailError, setNoEmailError] = useState(false);
    const [isConsentAccepted, setIsConsentAccepted] = useState(false);

    const handleButtonClick = (): void => {
        if (!isConsentAccepted) return;
        onAction();
        setIsOpeningSurvey(true);

        if (!email) {
            setIsOpeningSurvey(false);
            setNoEmailError(true);
            return;
        }

        const request = email
            ? {
                  firstName: firstName || '',
                  lastName: lastName || '',
                  email,
                  customType,
              }
            : undefined;

        CpDataApi.post(getCustomerHappinessIndexEndpoint('fr'), request)
            .then((response) => {
                setIsOpeningSurvey(false);
                window.open(response?.data?.redirectUrl, '_blank');
            })
            .catch(() => {
                setIsOpeningSurvey(false);
                setIsSurveyError(true);
            });
    };

    return (
        <>
            {!noEmailError ? (
                <CustomerHappinessIndexWithLoadingHandler
                    isLoading={isOpeningSurvey}
                    hasError={isSurveyError}
                    handleButtonClick={handleButtonClick}
                    isConsentAccepted={isConsentAccepted}
                    setIsConsentAccepted={setIsConsentAccepted}
                />
            ) : (
                <Notification status={NotificationStatus.error} text={t('modal-missing-email-error.text')} />
            )}
        </>
    );
};
