import { AuthorizationLeaveCountryRequest } from '@cp-fr/common';
import { RequestInitialValues } from '../../../../initialValues';

export const authorizationLeaveCountryMapper = ({
    authorizationLeaveCountry,
    contractsSelection,
}: RequestInitialValues): AuthorizationLeaveCountryRequest => {
    return {
        requestCategory: 'AUTHORIZATION_LEAVE_COUNTRY',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: authorizationLeaveCountry.email,
        phonenumber: authorizationLeaveCountry.phonenumber,
        exitDate: authorizationLeaveCountry.exitDate,
        returnDate: authorizationLeaveCountry.returnDate,
        reason: authorizationLeaveCountry.reason,
        countryOfDestination: authorizationLeaveCountry.countryOfDestination,
    };
};
