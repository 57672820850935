import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { SearchCustomerIndexResponseWithAllValues, SearchCustomerIndexResponseWithNoValues } from './ExampleData';
import { searchCustomerIndexEndpoint } from '@cp-shared-8/apis';
import { ParkingSessionsMock } from './parking-sessions/ParkingSessionsReponseMocks';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: SearchCustomerIndexResponseWithAllValues || {},
    },
    'No customer found': {
        status: 200,
        responseBody: SearchCustomerIndexResponseWithNoValues || {},
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const SearchCustomerIndexMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Search customer index Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: searchCustomerIndexEndpoint(),
};

export const ParkingResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [SearchCustomerIndexMock, ParkingSessionsMock]);

    return <div>{storyFn()}</div>;
};
