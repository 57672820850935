import React from 'react';
import { ValidatedInput } from '@cp-shared-8/frontend-ui';

type EmailProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const EmailInput: React.FC<EmailProps> = ({ label, name, isMandatory = true }) => {
    return (
        <ValidatedInput
            label={isMandatory ? `${label} *` : label}
            name={name}
            inputMode={'email'}
            testId={`test-${name}`}
        />
    );
};
