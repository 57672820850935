import { RequestFormValidatorErrors } from '../types';
import * as requestMessages from './requestMessages';

export const RequestFormMessages: RequestFormValidatorErrors = {
    contractsSelection: {
        required: 'contractsSelection is required',
    },
    categorySelection: {
        required: 'categorySelection is required',
    },
    contractsNumbers: {
        required: 'contractsNumbers is required',
    },
    requestAuthorizationDebitMessages: requestMessages.RequestAuthorizationDebitError,
    requestAuthorizationLeaveCountryMessages: requestMessages.RequestAuthorizationLeaveCountryError,
    requestDeadlineErrorMessages: requestMessages.RequestDeadlineError,
    requestVehicleClaimErrorMessages: requestMessages.RequestVehicleClaimError,
    requestFormDeathErrorMessages: requestMessages.RequestFormDeathError,
    requestContractTransferMessages: requestMessages.RequestContractTransferError,
    requestDocumentsErrorMessages: requestMessages.RequestDocumentsError,
    requestLossErrorMessages: requestMessages.RequestLossError,
    requestRegistrationCertificateDuplicateErrorMessages: requestMessages.RequestRegistrationCertificateDuplicateError,
    requestTenantChangeRequestErrorMessages: requestMessages.RequestTenantChangeError,
    requestChangeDurationMileageErrorMessages: requestMessages.RequestChangeDurationMileageError,
    requestOtherErrorMessages: requestMessages.RequestOtherError,
};
