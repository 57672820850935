import { Base64File } from '@cp-fr/common';
import { ContractTransferRequest } from '@cp-fr/common/src/apis/services/types/request';
import { groupByFileSize, mapFilesToBase64 } from 'utils/file-utils';
import { maxFileSize } from '../../../../../../../config';
import { RequestInitialValues } from '../../../../initialValues';

export const requestContractTransferMapper = async ({
    contractsSelection,
    requestContractTransfer,
}: RequestInitialValues): Promise<ContractTransferRequest[]> => {
    const {
        signedConfirmation,
        buyerIdentity,
        bankDetails,
        payslips,
        taxNotice,
        proofOfAddress,
        buyerProofOfAddress,
        accomodationCertificate,
        indentityDoc,
        registrationDoc,
    } = requestContractTransfer.files;
    const files = [
        ...signedConfirmation,
        ...buyerIdentity,
        ...bankDetails,
        ...payslips,
        ...taxNotice,
        ...registrationDoc,
    ];

    if (
        requestContractTransfer.employmentStatus === 'Propriétaire' ||
        requestContractTransfer.employmentStatus === 'Locataire'
    ) {
        files.push(...(proofOfAddress || []));
    } else {
        files.push(...(buyerProofOfAddress || []), ...(accomodationCertificate || []), ...(indentityDoc || []));
    }

    const filesForRequest = await mapFilesToBase64(files);

    const mapToRequest = (files: Base64File[]): ContractTransferRequest => ({
        requestCategory: 'REQUEST_CONTRACT_TRANSFER',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: requestContractTransfer.email,
        phonenumber: requestContractTransfer.phonenumber,
        buyerNames: requestContractTransfer.buyerNames,
        buyerPhonenumber: requestContractTransfer.buyerPhonenumber,
        buyerEmail: requestContractTransfer.buyerEmail,
        requestReason: requestContractTransfer.requestReason,
        requestReasonDescription: requestContractTransfer.requestReasonDescription,
        employmentStatus: requestContractTransfer.employmentStatus,
        socioprofessionalCategory: requestContractTransfer.socioprofessionalCategory,
        files,
    });

    return groupByFileSize(filesForRequest, maxFileSize).map((group) => mapToRequest(group));
};
