import { RequestLossValidatorErrors } from '../../types';

export const RequestLossError: RequestLossValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    natureOfClaim: {
        required: 'natureOfClaim is required',
    },
    startDate: {
        required: 'startDate is required',
    },
};
