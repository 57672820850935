import { BundledProduct } from '@cp-fr/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../localization/useTranslationWithFormatting';
import { RootState } from '../../../../../store/rootReducer';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import { BundledProductContentSelector } from './BundledProductContentSelector';

type BundledProductComponentProps = {
    isActive: boolean;
    isSMECustomer?: boolean;
    details?: BundledProduct;
    contractMileage?: number;
};

export const BundledProductComponent: React.FC<BundledProductComponentProps> = ({
    isActive,
    isSMECustomer,
    details,
    contractMileage,
}) => {
    const { t, f } = useTranslationWithFormatting('bundled-products');

    const { cmsContent: bundledProductsContactInfo } = useSelector((state: RootState) =>
        BundledProductContentSelector(state),
    );

    if (!details) {
        return null;
    }

    const { name, status, startDate, endDate, supplier } = details;

    const notAvailable = '-';

    const getContactLine = () => {
        if (!bundledProductsContactInfo || !name || !supplier) return [];

        const matchedInfo = bundledProductsContactInfo.find(({ supplierName, productNames, customerType }) => {
            const isMatchedSupplierName: boolean = supplier.toLowerCase().includes(supplierName.toLowerCase());
            const isMatchedProductName: boolean = productNames.some((productName) =>
                name.toLowerCase().includes(productName.toLowerCase()),
            );

            const isMatchedCustomerType: boolean = customerType
                ? customerType === (isSMECustomer ? 'SME' : 'retail')
                : true;
            return isMatchedSupplierName && isMatchedProductName && isMatchedCustomerType;
        });

        if (matchedInfo) {
            const { contactName, contactNumber, contactLink, learnMoreLink } = matchedInfo;
            const contactInfoLine = [contactName, contactNumber, contactLink].filter(Boolean).join('\n');

            return [
                {
                    label: t('contact'),
                    value: contactInfoLine ? (
                        <span style={{ whiteSpace: 'break-spaces' }}>{contactInfoLine}</span>
                    ) : (
                        '-'
                    ),
                    testId: 'contact',
                },
                {
                    label: t('coverage'),
                    value: learnMoreLink ? (
                        <a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
                            {learnMoreLink}
                        </a>
                    ) : (
                        '-'
                    ),
                    testId: 'coverage',
                },
            ];
        }

        return [];
    };

    const contactLine: DefinitionListItem[] = getContactLine();

    const nameLine: DefinitionListItem[] = [
        {
            label: t('name'),
            value: name || notAvailable,
            testId: 'name',
        },
    ];

    const startDateLine: DefinitionListItem[] = name?.includes('Extension de Garantie')
        ? [
              {
                  label: t('start-date'),
                  value: startDate ? f(startDate, TranslationFormat.DATE) : notAvailable,
                  testId: 'start-date',
              },
          ]
        : [];

    const endDateLine: DefinitionListItem[] =
        status === 'INACTIVE'
            ? [
                  {
                      label: t('end-date'),
                      value: endDate ? f(endDate, TranslationFormat.DATE) : notAvailable,
                      testId: 'end-date',
                  },
              ]
            : [];

    const contractMileageLine: DefinitionListItem[] =
        name?.toLowerCase().includes('entretien') || name?.includes('Extension de Garantie')
            ? [
                  {
                      label: t('contract-mileage'),
                      value: contractMileage ? f(contractMileage, TranslationFormat.DISTANCE) : notAvailable,
                      testId: 'contract-mileage',
                  },
              ]
            : [];

    const supplierLine: DefinitionListItem[] = [
        {
            label: t('supplier'),
            value: supplier || notAvailable,
            testId: 'supplier',
        },
    ];

    const statusLine: DefinitionListItem[] = isSMECustomer
        ? []
        : [
              {
                  label: t('status'),
                  value: status || notAvailable,
                  testId: 'status',
              },
          ];

    const itemList: DefinitionListItem[] = [
        ...nameLine,
        ...statusLine,
        ...startDateLine,
        ...endDateLine,
        ...supplierLine,
        ...contactLine,
        ...contractMileageLine,
    ];

    const list = isActive ? itemList : nameLine;
    const isError = !list.filter(({ value }) => value !== notAvailable).length;

    return isError ? (
        <ErrorNotification testId={'not-found-error'} headline={t('error.headline')} text={t('error.text')} />
    ) : (
        <DefinitionList split={true} list={list} />
    );
};
