import { getChangeMarketingSettingsEndpoint } from '@cp-fr/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';

const mockOptionsRetail: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: {},
    },
    Error: {
        status: 500,
        responseBody: {},
    },
};

const mockOptionsSME: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: {},
    },
    Error: {
        status: 500,
        responseBody: {},
    },
};

export const MarkettingSettingsRetailMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsRetail,
    url: getChangeMarketingSettingsEndpoint(),
};

export const MarkettingSettingsSMEMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsSME,
    url: getChangeMarketingSettingsEndpoint(),
};

export const MyProfileResponseRetailMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MarkettingSettingsRetailMock]);

    return <div>{storyFn()}</div>;
};

export const MyProfileResponseSMEMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MarkettingSettingsSMEMock]);

    return <div>{storyFn()}</div>;
};
