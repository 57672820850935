export enum EditStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NOT_PERFORMED = 'NOT_PERFORMED',
    CHECK_FAILURE = 'CHECK_FAILURE',
    ERROR_DOCUMENTS = 'ERROR_DOCUMENTS',
    NO_EMAIL_ERROR = 'USER_WITH_NO_EMAIL',
}

export enum EditViewName {
    FORM_VIEW = 'FORM_VIEW',
    FILE_UPLOAD_VIEW = 'FILE_UPLOAD_VIEW',
}
