import moment from 'moment-timezone';
import numeral from 'numeral';
import 'moment/locale/fr';
import 'numeral/locales/fr';
import { CPDate, formatCpDate } from '@cp-shared-8/common-utilities';

// Check out https://momentjs.com/timezone/
export const FR_TIMEZONE = 'Europe/Paris';

moment.tz.setDefault(FR_TIMEZONE);

// TODO Check the format definitions
const locale = 'fr';
export const numberFormat = '0,0';
export const numberFormatRoundedOne = '0,0.0';
export const numberFormatRoundedTwo = '0,0.00';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
export const longDateFormat = 'DD/MM/YYYY';

export function changeLocale(locale: 'en' | 'fr'): void {
    moment.locale(locale);
    numeral.locale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(currencyFormat)} €` : '';
}

export function formatAsDate(date?: CPDate): string {
    return formatCpDate(date).format(longDateFormat);
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsPercentage(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormatRoundedTwo)} %` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}
