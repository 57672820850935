import { Contract, ProductTypeEN, VehicleDetails } from '@cp-fr/common';
import { AccordionItem, DefinitionList, DefinitionListItem, openInNewWindow } from '@cp-shared-8/frontend-ui';
import { Button } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { emptyObjectOrAllValuesAreUndefined } from '../../../../utils';
import { ErrorNotification } from '../../../notifications/error/ErrorNotification';

export type VehicleDetailsProps = { contract: Contract; vehicleDetails?: VehicleDetails; isSMECustomer?: boolean };

export const VehicleDetailsComponent: React.FC<VehicleDetailsProps> = ({ contract, vehicleDetails, isSMECustomer }) => {
    const { t } = useTranslation('vehicle-details');
    const [isError, setIsError] = React.useState(false);

    const { isActive, productTypeEN } = contract;
    if (!vehicleDetails || isSMECustomer === undefined) {
        return null;
    }

    const vinLine: DefinitionListItem[] = [
        {
            label: t('vin'),
            value: vehicleDetails?.vin,
            testId: 'vin',
        },
    ];

    const privateItemList: DefinitionListItem[] = [
        {
            label: t('car-description'),
            value: vehicleDetails?.carDescription,
            testId: 'car-description',
        },
        {
            label: t('car-add-description'),
            value: vehicleDetails?.carAddDescription,
            testId: 'car-add-description',
        },
        ...vinLine,
        {
            label: t('license-plate'),
            value: vehicleDetails?.licensePlate,
            testId: 'license-plate',
        },
    ];

    const smeItemList: DefinitionListItem[] = [
        {
            label: t('vehicle-type'),
            value: vehicleDetails?.vehicleType,
            testId: 'vehicle-type',
        },
        {
            label: t('co2'),
            value: vehicleDetails?.co2,
            testId: 'co2',
        },
        {
            label: t('energy'),
            value: vehicleDetails?.energy,
            testId: 'energy',
        },
        {
            label: t('fiscal-power'),
            value: vehicleDetails?.fiscalPower,
            testId: 'fiscal-power',
        },
        {
            label: t('driver'),
            value: vehicleDetails?.driver,
            testId: 'driver',
        },
    ];
    const getItemList = (): DefinitionListItem[] => {
        let itemList: DefinitionListItem[];
        if (isSMECustomer && isActive) {
            itemList = [...vinLine, ...smeItemList];
        } else if (isSMECustomer) {
            itemList = [...vinLine, smeItemList[smeItemList.length - 1]];
        } else {
            itemList = privateItemList;
        }
        const outputList = itemList.filter(({ value }) => !!value);
        if (emptyObjectOrAllValuesAreUndefined(vehicleDetails) || !outputList.length) {
            setIsError(true);
        }
        return outputList;
    };

    return (
        <AccordionItem title={t('title')} contentClassName={'u-pr-none'}>
            {isError ? (
                <ErrorNotification testId={'not-found-error'} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <div>
                    <DefinitionList split={true} list={getItemList()} />
                </div>
            )}
            <>
                {isSMECustomer &&
                    !isActive &&
                    (productTypeEN === ProductTypeEN.OPERATIVE_LEASING || productTypeEN === ProductTypeEN.VDL) && (
                        <Button
                            icon="semantic-forward"
                            iconReversed
                            link
                            secondary
                            small
                            onClick={() => openInNewWindow(t('consult-export-report-url'))}
                            testId={'consult-button'}
                        >
                            {t('consult-export-report')}
                        </Button>
                    )}
            </>
        </AccordionItem>
    );
};
