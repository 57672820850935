import { CustomerIndexIdentifierType, SearchCustomerIndexResponse } from '@cp-shared-8/apis';

export const exampleCustomerIndexId = 'customer-index-id';
export const exampleCustomerId = 'customer-id';
export const examplePayByPhoneId = 'pay-by-phone-id';

export const SearchCustomerIndexResponseWithAllValues: SearchCustomerIndexResponse = {
    id: exampleCustomerIndexId,
    items: [
        { type: CustomerIndexIdentifierType.FSID, id: exampleCustomerId },
        { type: CustomerIndexIdentifierType.PAYBYPHONEID, id: examplePayByPhoneId },
    ],
};

export const SearchCustomerIndexResponseWithMissingValues: SearchCustomerIndexResponse = {
    id: exampleCustomerIndexId,
    items: [{ type: CustomerIndexIdentifierType.FSID, id: exampleCustomerId }],
};

export const SearchCustomerIndexResponseWithNoValues: SearchCustomerIndexResponse = null;
