import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../../simple-form-components';

export type TransferTo = 'Personne physique' | 'Personne morale';

type DocumentsUploadProps = {
    transferringTo: TransferTo;
};

export const DocumentsUpload: React.FC<DocumentsUploadProps> = ({ transferringTo }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.tenant.files';
    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            {transferringTo ? (
                <>
                    <Layout.Item>
                        <FileUpload
                            name={'tenantChangeRequest.files.dialogueSheet'}
                            label={translate('dialogue-sheet')}
                        />
                    </Layout.Item>
                    {transferringTo === 'Personne physique' && (
                        <>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.idCopy'}
                                    label={translate('id-copy')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.taxPackage'}
                                    label={translate('tax-package')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.kbisOrInsee'}
                                    label={translate('kbis-or-insee')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.newTenantIban'}
                                    label={translate('new-tenant-iban')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                        </>
                    )}
                    {transferringTo === 'Personne morale' && (
                        <>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.idCopyManager'}
                                    label={translate('id-copy-manager')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.taxReturn'}
                                    label={translate('tax-return')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <FileUpload
                                    name={'tenantChangeRequest.files.newTenantIban'}
                                    label={translate('new-tenant-iban')}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                        </>
                    )}
                    <Layout.Item>
                        <FileUpload
                            name={'tenantChangeRequest.files.vehicleId'}
                            label={translate('vehicle-id')}
                            isMandatory={true}
                        />
                    </Layout.Item>
                </>
            ) : null}
        </>
    );
};
