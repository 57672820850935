import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { formatAsCurrency } from '@cp-fr/common';

export type UnpaidAlertNotificationProps = {
    amountUnpaidNotPaid?: number;
};

export const UnpaidAlertNotificationUi: React.FC<UnpaidAlertNotificationProps> = ({ amountUnpaidNotPaid }) => {
    const { t } = useTranslation('contracts');

    if (!amountUnpaidNotPaid || amountUnpaidNotPaid <= 0) {
        return null;
    }

    return (
        <Notification
            status={NotificationStatus.warning}
            headline={t('unpaid-alert.headline')}
            testId={'active-contract-unpaid-alert'}
            className={'u-mv'}
        >
            {t('unpaid-alert.text-unpaid-ammount', { value: formatAsCurrency(amountUnpaidNotPaid) })}
            <br />
            {t('unpaid-alert.text-support')}
        </Notification>
    );
};
