import { DocumentType } from '@cp-fr/common';

export type InitialValues = {
    proofOfAddress: File[];
    id: File[];
    accomodationCertificate: File[];
    hostId: File[];
    kbis: File[];
    insee: File[];
};

export const initialValues: InitialValues = {
    proofOfAddress: [],
    id: [],
    accomodationCertificate: [],
    hostId: [],
    kbis: [],
    insee: [],
};

export type RequestDocument = {
    file: File[];
    type: DocumentType;
};

export type fileUpload = 'proofOfAddress' | 'id' | 'accomodationCertificate' | 'hostId' | 'kbis' | 'insee';

export const fileTypes = {
    proofOfAddress: DocumentType.PROOF_OF_ADDRESS,
    id: DocumentType.IDENTITY_DOCUMENT,
    accomodationCertificate: DocumentType.CERTIFICATE_OF_ACCOMODATION,
    hostId: DocumentType.HOST_IDENTITY_DOCUMENT,
    kbis: DocumentType.KBIS,
    insee: DocumentType.INSEE,
};
