import { getBundledProductsContactInfoEndpoint, BundledProductContactRecord } from '@cp-fr/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<BundledProductContactRecord[]>({
    contentName: 'bundledProductsContactInfo',
    contentEndpoint: getBundledProductsContactInfoEndpoint,
});

export default reducer;
export const fetchBundledProductsContactInfo = fetchContent;
