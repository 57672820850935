import * as Yup from 'yup';
import { PartialEarlySettlementValidatorErrors } from '../types';
import { PartialEarlySettlementError } from '../messages';

export type PartialEarlySettlementFormikInitialValues = {
    requestDate: string;
    amount: string;
    goal: string;
    reason: string;
};

export const partialEarlySettlementValidationSchema = (
    errors: PartialEarlySettlementValidatorErrors = PartialEarlySettlementError,
): Yup.SchemaOf<PartialEarlySettlementFormikInitialValues> =>
    Yup.object().shape({
        requestDate: Yup.string().trim().required(errors.requestDate.required),
        reason: Yup.string().trim().required(errors.reason.required),
        amount: Yup.string().trim().required(errors.amount.required),
        goal: Yup.string().trim().required(errors.goal.required),
    });
