import { getChangeNameEndpoint, getChangeNameSMEEndpoint, getMyProfileEndpoint } from '@cp-fr/common';
import { getStorageFileUploadEndpoint } from '@cp-shared-8/apis';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { ContractsMock } from 'components/contracts';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContactInformationChangeStatusMock } from './ContactInformationChangeStatusResponseMocks';
import {
    MyProfileRetailWithAllValues,
    MyProfileRetailWithMissingAddress,
    MyProfileRetailWithMissingContact,
    MyProfileRetailWithMissingIdentification,
    MyProfileRetailWithMissingValues,
    MyProfileRetailWithNoData,
    MyProfileSMEWithAllValues,
    MyProfileSMEWithMissingAddress,
    MyProfileSMEWithMissingContact,
    MyProfileSMEWithMissingIdentification,
    MyProfileSMEWithMissingValues,
    MyProfileSMEWithNoData,
    UploadFilesRequestResponse,
} from './examples/ExampleData';
import {
    MarkettingSettingsRetailMock,
    MarkettingSettingsSMEMock,
} from './marketing-settings-section/MarketingSettingsResponseMock';

const mockOptionsRetail: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileRetailWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: MyProfileRetailWithMissingValues,
    },
    'With missing identification': {
        status: 200,
        responseBody: MyProfileRetailWithMissingIdentification,
    },
    'With missing contact': {
        status: 200,
        responseBody: MyProfileRetailWithMissingContact,
    },
    'With missing address': {
        status: 200,
        responseBody: MyProfileRetailWithMissingAddress,
    },
    'With no data for all sections': {
        status: 200,
        responseBody: {},
    },
    'With all values missing': {
        status: 200,
        responseBody: MyProfileRetailWithNoData,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const mockOptionsSME: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileSMEWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: MyProfileSMEWithMissingValues,
    },
    'With missing identification': {
        status: 200,
        responseBody: MyProfileSMEWithMissingIdentification,
    },
    'With missing contact': {
        status: 200,
        responseBody: MyProfileSMEWithMissingContact,
    },
    'With missing address': {
        status: 200,
        responseBody: MyProfileSMEWithMissingAddress,
    },
    'With no data for all sections': {
        status: 200,
        responseBody: {},
    },
    'With all values missing': {
        status: 200,
        responseBody: MyProfileSMEWithNoData,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileRetailMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'MyProfile Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsRetail,
    url: getMyProfileEndpoint(),
};

export const MyProfileSMEMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'MyProfile Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsSME,
    url: getMyProfileEndpoint(),
};

const UploadFilesRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Upload Files Request',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: UploadFilesRequestResponse,
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: getStorageFileUploadEndpoint(),
};

const UploadFileToTemporalUrlMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Upload File to temporal URL',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: UploadFilesRequestResponse.temporalUrl,
};

const ChangeIdentificationRequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change retail identification request',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
            delay: 2000,
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: getChangeNameEndpoint(),
};

const ChangeIdentificationSMERequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Change sme identification request',
    method: 'put',
    defaultMockOption: 'Success',
    mockOptions: {
        Success: {
            status: 200,
            responseBody: {},
            delay: 2000,
        },
        'Business Error': DefaultBusinessMarketApiError,
    },
    url: getChangeNameSMEEndpoint(),
};

export const MyProfileResponseRetailMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileRetailMock, MarkettingSettingsRetailMock]);

    return <div>{storyFn()}</div>;
};

export const MyProfileResponseSMEMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileSMEMock, MarkettingSettingsSMEMock]);

    return <div>{storyFn()}</div>;
};

export const MyProfileWithContractsAndStatusChangeResponseRetailMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        MyProfileRetailMock,
        MarkettingSettingsRetailMock,
        ContractsMock,
        ContactInformationChangeStatusMock,
        UploadFilesRequestMock,
        UploadFileToTemporalUrlMock,
        ChangeIdentificationRequestMock,
    ]);

    return <div>{storyFn()}</div>;
};

export const MyProfileWithContractsAndStatusChangeResponseSMEMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        MyProfileSMEMock,
        MarkettingSettingsSMEMock,
        ContractsMock,
        ContactInformationChangeStatusMock,
        UploadFilesRequestMock,
        UploadFileToTemporalUrlMock,
        ChangeIdentificationSMERequestMock,
    ]);

    return <div>{storyFn()}</div>;
};
