export type InitialValues = {
    firstName: string;
    lastName: string;
    id: File[];
    proofOfIdentityChange: File[];
    registrationCertificate: File[];
    proofOfAddress: File[];
};

export const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    id: [],
    proofOfIdentityChange: [],
    registrationCertificate: [],
    proofOfAddress: [],
};
