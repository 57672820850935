import { CPDate } from '@cp-shared-8/common-utilities';

export type ParkingSessionDetails = {
    startDate: CPDate;
    endDate: CPDate;
    city: string;
    licensePlate: string;
    amount: number;
};

export type ParkingSessionSummary = {
    parkingSessionsCount: string;
    parkingSessionsAmount: string;
};

export type ParkingSessions = {
    phoneNumber: string;
    sessions: ParkingSessionDetails[];
    summary: ParkingSessionSummary;
};

export enum PbpCustomerIndexErrors {
    PBPID_ALREADY_EXISTS = 'PBPID_ALREADY_EXISTS',
    USER_ALREADY_LINKED = 'USER_ALREADY_LINKED',
}
