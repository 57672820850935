import { ValidatedSelect } from '@cp-shared-8/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    EmailInput,
    EmploymentStatusSelection,
    PhonenumberInput,
    TextArea,
    TextInput,
} from '../../simple-form-components';
import { SocioprofessionalCategorySelection } from '../../simple-form-components/socioprofessional-category-selection/SocioprofessionalCategorySelection';
import { DocumentsUpolad } from './DocumentsUpload';

export const RequestContractTransferForm: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.request-contract-transfer';
    const [requestReasonField] = useField('requestContractTransfer.requestReason');
    const [employmentStatusField] = useField('requestContractTransfer.employmentStatus');
    const [socioprofessionalCategoryField] = useField('requestContractTransfer.socioprofessionalCategory');

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const availableRequesReasons = [
        'Projet immobilier',
        'Changement de situation familiale',
        "Acquisition d'un modèle de véhicule plus adapté",
        'Difficultés financières',
        'Autre, précisez',
    ];

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate('description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'requestContractTransfer.email'} isMandatory={true} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber')}
                    name={'requestContractTransfer.phonenumber'}
                    isMandatory={false}
                />
            </Layout.Item>
            <Layout.Item>
                <TextInput
                    label={translate('buyer-names')}
                    name={'requestContractTransfer.buyerNames'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('buyer-phonenumber')}
                    name={'requestContractTransfer.buyerPhonenumber'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput
                    label={translate('buyer-email')}
                    name={'requestContractTransfer.buyerEmail'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <ValidatedSelect
                    isMandatory={true}
                    label={translate('request-reason')}
                    name={'requestContractTransfer.requestReason'}
                    selectItems={availableRequesReasons.map((reason) => ({
                        value: reason,
                        label: reason,
                    }))}
                    emptyByDefault
                />
            </Layout.Item>
            {requestReasonField.value === 'Autre, précisez' && (
                <Layout.Item>
                    <TextArea
                        label={''}
                        name={'requestContractTransfer.requestReasonDescription'}
                        isMandatory={false}
                    />
                </Layout.Item>
            )}
            <Layout.Item>
                <EmploymentStatusSelection
                    label={translate('employment-status')}
                    name={'requestContractTransfer.employmentStatus'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <SocioprofessionalCategorySelection
                    label={translate('socioprofessional-category')}
                    name={'requestContractTransfer.socioprofessionalCategory'}
                    isMandatory={true}
                />
            </Layout.Item>
            <DocumentsUpolad
                employmentStatus={employmentStatusField.value}
                socioprofessionalCategory={socioprofessionalCategoryField.value}
            />
        </>
    );
};
