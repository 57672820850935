import { Contract } from '@cp-fr/common';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { ContractDetails as TContractDetails } from '@cp-fr/common/src/apis/services/types/contracts';

export function useContracts(): AbstractDataState<
    Array<Contract & TContractDetails>,
    DefaultBusinessMarketApiErrorCode
> {
    return useGetSimpleApiData(fetchContracts, ContractsDataSelector);
}
