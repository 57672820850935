import { ParkingSessionDetails } from '@cp-fr/common';
import { Cell, createDynamicTable } from '@cp-shared-8/frontend-ui';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatOptions, generateRow, getColumnHeadings, sortOptions } from './utils';

type ParkingTableProps = {
    sessions: ParkingSessionDetails[];
};

const DynamicTable = createDynamicTable<ParkingSessionDetails>();

export const ParkingTable: React.FC<ParkingTableProps> = ({ sessions }) => {
    const { t } = useTranslation('parking');

    return (
        <Layout.Item>
            <Card element="article" title="">
                <DynamicTable
                    rows={sessions}
                    columnHeadings={getColumnHeadings(t)}
                    formatOptions={formatOptions}
                    columnSortOptions={sortOptions}
                    toColumnValues={(row: ParkingSessionDetails): Cell[] => generateRow(row)}
                />
            </Card>
        </Layout.Item>
    );
};
