import { preventSubmit, ValidatedInput, ValidatedSelect } from '@cp-shared-8/frontend-ui';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { PhoneInput } from 'components/request/form-view/category-selection/category-view/simple-form-components/phone-input/PhoneInput';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { contactDetailsCheckValidation } from './validationSchema';

export type ContactDetailsCheckFormProps = {
    initPhone?: string;
    initPrefix?: string;
    fsidMail: string;
    profileMail?: string;
    handleSubmit: (formValues: ContactDetailsCheckFormFields) => void;
};

export type ContactDetailsCheckFormFields = {
    mobilePhone: string;
    mobilePhonePrefix: string;
    emailSelect: string;
    email: string;
};

export const ContactDetailsCheckForm: React.FC<ContactDetailsCheckFormProps> = ({
    initPhone,
    initPrefix,
    handleSubmit,
    fsidMail,
    profileMail,
}) => {
    const { t } = useTranslation('contact-details-check');
    const isEmailSelect = profileMail && fsidMail !== profileMail;

    return (
        <Formik
            initialValues={{
                mobilePhone: initPhone || '',
                mobilePhonePrefix: initPrefix || '',
                email: fsidMail,
                emailSelect: fsidMail,
            }}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={contactDetailsCheckValidation(t)}
        >
            {(formik) => (
                <Form onSubmit={(e) => preventSubmit(e)}>
                    <Fieldset>
                        {isEmailSelect && (
                            <>
                                <Fieldset.Row>
                                    <ValidatedSelect
                                        tooltip={t('form.email.tooltip')}
                                        label={t('form.email.label')}
                                        name={'emailSelect'}
                                        selectItems={[
                                            ...[fsidMail, profileMail || ''].map((mail) => ({
                                                value: mail,
                                                label: mail,
                                            })),
                                            {
                                                value: 'OTHER',
                                                label: t('form.email.other-option'),
                                            },
                                        ]}
                                        testId={'emailSelect'}
                                        onChange={(value: string) => {
                                            formik.setFieldValue('email', value !== 'OTHER' ? value : '');
                                            formik.setFieldValue('emailSelect', value);
                                        }}
                                    />
                                </Fieldset.Row>
                                {formik.values.emailSelect === 'OTHER' && (
                                    <Fieldset.Row>
                                        <ValidatedInput label={''} name="email" testId="email" type="email" />
                                    </Fieldset.Row>
                                )}
                            </>
                        )}
                        {!profileMail && (
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={t('form.email.label')}
                                    tooltip={t('form.email.tooltip')}
                                    name="email"
                                    testId="email"
                                    type="email"
                                />
                            </Fieldset.Row>
                        )}

                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item>
                                    <PhoneInput
                                        phoneFieldLabel={t('form.mobile-phone.label')}
                                        phoneFieldName={'mobilePhone'}
                                        phoneFieldTestId={'mobilePhone'}
                                        phoneFieldTooltip={t('form.mobile-phone.tooltip')}
                                        phoneFieldPrefixLabel={t('form.mobile-phone.prefix-label')}
                                        phoneFieldPrefixName={'mobilePhonePrefix'}
                                        phoneFieldPrefixTestId={'mobilePhonePrefix'}
                                        setFormikFieldValue={formik.setFieldValue}
                                        hidePrefix={false}
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <Button
                                full={true}
                                onClick={() => formik.handleSubmit()}
                                testId="submit-button"
                                type="button"
                            >
                                {t('translation:editable-section-nav.continue')}
                            </Button>
                        </Fieldset.Row>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
