import { RequestStatus } from '@cp-fr/common';
import { ContractsLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContactInformationChangeStatus } from 'components/my-profile/useContactInformationChangeStatus';
import { useMyProfile } from '../my-profile/useMyProfile';
import React, { useEffect } from 'react';
import { ContractsUi } from './ui';
import { useContracts } from './useContracts';
import { useBundledProduct } from './contract-details/bundled-products/bundled-product/useBundledProduct';
import { useDispatch } from 'react-redux';
import { paymentDayStatusTagFetchStateActions } from './contract-details/financial-details/collection-date-section/non-edit-view/usePaymentDayStatusTagSlice';
import { ibanStatusTagFetchStateActions } from './contract-details/financial-details/iban-section/consult-view/useIbanStatusTagSlice';

export const Contracts: React.FC = () => {
    const { data: contracts, isLoading, loadingError } = useContracts();
    const { isLoading: isLoadingBundledProducts } = useBundledProduct();
    const {
        data: contactInformationChange,
        isLoading: isLoadingContactInformationChange,
        loadingError: loadingContactInformationChangeError,
    } = useContactInformationChangeStatus();
    const { data: myProfile, isLoading: isLoadingProfile } = useMyProfile();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paymentDayStatusTagFetchStateActions.setDataValidity(false));
        dispatch(ibanStatusTagFetchStateActions.setDataValidity(false));
    }, [dispatch]);

    const ContractsWithHandlers = withLoadingAndNoConnectionHandler(ContractsUi);

    return (
        <ContractsWithHandlers
            isLoading={isLoading || isLoadingContactInformationChange || isLoadingProfile || isLoadingBundledProducts}
            contracts={contracts}
            myProfile={myProfile}
            hasError={!!loadingError || !!loadingContactInformationChangeError}
            isContactInformationChangePending={
                contactInformationChange?.requestCustomerStatus === RequestStatus.IN_PROGRESS
            }
            loadingPlaceholder={<ContractsLoadingPlaceholder numberOfContracts={1} />}
        />
    );
};
