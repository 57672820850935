import {
    Contract,
    MyProfileData,
    EarlyReturnPenaltyRequest,
    getEarlyReturnPenaltyEndpoint,
    frontEndEarlyReturnPenaltyValidationSchema,
} from '@cp-fr/common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Card, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';
import { Form, Formik } from 'formik';
import { CleaveInput, ContractHeader, Spinner } from '@cp-shared-8/frontend-ui';
import { getInitialValues, EarlyReturnPenaltyFormValues } from './initialValues';
import { LicensePlate } from 'components/license-plate/LicensePlate';
import { CpDataApi } from 'cp-xhr';
import { AvailableDateSelector } from './AvailableDateSelector';
import { EarlyReturnPenaltyModal } from './EarlyReturnPenaltyModal';
import { ContractDetails as TContractDetails } from '@cp-fr/common/src/apis/services/types/contracts';

type RequestProps = {
    status?: 'error' | 'success' | 'inProgress';
};

const USER_WITH_NO_EMAIL_CODE = 'USER_WITH_NO_EMAIL';

export const EarlyReturnPenaltyUi: React.FC<{
    contract?: Contract & TContractDetails;
    myProfile?: MyProfileData;
}> = ({ contract, myProfile }) => {
    const { t } = useTranslation('early-return-penalty');
    const history = useHistory();
    const [requestProgress, setRequestProgress] = useState<RequestProps>({});
    const [modalCustomTextKey, setModalCustomTextKey] = useState<string | null>(null);

    if (!contract || !myProfile) return <NoConnectionNotification />;

    const {
        contractNumber,
        productType = '',
        carModel = '',
        licensePlate = '',
        contractEndDate,
        contractStartDate,
    } = contract;
    const customerNumber = myProfile.identification?.data.customerNumber;

    if (!contractNumber || !customerNumber || !contractEndDate || !contractStartDate)
        return <NoConnectionNotification />;

    const errorMessages = {
        requestDate: {
            required: t('form.validation.date-empty'),
            min: t('form.validation.date-in-future'),
            deliveryDatePlusNineMonths: t('form.validation.delivery-date-plus-nine-months'),
            afterContractEnds: t('form.validation.after-contract-ends'),
        },
        estimatedMileage: {
            required: t('form.validation.mileage-empty'),
            positive: t('form.validation.mileage-empty'),
        },
    };

    const handleSubmit = async (data: EarlyReturnPenaltyFormValues) => {
        setRequestProgress({ status: 'inProgress' });

        const body: EarlyReturnPenaltyRequest = {
            ...data,
            estimatedMileage: Number(data.estimatedMileage),
            customerNumber: customerNumber,
            contractNumber: contractNumber,
            contractEndDate: contractEndDate,
            contractStartDate: contractStartDate,
        };

        await CpDataApi.post(getEarlyReturnPenaltyEndpoint(), body)
            .then(() => setRequestProgress({ status: 'success' }))
            .catch((error) => {
                if (error.response.data.code === USER_WITH_NO_EMAIL_CODE) {
                    setModalCustomTextKey('missingEmail');
                }
                setRequestProgress({ status: 'error' });
            });
    };

    return (
        <>
            {requestProgress.status === 'inProgress' && <Spinner fullPage={true} />}
            <Layout>
                <Layout.Item default="1/1">
                    <Card element="article" title="">
                        <ContractHeader
                            carInformation={carModel}
                            contractCategory={productType}
                            contractNumber={contractNumber}
                            contractIdentifier={<LicensePlate registrationNumber={licensePlate} />}
                        />
                    </Card>
                </Layout.Item>
                <Layout.Item default="1/1">{t('info')}</Layout.Item>
                <Layout.Item default="1/1">
                    <Formik
                        initialValues={getInitialValues()}
                        onSubmit={(data) => handleSubmit(data)}
                        validationSchema={frontEndEarlyReturnPenaltyValidationSchema(errorMessages)}
                    >
                        {(formik) => (
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item>
                                                <AvailableDateSelector contract={contract} />
                                            </Layout.Item>
                                            <Layout.Item>
                                                <CleaveInput
                                                    cleaveOptions={{
                                                        numeral: true,
                                                        numeralDecimalMark: ',',
                                                        delimiter: '',
                                                        numeralThousandsGroupStyle: 'thousand',
                                                        numericOnly: true,
                                                        numeralDecimalScale: 2,
                                                    }}
                                                    inputMode={'numeric'}
                                                    label={t('form.estimated-mileage.label')}
                                                    name={'estimatedMileage'}
                                                    testId={'estimated-mileage'}
                                                    placeholder={t('form.estimated-mileage.placeholder')}
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer className={'u-mt'} center>
                                            <Button
                                                secondary
                                                onClick={(): void => history.push(dashboardPagePath())}
                                                testId="backButton"
                                            >
                                                {t('form.button.cancel')}
                                            </Button>
                                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                            <Button onClick={formik.handleSubmit as any} testId="sendButton">
                                                {t('form.button.send')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </Layout.Item>
            </Layout>
            <EarlyReturnPenaltyModal
                status={requestProgress.status}
                onModalClose={() => setRequestProgress({})}
                textKey={modalCustomTextKey}
            />
        </>
    );
};
