import * as Yup from 'yup';
import { AdressSectionFileUploadValidatorErrors } from '../../types/myProfileTypes';
import { AdressSectionFileUploadError } from '../../messages/myProfileMessages';

export type AdressSectionFileUploadFrontendTypes = {
    proofOfAddress?: string[] | undefined;
    id?: string[] | undefined;
    accomodationCertificate?: string[] | undefined;
    hostId?: string[] | undefined;
};

export type AdressSectionFileUploadSmeFrontendTypes = {
    kbis?: string[] | undefined;
    insee?: string[] | undefined;
};

const isAtLeastOneForPrivate = (
    files: AdressSectionFileUploadFrontendTypes,
    testContext: Yup.TestContext,
    errors: string = AdressSectionFileUploadError.atLeastOne,
): Yup.ValidationError | boolean => {
    return files.proofOfAddress?.length === 0 &&
        files.id?.length === 0 &&
        files.accomodationCertificate?.length === 0 &&
        files.hostId?.length === 0
        ? testContext.createError({
              message: errors,
              path: 'proofOfAddress',
          })
        : true;
};

const isAtLeastOneForSME = (
    files: AdressSectionFileUploadSmeFrontendTypes,
    testContext: Yup.TestContext,
    errors: string = AdressSectionFileUploadError.atLeastOne,
): Yup.ValidationError | boolean => {
    return files?.kbis?.length === 0 && files?.insee?.length === 0
        ? testContext.createError({
              message: errors,
              path: 'kbis',
          })
        : true;
};

export const adressSectionFileUploadFrontendSchema = (
    typeOfCustomer: string,
    isBillingAddress: boolean,
    errors: AdressSectionFileUploadValidatorErrors = AdressSectionFileUploadError,
): Yup.SchemaOf<AdressSectionFileUploadFrontendTypes | AdressSectionFileUploadSmeFrontendTypes> | undefined => {
    if (typeOfCustomer === 'private') {
        return Yup.object({
            proofOfAddress: Yup.array().test('is-optional', '', function () {
                return isAtLeastOneForPrivate(this.parent, this, errors.proofOfAddress.required);
            }),
            id: Yup.array().test('is-optional', '', function () {
                return isAtLeastOneForPrivate(this.parent, this, errors.id.required);
            }),
            accomodationCertificate: Yup.array().test('is-optional', '', function () {
                return isAtLeastOneForPrivate(this.parent, this, errors.accomodationCertificate.required);
            }),
            hostId: Yup.array().test('is-optional', '', function () {
                return isAtLeastOneForPrivate(this.parent, this, errors.hostId.required);
            }),
        });
    }
    if (typeOfCustomer === 'SME') {
        return isBillingAddress
            ? Yup.object({
                  kbis: Yup.array().test('is-optional', '', function () {
                      return isAtLeastOneForSME(this.parent, this, errors.kbis.required);
                  }),
                  insee: Yup.array().test('is-optional', '', function () {
                      return isAtLeastOneForSME(this.parent, this, errors.insee.required);
                  }),
              })
            : Yup.object({
                  kbis: Yup.array().test('is-optional', '', function () {
                      return isAtLeastOneForSME(this.parent, this, errors.kbis.required);
                  }),
                  insee: Yup.array().test('is-optional', '', function () {
                      return isAtLeastOneForSME(this.parent, this, errors.insee.required);
                  }),
              });
    }
    return;
};
