// Based on https://www.iban.com/structure
const ibanLengths: Record<string, number> = {
    FR: 27,
    DE: 22,
    AT: 20,
    AD: 24,
    BE: 16,
    ES: 24,
    GR: 27,
    IE: 22,
    IT: 27,
    LU: 20,
    NL: 18,
    PT: 25,
    GB: 22,
    SM: 27,
    SI: 19,
    CH: 21,
    MC: 27,
};
export default ibanLengths;
