import * as Yup from 'yup';
import { TotalEarlySettlementValidatorErrors } from '../types';
import { TotalEarlySettlementError } from '../messages';

export type TotalEarlySettlementFormikInitialValues = {
    requestDate: string;
    reason: string;
};

export const totalEarlySettlementValidationSchema = (
    errors: TotalEarlySettlementValidatorErrors = TotalEarlySettlementError,
): Yup.SchemaOf<TotalEarlySettlementFormikInitialValues> =>
    Yup.object().shape({
        requestDate: Yup.string().trim().required(errors.requestDate.required),
        reason: Yup.string().trim().required(errors.reason.required),
    });
