import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../../simple-form-components';

export const BalloonUpload: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.vehicle-claim.files.balloon';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            <Layout.Item>
                <FileUpload
                    name={'vehicleClaim.nonReparableFields.files.expertReport'}
                    label={translate('file-one')}
                    isMandatory
                />
            </Layout.Item>
        </>
    );
};
