import { getPaymentDayStatusEndpoint } from '@cp-fr/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CpDataApi } from '../../../../../../cp-xhr';
import { RootState } from '../../../../../../store/rootReducer';
import { paymentDayStatusTagFetchStateActions } from './usePaymentDayStatusTagSlice';

let _inProgress = false;

export const usePaymentDayStatusTag = () => {
    const dataValid = useSelector((state: RootState) => state.paymentDayStatusTagFetchState.dataValid);
    const inProgress = useSelector((state: RootState) => state.paymentDayStatusTagFetchState.inProgress);
    const [isPaymentDayStatusTag, setIsPaymentDayStatusTag] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (_inProgress || inProgress || dataValid) return;

        dispatch(paymentDayStatusTagFetchStateActions.setFetchProgress(true));
        _inProgress = true;
        dispatch(paymentDayStatusTagFetchStateActions.setDataValidity(true));

        CpDataApi.get(getPaymentDayStatusEndpoint())
            .then((response) => {
                if (response.data.requestCustomerStatus === 'IN_PROGRESS') {
                    setIsPaymentDayStatusTag(true);
                }
            })
            .catch(() => {
                setIsPaymentDayStatusTag(false);
            })
            .finally(() => {
                dispatch(paymentDayStatusTagFetchStateActions.setFetchProgress(false));
                _inProgress = false;
            });
    }, [dispatch, dataValid, inProgress]);

    return isPaymentDayStatusTag;
};
