export enum MarketError {
    NOT_FOUND = 'NOT_FOUND',
    CHECK_FAILURE = 'CHECK_FAILURE',
    NOT_ALLOWED_DOCUMENT_TYPE = 'NOT_ALLOWED_DOCUMENT_TYPE',
    UNDEFINED_POSTAL_ADDRESS = 'UNDEFINED_POSTAL_ADDRESS',
    WRONG_ADDRESS_INFORMATION = 'WRONG_ADDRESS_INFORMATION',
    PROOF_OF_ADDRESS_MANDATORY = 'PROOF_OF_ADDRESS_MANDATORY',
    CASE_MANAGEMENT_FAILED = 'CASE_MANAGEMENT_FAILED',
    NO_CHANGE_DETECTED = 'NO_CHANGE_DETECTED',
    WRONG_REASON = 'WRONG_REASON',
    MARKET_API_NOT_REACHABLE = 'MARKET_API_NOT_REACHABLE',
    VIRUS = 'VIRUS',
    SIREN_IS_MANDATORY_FOR_SME = 'SIREN_IS_MANDATORY_FOR_SME',
    LACK_OF_EMAIL = "should have required property 'customerMail'",
    ERROR_NO_EMAIL = 'USER_WITH_NO_EMAIL',
}
