import React, { useEffect, useMemo, useState } from 'react';
import { Button, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ComboboxOption } from 'types/bronson-react';
import { Contract, MyDocumentsData } from '@cp-fr/common';
import { useDispatch } from 'react-redux';
import { DocumentFilters, documentFiltersStateActions } from './DocumentFiltersSlice';
import { SelectField } from './SelectField';
import { useFormikContext } from 'formik';
import { ContractSelect } from './ContractSelect';

const DocumentFilter: ComboboxOption[] = [
    { key: 'all', value: 'Tous' },
    { key: 'Contrat', value: 'Contrat' },
    { key: 'Courrier', value: 'Courrier' },
    { key: 'Facture', value: 'Facture' },
    { key: 'Fiscalité', value: 'Fiscalité' },
    { key: 'Restitution', value: 'Restitution' },
    { key: 'Véhicule', value: 'Véhicule' },
];

const TimePeriodFilter: ComboboxOption[] = [
    { key: 'all', value: 'Tous' },
    { key: 'last3Months', value: '3 derniers mois' },
    { key: 'last6Months', value: '6 derniers mois' },
    { key: 'last12Months', value: '12 derniers mois' },
    { key: 'last2Years', value: '2 dernières années' },
    { key: 'moreThan2Years', value: 'Supérieur à 2 ans' },
];

export const FiltersSection: React.FC<{ documents?: MyDocumentsData[]; contracts?: Contract[] }> = ({
    documents,
    contracts,
}) => {
    const { values, setFieldValue } = useFormikContext<DocumentFilters>();
    const [clearing, setClearing] = useState(false);
    const { t } = useTranslation('my-documents');
    const dispatch = useDispatch();
    const ContractFilter: ComboboxOption[] = [{ key: 'all', value: 'Tous' }];
    documents?.forEach((doc) => {
        if (!ContractFilter.find((o) => o.key === doc.contractNumber)) {
            ContractFilter.push({ key: doc.contractNumber, value: doc.contractNumber });
        }
    });

    const clearAllFilters = () => {
        // Combobox component does not support setting value method. Clearing state is used to unmount and mount this component again with proper value.
        setClearing(true);
        setFieldValue('documentTypeFilter', 'all');
        setFieldValue('contractFilter', 'all');
        setFieldValue('timePeriodFilter', 'all');
        dispatch(documentFiltersStateActions.clearFilters());
    };

    useEffect(() => {
        dispatch(documentFiltersStateActions.setDocumentTypeFilter(values.documentTypeFilter));
        dispatch(documentFiltersStateActions.setIdDocumentFilter(values.contractFilter));
        dispatch(documentFiltersStateActions.setTimePeriodFilter(values.timePeriodFilter));
        setClearing(false);
    }, [values]);

    const isClearFilterDisabled = useMemo(
        () =>
            values.documentTypeFilter === 'all' && values.contractFilter === 'all' && values.timePeriodFilter === 'all',
        [values],
    );

    return (
        <>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/4" s="1/1">
                            <SelectField
                                testId={'document-type-filter'}
                                name={'documentTypeFilter'}
                                options={DocumentFilter}
                                label={t('filters.document-type-filter-header')}
                                visible={!clearing}
                            />
                        </Layout.Item>
                        <Layout.Item default="1/4" s="1/1">
                            <ContractSelect
                                testId={'contract-filter'}
                                name={'contractFilter'}
                                options={ContractFilter}
                                visible={!clearing}
                                label={t('filters.contract-filter-header')}
                                contracts={contracts}
                            />
                        </Layout.Item>
                        <Layout.Item default="1/4" s="1/1">
                            <SelectField
                                testId={'time-period-filter'}
                                name={'timePeriodFilter'}
                                options={TimePeriodFilter}
                                visible={!clearing}
                                label={t('filters.time-period-filter-header')}
                            />
                        </Layout.Item>
                        <Layout.Item default="1/4" s="1/1">
                            <Button
                                type="button"
                                className="u-mt"
                                secondary
                                onClick={clearAllFilters}
                                disabled={isClearFilterDisabled}
                            >
                                {t('clear-all')}
                            </Button>
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
};
