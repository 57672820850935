import { HeroImage, Faq as FaqShared, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { SimpleTeaser } from '@cp-fr/common';
import { FaqCMS } from '@cp-shared-8/apis';

const Teaser: React.FC<{ teaser?: SimpleTeaser }> = ({ teaser }) =>
    teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

const Content: React.FC<{ content?: FaqCMS }> = ({ content }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    return content ? (
        <ContentSection pageWrapSize="medium">
            <FaqShared
                header={content.header}
                descriptionText={content.descriptionText}
                items={content.items}
                itemGroups={content.itemGroups}
                onChange={handleOnChange}
            />
        </ContentSection>
    ) : null;
};

export const FaqUi: React.FC<{ faqTeaser?: SimpleTeaser; faqContent?: FaqCMS }> = ({ faqTeaser, faqContent }) =>
    faqContent ? (
        <>
            <Teaser teaser={faqTeaser} />
            <Content content={faqContent} />
        </>
    ) : null;
