import { Identification } from '@cp-fr/common';
import { ValidatedSelect } from '@cp-shared-8/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    BalanceReasonSelection,
    EmailInput,
    PhonenumberInput,
    TextArea,
    TextInput,
} from '../../simple-form-components';
import { monthNames } from '../../../../../../../utils/monthNames';

export type AuthorizationDebitFormProps = {
    identification?: Identification;
};

export const AuthorizationDebitForm: React.FC<AuthorizationDebitFormProps> = ({ identification }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.authorization-debit';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const isNameSurnameDisabled = !!identification?.data.name && !!identification.data.surname;

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate('description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'authorizationDebit.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput label={translate('phonenumber')} name={'authorizationDebit.phonenumber'} />
            </Layout.Item>
            <Layout.Item>
                <TextInput
                    label={translate('name-surname')}
                    name={'authorizationDebit.nameSurname'}
                    disabled={isNameSurnameDisabled}
                />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('authorizes')} name={'authorizationDebit.authorizes'} disabled />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('amount')} name={'authorizationDebit.amount'} />
            </Layout.Item>
            <Layout.Item>
                <ValidatedSelect
                    name={'authorizationDebit.expirationMonth'}
                    label={translate('expiration-date')}
                    selectItems={monthNames.map((month) => ({
                        value: month,
                        label: month,
                    }))}
                />
            </Layout.Item>
            <Layout.Item>
                <BalanceReasonSelection label={translate('balance-reason')} name={'authorizationDebit.balanceReason'} />
            </Layout.Item>
            <Layout.Item>
                <TextArea
                    label={translate('other-balance-reason')}
                    name={'authorizationDebit.otherBalanceReason'}
                    isMandatory={false}
                />
            </Layout.Item>
        </>
    );
};
