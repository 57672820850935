import { RequestCustomerStatus, getNameStatusEndpoint } from '@cp-fr/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    RequestCustomerStatus,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'NameChangeStatus',
    fetchCallback() {
        return CpDataApi.get<RequestCustomerStatus>(getNameStatusEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchNameChangeStatus = fetchData;
export const updateNameChangeStatus = updateData;
