import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { Contract, ContractDetails } from '@cp-fr/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

type ContractTransformer = (contracts?: Array<Contract & ContractDetails>) => (Contract & ContractDetails) | undefined;

function getFilterForContractId(contractId: string): ContractTransformer {
    return (contracts) =>
        contracts ? contracts.filter((contract) => contract.contractId === contractId)[0] : undefined;
}
/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useContract(
    contractId: string,
): AbstractDataState<(Contract & ContractDetails) | undefined, DefaultBusinessMarketApiErrorCode> {
    const contractFilter = useMemo(() => getFilterForContractId(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
