import { RequestInitialValues } from '../../../../initialValues';
import { LossRequest, NatureOfClaim } from '@cp-fr/common';

export const lossMapper = ({ contractsSelection, loss }: RequestInitialValues): LossRequest => {
    return {
        requestCategory: 'LOSS',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: loss.email,
        phonenumber: loss.phonenumber,
        natureOfClaim: loss.natureOfClaim as NatureOfClaim,
        startDate: loss.startDate,
    };
};
