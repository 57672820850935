import { formatAsCurrency, ParkingSessionDetails } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { Cell, DynamicTableFormatOptions, DynamicTableSortOptions, TableHeaderEntry } from '@cp-shared-8/frontend-ui';
import { TFunction } from 'i18next';

export const formatOptions: DynamicTableFormatOptions = {
    auto: false,
    noScroll: false,
    colored: false,
    bordered: false,
    highlight: false,
    wide: true,
    narrow: false,
};

export const getColumnHeadings = (t: TFunction): TableHeaderEntry[] => {
    return [
        t('session-headings.start-date'),
        t('session-headings.end-date'),
        t('session-headings.city'),
        t('session-headings.license-plate'),
        t('session-headings.amount'),
    ];
};

export const generateRow = (row: ParkingSessionDetails): Cell[] => {
    return [
        { value: formatCpDate(row.startDate).format('YYYY-MM-DD HH:MM'), nowrap: true, hiddenForMobile: false },
        { value: formatCpDate(row.endDate).format('YYYY-MM-DD HH:MM'), nowrap: true, hiddenForMobile: false },
        { value: row.city, nowrap: true, hiddenForMobile: false },
        { value: row.licensePlate, nowrap: true, hiddenForMobile: false },
        { value: formatAsCurrency(row.amount), nowrap: true, hiddenForMobile: false },
    ];
};

export const sortOptions: DynamicTableSortOptions<ParkingSessionDetails>[] = [
    {
        compare: (row1: ParkingSessionDetails, row2: ParkingSessionDetails) =>
            row2.startDate.localeCompare(row1.startDate),
        reversed: true,
    },
    {
        compare: (row1: ParkingSessionDetails, row2: ParkingSessionDetails) => row2.endDate.localeCompare(row1.endDate),
        reversed: true,
    },
    {
        compare: (row1: ParkingSessionDetails, row2: ParkingSessionDetails) => row1.city.localeCompare(row2.city),
        reversed: true,
    },
    {
        compare: (row1: ParkingSessionDetails, row2: ParkingSessionDetails) =>
            row1.licensePlate.localeCompare(row2.licensePlate),
        reversed: true,
    },
    {
        compare: (row1: ParkingSessionDetails, row2: ParkingSessionDetails) => row2.amount - row1.amount,
        reversed: true,
    },
];
