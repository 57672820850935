import { RequestDocumentsValidatorErrors } from '../../types';

export const RequestDocumentsError: RequestDocumentsValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    documentType: {
        required: 'documentType is required',
    },
    invoiceMonth: {
        required: 'invoiceMonth is required',
    },
    invoiceYear: {
        required: 'invoiceYear is required',
    },
    desiredYear: {
        required: 'desiredYear is required',
        invalidYear: 'year must be 2000 or above',
    },
    desiredTaxStatus: {
        required: 'desiredTaxStatus is required',
    },
    periodStartDate: {
        required: 'periodStartDate is required',
    },
    periodEndDate: {
        required: 'periodEndDate is required',
    },
    exitDate: {
        required: 'periodEndDate is required',
    },
    returnDate: {
        required: 'periodEndDate is required',
    },
    countryOfDestination: {
        required: 'periodEndDate is required',
    },
    reason: {
        required: 'periodEndDate is required',
    },
};
