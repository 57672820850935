import { useGetSimpleUpdateData } from '@cp-shared-8/frontend-integration';
import { Contract } from '@cp-fr/common';
import { updateContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { ContractDetails as TContractDetails } from '@cp-fr/common/src/apis/services/types/contracts';

type UpdateContractsType = {
    updateContracts: (data: Array<Contract & TContractDetails>) => void;
    contractsData: Array<Contract & TContractDetails>;
};

export function useUpdateContractsAll(): UpdateContractsType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateContracts, ContractsDataSelector);
    return {
        updateContracts: updateData,
        contractsData: data as Array<Contract & TContractDetails>,
    };
}
