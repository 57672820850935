import { ParkingSessions } from '@cp-fr/common';
import React, { useState } from 'react';
import { Heading, Layout, Button, Modal } from '@vwfs-bronson/bronson-react';
import { ParkingInfo } from '../parking-info/ParkingInfo';
import { ParkingTable } from '../parking-table/ParkingTable';
import { Notification, NotificationStatus, Spinner } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from 'cp-xhr';
import { CustomerIndexIdentifierType, deleteCustomerIndexItemEndpoint } from '@cp-shared-8/apis';
import { parkingPagePath } from '../../../navigation/paths';

type ParkingSessionsUiProps = {
    parkingSessions?: ParkingSessions;
};

export const ParkingSessionsUi: React.FC<ParkingSessionsUiProps> = ({ parkingSessions }) => {
    const { t } = useTranslation('parking');
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [unlinkingError, setUnlinkingError] = useState<boolean>(false);

    if (!parkingSessions) {
        return null;
    }

    const handleOnConfirm = (): void => {
        setIsLoading(true);
        CpDataApi.delete(deleteCustomerIndexItemEndpoint(CustomerIndexIdentifierType.PAYBYPHONEID))
            .then(() => {
                localStorage.setItem('isUnlinkingSuccessful', 'true');
                setUnlinkingError(false);
                window.location.assign(parkingPagePath());
            })
            .catch(() => {
                localStorage.setItem('isUnlinkingSuccessful', '');
                setUnlinkingError(true);
                setIsLoading(false);
            });
        setModalOpen(false);
    };

    const handleOnCancel = (): void => {
        setModalOpen(false);
    };

    const { phoneNumber, summary, sessions } = parkingSessions;

    return (
        <>
            {isLoading ? (
                <Spinner center />
            ) : (
                <>
                    <Heading level={1} className="c-data-overview__header u-pt">
                        {t('headline')}
                        <Button
                            element="a"
                            link
                            small
                            className=" u-mb-none u-mt-none u-mr-small"
                            icon="control-logout"
                            onClick={() => setModalOpen(true)}
                            simple
                            testId={'unlink-button'}
                        >
                            {t('unlink-button')}
                        </Button>
                    </Heading>

                    <Modal
                        shown={isModalOpen}
                        title={t('unlink-modal.title')}
                        buttonCancelText={t('unlink-modal.cancel-button')}
                        buttonConfirmText={t('unlink-modal.confirm-button')}
                        left
                        onCancel={handleOnCancel}
                        onClose={handleOnCancel}
                        onConfirm={handleOnConfirm}
                        testId={'unlink-modal'}
                    >
                        {t('unlink-modal.text')}
                    </Modal>
                    <Layout>
                        {unlinkingError && (
                            <Layout.Item default="1/1" s="1/1">
                                <Notification
                                    status={NotificationStatus.error}
                                    testId={'error-unlinking-notification'}
                                    text={t('error-unlinking-notification')}
                                />
                            </Layout.Item>
                        )}

                        {parkingSessions.sessions.length === 0 ? (
                            <Notification
                                status={NotificationStatus.warning}
                                text={t('not-found')}
                                className={'u-mb u-mt-large'}
                                testId={`parking-notification`}
                            />
                        ) : (
                            <>
                                <ParkingInfo phoneNumber={phoneNumber} summary={summary} />
                                <ParkingTable sessions={sessions} />
                            </>
                        )}
                    </Layout>
                </>
            )}
        </>
    );
};
