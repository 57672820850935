import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { Civility } from '@cp-fr/common';

type CivilitySelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const CivilitySelection: React.FC<CivilitySelectionProps> = ({ label, name, isMandatory = true }) => {
    const civilities: Civility[] = ['Madame', 'Monsieur'];

    const selectItems: ValidatedSelectItem[] = civilities.map((civility: Civility) => {
        return { value: civility, label: civility };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
