import { ContractParty } from '@cp-fr/common';
import { AccordionItem, DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { emptyObjectOrAllValuesAreUndefined } from '../../../../utils';
import { ErrorNotification } from '../../../notifications/error/ErrorNotification';

type ContractPartiesProps = {
    contractParties?: ContractParty[];
};

const DEALER_ROLE = 'Concessionnaire';

export const ContractPartiesComponent: React.FC<ContractPartiesProps> = ({ contractParties }) => {
    const { t } = useTranslation('contract-parties');
    const { t: tP } = useTranslation();

    if (!contractParties) return null;

    const noValuePlaceholder = tP('no-value-placeholder');

    const isDealer = (role?: string): boolean => role === DEALER_ROLE;

    const itemLists: DefinitionListItem[][] = contractParties.map(({ name = '', surname = '', role, address }) =>
        [
            {
                label: t('name'),
                value: name || surname ? `${name} ${surname}`.trim() : noValuePlaceholder,
                testId: 'contract-parties-name',
            },
            {
                label: t('street'),
                value: isDealer(role) ? address?.street || noValuePlaceholder : undefined,
                testId: 'contract-parties-street',
            },
            {
                label: t('postal-code'),
                value: isDealer(role) ? address?.postalCode || noValuePlaceholder : undefined,
                testId: 'contract-parties-postal-code',
            },
            {
                label: t('city'),
                value: isDealer(role) ? address?.city || noValuePlaceholder : undefined,
                testId: 'contract-parties-city',
            },
            {
                label: t('role'),
                value: role || noValuePlaceholder,
                testId: 'contract-parties-role',
            },
        ].filter(({ value }) => !!value),
    );

    const isError = contractParties.every((contractParty) => emptyObjectOrAllValuesAreUndefined(contractParty));

    return (
        <AccordionItem title={t('title')}>
            {isError ? (
                <ErrorNotification testId={'not-found-error'} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <div>
                    {itemLists.map((itemList, index) => (
                        <div key={`contract-party-${index}`}>
                            {!!index && <Hr className={'u-mv'} />}
                            <DefinitionList split={true} list={itemList} />
                        </div>
                    ))}
                </div>
            )}
        </AccordionItem>
    );
};
