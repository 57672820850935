import { useTranslation } from 'react-i18next';
import React, { Dispatch, useEffect, useState } from 'react';
import { FileUpload, ValidatedInput } from '@cp-shared-8/frontend-ui';
import { Fieldset, FormFieldLabel, Layout, RadioButton, RadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { FormikProps, useFormikContext } from 'formik';
import { maxFileSize } from 'config';
import { formatAsFileSize } from '@cp-fr/common';
import { CustomerType, getFormCustomerType, CustomerDataFormFields } from './utils';

export const ProfessionalsFields: React.FC<{ setRequiredFiles: Dispatch<React.SetStateAction<boolean[]>> }> = ({
    setRequiredFiles,
}) => {
    const { t } = useTranslation(['confirm-information', 'my-profile']);
    const fileUploadTranslationPrefix = 'identification.edit-view.file-upload';
    const formik: FormikProps<CustomerDataFormFields> = useFormikContext();
    const [customerType, setCustomerType] = useState<CustomerType>('');

    const [initial] = useState(formik.initialValues.professional);

    useEffect(() => {
        setCustomerType(getFormCustomerType(formik.initialValues.identification.customerType));

        setRequiredFiles((files) => {
            const updated = [...files];
            updated[2] =
                customerType !== 'PM' || formik.values.professional.activityChanged !== initial.activityChanged;
            updated[3] = !!formik.values.professional.authorizationChanged;
            updated[4] =
                customerType === 'SME' && formik.values.professional.activityChanged !== initial.activityChanged;
            return updated;
        });
    }, [initial, formik.values]);

    return (
        <>
            <Fieldset>
                {customerType === 'Retail' && (
                    <>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item default="1/2" s="1/1">
                                    <FormFieldLabel>{t('proffession-changed')}</FormFieldLabel>
                                    <RadioButtonGroup>
                                        <RadioButton
                                            name="professional.employerChanged"
                                            checked={formik.values.professional.employerChanged}
                                            onChange={() => formik.setFieldValue('professional.employerChanged', true)}
                                        >
                                            {t('yes')}
                                        </RadioButton>
                                        <RadioButton
                                            name="professional.employerChanged"
                                            checked={!formik.values.professional.employerChanged}
                                            onChange={() => formik.setFieldValue('professional.employerChanged', false)}
                                        >
                                            {t('no')}
                                        </RadioButton>
                                    </RadioButtonGroup>
                                </Layout.Item>
                                <Layout.Item default="1/2" s="1/1">
                                    <ValidatedInput
                                        label={t('form.employer')}
                                        name="professional.employer"
                                        testId="employer"
                                        type="text"
                                        disabled={!formik.values.professional.employerChanged}
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                    </>
                )}

                {customerType !== 'Retail' && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/2" s="1/1">
                                <FormFieldLabel>{t('activity-changed')}</FormFieldLabel>
                                <RadioButtonGroup>
                                    <RadioButton
                                        name="professional.activityChanged"
                                        checked={formik.values.professional.activityChanged}
                                        onChange={() => formik.setFieldValue('professional.activityChanged', true)}
                                    >
                                        {t('yes')}
                                    </RadioButton>
                                    <RadioButton
                                        name="professional.activityChanged"
                                        checked={!formik.values.professional.activityChanged}
                                        onChange={() => formik.setFieldValue('professional.activityChanged', false)}
                                    >
                                        {t('no')}
                                    </RadioButton>
                                </RadioButtonGroup>
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
                {customerType === 'SME' && formik.values.professional.activityChanged !== initial.activityChanged && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item>
                                <FileUpload
                                    name="proofOfActivityChange"
                                    descriptionText={t('confirm-information:kbis')}
                                    buttonText={t(`my-profile:${fileUploadTranslationPrefix}.button`)}
                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                    fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                    maxFileSize={maxFileSize}
                                    multiple={false}
                                    validFileFormats={['pdf']}
                                    sizeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                    typeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                    sizeAndTypeError={
                                        <>
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                            <br />
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                        </>
                                    }
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}

                {customerType !== 'PM' && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/2" s="1/1">
                                <FormFieldLabel>{t('confirm-information:income-changed')}</FormFieldLabel>
                                <ValidatedInput
                                    label={t('form.income')}
                                    name="professional.income"
                                    testId="income"
                                    type="text"
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
                {(customerType !== 'PM' || formik.values.professional.activityChanged !== initial.activityChanged) && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item>
                                <FileUpload
                                    name="proofOfProfessionChange"
                                    descriptionText={
                                        customerType === 'Retail'
                                            ? t(`my-profile:${fileUploadTranslationPrefix}.proof-of-proffesion-change`)
                                            : customerType === 'PM'
                                            ? t('confirm-information:kbis')
                                            : t(`confirm-information:income-changed-upload`)
                                    }
                                    buttonText={t(`my-profile:${fileUploadTranslationPrefix}.button`)}
                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                    fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                    maxFileSize={maxFileSize}
                                    multiple={false}
                                    validFileFormats={['pdf']}
                                    sizeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                    typeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                    sizeAndTypeError={
                                        <>
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                            <br />
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                        </>
                                    }
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}

                {customerType === 'PM' && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/2" s="1/1">
                                <FormFieldLabel>{t('authorisation-changed')}</FormFieldLabel>
                                <RadioButtonGroup>
                                    <RadioButton
                                        name="professional.authorizationChanged"
                                        checked={formik.values.professional.authorizationChanged}
                                        onChange={() => formik.setFieldValue('professional.authorizationChanged', true)}
                                    >
                                        {t('yes')}
                                    </RadioButton>
                                    <RadioButton
                                        name="professional.authorizationChanged"
                                        checked={!formik.values.professional.authorizationChanged}
                                        onChange={() =>
                                            formik.setFieldValue('professional.authorizationChanged', false)
                                        }
                                    >
                                        {t('no')}
                                    </RadioButton>
                                </RadioButtonGroup>
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}

                {formik.values.professional.authorizationChanged && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item>
                                <FileUpload
                                    name="proofOfAuthChangeId"
                                    descriptionText={t(
                                        `my-profile:${fileUploadTranslationPrefix}.proof-of-authorization-change-id`,
                                    )}
                                    buttonText={t(`my-profile:${fileUploadTranslationPrefix}.button`)}
                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                    fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                    maxFileSize={maxFileSize}
                                    multiple={false}
                                    validFileFormats={['pdf']}
                                    sizeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                    typeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                    sizeAndTypeError={
                                        <>
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                            <br />
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                        </>
                                    }
                                />
                            </Layout.Item>
                        </Layout>
                        <Layout>
                            <Layout.Item>
                                <FileUpload
                                    name="proofOfAuthChangeSign"
                                    descriptionText={t(
                                        `my-profile:${fileUploadTranslationPrefix}.proof-of-authorization-change-sign`,
                                    )}
                                    buttonText={t(`my-profile:${fileUploadTranslationPrefix}.button`)}
                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                    fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                    maxFileSize={maxFileSize}
                                    multiple={false}
                                    validFileFormats={['pdf']}
                                    sizeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                    typeError={t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                    sizeAndTypeError={
                                        <>
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                            <br />
                                            {t(`my-profile:${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                        </>
                                    }
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
            </Fieldset>
        </>
    );
};
