import { RegistrationCertificateDuplicateReason } from '@cp-fr/common';
import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../../simple-form-components';

type DocumentsUploadProps = {
    requestReason?: RegistrationCertificateDuplicateReason;
};

export const DocumentsUpload: React.FC<DocumentsUploadProps> = ({ requestReason }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.registration-certificate-duplicate.files';
    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            <p>{translate('label')}</p>
            <Layout.Item>
                <FileUpload name={'registrationCertificateDuplicate.files.id'} label={translate('id')} />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name={'registrationCertificateDuplicate.files.proofOfAddress'}
                    label={translate('proof-of-address')}
                />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name={'registrationCertificateDuplicate.files.registrationCertificate'}
                    label={translate('registration-certificate')}
                    isMandatory={false}
                />
            </Layout.Item>
            {requestReason === 'Perte' && (
                <Layout.Item>
                    <FileUpload
                        name={'registrationCertificateDuplicate.files.lossDeclaration'}
                        label={translate('loss-declaration')}
                    />
                </Layout.Item>
            )}
            {requestReason === 'Vol' && (
                <Layout.Item>
                    <FileUpload
                        name={'registrationCertificateDuplicate.files.theftDeclaration'}
                        label={translate('theft-declaration')}
                    />
                </Layout.Item>
            )}
        </>
    );
};
