import { NatureOfClaim } from '@cp-fr/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import React from 'react';

type NatureOfClaimSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const NatureOfClaimSelection: React.FC<NatureOfClaimSelectionProps> = ({ label, name, isMandatory = true }) => {
    const naturesOfClaim: NatureOfClaim[] = ['Incapacité de travail', 'Perte d’emploi'];

    const selectItems: ValidatedSelectItem[] = naturesOfClaim.map((natureOfClaim: NatureOfClaim) => {
        return { value: natureOfClaim, label: natureOfClaim };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
