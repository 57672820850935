import { RequestVehicleClaimValidatorErrors } from '../../types';

export const RequestVehicleClaimError: RequestVehicleClaimValidatorErrors = {
    reparable: {
        required: 'reparable is required',
    },
    nonReparableFields: {
        email: {
            required: 'email is required',
            mustBeEmail: 'email must be an email',
        },
        phoneNumber: {
            required: 'phoneNumber is required',
            mustBePhoneNumber: 'phoneNumber must have 10 characters',
        },
        natureOfDamage: {
            required: 'natureOfDamage is required',
        },
        dateOfDamage: {
            required: 'dateOfDamage is required',
        },
        nameOfInsurer: {
            required: 'nameOfInsurer is required',
        },
        circumstancesOfTheDisaster: {
            required: 'circumstancesOfTheDisaster is required',
        },
        causeOfSinistre: {
            required: 'causeOfSinistre is required',
        },
        addressOfInsurer: {
            required: 'addressOfInsurer is required',
        },
        postcodeOfInsurer: {
            required: 'postcodeOfInsurer is required',
            minLength: 'postcodeOfInsurer must contain minimum 5 characters',
        },
        cityOfInsurer: {
            required: 'cityOfInsurer is required',
        },
        insuranceCustomerNumber: {
            required: 'insuranceCustomerNumber is required',
        },
        damageNumber: {
            required: 'damageNumber is required',
        },
        phonenumberOfInsurer: {
            required: 'phonenumberOfInsurer is required',
            mustBePhoneNumber: 'phonenumberOfInsurer must have 10 characters',
        },
        emailOfInsurer: {
            required: 'emailOfInsurer is required',
            mustBeEmail: 'emailOfInsurer must be an email',
        },
        originalRegDocument: {
            required: 'originalRegDocument is required',
        },
        agreeToSend: {
            required: 'agreeToSend is required',
        },
        registrationFile: {
            maxLength: 'registrationFile must contain 1 file',
            required: 'registrationFile file is required',
        },
        files: {
            flightReport: {
                maxLength: 'flightReport must contain 1 file',
                required: 'flightReport file is required',
            },
            invoice: {
                maxLength: 'invoice must contain 1 file',
                required: 'invoice file is required',
            },
            expertReport: {
                maxLength: 'expertReport must contain 1 file',
                required: 'expertReport file is required',
            },
            paymentDetails: {
                maxLength: 'paymentDetails must contain 1 file',
                required: 'paymentDetails file is required',
            },
            transferProposal: {
                maxLength: 'transferProposal must contain 1 file',
                required: 'transferProposal file is required',
            },
        },
    },
};
