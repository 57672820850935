import { ChangeNameDocument, ChangeNameDocumentType, FileInfo } from '@cp-fr/common';
import { uploadFilesToRequestedUrls } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

export type fileUpload = 'kbis' | 'insee';

export const fileTypes = {
    kbis: ChangeNameDocumentType.KBIS,
    insee: ChangeNameDocumentType.INSEE,
};

type DocumentList = {
    kbis: File[];
    insee: File[];
};

export const uploadFilesToMediaStorage = (documentsValues: DocumentList): Promise<ChangeNameDocument>[] =>
    Object.entries(documentsValues)
        .filter((value) => value[1].length)
        .map(async (value: [string, File[]]) => {
            return {
                fileId: await uploadFilesToRequestedUrls(value[1], CpDataApi),
                type: fileTypes[value[0] as fileUpload],
            };
        });

export const getSeparatedFilesIds = (mediaStorageFilesIds: ChangeNameDocument[]): FileInfo[] =>
    mediaStorageFilesIds.reduce((acc: FileInfo[], cur) => {
        return acc.concat(
            cur.fileId.map((nestedFileId: string) => {
                return { fileId: nestedFileId, type: cur.type };
            }),
        );
    }, []);
