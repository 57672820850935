export function getRequestSendingEndpoint(): string {
    return '/request/send';
}

export function getMailSendingEndpoint(): string {
    return '/mail/send';
}

export function getDocumentSendingEndpoint(): string {
    return `/customers/requests`;
}

export function getIbanStatusEndpoint(): string {
    return `/iban-status`;
}

export function getPaymentDayStatusEndpoint(): string {
    return `/payment-day-status`;
}

export function getNameStatusEndpoint(): string {
    return `/name-status`;
}

export function getAddressStatusEndpoint(): string {
    return `/address-status`;
}

export function getContactDetailsStatusEndpoint(): string {
    return '/contact-details-status';
}
