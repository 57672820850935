import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorNotification } from '../../notifications/error/ErrorNotification';

export const NotFoundError: React.FC = () => {
    const { t } = useTranslation('my-profile');

    return (
        <>
            <ErrorNotification
                testId={'no-found-error'}
                headline={t('not-found-error.headline')}
                text={t('not-found-error.text')}
            />
        </>
    );
};
