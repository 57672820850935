import { Base64File } from '@cp-fr/common';
import { byteLength, fromByteArray } from 'base64-js';

export function getBase64(file: File): Promise<Base64File> {
    return file.arrayBuffer().then((buffer: ArrayBuffer) => {
        const base64: string = fromByteArray(new Uint8Array(buffer));
        return { filename: file.name, base64: base64, size: byteLength(base64), mimeType: file.type };
    });
}

export async function mapFilesToBase64(files: File[]): Promise<Base64File[]> {
    const filePromises: Promise<Base64File>[] = files.map((file) => getBase64(file));
    return Promise.all(filePromises).then((files) => {
        return files;
    });
}

export async function mapFileToBase64(files: File[]): Promise<Base64File> {
    const filePromises: Promise<Base64File>[] = files.map((file) => getBase64(file));
    return Promise.all(filePromises).then((files) => {
        return files[0];
    });
}

export function groupByFileSize<T extends { size: number }>(files: T[], maxRequestSize: number): Array<T[]> {
    return files
        .sort(({ size: a }, { size: b }) => b - a)
        .reduce((acc: Array<T[]>, curr: T) => {
            let assigned = false;
            const result = acc.map((combined: T[]) => {
                const size = combined.reduce((sum, file: T) => sum + file.size, 0);
                if (size + curr.size < maxRequestSize && !assigned) {
                    assigned = true;
                    return [...combined, curr];
                }
                return combined;
            });
            return !assigned ? [...result, [curr]] : result;
        }, []);
}
