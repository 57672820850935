import ibanLengths from './IbanLength';

export const stripWhitespaces = (iban?: string | null): string => iban?.replace(/\s/g, '') ?? '';

const getCountryCodeFromIban = (iban: string): string => stripWhitespaces(iban).substr(0, 2);

const validLength = (iban: string): number => {
    const countryCode = getCountryCodeFromIban(iban);
    return countryCode in ibanLengths ? ibanLengths[countryCode] : Math.max(...Object.values(ibanLengths));
};

export const getIbanNumber = (iban: string): string => stripWhitespaces(iban).substr(2);

export const isValidLength = (iban?: string | null): boolean =>
    iban ? stripWhitespaces(iban).length === validLength(iban) : false;

export const isValidCountryCode = (iban?: string | null): boolean =>
    iban ? getCountryCodeFromIban(iban) in ibanLengths : false;
