export function getMyProfileEndpoint(): string {
    return `/my-profile`;
}

export function getChangeMarketingSettingsEndpoint(): string {
    return `${getMyProfileEndpoint()}/marketing-settings`;
}

export function getUnsubscribeEndpoint(): string {
    return '/unsubscribe';
}

export function getChangeNameEndpoint(): string {
    return '/change-name';
}

export function getChangeNameSMEEndpoint(): string {
    return '/change-name-sme';
}

export function getChangeStatusMessageEndpoint(): string {
    return `${getMyProfileEndpoint()}/change-status-message`;
}
