import {
    ContactDetails,
    ContactDetailsChange,
    getChangeContactDetailsEndpoint,
    RequestStatus,
    editViewContactSectionValidationSchema,
} from '@cp-fr/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
} from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { updateContactInformationChangeStatus } from 'components/my-profile/ContactInformationChangeStatusSlice';
import { PhoneInput } from 'components/request/form-view/category-selection/category-view/simple-form-components/phone-input/PhoneInput';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getAreaCode, removeAreaCodeFromPhoneNumber } from './helper';

export enum EditStatus {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NOT_PERFORMED = 'NOT_PERFORMED',
}

export type EditViewProps = {
    contactDetails?: ContactDetails;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedContactDetails?: ContactDetails) => void;
};

export const EditView: React.FC<EditViewProps> = ({ contactDetails, cancelEditing, finishEditing }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();

    const trackingSection = 'Contact';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileContactTypedIn',
    });
    const getInitialErrors = (values: { [k: string]: string | undefined }) =>
        ['emailAddress', 'mobilePhone'].filter((element) => !values[element] || values[element] === '').join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

    const { t } = useTranslation('my-profile');
    const validationPrefix = 'contact-details.edit-view.validation';

    const initialValues: ContactDetailsChange = {
        emailAddress: contactDetails?.email || '',
        mobilePhoneCountryCode: getAreaCode(contactDetails?.mobilePhone) || '33',
        mobilePhone: removeAreaCodeFromPhoneNumber(contactDetails?.mobilePhone),
        otherPhoneCountryCode: getAreaCode(contactDetails?.otherPhone) || '33',
        otherPhone: removeAreaCodeFromPhoneNumber(contactDetails?.otherPhone),
    };

    const handleSubmit = ({
        emailAddress,
        mobilePhoneCountryCode,
        mobilePhone,
        otherPhoneCountryCode,
        otherPhone,
    }: ContactDetailsChange): void => {
        const preparedChangeValues: ContactDetailsChange = {
            emailAddress: emailAddress,
            mobilePhoneCountryCode: `+${mobilePhoneCountryCode}`,
            mobilePhone: mobilePhone.replace(/\s/g, ''),
            otherPhoneCountryCode: otherPhoneCountryCode ? `+${otherPhoneCountryCode}` : '',
            otherPhone: otherPhone?.replace(/\s/g, ''),
        };

        setIsSubmitting(true);

        CpDataApi.put(getChangeContactDetailsEndpoint(), preparedChangeValues)
            .then(() => {
                const preparedChanges: ContactDetails = {
                    email: emailAddress,
                    mobilePhone: `+${mobilePhoneCountryCode} ${mobilePhone.replace(/\s/g, '')}`,
                    otherPhone: otherPhone ? `+${otherPhoneCountryCode} ${otherPhone?.replace(/\s/g, '')}` : '',
                };

                const updatedContact = { ...contactDetails, ...preparedChanges };
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS, updatedContact);
                dispatch(
                    updateContactInformationChangeStatus({
                        requestCustomerStatus: RequestStatus.IN_PROGRESS,
                    }),
                );
            })
            .catch(() => {
                setIsSubmitting(false);
                setIsError(true);
            });
    };

    const closeButtonProps = {
        onClick: cancelEditing,
        className: 'c-icon  c-icon--[semantic-close]',
        testId: 'x-button',
    };

    const errorMessages = {
        emailAddress: {
            required: t(`${validationPrefix}.email.required`),
            email: t(`${validationPrefix}.email.invalid`),
        },
        mobilePhone: {
            required: t(`${validationPrefix}.phone.required`),
            matches: t(`${validationPrefix}.phone.invalid`),
        },
        otherPhone: {
            matches: t(`${validationPrefix}.phone.invalid`),
        },
        mobilePhoneCountryCode: {
            required: 'Mobile phone country code is required',
        },
        otherPhoneCountryCode: {},
    };

    return (
        <DataOverview title={t('contact-details.edit-view.headline')} buttonLabel={' '} buttonProps={closeButtonProps}>
            <>
                <Notification
                    status={NotificationStatus.warning}
                    text={t('contact-details.edit-view.info')}
                    className={'u-mb'}
                />
                {isSubmitting && <Spinner fullPage />}
                <Formik
                    initialValues={initialValues}
                    validationSchema={editViewContactSectionValidationSchema(errorMessages)}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form
                            onSubmit={(e) => preventSubmit(e)}
                            data-testid="edit-form"
                            onChange={() => onTyping(formik.errors, formik.touched)}
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <ValidatedInput
                                                label={t('contact-details.edit-view.email')}
                                                name="emailAddress"
                                                testId="email-address"
                                                type="email"
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/2" s="1/1">
                                            <PhoneInput
                                                phoneFieldLabel={t('contact-details.edit-view.mobile-phone-digits')}
                                                phoneFieldName={'mobilePhone'}
                                                phoneFieldTestId={'mobile-phone'}
                                                phoneFieldPrefixLabel={t(
                                                    'contact-details.edit-view.mobile-phone-country-code',
                                                )}
                                                phoneFieldPrefixName={'mobilePhoneCountryCode'}
                                                setFormikFieldValue={formik.setFieldValue}
                                            />
                                        </Layout.Item>
                                        <Layout.Item default="1/2" s="1/1">
                                            <PhoneInput
                                                phoneFieldLabel={t('contact-details.edit-view.fixed-phone-digits')}
                                                phoneFieldName={'otherPhone'}
                                                phoneFieldTestId={'main-phone'}
                                                phoneFieldPrefixLabel={t(
                                                    'contact-details.edit-view.mobile-phone-country-code',
                                                )}
                                                phoneFieldPrefixName={'otherPhoneCountryCode'}
                                                setFormikFieldValue={formik.setFieldValue}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            {isError && (
                                <Notification
                                    status={NotificationStatus.error}
                                    text={t('contact-details.notification.error')}
                                    testId={'errorNotification'}
                                    className="u-mb"
                                />
                            )}
                            <Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            onClick={() => {
                                                cancelEditing();
                                            }}
                                            testId="cancel-button"
                                            type="button"
                                        >
                                            {t('contact-details.edit-view.back-button')}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                formik.handleSubmit();
                                                const initialErrors = getInitialErrors({
                                                    emailAddress: formik.values.emailAddress,
                                                    mobilePhone: formik.values.mobilePhone,
                                                });
                                                if (!isEmpty(formik.errors)) {
                                                    const errorsList = getErrors(formik.errors);
                                                    onValidationError(errorsList);
                                                } else if (!!initialErrors) {
                                                    onValidationError(initialErrors);
                                                }
                                            }}
                                            testId="submit-button"
                                            type="button"
                                        >
                                            {t('contact-details.edit-view.confirm-button')}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </>
        </DataOverview>
    );
};
