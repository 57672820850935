import { RegistrationLegalPersonValidatorErrors } from '../types';

export const RegistrationLegalPersonError: RegistrationLegalPersonValidatorErrors = {
    contractNumber: {
        empty: 'Contract number is required',
        format: 'Contract number should have correct format',
        format11: 'Contract number should have correct format',
        format12: 'Contract number should have correct format',
    },
    customerNumber: {
        empty: 'Customer number is required',
        format: 'Customer number should have correct format',
    },
    companyName: {
        empty: 'Company name is required',
        format: ' should have correct format',
    },
    siren: {
        empty: 'Siren number is required',
        format: 'Siren number should have correct format',
    },
    privacyPolicy: 'Privacy policy is required',
    usageAgreement: 'Usage agreement is required',
};
