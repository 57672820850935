import { ValidatedInput } from '@cp-shared-8/frontend-ui';
import { Combobox, ErrorMessage, FormField, Layout } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { countryCodeMapping } from './country-code';
import { ComboboxOption } from '../../../../../../../types/bronson-react';

type PhoneNumberProps = {
    phoneFieldLabel?: string;
    phoneFieldTooltip?: string;
    phoneFieldName: string;
    phoneFieldTestId?: string;
    phoneFieldPrefixLabel?: string;
    phoneFieldPrefixName: string;
    phoneFieldPrefixTestId?: string;
    hidePrefix?: boolean;
    setFormikFieldValue: (field: string, value: string) => void;
};

const optionsFromCountryMapping = (): JSX.Element[] => {
    return Object.entries(countryCodeMapping).map((value: [string, string[]], index: number) => (
        <Combobox.Item key={index} optionKey={value[1][0]} optionValue={value[0]}>
            {value[0]}
        </Combobox.Item>
    ));
};

const getDefaultValue = (key: string): string => {
    return (
        Object.entries(countryCodeMapping).find((value: [string, string[]]) => {
            return value[1][0] === key;
        })?.[0] || ''
    );
};

export const PhoneInput: React.FC<PhoneNumberProps> = ({
    phoneFieldLabel,
    phoneFieldName,
    phoneFieldTestId,
    phoneFieldTooltip,
    phoneFieldPrefixLabel,
    phoneFieldPrefixName,
    phoneFieldPrefixTestId,
    setFormikFieldValue,
    hidePrefix = true,
}) => {
    const [prefixField, meta] = useField(phoneFieldPrefixName);

    const onChange = useCallback((selectedPrefixes: ComboboxOption[]) => {
        setFormikFieldValue(phoneFieldPrefixName, selectedPrefixes[0]?.key);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const defaultState = {
        value: [
            {
                value: getDefaultValue(prefixField.value),
                key: prefixField.value,
            },
        ],
    };

    return (
        <>
            {!hidePrefix && (
                <Layout.Item default="1/2" s="1/1" className={'u-pl-none'}>
                    <FormField
                        type="select"
                        errorMessage={
                            meta.touched &&
                            meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
                        }
                        labelText={phoneFieldPrefixLabel || ''}
                    >
                        <Combobox
                            name={phoneFieldPrefixName}
                            testId={phoneFieldPrefixTestId}
                            defaultState={defaultState}
                            onChange={onChange}
                        >
                            {optionsFromCountryMapping()}
                        </Combobox>
                    </FormField>
                </Layout.Item>
            )}
            <Layout.Item default={`1/${hidePrefix ? '1' : '2'}`} s="1/1" className={hidePrefix ? 'u-pl-none' : ''}>
                <ValidatedInput
                    tooltip={phoneFieldTooltip}
                    label={phoneFieldLabel || ''}
                    name={phoneFieldName}
                    testId={phoneFieldTestId}
                    addonText={!hidePrefix ? `+${prefixField.value}` : undefined}
                    reversed={!hidePrefix}
                    type="tel"
                />
            </Layout.Item>
        </>
    );
};
