import { IdentificationSectionEditViewValidatorErrors } from '../../types';

export const IdentificationSectionEditViewError: IdentificationSectionEditViewValidatorErrors = {
    firstName: {
        required: 'First name is required',
        matches: 'First name does not match',
    },
    lastName: {
        required: 'Last name is required',
        matches: 'Last name does not match',
    },
    id: {
        required: 'id is required',
        max: 'max id is reached',
    },
    proofOfIdentityChange: {
        required: 'proof of identity change is required',
        max: 'max of proof of identity is reached',
    },
};
