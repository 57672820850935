type CountryCode = { [key: string]: string[] };

export const countryCodeMapping: CountryCode = {
    Afghanistan: ['93', 'AF'],
    'Afrique du Sud': ['27', 'ZA'],
    'Îles Åland': ['358', 'AX'],
    Albanie: ['355', 'AL'],
    Algérie: ['213', 'DZ'],
    Allemagne: ['49', 'DE'],
    Andorre: ['376', 'AD'],
    Angola: ['244', 'AO'],
    Anguilla: ['1-264', 'AI'],
    Antarctique: ['672', 'AQ'],
    'Antigua-et-Barbuda': ['1-268', 'AG'],
    'Arabie saoudite': ['966', 'SA'],
    Argentine: ['54', 'AR'],
    Arménie: ['374', 'AM'],
    Aruba: ['297', 'AW'],
    Australie: ['61', 'AU'],
    Autriche: ['43', 'AT'],
    Azerbaïdjan: ['994', 'AZ'],
    Bahamas: ['1-242', 'BS'],
    Bahreïn: ['973', 'BH'],
    Bangladesh: ['880', 'BD'],
    Barbade: ['1-246', 'BB'],
    Biélorussie: ['375', 'BY'],
    Belgique: ['32', 'BE'],
    Belize: ['501', 'BZ'],
    Bénin: ['229', 'BJ'],
    Bermudes: ['1-441', 'BM'],
    Bhoutan: ['975', 'BT'],
    Bolivie: ['591', 'BO'],
    'Pays-Bas caribéens': ['599', 'BQ'],
    'Bosnie-Herzégovine': ['387', 'BA'],
    Botswana: ['267', 'BW'],
    'Île Bouvet': ['47', 'BV'],
    Brésil: ['55', 'BR'],
    Brunei: ['673', 'BN'],
    Bulgarie: ['359', 'BG'],
    'Burkina Faso': ['226', 'BF'],
    Burundi: ['257', 'BI'],
    'Îles Caïmans': ['1-345', 'KY'],
    Cambodge: ['855', 'KH'],
    Cameroun: ['237', 'CM'],
    Canada: ['1', 'CA'],
    'Cap-Vert': ['238', 'CV'],
    'République centrafricaine': ['236', 'CF'],
    Chili: ['56', 'CL'],
    Chine: ['86', 'CN'],
    'Île Christmas': ['61', 'CX'],
    'Chypre (pays)': ['357', 'CY'],
    'Îles Cocos': ['61', 'CC'],
    Colombie: ['57', 'CO'],
    'Comores (pays)': ['269', 'KM'],
    'République du Congo': ['242', 'CG'],
    'République démocratique du Congo': ['243', 'CD'],
    'Îles Cook': ['682', 'CK'],
    'Corée du Sud': ['82', 'KR'],
    'Corée du Nord': ['850', 'KP'],
    'Costa Rica': ['506', 'CR'],
    'Côte dIvoire': ['225', 'CI'],
    Croatie: ['385', 'HR'],
    Cuba: ['53', 'CU'],
    Curaçao: ['599', 'CW'],
    Danemark: ['45', 'DK'],
    Djibouti: ['253', 'DJ'],
    'République dominicaine (1-809)': ['1-809', 'DO'],
    'République dominicaine (1-829)': ['1-829', 'DO'],
    'République dominicaine (1-849)': ['1-849', 'DO'],
    Dominique: ['1-767', 'DM'],
    Égypte: ['20', 'EG'],
    Salvador: ['503', 'SV'],
    'Émirats arabes unis': ['971', 'AE'],
    'Équateur (pays)': ['593', 'EC'],
    Érythrée: ['291', 'ER'],
    Espagne: ['34', 'ES'],
    Estonie: ['372', 'EE'],
    'États-Unis': ['1', 'US'],
    Éthiopie: ['251', 'ET'],
    Malouines: ['500', 'FK'],
    'Îles Féroé': ['298', 'FO'],
    Fidji: ['679', 'FJ'],
    Finlande: ['358', 'FI'],
    France: ['33', 'FR'],
    Gabon: ['241', 'GA'],
    Gambie: ['220', 'GM'],
    'Géorgie (pays)': ['995', 'GE'],
    'Géorgie du Sud-et-les îles Sandwich du Sud': ['500', 'GS'],
    Ghana: ['233', 'GH'],
    Gibraltar: ['350', 'GI'],
    Grèce: ['30', 'GR'],
    'Grenade (pays)': ['1-473', 'GD'],
    Groenland: ['299', 'GL'],
    Guadeloupe: ['590', 'GP'],
    Guam: ['1-671', 'GU'],
    Guatemala: ['502', 'GT'],
    Guernesey: ['44-1481', 'GG'],
    Guinée: ['224', 'GN'],
    'Guinée-Bissau': ['245', 'GW'],
    'Guinée équatoriale': ['240', 'GQ'],
    Guyana: ['592', 'GY'],
    Guyane: ['594', 'GF'],
    Haïti: ['509', 'HT'],
    'Îles Heard-et-MacDonald': ['672', 'HM'],
    Honduras: ['504', 'HN'],
    'Hong Kong': ['852', 'HK'],
    Hongrie: ['36', 'HU'],
    'Île de Man': ['44-1624', 'IM'],
    'Îles mineures éloignées des États-Unis': ['246', 'UM'],
    'Îles Vierges britanniques': ['1-284', 'VG'],
    'Îles Vierges des États-Unis': ['1-340', 'VI'],
    Inde: ['91', 'IN'],
    Indonésie: ['62', 'ID'],
    Iran: ['98', 'IR'],
    Irak: ['964', 'IQ'],
    'Irlande (pays)': ['353', 'IE'],
    Islande: ['354', 'IS'],
    Israël: ['972', 'IL'],
    Italie: ['39', 'IT'],
    Jamaïque: ['1-876', 'JM'],
    Japon: ['81', 'JP'],
    Jersey: ['44-1534', 'JE'],
    Jordanie: ['962', 'JO'],
    Kazakhstan: ['7', 'KZ'],
    Kenya: ['254', 'KE'],
    Kirghizistan: ['996', 'KG'],
    Kiribati: ['686', 'KI'],
    Koweït: ['965', 'KW'],
    Laos: ['856', 'LA'],
    Lesotho: ['266', 'LS'],
    Lettonie: ['371', 'LV'],
    Liban: ['961', 'LB'],
    Liberia: ['231', 'LR'],
    Libye: ['218', 'LY'],
    Liechtenstein: ['423', 'LI'],
    Lituanie: ['370', 'LT'],
    'Luxembourg (pays)': ['352', 'LU'],
    Macao: ['853', 'MO'],
    'Macédoine du Nord': ['389', 'MK'],
    Madagascar: ['261', 'MG'],
    Malaisie: ['60', 'MY'],
    Malawi: ['265', 'MW'],
    Maldives: ['960', 'MV'],
    Mali: ['223', 'ML'],
    Malte: ['356', 'MT'],
    'Îles Mariannes du Nord': ['1-670', 'MP'],
    Maroc: ['212', 'MA'],
    'Îles Marshall (pays)': ['692', 'MH'],
    Martinique: ['596', 'MQ'],
    'Maurice (pays)': ['230', 'MU'],
    Mauritanie: ['222', 'MR'],
    Mayotte: ['262', 'YT'],
    Mexique: ['52', 'MX'],
    'États fédérés de Micronésie (pays)': ['691', 'FM'],
    Moldavie: ['373', 'MD'],
    Monaco: ['377', 'MC'],
    Mongolie: ['976', 'MN'],
    Monténégro: ['382', 'ME'],
    Montserrat: ['1-664', 'MS'],
    Mozambique: ['258', 'MZ'],
    Birmanie: ['95', 'MM'],
    Namibie: ['264', 'NA'],
    Nauru: ['674', 'NR'],
    Népal: ['977', 'NP'],
    Nicaragua: ['505', 'NI'],
    Niger: ['227', 'NE'],
    Nigeria: ['234', 'NG'],
    Niue: ['683', 'NU'],
    'Île Norfolk': ['672', 'NF'],
    Norvège: ['47', 'NO'],
    'Nouvelle-Calédonie': ['687', 'NC'],
    'Nouvelle-Zélande': ['64', 'NZ'],
    'Territoire britannique de locéan Indien': ['246', 'IO'],
    Oman: ['968', 'OM'],
    Ouganda: ['256', 'UG'],
    Ouzbékistan: ['998', 'UZ'],
    Pakistan: ['92', 'PK'],
    Palaos: ['680', 'PW'],
    Palestine: ['970', 'PS'],
    Panama: ['507', 'PA'],
    'Papouasie-Nouvelle-Guinée': ['675', 'PG'],
    Paraguay: ['595', 'PY'],
    'Pays-Bas': ['31', 'NL'],
    Pérou: ['51', 'PE'],
    Philippines: ['63', 'PH'],
    'Îles Pitcairn': ['649', 'PN'],
    Pologne: ['48', 'PL'],
    'Polynésie française': ['689', 'PF'],
    'Porto Rico (1-787)': ['1-787', 'PR'],
    'Porto Rico (1-939)': ['1-939', 'PR'],
    Portugal: ['351', 'PT'],
    Qatar: ['974', 'QA'],
    'La Réunion': ['262', 'RE'],
    Roumanie: ['40', 'RO'],
    'Royaume-Uni': ['44', 'G'],
    Russie: ['7', 'RU'],
    Rwanda: ['250', 'RW'],
    'République arabe sahraouie dmocratique': ['212', 'EH'],
    'Saint-Barthélemy': ['590', 'B'],
    'Saint-Christophe-et-Niévès': ['1-869', 'KN'],
    'Saint-Marin': ['378', 'SM'],
    'Saint-Martin': ['590', 'MF'],
    'Saint-Martin (1-721)': ['1-721', 'SX'],
    'Saint-Pierre-et-Miquelon': ['508', 'PM'],
    'Saint-Siège (État de la Cité du Vatcan)': ['379', 'VA'],
    'Saint-Vincent-et-les-Grenadines': ['1-784', 'VC'],
    'Sainte-HélèneAscension et Tristan d Cunha': ['290', 'SH'],
    'Sainte-Lucie': ['58', 'LC'],
    Salomon: ['677', 'SB'],
    Samoa: ['685', 'WS'],
    'Samoa américaines': ['1-684', 'AS'],
    'Sao Tomé-et-Principe': ['239', 'ST'],
    Sénégal: ['221', 'SN'],
    Serbie: ['381', 'RS'],
    Seychelles: ['248', 'SC'],
    'Sierra Leone': ['232', 'SL'],
    Singapour: ['65', 'SG'],
    Slovaquie: ['421', 'SK'],
    Slovénie: ['386', 'SI'],
    Somalie: ['252', 'SO'],
    Soudan: ['249', 'SD'],
    'Soudan du Sud': ['211', 'SS'],
    'Sri Lanka': ['94', 'LK'],
    Suède: ['46', 'SE'],
    Suisse: ['41', 'CH'],
    Suriname: ['597', 'SR'],
    'Svalbard et ile Jan Mayen': ['47', 'SJ'],
    Eswatini: ['268', 'SZ'],
    Syrie: ['963', 'SY'],
    Tadjikistan: ['992', 'TJ'],
    'Taïwan / (République de Chine (Taïwan))': ['886', 'TW'],
    Tanzanie: ['255', 'TZ'],
    Tchad: ['235', 'TD'],
    Tchéquie: ['420', 'CZ'],
    'Terres australes et antarctiques françaises': ['590', 'TF'],
    Thaïlande: ['66', 'TH'],
    'Timor oriental': ['670', 'TL'],
    Togo: ['228', 'TG'],
    Tokelau: ['690', 'TK'],
    Tonga: ['676', 'TO'],
    'Trinité-et-Tobago': ['1-868', 'TT'],
    Tunisie: ['216', 'TN'],
    Turkménistan: ['993', 'TM'],
    'Îles Turques-et-Caïques': ['1-649', 'TC'],
    Turquie: ['90', 'TR'],
    Tuvalu: ['688', 'TV'],
    Ukraine: ['380', 'UA'],
    Uruguay: ['598', 'UY'],
    Vanuatu: ['678', 'VU'],
    Venezuela: ['58', 'VE'],
    'Viêt Nam': ['84', 'VN'],
    'Wallis-et-Futuna': ['681', 'WF'],
    Yémen: ['967', 'YE'],
    Zambie: ['260', 'ZM'],
    Zimbabwe: ['263', 'ZW'],
};
