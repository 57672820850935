import { MarketingSettings } from '@cp-fr/common';
import { Checkbox, DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ConsultViewProps = {
    marketingSettings?: MarketingSettings;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ marketingSettings }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'marketing-settings.consult-view';
    if (!marketingSettings) {
        return null;
    }

    const { email, mobilePhone, homePhone } = marketingSettings;
    const marketingData: Array<{ key: string; value: boolean | undefined }> = [
        { key: 'email', value: email },
        { key: 'mobile-phone', value: mobilePhone },
        { key: 'home-phone', value: homePhone },
    ];

    return (
        <DataOverview title={t(`${translationPrefix}.title`)}>
            {marketingData.map(({ key, value }) => (
                <div className={'u-mb-xsmall'} key={key}>
                    <Checkbox checked={value} disabled>
                        {t(`${translationPrefix}.${key}`)}
                    </Checkbox>
                </div>
            ))}
        </DataOverview>
    );
};
