import { Contract, ProductTypeEN, hasUnpaidAmmount } from '@cp-fr/common';
import { allowedFractions } from '@cp-shared-8/frontend-ui';
import { Button, Layout, Card, SectionHeading } from '@vwfs-bronson/bronson-react';
import {
    dashboardPagePath,
    partialEarlySettlementPagePath,
    totalEarlySettlementPagePath,
} from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const JointEntryPointUi: React.FC<{ contract?: Contract }> = ({ contract }) => {
    const { t } = useTranslation('early-settlement');
    const history = useHistory();

    if (!contract) return null;
    const { contractId, productTypeEN } = contract;

    const cards = [];
    if (productTypeEN === ProductTypeEN.CREDIT) {
        cards.push({
            testId: 'partialEarlySettlement',
            icon: 'semantic-calculator',
            title: t('joint-entry-point.partial.title'),
            body: t('joint-entry-point.partial.body'),
            pagePath: () => history.push(partialEarlySettlementPagePath(contractId)),
            deactivated: false,
        });
    }

    if (
        productTypeEN === ProductTypeEN.CREDIT ||
        productTypeEN === ProductTypeEN.BALLON_LEASING ||
        productTypeEN === ProductTypeEN.FINANCIAL_LEASE
    ) {
        cards.push({
            testId: 'totalEarlySettlement',
            icon: 'semantic-checkmark',
            title: t('joint-entry-point.total.title'),
            body: t('joint-entry-point.total.body'),
            pagePath: () => history.push(totalEarlySettlementPagePath(contractId)),
        });
    }

    // If a person has unpaid amount, we should block Remboursement de solde partiel
    // https://confluence.platform.vwfs.io/display/CPFR/Display+of+unpaid+details+and+payment
    if (hasUnpaidAmmount(contract)) {
        const index = cards.findIndex((card) => card.testId === 'partialEarlySettlement');
        if (index > -1) {
            cards[index].deactivated = true;
        }
    }

    if (!cards.length) return null;

    return (
        <>
            <SectionHeading level="3" subtitle={cards.length === 1 ? '' : t('joint-entry-point.sub-headline')}>
                {t('joint-entry-point.headline')}
            </SectionHeading>

            <Layout>
                <Layout.Item default="1/1">
                    <Layout className={'o-layout--equal-height'}>
                        {cards.map((card, index) => (
                            <Layout.Item key={index} default={`1/${cards.length}` as allowedFractions} m="1/1">
                                <Card
                                    testId={`${card.testId}Card`}
                                    element="article"
                                    className="u-mt"
                                    icon={card.icon}
                                    title={card.title}
                                >
                                    <>
                                        {card.body}
                                        <div className="u-mt">
                                            <Button
                                                testId={`${card.testId}Button`}
                                                key={index}
                                                onClick={card.pagePath}
                                                disabled={card.deactivated}
                                            >
                                                {t('translation:editable-section-nav.continue')}
                                            </Button>
                                        </div>
                                    </>
                                </Card>
                            </Layout.Item>
                        ))}
                    </Layout>
                </Layout.Item>
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button secondary onClick={(): void => history.push(dashboardPagePath())} testId="backButton">
                        {t('translation:editable-section-nav.back')}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
