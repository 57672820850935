import { ContactDetailsPatch, getChangeContactDetailsEndpoint } from '@cp-fr/common';
import {
    Notification,
    NotificationStatus,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-8/frontend-ui';
import { withLoadingHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContactDetailsCheckForm, ContactDetailsCheckFormFields } from './ui';

const ContactDetailsCheckWithHandlers = withLoadingHandler(ContactDetailsCheckForm);

type AuthTokenWithMail = {
    email: string;
};

type RequestProps = {
    isPending: boolean;
    isError: boolean;
};

export const ContactDetailsCheck: React.FC = () => {
    const { t } = useTranslation('contact-details-check');
    const { data, isLoading } = useMyProfile();
    const [request, setRequest] = useState<RequestProps>({ isPending: false, isError: false });
    const auth = useAuthentication();
    const history = useHistory();

    useAnalyticsPageViewTracker('authenticationVerification', !!data);

    const { onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        confirm: 'onVerificationPageConfirm',
    });

    const { onAction: onSuccess } = useAnalyticsActionTracker('onVerificationPageSuccess');

    const handleSubmit = ({ email, mobilePhone }: ContactDetailsCheckFormFields): void => {
        const body: ContactDetailsPatch = {
            emailAddress: email,
            mobilePhone: mobilePhone,
        };
        setRequest({ isPending: true, isError: false });
        CpDataApi.patch(getChangeContactDetailsEndpoint(), body)
            .then(() => {
                onSuccess();
                history.push(dashboardPagePath());
            })
            .catch(() => {
                trackOnSubmit();
                setRequest({ isPending: false, isError: true });
            });
    };

    return (
        <>
            {request.isPending && <Spinner fullPage={true} />}
            {request.isError && (
                <Notification
                    status={NotificationStatus.error}
                    text={t('error.change-unsuccessful')}
                    className={'u-mb'}
                />
            )}
            <ContactDetailsCheckWithHandlers
                isLoading={isLoading}
                initPhone={data?.contactDetails.mobilePhone}
                initPrefix={'33'}
                handleSubmit={handleSubmit}
                fsidMail={auth.isAuthenticated ? (auth.tokenParsed as AuthTokenWithMail).email : ''}
                profileMail={data?.contactDetails?.email}
            />
        </>
    );
};
