import React from 'react';
import { useContract } from '../useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { ContractEndOptionsUi } from './ui';
import { useMyProfile } from 'components/my-profile';

const ContractEndOptionsWithHandlers = withLoadingAndNoConnectionHandler(ContractEndOptionsUi);

export const ContractEndOptions: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data: contract, isLoading, loadingError } = useContract(contractId);
    const { data: myProfile, isLoading: isLoadingProfile, loadingError: profileLoadingError } = useMyProfile();

    return (
        <ContractEndOptionsWithHandlers
            contract={contract}
            myProfile={myProfile}
            isLoading={isLoading || isLoadingProfile}
            hasError={!!profileLoadingError || !!loadingError}
        />
    );
};
