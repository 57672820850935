import React from 'react';
import { LandingPage } from '@cp-fr/common';
import { HeroImage } from '@cp-shared-8/frontend-ui';
import { CompareAndSelect, ContentSection, Heading, IconList, Video } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type LandingPageUiProps = {
    landingPage?: LandingPage;
    clickHandler?: React.MouseEventHandler;
    isAuthenticated: boolean;
    brand: string;
};

type Card = {
    title: string;
    icon: string;
    points: string[];
};

const brandsWithVideo = ['vw', 'vwfs', 'vwcv'];

export const LandingPageUi: React.FC<LandingPageUiProps> = ({ landingPage, clickHandler, isAuthenticated, brand }) => {
    const { t } = useTranslation('landing-page');
    // for unit test executions
    const getCards = () => (t('cards') ? (t('cards', { returnObjects: true }) as Card[]) : []);
    const cardsArr = getCards();
    const cards: Card[] = cardsArr.length && cardsArr[0].title ? cardsArr : [];

    if (!landingPage || !clickHandler) {
        return null;
    }

    const { title, subTitle, text, buttonText, buttonTextAuthenticated, imageUrl, isInverted = false } = landingPage;

    const isVideoDisplayed = brandsWithVideo.includes(brand);

    return (
        <>
            <HeroImage
                title={title}
                imageUrl={imageUrl}
                subTitle={subTitle}
                text={text}
                buttonText={isAuthenticated ? buttonTextAuthenticated : buttonText}
                clickHandler={clickHandler}
                inverted={isInverted}
            />
            <ContentSection pageWrap>
                <Heading level={3} className={'u-mb u-text-center'}>
                    {t('title')}
                </Heading>
                <CompareAndSelect preserveHeight>
                    {cards.map((card, index) => (
                        <CompareAndSelect.Item
                            title={card.title}
                            icon={card.icon}
                            key={index}
                            description={card.points.map((point, index) => (
                                <IconList key={`list-${index}`}>
                                    <IconList.Item icon="checkmark" key={index} className="u-mb-xxsmall">
                                        {point}
                                    </IconList.Item>
                                </IconList>
                            ))}
                        />
                    ))}
                </CompareAndSelect>
            </ContentSection>
            {isVideoDisplayed && (
                <ContentSection pageWrap>
                    <Heading level={3} className={'u-mb u-text-center'}>
                        {t('title-video')}
                    </Heading>
                    <Video service="youtube" id="XnZb7BqZ3uM" />
                </ContentSection>
            )}
        </>
    );
};
