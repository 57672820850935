export type TotalEarlySettlementFormValues = {
    requestDate:
        | 'JANUARY'
        | 'FEBRUARY'
        | 'MARCH'
        | 'APRIL'
        | 'MAY'
        | 'JUNE'
        | 'JULY'
        | 'AUGUST'
        | 'SEPTEMBER'
        | 'OCTOBER'
        | 'NOVEMBER'
        | 'DECEMBER';
    reason: string;
};

export const getInitialValues: () => TotalEarlySettlementFormValues = () => ({
    requestDate: 'JANUARY',
    reason: '',
});
