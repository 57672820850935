import { FinancialDetails, formatAsIban } from '@cp-fr/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { Heading } from '@vwfs-bronson/bronson-react';
import { withLoadingHandler } from 'components/integration-wrapper';
import { currentBrand } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/rootReducer';
import { EditButtonWithStatus } from '../../common/edit-button-with-status';
import { EditStatus } from '../enums';
import { IbanChangeNotification } from '../iban-change-notification/IbanChangeNotification';
import { useIbanStatusTag } from './useIbanStatusTag';

type ConsultViewProps = {
    startEditing: () => void;
    lastEditStatus: EditStatus;
    isChangeEnabled?: boolean;
    details: FinancialDetails;
    effectDate?: string;
    disabledEdit?: boolean;
};

const EditButtonWithStatusWithHandlers = withLoadingHandler(EditButtonWithStatus);

export const ConsultView: React.FC<ConsultViewProps> = ({
    startEditing,
    lastEditStatus,
    isChangeEnabled,
    details,
    effectDate,
    disabledEdit,
}) => {
    const { t } = useTranslation('financial-details');
    const isIbanStatusTag = useIbanStatusTag();
    const inProgress = useSelector((state: RootState) => state.ibanStatusTagFetchState.inProgress);

    const { iban, ibanHolder } = details;

    const itemList: DefinitionListItem[] = [];

    if (iban) {
        itemList.push({
            label: t('iban-section.iban'),
            value: formatAsIban(iban),
            testId: 'iban',
        });
    }

    if (ibanHolder) {
        itemList.push({
            label: t('iban-section.account-holder'),
            value: ibanHolder,
            testId: 'iban',
        });
    }

    return (
        <div data-theme={currentBrand === 'seat' ? 'alternative' : 'primary'}>
            <Heading
                className={`c-data-overview__header u-pt ${isChangeEnabled ? 'u-mb-none' : 'u-mb-small'}`}
                level={'6'}
            >
                {t('iban-section.title')}
                {isChangeEnabled && (
                    <EditButtonWithStatusWithHandlers
                        isLoading={inProgress}
                        onClick={startEditing}
                        showStatus={isIbanStatusTag || lastEditStatus === EditStatus.SUCCESS}
                        disabled={disabledEdit || isIbanStatusTag || lastEditStatus === EditStatus.SUCCESS}
                    />
                )}
            </Heading>
            <>
                <DefinitionList split={true} list={itemList} />
                <IbanChangeNotification lastEditStatus={lastEditStatus} effectDate={effectDate} />
            </>
        </div>
    );
};
