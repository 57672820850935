import React from 'react';
import { NotificationsCenterUi } from './ui/NotificationsCenterUi';
import { useMyProfile } from 'components/my-profile';
import { Contract } from '@cp-fr/common';
import { useContracts } from '../contracts/useContracts';

export const NotificationsCenter: React.FC = () => {
    const { data: myProfileData } = useMyProfile();
    const { data: contracts } = useContracts();

    const checkContractNotification = (property: string): Contract | undefined => {
        return contracts?.find((contract) => {
            return contract[property as keyof typeof contract];
        });
    };

    return (
        <NotificationsCenterUi
            myProfileNotification={myProfileData?.identification?.data.isEligibleNpaiNotification}
            contractNotificatioForLessOneMonth={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOneMonth',
            )}
            // Release 5.0
            renuewalContractNotificationForLessTwelveMonths={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOfTwelveMonths',
            )}
            renuewalContractNotificationForLessFourMonths={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOfFourMonths',
            )}
            renuewalContractNotificationForLessThreeMonths={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOfThreeMonths',
            )}
            renuewalContractNotificationForLessTwoMonths={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOfTwoMonths',
            )}
            renuewalContractNotificationForLessOneMonth={checkContractNotification(
                'isEligibleRenewalContractNotificationForLessOfOneMonth',
            )}
            renuewalContractNotificationForLoiLagarde={checkContractNotification(
                'isEligibleRenewalContractNotificationForLoiLagarde',
            )}
        />
    );
};
