import React from 'react';
import { Notification } from '@vwfs-bronson/bronson-react';
import { NotificationStatus } from '@cp-shared-8/frontend-ui';
import { EditStatus } from '../enums';
import { useTranslation } from 'react-i18next';
import { formatAsDate } from '@cp-fr/common';

type IbanChangeNotificationProps = {
    lastEditStatus: EditStatus;
    effectDate?: string;
};

type NotificationProps = {
    visible: boolean;
    showCloseButton: boolean;
    testId: string;
    status: NotificationStatus;
    title?: string;
    text: string;
};

export const IbanChangeNotification: React.FC<IbanChangeNotificationProps> = ({ lastEditStatus, effectDate }) => {
    const { t } = useTranslation('financial-details');
    if (lastEditStatus === EditStatus.NOT_PERFORMED) {
        return null;
    }

    const prefix = 'iban-section.notification';

    const getNotificationProps = (): NotificationProps => {
        switch (lastEditStatus) {
            case EditStatus.CHECK_FAILURE:
                return {
                    visible: true,
                    showCloseButton: false,
                    testId: 'check-failure',
                    status: NotificationStatus.error,
                    text: (
                        <div dangerouslySetInnerHTML={{ __html: t(`${prefix}.error.check-failure.text`) }} /> // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ) as any,
                };
            case EditStatus.BFF_API_ERROR:
                return {
                    visible: true,
                    showCloseButton: false,
                    testId: 'no-connection-error',
                    status: NotificationStatus.error,
                    text: t(`${prefix}.error.no-connection.text`) as string,
                };
            case EditStatus.USER_WITH_NO_EMAIL_CODE:
                return {
                    visible: true,
                    showCloseButton: false,
                    testId: 'no-email-error',
                    status: NotificationStatus.error,
                    text: (
                        <div dangerouslySetInnerHTML={{ __html: t(`${prefix}.error.missing-email.text`) }} /> // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ) as any,
                };
            case EditStatus.SUCCESS:
                return effectDate
                    ? {
                          visible: true,
                          showCloseButton: false,
                          testId: 'full-request-success',
                          status: NotificationStatus.success,
                          title: t(`${prefix}.success.full-request.headline`),
                          text: t(`${prefix}.success.full-request.text`, {
                              effectDate: formatAsDate(effectDate),
                          }) as string,
                      }
                    : {
                          visible: true,
                          showCloseButton: false,
                          testId: 'request-saved-success',
                          status: NotificationStatus.success,
                          title: t(`${prefix}.success.request-saved.headline`),
                          text: t(`${prefix}.success.request-saved.text`) as string,
                      };
        }
    };

    return <Notification {...getNotificationProps()} />;
};
