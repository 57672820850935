import React from 'react';
import { useTranslation } from 'react-i18next';
import { MarketError, RegistrationError } from '@cp-fr/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-8/common-utilities';
import { Notification, NotificationStatus, ScrollToTopOnNavigation } from '@cp-shared-8/frontend-ui';

export const testId = 'registrationErrorNotification';

export const RegistrationErrorNotification: React.FC<{
    errorCode?: WithDefaultCpIntegrationErrors<RegistrationError | MarketError>;
}> = ({ errorCode }) => {
    const { t } = useTranslation('registration');

    if (!errorCode) {
        return null;
    }

    const isRegistrationErrorCode = (errorCode: WithDefaultCpIntegrationErrors<RegistrationError | MarketError>) => {
        return errorCode in RegistrationError;
    };

    return (
        <ScrollToTopOnNavigation>
            {!(isRegistrationErrorCode(errorCode) || errorCode === MarketError.NOT_FOUND) && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.no-connection-error') }} />
                </Notification>
            )}
            {(errorCode === MarketError.NOT_FOUND || errorCode === RegistrationError.CUSTOMER_NOT_FOUND) && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.not-found-error') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.CONTRACT_NOT_YET_IN_SCOPE && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.contract-not-yet-in-scope') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.CONTRACT_TERMINATED && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.terminated') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.FLEET_CONTRACT_NOT_YET_IN_SCOPE && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span
                        dangerouslySetInnerHTML={{ __html: t('registration-form.fleet-contract-not-yet-in-scope') }}
                    />
                </Notification>
            )}
            {errorCode === RegistrationError.CUSTOMER_FLEET_NOT_IN_SCOPE && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.customer-fleet-not-in-scope') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.BAD_KYC_TYPE && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.bad-kyc-type') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.SIREN_IS_MANDATORY_FOR_SME && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.siren-is-mantadatory-for_sme') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.CUSTOMER_ALREADY_REGISTRED && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span dangerouslySetInnerHTML={{ __html: t('registration-form.customer-already-registred') }} />
                </Notification>
            )}
            {errorCode === RegistrationError.UNABLE_TO_PROCESS_DIGITAL_COMMUNICATION_REFERENCE && (
                <Notification testId={'error-notification-identification-form'} status={NotificationStatus.error}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('registration-form.unable-to-process-digital-communication-reference'),
                        }}
                    />
                </Notification>
            )}
        </ScrollToTopOnNavigation>
    );
};
