import { RequestAuthorizationDebitValidatorErrors } from '../../types';

export const RequestAuthorizationDebitError: RequestAuthorizationDebitValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    nameSurname: {
        required: 'nameSurname is required',
    },
    authorizes: {
        required: 'authorizes value is required',
    },
    amount: {
        required: 'amount is required',
    },
    balanceReason: {
        required: 'balanceReason is required',
    },
};
