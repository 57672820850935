import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { RegistrationCertificateDuplicateReason } from '@cp-fr/common';

type RequestReasonSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const RequestReasonSelection: React.FC<RequestReasonSelectionProps> = ({ label, name, isMandatory = true }) => {
    const requestReason: RegistrationCertificateDuplicateReason[] = ['Perte', 'Vol'];

    const selectItems: ValidatedSelectItem[] = requestReason.map(
        (requestReason: RegistrationCertificateDuplicateReason) => {
            return { value: requestReason, label: requestReason };
        },
    );

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
