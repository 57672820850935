import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { BundledProductWithMarketingInfo, ProductRoutingResponse } from '@cp-fr/common';
import { ProductRoutingDataSelector } from './ProductRoutingSelector';
import { fetchProductRouting } from './ProductRoutingSlice';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

export const useProductRouting = (
    product?: BundledProductWithMarketingInfo,
): AbstractDataState<ProductRoutingResponse, DefaultBusinessMarketApiErrorCode> => {
    const isFetchingAllowed = !!(product && product.carCode);
    const requestConfig =
        product && product.carCode
            ? {
                  data: {
                      brand: product.carCode,
                      licensePlate: product.licensePlate,
                      carModel: product.carModel,
                      vin: product.vin,
                      firstRegistrationDate: product.firstRegistrationDate,
                  },
              }
            : undefined;

    return useGetSimpleApiData(
        fetchProductRouting,
        ProductRoutingDataSelector,
        false,
        requestConfig,
        undefined,
        isFetchingAllowed,
    );
};
