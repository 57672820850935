import { FinancialDetails, MAX_IBAN_CHANGE_ATTEMPTS } from '@cp-fr/common';
import { useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeIbanPath } from '../../../../navigation/paths';
import { ConsultView } from './consult-view';
import { EditView } from './edit-view';
import { EditStatus } from './enums';

type IbanSectionProps = {
    details: FinancialDetails;
    isActive?: boolean;
    contractId: string;
    userEmail: string;
    isContactInformationChangePending: boolean;
};

export const IbanSection: React.FC<IbanSectionProps> = ({
    details,
    isActive,
    contractId,
    userEmail,
    isContactInformationChangePending,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [effectDate, setEffectDate] = useState<string>();
    const [attemptsCounter, setAttemptsCounter] = useState<number>(1);
    const { onAction } = useAnalyticsActionTracker('financialDetailsEditIbanSection');
    const { onAction: onEditSuccess } = useAnalyticsActionTracker('financialDetailsEditIbanSectionSuccess');

    if (!isActive || !details) {
        return null;
    }

    const isChangeEnabled = !!details._links?.changeIban;

    const startEditing = (): void => {
        if (isChangeEnabled) {
            setLastEditStatus(EditStatus.NOT_PERFORMED);
            onAction();
            history.push(changeIbanPath(contractId));
        }
    };

    const finishEditing = (lastEditStatus: EditStatus): void => {
        setLastEditStatus(lastEditStatus);
        history.push(path);
    };

    const onSuccess = (lastEditStatus: EditStatus, effectDate?: string): void => {
        setAttemptsCounter((state) => state + 1);
        setEffectDate(effectDate);
        onEditSuccess();
        finishEditing(lastEditStatus);
    };

    const onFail = (lastEditStatus: EditStatus): void => {
        setAttemptsCounter((state) => state + 1);
        if (attemptsCounter === MAX_IBAN_CHANGE_ATTEMPTS) {
            finishEditing(lastEditStatus);
        }
    };

    return (
        <>
            <Hr className={'u-mb-none'} />
            <Switch>
                {isChangeEnabled && (
                    <Route exact path={changeIbanPath(contractId)}>
                        <EditView
                            iban={details.iban}
                            onSuccess={onSuccess}
                            onFail={onFail}
                            contractId={contractId}
                            email={userEmail}
                            attempt={attemptsCounter}
                        />
                    </Route>
                )}
                <Route path={path}>
                    <ConsultView
                        details={details}
                        startEditing={startEditing}
                        lastEditStatus={lastEditStatus}
                        isChangeEnabled={isChangeEnabled}
                        effectDate={effectDate}
                        disabledEdit={isContactInformationChangePending || attemptsCounter > MAX_IBAN_CHANGE_ATTEMPTS}
                    />
                </Route>
            </Switch>
        </>
    );
};
