import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import { ConfirmInformation } from 'components/kyc/confirm-information/ConfirmInformation';
import React from 'react';

export const ConfirmInformationPage: React.FC = () => {
    return (
        <ContentSection>
            <PageWrap size={'medium'}>
                <ConfirmInformation />
            </PageWrap>
        </ContentSection>
    );
};
