import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { emptyObjectOrAllValuesAreUndefined } from '../../../../../utils';
import { Address } from '@cp-fr/common/src/apis/services/types/my-profile';

type AddressComponentProps = { address?: Address };

export const AddressComponent: React.FC<AddressComponentProps> = ({ address }) => {
    const { t } = useTranslation('my-profile');
    const { t: tP } = useTranslation();

    if (!address || emptyObjectOrAllValuesAreUndefined(address)) {
        return null;
    }

    const translationPrefix = 'address.consult-view';

    const getAddressLabel = (...str: (string | undefined)[]): string | undefined =>
        str.filter((v) => v).join(' ') || undefined;

    const noValuePlaceholder = tP('no-value-placeholder');

    const addressList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.first-address-line`),
            value: getAddressLabel(address?.houseNumber, address?.street, address?.streetAdd) ?? noValuePlaceholder,
            testId: 'first-address-line',
        },
        {
            label: t(`${translationPrefix}.second-address-line`),
            value: getAddressLabel(address?.building, address?.door) ?? noValuePlaceholder,
            testId: 'second-address-line',
        },

        {
            label: t(`${translationPrefix}.third-address-line`),
            value: getAddressLabel(address?.postalCode, address?.city) ?? noValuePlaceholder,
            testId: 'third-address-line',
        },
        {
            label: t(`${translationPrefix}.country`),
            value: `${address.country}` ?? noValuePlaceholder,
            testId: 'country',
        },
    ];

    return (
        <>
            <DefinitionList split={true} list={addressList} />
        </>
    );
};
