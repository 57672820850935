import { Address, ChangeAddress } from '@cp-fr/common';
import { isCustomerType } from '@cp-fr/common/src/apis/services/types/contracts';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-8/frontend-ui';
import { changeAddressPath, changeBillingAddressPath } from 'components/navigation/paths';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { EditStatus } from './enums';

export type EditStatusWithData = {
    status: EditStatus;
    address?: Address;
    formValues?: ChangeAddress;
};

export type AddressSectionProps = {
    address?: Address;
    billingAddress?: Address;
    customerType: isCustomerType;
    hasBallonContracts?: boolean;
    isAddressChangePending: boolean;
    isContactInformationChangePending: boolean;
    isEligibleNpaiNotification?: boolean;
};

export const isEmptyAddress = (address?: Address): boolean => {
    return !address || isEmpty(address);
};

export const AddressSection: React.FC<AddressSectionProps> = ({
    address,
    billingAddress,
    customerType,
    hasBallonContracts,
    isAddressChangePending,
    isContactInformationChangePending,
    isEligibleNpaiNotification,
}) => {
    const { onAction: onEditFail } = useAnalyticsActionTracker('onEditProfileAddressSubmitFailed');
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onSuccess } = useAnalyticsFormTracker({
        confirmSuccess: 'onEditProfileAddressSuccess',
    });

    const { path } = useRouteMatch();
    const history = useHistory();
    const currentPathName = useLocation().pathname;
    const [editPostalAddressStatus, setEditPostalAddressStatus] = useState<EditStatusWithData>({
        status: EditStatus.NOT_PERFORMED,
        address,
    });
    const [editBillingAddressStatus, setEditBillingAddressStatus] = useState<EditStatusWithData>({
        status: EditStatus.NOT_PERFORMED,
        address: billingAddress,
    });

    const isBillingAddress = (): boolean => {
        return currentPathName === changeBillingAddressPath();
    };

    if (isEmpty(address)) return null;

    const startEditing = (addressType: string): void => {
        if (addressType === 'billing') {
            setEditPostalAddressStatus({
                ...editPostalAddressStatus,
                status: EditStatus.NOT_PERFORMED,
            });
            history.push(changeBillingAddressPath());
        } else if (addressType === 'postal') {
            onStart();
            setEditBillingAddressStatus({
                ...editBillingAddressStatus,
                status: EditStatus.NOT_PERFORMED,
            });
            history.push(changeAddressPath());
        }
    };

    const cancelEditing = (): void => {
        if (isBillingAddress()) {
            setEditBillingAddressStatus({
                ...editBillingAddressStatus,
                status: EditStatus.NOT_PERFORMED,
                address: billingAddress,
            });
        } else {
            onCancel('Address');
            setEditPostalAddressStatus({
                ...editPostalAddressStatus,
                status: EditStatus.NOT_PERFORMED,
                address,
            });
        }
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedAddress?: Address): void => {
        if (isBillingAddress()) {
            setEditBillingAddressStatus({
                ...editBillingAddressStatus,
                status: newEditStatus,
                address: newEditStatus === EditStatus.SUCCESS ? updatedAddress : billingAddress,
            });
        } else {
            newEditStatus === EditStatus.SUCCESS ? onSuccess() : onEditFail();
            setEditPostalAddressStatus({
                ...editPostalAddressStatus,
                status: newEditStatus,
                address: newEditStatus === EditStatus.SUCCESS ? updatedAddress : address,
            });
        }
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeAddressPath()}>
                <EditView
                    address={address}
                    customerType={customerType}
                    cancelEditing={cancelEditing}
                    finishEditing={finishEditing}
                    hasBallonContracts={hasBallonContracts}
                />
            </Route>
            <Route exact path={changeBillingAddressPath()}>
                <EditView
                    address={billingAddress}
                    customerType={customerType}
                    cancelEditing={cancelEditing}
                    finishEditing={finishEditing}
                    hasBallonContracts={hasBallonContracts}
                />
            </Route>
            <Route path={path}>
                <ConsultView
                    address={editPostalAddressStatus.address}
                    billingAddress={editBillingAddressStatus.address}
                    isPrivateCustomer={customerType.isPrivateCustomer}
                    startEditing={startEditing}
                    editPostalAddressStatus={editPostalAddressStatus.status}
                    editBillingAddressStatus={editBillingAddressStatus.status}
                    isAddressChangePending={isAddressChangePending}
                    isContactInformationChangePending={isContactInformationChangePending}
                    isEligibleNpaiNotification={isEligibleNpaiNotification}
                />
            </Route>
        </Switch>
    );
};
