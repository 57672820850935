import { Contract, ProductTypeEN, hasUnpaidAmmount } from '@cp-fr/common';
import { ValidatedSelect } from '@cp-shared-8/frontend-ui';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { head, kebabCase, toInteger, uniqBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    DateInput,
    YearInput,
    EmailInput,
    PhonenumberInput,
    TextInput,
    CountrySelection,
    MonthSelection,
} from '../../simple-form-components';

export type DocumentsFormProps = {
    isSMECustomer?: boolean;
    contractsSelected?: Contract[];
};

export const DocumentsForm: React.FC<DocumentsFormProps> = ({ isSMECustomer, contractsSelected = [] }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.documents';
    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const [documentTypeField] = useField('documents.documentType');
    const [selectedStartDate] = useField('documents.periodStartDate');
    const [selectedStartExitDate] = useField('documents.exitDate');

    const parseDate = (dateToParse: string, offsetD?: number, offsetM?: number, offsetY?: number): Date => {
        const parts = dateToParse.match(/(\d+)/g);
        return parts
            ? new Date(
                  toInteger(parts[2]) + (offsetY ?? 0),
                  toInteger(parts[1]) + (offsetM ?? 0) - 1,
                  toInteger(parts[0]) + (offsetD ?? 0),
              )
            : new Date();
    };

    const minEndDate = selectedStartDate.value ? parseDate(selectedStartDate.value, 1).toISOString() : '';
    // const calculatedDate = selectedStartDate.value ? parseDate(selectedStartDate.value, -1, 12).toISOString() : '';
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const maxStartDate = today.toISOString();
    const minExitEndDate = selectedStartExitDate.value ? parseDate(selectedStartExitDate.value, 1).toISOString() : '';
    const maxExitEndDate = selectedStartExitDate.value ? parseDate(selectedStartExitDate.value, 30).toISOString() : '';

    const currentYear = today.getFullYear();
    const invoiceYears = Array.from({ length: 3 }, (_, i) => currentYear - i).map((year) => year.toString());

    const desiredTaxStatus = ['Amortissements non déductibles', 'Avantages en nature'];
    const reasonToLeave = ['Motif professionnel', 'Motif personnel'];

    const documentTypesPerProduct: { [key: string]: string[] } = {
        [ProductTypeEN.CREDIT]: ['Copie de mon contrat', 'Echéancier'],
        [ProductTypeEN.BALLON_LEASING]: ['Copie de mon contrat', 'Documents de cession', 'Echéancier'],
        [ProductTypeEN.OPERATIVE_LEASING]: ['Copie de mon contrat'],
        // eslint-disable-next-line
        ['Long Term Leasing SME']: [
            'Copie de mon contrat',
            'Duplicata de facture de loyer',
            'Etats fiscaux - TVS',
            'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
        ],
        [ProductTypeEN.FINANCIAL_LEASE]: [
            'Duplicata de facture de loyer',
            'Copie de mon contrat',
            'Etats fiscaux - TVS',
            'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
            'Documents de cession',
            'Echéancier',
        ],
        [ProductTypeEN.VDL]: ['Copie de mon contrat'],
        //TODO need to be investigated saved for evidence
        // [ProductTypeEN.VDL]: [
        //     'Documents de cession',
        //     'Duplicata de facture de loyer',
        //     'Etats fiscaux - TVS',
        //     'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
        // ],
        [ProductTypeEN.STAND_ALONE]: ['Copie de mon contrat'],
        [ProductTypeEN.OTHER]: [
            'Documents de cession',
            'Duplicata de facture de loyer',
            'Etats fiscaux - TVS',
            'Etats fiscaux - Amortissements non déductibles/ Avantages en nature',
        ],
    };

    const getAvailableDocumentTypes = () => {
        let allowedDocuments: string[] = [];
        const selectedProductTypes = uniqBy(contractsSelected, 'productTypeEN').map((item) => item.productTypeEN);
        const excludedDocumentTypes = ['Copie de mon contrat', 'Echéancier'];
        const isMultipleContractsSelected = contractsSelected.length > 1;

        if (selectedProductTypes.length === 1) {
            const selectedTypeEN: ProductTypeEN | undefined | string = head(selectedProductTypes);

            // if contracts have similar types
            if (isMultipleContractsSelected) {
                allowedDocuments = selectedTypeEN
                    ? selectedTypeEN === 'Long Term Leasing'
                        ? documentTypesPerProduct['Long Term Leasing SME']
                        : documentTypesPerProduct[selectedTypeEN]
                    : ['OTHER'];
            } else {
                if (
                    (selectedTypeEN === ProductTypeEN.OPERATIVE_LEASING || selectedTypeEN === ProductTypeEN.VDL) &&
                    isSMECustomer
                ) {
                    allowedDocuments = documentTypesPerProduct['Long Term Leasing SME'];
                } else {
                    allowedDocuments = selectedTypeEN ? documentTypesPerProduct[selectedTypeEN] : ['OTHER'];
                }
            }
        } else {
            for (const productType of Object.keys(documentTypesPerProduct)) {
                // skip when productType is not matching any selected contract product type
                const extendedSelectedProductTypes: Array<ProductTypeEN | undefined | string> =
                    (selectedProductTypes.includes(ProductTypeEN.OPERATIVE_LEASING) ||
                        selectedProductTypes.includes(ProductTypeEN.VDL)) &&
                    isSMECustomer
                        ? selectedProductTypes.map((item) =>
                              item === ProductTypeEN.OPERATIVE_LEASING || item === ProductTypeEN.VDL
                                  ? 'Long Term Leasing SME'
                                  : item,
                          )
                        : selectedProductTypes;
                if (!extendedSelectedProductTypes.find((item) => item === productType)) continue;

                for (const category of documentTypesPerProduct[
                    (productType === ProductTypeEN.OPERATIVE_LEASING || productType === ProductTypeEN.VDL) &&
                    isSMECustomer
                        ? 'Long Term Leasing SME'
                        : productType
                ]) {
                    // skip when category is already added to dropdown
                    if (allowedDocuments.includes(category)) continue;
                    let addItem = true;

                    // check if category is allowed for all selected contract types
                    for (const selectedProductType of extendedSelectedProductTypes) {
                        if (selectedProductType && !documentTypesPerProduct[selectedProductType].includes(category)) {
                            addItem = false;
                        }
                    }

                    if (addItem) {
                        allowedDocuments.push(category);
                    }
                }
            }
        }

        if (isMultipleContractsSelected) {
            allowedDocuments = allowedDocuments.filter(
                (item) => !excludedDocumentTypes.includes(item) && item !== 'Duplicata de facture de loyer',
            );
        }

        // If a person has unpaid amount, we should block documents de cession
        // https://confluence.platform.vwfs.io/display/CPFR/Display+of+unpaid+details+and+payment
        if (contractsSelected.find((item) => hasUnpaidAmmount(item))) {
            const restrictedDocument = ['Documents de cession'];
            allowedDocuments = allowedDocuments.filter((item) => !restrictedDocument.includes(item));
        }

        return allowedDocuments;
    };

    const availableDocumentTypes = getAvailableDocumentTypes();

    return (
        <>
            <Layout.Item>
                <ValidatedSelect
                    isMandatory={true}
                    name={'documents.documentType'}
                    selectItems={availableDocumentTypes.map((docType) => ({
                        value: docType,
                        label: docType,
                    }))}
                    label={translate('document-type')}
                    emptyByDefault
                />
            </Layout.Item>
            {!!documentTypeField.value && (
                <>
                    <Layout.Item>
                        <p>{translate(`description.${kebabCase(documentTypeField.value)}`)}</p>
                    </Layout.Item>
                    <Layout.Item>
                        <EmailInput label={translate('email')} name={'documents.email'} isMandatory={true} />
                    </Layout.Item>
                    <Layout.Item>
                        <PhonenumberInput
                            label={translate('phonenumber')}
                            name={'documents.phonenumber'}
                            isMandatory={false}
                        />
                    </Layout.Item>
                    {documentTypeField.value === 'Duplicata de facture de loyer' && (
                        <>
                            <Layout.Item default="1/2">
                                <MonthSelection name={'documents.invoiceMonth'} label={translate('invoice-month')} />
                            </Layout.Item>
                            <Layout.Item default="1/2">
                                <ValidatedSelect
                                    isMandatory={true}
                                    name={'documents.invoiceYear'}
                                    selectItems={invoiceYears.map((year) => ({
                                        value: year,
                                        label: year,
                                    }))}
                                    label={translate('invoice-year')}
                                    emptyByDefault
                                />
                            </Layout.Item>
                        </>
                    )}
                    {documentTypeField.value === 'Etats fiscaux - TVS' && (
                        <Layout.Item>
                            <YearInput label={translate('year')} name={'documents.desiredYear'} isMandatory={true} />
                        </Layout.Item>
                    )}
                    {documentTypeField.value ===
                        'Etats fiscaux - Amortissements non déductibles/ Avantages en nature' && (
                        <>
                            <Layout.Item>
                                <ValidatedSelect
                                    isMandatory={true}
                                    name={'documents.desiredTaxStatus'}
                                    selectItems={desiredTaxStatus.map((taxStatus) => ({
                                        value: taxStatus,
                                        label: taxStatus,
                                    }))}
                                    label={translate('desired-tax-status')}
                                    placeholder={translate('tax-status-placeholder')}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <DateInput
                                    maxDate={maxStartDate}
                                    label={translate('period-start-date')}
                                    name={'documents.periodStartDate'}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <DateInput
                                    minDate={minEndDate}
                                    label={translate('period-end-date')}
                                    name={'documents.periodEndDate'}
                                    isMandatory={true}
                                    // value={calculatedDate}
                                />
                            </Layout.Item>
                        </>
                    )}
                    {documentTypeField.value === 'Autorisation de sortie du territoire' && (
                        <>
                            <Layout.Item>
                                <DateInput
                                    label={translate('exit-date')}
                                    name={'documents.exitDate'}
                                    isMandatory={true}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <DateInput
                                    label={translate('return-date')}
                                    name={'documents.returnDate'}
                                    minDate={minExitEndDate}
                                    maxDate={maxExitEndDate}
                                />
                            </Layout.Item>
                            <Layout.Item>
                                <CountrySelection
                                    name={'documents.countryOfDestination'}
                                    label={translate('country-of-destination')}
                                ></CountrySelection>
                            </Layout.Item>
                            <Layout.Item>
                                <ValidatedSelect
                                    isMandatory={true}
                                    name={'documents.reason'}
                                    selectItems={reasonToLeave.map((reason) => ({
                                        value: reason,
                                        label: reason,
                                    }))}
                                    label={translate('reason')}
                                    emptyByDefault
                                />
                            </Layout.Item>
                        </>
                    )}
                    <Layout.Item>
                        <TextInput label={translate('comment')} name={'documents.comment'} isMandatory={false} />
                    </Layout.Item>
                </>
            )}
        </>
    );
};
