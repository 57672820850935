import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { CustomerType } from './utils';

const translationValidationPrefix = 'address.edit-view.validation';

export const customerDataValidation = (t: TFunction, requiredFiles: boolean[], customerType: CustomerType) => {
    const addressErrorMessages = {
        houseNumber: {
            max: t(`confirm-information:required.house-number`),
            required: t(`confirm-information:required.house-number`),
        },
        street: {
            max: t(`${translationValidationPrefix}.street-name-invalid`),
            required: t(`${translationValidationPrefix}.street-name-required`),
        },
        building: {
            max: t(`${translationValidationPrefix}.block-invalid`),
        },
        door: {
            max: t(`${translationValidationPrefix}.door-invalid`),
        },
        postalCode: {
            required: t(`confirm-information:zip-code-invalid`),
            max: t(`confirm-information:zip-code-invalid`),
        },
        city: {
            required: t(`confirm-information:city-invalid`),
            max: t(`confirm-information:city-invalid`),
        },
        country: {
            required: t(`${translationValidationPrefix}.country-invalid`),
            max: t(`${translationValidationPrefix}.country-invalid`),
        },
    };
    const fileUploadTranslationPrefix = 'identification.edit-view.file-upload';

    const identificationErrorMessage = {
        name: { required: t('confirm-information:required.name') },
        surname: { required: t('confirm-information:required.surname') },
        birthDate: { required: t('confirm-information:required.birthDate') },
        companyName: { required: t('confirm-information:required.companyName') },
        proofOfIdentityChange: {
            required: t(`${fileUploadTranslationPrefix}.validation.required`),
            max: t(`${fileUploadTranslationPrefix}.validation.wrong-number-of-files`),
        },
    };

    const professionalErrorMessage = {
        employer: { required: t('confirm-information:required.employer') },
        employerChanged: { required: t('confirm-information:required.changed') },
        income: { required: t('confirm-information:required.income') },
    };

    const contactErrorMessage = {
        mobilePhone: { required: t('confirm-information:required.mobile-phone') },
        mobilePhonePrefix: { required: t('confirm-information:required.mobile-phone') },
        email: { required: t('confirm-information:required.email') },
    };
    const fileRequired = t('confirm-information:file-mandatory');

    return Yup.object({
        identification: Yup.object({
            data: Yup.object().shape({
                //name: Yup.string().trim().required(identificationErrorMessage.name.required),
                //surname: Yup.string().trim().required(identificationErrorMessage.surname.required),
                name: Yup.string().when([], {
                    is: () => customerType !== 'PM',
                    then: Yup.string().trim().required(identificationErrorMessage.name.required),
                    otherwise: Yup.string().notRequired(),
                }),
                surname: Yup.string().when([], {
                    is: () => customerType !== 'PM',
                    then: Yup.string().trim().required(identificationErrorMessage.surname.required),
                    otherwise: Yup.string().notRequired(),
                }),
                birthDate: Yup.string().when([], {
                    is: () => customerType === 'Retail',
                    then: Yup.string().trim().required(identificationErrorMessage.birthDate.required),
                    otherwise: Yup.string().notRequired(),
                }),
                companyName: Yup.string().when([], {
                    is: () => customerType === 'PM',
                    then: Yup.string().trim().required(identificationErrorMessage.companyName.required),
                    otherwise: Yup.string().notRequired(),
                }),
            }),
        }),
        address: Yup.object().shape({
            houseNumber: Yup.string()
                .trim()
                .max(10, addressErrorMessages.houseNumber.max)
                .required(addressErrorMessages.houseNumber.required),
            street: Yup.string()
                .trim()
                .max(60, addressErrorMessages.street.max)
                .required(addressErrorMessages.street.required),
            building: Yup.string().trim().max(10, addressErrorMessages.building.max),
            door: Yup.string().trim().max(5, addressErrorMessages.door.max),
            postalCode: Yup.string()
                .trim()
                .required(addressErrorMessages.postalCode.required)
                .max(5, addressErrorMessages.postalCode.max),
            city: Yup.string()
                .trim()
                .required(addressErrorMessages.city.required)
                .max(40, addressErrorMessages.city.max),
            country: Yup.string()
                .trim()
                .required(addressErrorMessages.country.required)
                .max(40, addressErrorMessages.country.max),
        }),
        personalUsage: Yup.boolean().when([], {
            is: () => customerType === 'SME',
            then: Yup.boolean().required(),
            otherwise: Yup.boolean().notRequired(),
        }),
        professional: Yup.object({
            employer: Yup.string()
                .trim()
                .when('employerChanged', {
                    is: true,
                    then: Yup.string().required(professionalErrorMessage.employer.required),
                }),
            employerChanged: Yup.boolean().required(professionalErrorMessage.employerChanged.required),
            income: Yup.string()
                .trim()
                .when([], {
                    is: () => customerType !== 'PM',
                    then: Yup.string().required(professionalErrorMessage.income.required),
                    otherwise: Yup.string().notRequired(),
                }),

            activityChanged: Yup.boolean().required(professionalErrorMessage.employerChanged.required),
        }),
        contactDetails: Yup.object({
            mobilePhone: Yup.string()
                .trim()
                .required(contactErrorMessage.mobilePhone.required)
                .matches(
                    /^0[1-9]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
                    contactErrorMessage.mobilePhone.required,
                ),
            mobilePhonePrefix: Yup.string().trim().required(contactErrorMessage.mobilePhone.required),
            email: Yup.string()
                .trim()
                .required(contactErrorMessage.email.required)
                .email(contactErrorMessage.email.required),
        }),

        proofOfIdentityChange: Yup.array().when([], {
            is: () => !!requiredFiles[0],
            then: Yup.array().min(1, fileRequired),
            otherwise: Yup.array().notRequired(),
        }),
        proofOfAddressChange: Yup.array().when([], {
            is: () => !!requiredFiles[1],
            then: Yup.array().min(1, fileRequired),
            otherwise: Yup.array().notRequired(),
        }),
        proofOfActivityChange: Yup.array().when([], {
            is: () => !!requiredFiles[4],
            then: Yup.array().min(1, fileRequired),
            otherwise: Yup.array().notRequired(),
        }),
        proofOfProfessionChange: Yup.array().when([], {
            is: () => !!requiredFiles[2],
            then: Yup.array().min(1, fileRequired),
            otherwise: Yup.array().notRequired(),
        }),
        proofOfAuthChangeId: Yup.array().when([], {
            is: () => !!requiredFiles[3],
            then: Yup.array().min(1, fileRequired),
            otherwise: Yup.array().notRequired(),
        }),
        proofOfAuthChangeSign: Yup.array().when([], {
            is: () => !!requiredFiles[3],
            then: Yup.array().min(1, fileRequired),
            otherwise: Yup.array().notRequired(),
        }),
    });
};
