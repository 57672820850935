import { EmploymentStatus, SocioprofessionalCategories, blueCategories } from '@cp-fr/common';
import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../../simple-form-components';

type DocumentsUploadProps = {
    employmentStatus?: EmploymentStatus;
    socioprofessionalCategory?: SocioprofessionalCategories;
};

export const DocumentsUpolad: React.FC<DocumentsUploadProps> = ({ employmentStatus, socioprofessionalCategory }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.request-contract-transfer.files';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const isPayslipsMandatory = (socioprofessionalCategory?: SocioprofessionalCategories): boolean => {
        if (!socioprofessionalCategory) return false;

        return blueCategories.includes(socioprofessionalCategory);
    };

    return (
        <>
            <p>{translate('description')}</p>
            <Layout.Item>
                <FileUpload
                    name={'requestContractTransfer.files.signedConfirmation'}
                    label={translate('signed-confirmation')}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name={'requestContractTransfer.files.buyerIdentity'}
                    label={translate('buyer-identity')}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name={'requestContractTransfer.files.bankDetails'}
                    label={translate('bank-details')}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name={'requestContractTransfer.files.payslips'}
                    label={translate('payslips')}
                    isMandatory={isPayslipsMandatory(socioprofessionalCategory)}
                />
            </Layout.Item>
            <Layout.Item>
                <FileUpload
                    name={'requestContractTransfer.files.taxNotice'}
                    label={translate('tax-notice')}
                    isMandatory={true}
                />
            </Layout.Item>
            {!!employmentStatus && <p>{translate('documents-to-provide')}</p>}
            {(employmentStatus === 'Propriétaire' || employmentStatus === 'Locataire') && (
                <>
                    <Layout.Item>
                        <FileUpload
                            name={'requestContractTransfer.files.proofOfAddress'}
                            label={translate('proof-of-address')}
                            isMandatory={true}
                        />
                    </Layout.Item>
                </>
            )}
            {employmentStatus === 'Logé à titre gratuit' && (
                <>
                    <Layout.Item>
                        <FileUpload
                            name={'requestContractTransfer.files.buyerProofOfAddress'}
                            label={translate('buyer-proof-of-address')}
                            isMandatory={true}
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <FileUpload
                            name={'requestContractTransfer.files.accomodationCertificate'}
                            label={translate('accomodation-certificate')}
                            isMandatory={true}
                        />
                    </Layout.Item>
                    <Layout.Item>
                        <FileUpload
                            name={'requestContractTransfer.files.indentityDoc'}
                            label={translate('identiy-doc')}
                            isMandatory={true}
                        />
                    </Layout.Item>
                </>
            )}
            <Layout.Item>
                <FileUpload
                    name={'requestContractTransfer.files.registrationDoc'}
                    label={translate('registration-doc')}
                    isMandatory={true}
                />
            </Layout.Item>
        </>
    );
};
