import { useTranslation } from 'react-i18next';
import React, { Dispatch, useEffect, useState } from 'react';
import { FileUpload, ValidatedInput } from '@cp-shared-8/frontend-ui';
import { Fieldset, FormFieldLabel, Layout, Input, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { CountryCodeCombobox } from 'components/my-profile/contact-section/country-code-combobox/CountryCodeCombobox';
import { maxFileSize } from 'config';
import { formatAsFileSize } from '@cp-fr/common';
import { FormikProps, useFormikContext } from 'formik';
import { isEqual, debounce } from 'lodash';
import { CustomerDataFormFields, CustomerType, getFormCustomerType } from './utils';
import { CpDataApi } from 'cp-xhr';
import { getAddressValidationAutocompleteEndpoint } from '@cp-shared-8/apis';

export const AddressFields: React.FC<{ setRequiredFiles: Dispatch<React.SetStateAction<boolean[]>> }> = ({
    setRequiredFiles,
}) => {
    const { t } = useTranslation('my-profile');
    const formik: FormikProps<CustomerDataFormFields> = useFormikContext();
    const fileUploadTranslationPrefix = 'identification.edit-view.file-upload';
    const [isDirty, setIsDirty] = useState(false);
    const [initial] = useState(formik.initialValues.address);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initialAddressList: any[] = [];
    const [addressList, setAddressList] = useState(initialAddressList);
    const [customerType, setCustomerType] = useState<CustomerType>('');

    useEffect(() => {
        const dirty = !isEqual(initial, formik.values.address);
        setCustomerType(getFormCustomerType(formik.initialValues.identification.customerType));
        setIsDirty(dirty);
        setRequiredFiles((files) => {
            const updated = [...files];
            updated[1] = dirty;
            return updated;
        });
    }, [initial, formik.values]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setAutocompleteInputFields = (item: any) => {
        formik.setFieldValue('address.city', String(item.address.city || ''));
        formik.setFieldValue('address.street', String(item.address.street || ''));
        formik.setFieldValue('address.postalCode', String(item.address.zipCode));
        formik.setFieldValue('address.country', String(item.address.countryName || ''));
        formik.setFieldValue('address.houseNumber', String(item.address.houseNumber || ''));
    };
    const handleAddressAutocompleteInputChange = (query: string): boolean => {
        let selected = false;
        for (let i = 0; i < addressList.length; i++) {
            if (addressList[i] && addressList[i]['address']['label'] === query) {
                setAutocompleteInputFields(addressList[i]);
                selected = true;
                break;
            }
        }
        if (!selected) {
            CpDataApi.get(getAddressValidationAutocompleteEndpoint(), {
                params: { countryCode: 'FR', query: query },
            })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then(({ data }: { data: { items: any[] } }) => {
                    if (data.items && data.items.length > 0) {
                        setAddressList(data.items);
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(() => {
                    return false;
                });
        }
        return false;
    };

    const debouncedInputChange = debounce(handleAddressAutocompleteInputChange, 1000);

    return (
        <>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/3" s="1/1">
                            <FormFieldLabel htmlFor="address-autocomplete-input">
                                {t('confirm-information:search-address')}
                            </FormFieldLabel>
                            <Input
                                name="addressAutocomplete"
                                id={'address-autocomplete-input'}
                                testId="address-autocomplete-input"
                                type="search"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                    debouncedInputChange(event.target.value);
                                }}
                                list="suggested-addresses"
                                autoComplete="off"
                            />
                            <datalist id="suggested-addresses">
                                {addressList.map((item, index) => (
                                    <option key={index} value={item.address.label}>
                                        {item.address.label}
                                    </option>
                                ))}
                            </datalist>
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('address.edit-view.street-name')}
                                name="address.street"
                                testId="street-name"
                                type="text"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('address.edit-view.house-street-number')}
                                name="address.houseNumber"
                                testId="house-number"
                                type="text"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            </Fieldset>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('address.edit-view.building')}
                                name="address.building"
                                testId="building"
                                type="text"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('address.edit-view.street-add')}
                                name="address.streetAdd"
                                testId="street-add"
                                type="text"
                                autocomplete="no"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            </Fieldset>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('address.edit-view.postal-code')}
                                name="address.postalCode"
                                testId="postal-code"
                                type="text"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('address.edit-view.city')}
                                name="address.city"
                                testId="city"
                                type="text"
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <CountryCodeCombobox
                                name="address.country"
                                testId="country"
                                labelText={t('address.edit-view.country')}
                                fullCountry
                            />
                            {!!formik.errors?.address?.country && (
                                <ErrorMessage>{formik.errors?.address?.country}</ErrorMessage>
                            )}
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
                {isDirty && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item>
                                <FileUpload
                                    disabled={!isDirty}
                                    name="proofOfAddressChange"
                                    descriptionText={
                                        customerType === 'Retail'
                                            ? t(`confirm-information:proof-of-address-change`)
                                            : customerType === 'PM'
                                            ? t('confirm-information:kbis')
                                            : formik.values.personalUsage
                                            ? t('confirm-information:proof-of-address-change')
                                            : t('confirm-information:kbis')
                                    }
                                    buttonText={t(`${fileUploadTranslationPrefix}.button`)}
                                    cancelLabel={t('translation:editable-section-nav.cancel')}
                                    fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                    maxFileSize={maxFileSize}
                                    multiple={false}
                                    validFileFormats={['pdf']}
                                    sizeError={t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                    typeError={t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                    sizeAndTypeError={
                                        <>
                                            {t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                            <br />
                                            {t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                        </>
                                    }
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
            </Fieldset>
        </>
    );
};
