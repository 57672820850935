import React from 'react';
import { MarketingCardLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { MarketingCardUi } from './ui';
import { useMarketingCard } from './useMarketingCard';
import { getMostRecentProductWithBrand, useBundledProductsForMarketingCards } from './utils';
import { useProductRouting } from './useProductRouting';
import { useContracts } from '../contracts/useContracts';
import { useMyProfile } from '../my-profile';

type MarketingCardProps = {
    setShouldRender: (shouldRender: boolean) => void;
    shouldRender?: boolean;
};

const MarketingCardWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardUi);

export const MarketingCard: React.FC<MarketingCardProps> = ({ setShouldRender, shouldRender }) => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingErrorContract } = useContracts();
    const { cmsContent: marketingCardContent, isLoading, loadingError: loadingErrorCard } = useMarketingCard();
    const { data: myProfile, isLoading: isLoadingProfile, loadingError: loadingErrorProfile } = useMyProfile();

    const { productsWithMaintenance, otherProducts } = useBundledProductsForMarketingCards(contracts);
    const { data: productRouting, isLoading: isLoadingProductRouting } = useProductRouting(
        getMostRecentProductWithBrand(otherProducts),
    );

    return (
        <MarketingCardWithHandlers
            hasError={!!loadingErrorContract && !!loadingErrorCard && !!loadingErrorProfile}
            isLoading={
                !!shouldRender && (isLoading || isLoadingContracts || isLoadingProfile || isLoadingProductRouting)
            }
            productsWithMaintenance={productsWithMaintenance}
            otherProducts={otherProducts}
            onlineServicePlanUrl={productRouting?.url}
            content={marketingCardContent}
            contracts={contracts}
            setShouldRender={setShouldRender}
            profileData={myProfile}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
