import { longDateFormat } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import {
    CleaveInput,
    InfoCheckbox,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedInput,
} from '@cp-shared-8/frontend-ui';
import { Button, Fieldset, Form, Layout, Icon } from '@vwfs-bronson/bronson-react';
import { currentBrand } from 'config';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { contactDetailsCheckPagePath } from '../../../../navigation/paths';
import { validationSchema } from './validation';
import { isEmpty } from 'lodash';
import { RegistrationErrorNotification } from '../../registration-error';
import { useRegistrationSubmission } from '../useRegistrationSubmission';
import { RegistrationPhysicalFields } from '../RegistrationFormDataTypes';

export const testIds: { [key: string]: string } = {
    contractNumber: 'contract-number',
    dateOfBirth: 'date-of-birth',
    firstName: 'first-name',
    surname: 'surname',
    siren: 'siren',
    confirmPrivacyPolicy: 'confirm-privacy-policy',
    confirmUsageAgreement: 'confirm-usage-agreement',
    submitButton: 'submit-registration-button',
};

type RegistrationPhysicalProps = {
    privacyPolicy: string;
    usageAgreement: string;
    isFS: boolean;
};

export const RegistrationPhysical: React.FC<RegistrationPhysicalProps> = ({ privacyPolicy, usageAgreement, isFS }) => {
    const { t } = useTranslation('registration');
    const translationPrefix = 'registration-form.person.input-labels';

    const {
        onError: trackOnError,
        onSuccess: trackOnSuccess,
        onTyping,
        onSubmit: trackOnSubmit,
    } = useAnalyticsFormTracker({
        confirmError: 'onConfirmIdentiyAuthFailed',
        confirmSuccess: 'onConfirmConfirmIdentiy',
        startTyping: 'onStartTypingConfirmIdentity',
    });

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors =
        'contractNumber, firstName, surname, dateOfBirth, confirmTermsAndConditions, confirmPrivacyPolicy';

    const onSubmissionSuccess = useMemo(
        () => (): void => {
            trackOnSuccess();
            window.location.assign(contactDetailsCheckPagePath());
        },
        [trackOnSuccess],
    );
    const onSubmissionError = useMemo(
        () => (): void => {
            trackOnError();
        },
        [trackOnError],
    );

    const { submit, isLoading, errorCode } = useRegistrationSubmission(onSubmissionSuccess, onSubmissionError, false);

    const onSubmit = (values: RegistrationPhysicalFields) => {
        trackOnSubmit();
        const { contractNumber, dateOfBirth, firstName, surname, siren } = values;
        submit({
            contractNumber,
            firstName,
            surname,
            siren,
            dateOfBirth: formatCpDate(dateOfBirth, longDateFormat).format('YYYY-MM-DD'),
        });
    };

    const initialValues: RegistrationPhysicalFields = {
        contractNumber: '',
        dateOfBirth: '',
        firstName: '',
        surname: '',
        siren: '',
        confirmPrivacyPolicy: false,
        confirmTermsAndConditions: false,
    };

    return (
        <>
            {isLoading && <Spinner fullPage={true} />}
            <Layout.Item>
                <RegistrationErrorNotification errorCode={errorCode} />
            </Layout.Item>
            <Layout.Item default={'1/1'}>
                <Formik initialValues={initialValues} validationSchema={validationSchema(t, isFS)} onSubmit={onSubmit}>
                    {(formik) => (
                        <Form
                            onSubmit={(e) => preventSubmit(e)}
                            onChange={() => {
                                onTyping(formik.errors, formik.touched);
                            }}
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            numericOnly: false,
                                        }}
                                        label={`${t(`${translationPrefix}.contract-number`)} `}
                                        name={'contractNumber'}
                                        testId={testIds.contractNumber}
                                        withHtmlValidationWarning
                                        tooltip={
                                            <img
                                                src={`contents/${currentBrand}/${currentBrand}.png`}
                                                alt="contract number"
                                            />
                                        }
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <CleaveInput
                                        cleaveOptions={{
                                            delimiter: '/',
                                            blocks: [2, 2, 4],
                                            numericOnly: true,
                                        }}
                                        inputMode={'numeric'}
                                        label={`${t(`${translationPrefix}.birth-date.label`)} `}
                                        name={'dateOfBirth'}
                                        testId={testIds.dateOfBirth}
                                        placeholder={t(`${translationPrefix}.birth-date.placeholder`)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t(`${translationPrefix}.name.label`)}
                                        name="firstName"
                                        testId={testIds.firstName}
                                        placeholder={t(`${translationPrefix}.name.placeholder`)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t(`${translationPrefix}.surname.label`)}
                                        name="surname"
                                        testId={testIds.surname}
                                        placeholder={t(`${translationPrefix}.surname.placeholder`)}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <label className="c-form-field__label">
                                        <Icon
                                            name="exclamation-mark"
                                            size="xsmall"
                                            className="u-text-alert u-mr-xxxsmall"
                                        />
                                        <strong>{t(`${translationPrefix}.siren-warning`)}</strong>
                                    </label>
                                    <ValidatedInput
                                        label=""
                                        name="siren"
                                        testId={testIds.siren}
                                        inputMode={'numeric'}
                                        placeholder={t(`${translationPrefix}.siren.placeholder`)}
                                    />
                                </Fieldset.Row>
                                <InfoCheckbox
                                    preLinkLabel={t(`${translationPrefix}.agreements.privacy-policy.label`)}
                                    linkLabel={t(`${translationPrefix}.agreements.privacy-policy.link`)}
                                    fieldName={'confirmPrivacyPolicy'}
                                    testId={testIds.confirmPrivacyPolicy}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                                </InfoCheckbox>
                                <InfoCheckbox
                                    preLinkLabel={t(`${translationPrefix}.agreements.terms-and-conditions.label`)}
                                    linkLabel={t(`${translationPrefix}.agreements.terms-and-conditions.link`)}
                                    fieldName={'confirmTermsAndConditions'}
                                    testId={testIds.confirmUsageAgreement}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: usageAgreement }} />
                                </InfoCheckbox>

                                <Fieldset.Row>
                                    <Button
                                        full={true}
                                        onClick={() => {
                                            formik.submitForm();
                                            if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                                const errorToString = getErrors(formik.errors).toString();
                                                if (!errorToString) {
                                                    onAction(initialListErrors);
                                                } else onAction(getErrors(formik.errors));
                                            }
                                        }}
                                        testId={testIds.submitButton}
                                    >
                                        {t(`registration-form.button-labels.confirm`)}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
