import {
    RegistrationPhysicalPersonValidatorErrors,
    registrationPhysicalPersonValidator,
    longDateFormat,
} from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { RegistrationPhysicalFields } from '../RegistrationFormDataTypes';

const translationPrefix = 'registration-form.person.validation-errors';

export const errorMessages: RegistrationPhysicalPersonValidatorErrors = {
    contractNumber: {
        empty: `${translationPrefix}.contract-number.empty`,
        format: `${translationPrefix}.contract-number.format`,
        format11: `${translationPrefix}.contract-number.format-11`,
        format12: `${translationPrefix}.contract-number.format-12`,
    },
    firstName: `${translationPrefix}.name-empty`,
    surname: `${translationPrefix}.surname-empty`,
    siren: {
        empty: `${translationPrefix}.siren.empty`,
        format: `${translationPrefix}.siren.format`,
    },
    dateOfBirth: {
        empty: `${translationPrefix}.date-of-birth.empty`,
        format: `${translationPrefix}.date-of-birth.format`,
        future: `${translationPrefix}.date-of-birth.future`,
        tooYoung: `${translationPrefix}.date-of-birth.to-young`,
        tooOld: `${translationPrefix}.date-of-birth.to-old`,
    },
    privacyPolicy: `${translationPrefix}.privacy-policy`,
    usageAgreement: `${translationPrefix}.terms-and-conditions`,
};

export const validationSchema = (t: TFunction, isFS = false): Yup.SchemaOf<RegistrationPhysicalFields> => {
    const errors: RegistrationPhysicalPersonValidatorErrors = {
        contractNumber: {
            empty: t(errorMessages.contractNumber.empty),
            format: t(errorMessages.contractNumber.format),
            format11: t(errorMessages.contractNumber.format11),
            format12: t(errorMessages.contractNumber.format12),
        },
        firstName: t(errorMessages.firstName),
        surname: t(errorMessages.surname),
        siren: {
            empty: t(errorMessages.siren.empty),
            format: t(errorMessages.siren.format),
        },
        dateOfBirth: {
            empty: t(errorMessages.dateOfBirth.empty),
            format: t(errorMessages.dateOfBirth.format),
            future: t(errorMessages.dateOfBirth.future),
            tooYoung: t(errorMessages.dateOfBirth.tooYoung),
            tooOld: t(errorMessages.dateOfBirth.tooOld),
        },
        privacyPolicy: t(errorMessages.privacyPolicy),
        usageAgreement: t(errorMessages.usageAgreement),
    };

    return registrationPhysicalPersonValidator(isFS, errors).shape({
        confirmTermsAndConditions: Yup.bool().oneOf([true], errors.usageAgreement),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], errors.privacyPolicy),
        dateOfBirth: Yup.string()
            .required(errors.dateOfBirth.empty)
            .test('format', errors.dateOfBirth.format, (date) => formatCpDate(date, longDateFormat).isValid())
            .test('no future date', errors.dateOfBirth.future, (date) =>
                formatCpDate(date, longDateFormat).toMoment().isSameOrBefore(formatCpDate().toMoment()),
            )
            .test('too old', errors.dateOfBirth.tooOld, (date) =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrAfter(formatCpDate().subtract(100, 'years').toMoment()),
            )
            .test('underage', errors.dateOfBirth.tooYoung, (date) =>
                formatCpDate(date, longDateFormat)
                    .toMoment()
                    .isSameOrBefore(formatCpDate().subtract(18, 'years').toMoment()),
            ),
    });
};
