import { HeroImage, StaticPageLoadingPlaceholder, useAnalyticsErrorPageTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useNotFoundErrorPage } from './useNotFoundErrorPage';

export const NotFoundErrorPage: React.FC = () => {
    const { cmsContent: notFoundErrorPage, isLoading, loadingError } = useNotFoundErrorPage();
    const history = useHistory();
    const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

    useAnalyticsErrorPageTracker('notFoundError', !!notFoundErrorPage);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={notFoundErrorPage?.pretitle}
            title={notFoundErrorPage?.title || ''}
            subTitle={notFoundErrorPage?.subTitle}
            buttonText={notFoundErrorPage?.buttonText}
            clickHandler={() => history.goBack()}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
        />
    );
};
