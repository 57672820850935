import { RegistrationLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useLegalNotice } from 'components/legal-notice';
import { usePrivacyPolicy } from 'components/privacy-policy';
import React from 'react';
import { RegistrationUi } from './ui';

const RegistrationWithHandlers = withLoadingAndNoConnectionHandler(RegistrationUi);

export const Registration: React.FC = () => {
    const {
        cmsContent: privacyPolicy,
        isLoading: isLoadingPrivacyPolicy,
        loadingError: loadingErrorPrivacyPolicy,
    } = usePrivacyPolicy();
    const {
        cmsContent: legalNotice,
        isLoading: isLoadingLegalNotice,
        loadingError: loadingErrorLegalNotice,
    } = useLegalNotice();

    const isLoading = isLoadingPrivacyPolicy || isLoadingLegalNotice;
    const loadingError = loadingErrorPrivacyPolicy || loadingErrorLegalNotice;

    useAnalyticsPageViewTracker('confirmIdentity');

    return (
        <RegistrationWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            privacyPolicy={privacyPolicy?.content}
            usageAgreement={legalNotice?.content}
            loadingPlaceholder={<RegistrationLoadingPlaceholder />}
        />
    );
};
