import { CPDate } from '@cp-shared-8/common-utilities';

export type PartialEarlySettlementFormValues = {
    requestDate: CPDate;
    amount: string;
    goal: string;
    reason: string;
};

export const getInitialValues: () => PartialEarlySettlementFormValues = () => ({
    requestDate: '',
    amount: '',
    goal: 'MONTHLY_AMOUNT_REDUCTION',
    reason: '',
});
