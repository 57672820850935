import { AuthorizationDebitRequest, BalanceReason } from '@cp-fr/common';
import { RequestInitialValues } from '../../../../initialValues';

export const authorizationDebitMapper = ({
    contractsSelection,
    authorizationDebit,
}: RequestInitialValues): AuthorizationDebitRequest => {
    return {
        requestCategory: 'AUTHORIZATION_DEBIT',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: authorizationDebit.email,
        phonenumber: authorizationDebit.phonenumber,
        nameSurname: authorizationDebit.nameSurname,
        authorizes: authorizationDebit.authorizes || '',
        amount: authorizationDebit.amount,
        balanceReason: authorizationDebit.balanceReason as BalanceReason,
        otherBalanceReason: authorizationDebit.otherBalanceReason,
        expirationMonth: authorizationDebit.expirationMonth,
    };
};
