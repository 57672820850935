import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Layout, Tag } from '@vwfs-bronson/bronson-react';
import { currentBrand } from 'config';

type EditButtonWithStatusProps = {
    onClick: () => void;
    showStatus: boolean;
    disabled?: boolean;
};

export const EditButtonWithStatus: React.FC<EditButtonWithStatusProps> = ({
    onClick,
    showStatus,
    disabled = false,
}) => {
    const { t } = useTranslation('translation');

    const isVW6 = currentBrand === 'vw';
    const isAudi = currentBrand === 'audi';
    const isSkoda = currentBrand === 'skoda';

    return (
        <Layout className="u-ml-none">
            <Layout.Item default={'1/1'} xs={'1/1'} className={'c-data-overview__button u-mb-small u-pl-small '}>
                <div className={`${showStatus ? 'u-mr' : 'u-mr-small'}`}>
                    <Layout>
                        {showStatus && (
                            <Layout.Item default={'2/3'} xs={'1/1'} className={'u-pl-small u-mt-none u-mb-small@s'}>
                                <Tag warning className={`${isAudi || isSkoda ? '' : 'u-mr-small'}`} testId="tagbox">
                                    {t('editable-section-nav.modification-in-progress')}
                                </Tag>
                            </Layout.Item>
                        )}
                        <Layout.Item default={'1/3'} xs={'1/1'} className={'u-pl-xxsmall u-mt-none'}>
                            <Button
                                element="a"
                                link
                                small
                                iconViaCss
                                iconReversed
                                onClick={onClick}
                                testId={'edit-button'}
                                disabled={disabled}
                                className={`c-data-overview__button u-mb-small ${
                                    isSkoda ? (showStatus ? 'u-mr-large' : '') : 'u-ml-xsmall'
                                } ${isVW6 ? 'u-mr-small u-mr@xxl' : ''} u-ml-none@xs`}
                            >
                                {t('editable-section-nav.edit')}
                            </Button>
                        </Layout.Item>
                    </Layout>
                </div>
            </Layout.Item>
        </Layout>
    );
};
