import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@vwfs-bronson/bronson-react';

export const ModificationStatus: React.FC = () => {
    const { t } = useTranslation('translation');

    return (
        <div className="u-text-right u-mb-small">
            <Tag warning testId="tagbox">
                {t('editable-section-nav.modification-in-progress')}
            </Tag>
        </div>
    );
};
