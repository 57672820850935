import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { Intentions } from '@cp-fr/common';

type IntentionsSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const IntentionsSelection: React.FC<IntentionsSelectionProps> = ({ label, name, isMandatory = true }) => {
    const intentions: Intentions[] = ['Restituer le véhicule', 'Conserver le véhicule'];

    const selectItems: ValidatedSelectItem[] = intentions.map((intention: Intentions) => {
        return { value: intention, label: intention };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
