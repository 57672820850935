import { Contract, getPartialEarlySettlementEndpoint, PartialEarlySettlement } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { ContractHeader, Spinner } from '@cp-shared-8/frontend-ui';
import { Card, ContentSection, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from 'components/license-plate/LicensePlate';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FormData, PartialEarlySettlementForm } from './form';

type PartialEarlySettlementUiProps = {
    contract?: Contract;
};

type RequestProps = {
    isLoading?: boolean;
    errorCode?: string;
    isSuccess?: boolean;
};

export const PartialEarlySettlementUi: React.FC<PartialEarlySettlementUiProps> = ({ contract }) => {
    const [earlySettlementRequest, setEarlySettlementRequest] = useState<RequestProps>({});

    const { t } = useTranslation('early-settlement');
    const history = useHistory();
    const handleModalClose = (): void => setEarlySettlementRequest({});

    if (!contract) return null;
    const handleSubmit = async (data: FormData): Promise<void> => {
        const body: PartialEarlySettlement = {
            ...data,
            amount: Number(data.amount.replace(',', '.')),
            requestDate: formatCpDate(data.requestDate).format('YYYY-MM-DD'),
            contractNumber: contract.contractNumber,
        };

        setEarlySettlementRequest({ isLoading: true });
        CpDataApi.post(getPartialEarlySettlementEndpoint(), body)
            .then(() => {
                setEarlySettlementRequest({ isSuccess: true });
            })
            .catch(({ response }) => {
                setEarlySettlementRequest({
                    errorCode: response?.data?.code,
                });
            });
    };

    const getErrorMessage = (errorCode: string | undefined) => {
        switch (errorCode) {
            case 'CHANGE_TOO_LATE':
                return 'partial-early-settlement.modal.error.change-to-late';
            case 'USER_WITH_NO_EMAIL':
                return 'partial-early-settlement.modal.error.missing-email';
            default:
                return 'partial-early-settlement.modal.error.sending-problem';
        }
    };

    const { contractNumber, productType = '', carModel = ' ', licensePlate = '' } = contract;

    return (
        <>
            {earlySettlementRequest.isLoading && <Spinner fullPage={true} />}
            <ContentSection pageWrapSize="medium">
                <Layout>
                    <Layout.Item default="1/1">
                        <Card element="article" title="">
                            <ContractHeader
                                carInformation={carModel}
                                contractCategory={productType}
                                contractNumber={contractNumber}
                                contractIdentifier={<LicensePlate registrationNumber={licensePlate} />}
                            />
                        </Card>
                    </Layout.Item>
                    <Layout.Item default="1/1">
                        <PartialEarlySettlementForm handleSubmit={handleSubmit} />
                    </Layout.Item>
                </Layout>
            </ContentSection>
            <Modal
                shown={!!earlySettlementRequest.isSuccess}
                status="success"
                onClose={(): void => history.push(dashboardPagePath())}
                onCancel={(): void => history.push(dashboardPagePath())}
                buttonCancelText={t('translation:editable-section-nav.ok')}
                title={t('partial-early-settlement.modal.success.header')}
                testId={'success-modal'}
            >
                {t('partial-early-settlement.modal.success.message')}
            </Modal>
            <Modal
                shown={!!earlySettlementRequest.errorCode}
                status="error"
                onClose={handleModalClose}
                onCancel={handleModalClose}
                buttonCancelText={t('translation:editable-section-nav.ok')}
                testId={'error-modal'}
            >
                {t(getErrorMessage(earlySettlementRequest.errorCode) || '')}
            </Modal>
        </>
    );
};
