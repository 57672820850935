import { RegistrationPhysicalPersonValidatorErrors } from '../types';

export const RegistrationPhysicalPersonError: RegistrationPhysicalPersonValidatorErrors = {
    contractNumber: {
        empty: 'Contract number is empty',
        format: 'Contract number is wrong format',
        format11: 'Contract number is wrong format',
        format12: 'Contract number is wrong format',
    },
    firstName: 'First name is empty',
    surname: 'Surname is empty',
    siren: {
        empty: 'Siren number is required',
        format: 'Siren number should have correct format',
    },
    dateOfBirth: {
        empty: 'Date of birth empty',
        format: 'Date of birth wrong format',
        future: 'Date of birth in the future',
        tooYoung: 'Date of birth indicates client too young',
        tooOld: 'Date of birth indicates client too old',
    },
    privacyPolicy: 'Privacy policy is required',
    usageAgreement: 'Usage agreement is required',
};
