import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RegistrationCertificateDuplicateForm } from '.';
import { useMyProfile } from 'components/my-profile';

const FormWithHandlers = withLoadingAndNoConnectionHandler(RegistrationCertificateDuplicateForm);

export const RegistrationCertificateDuplicateFormWithHandlers: React.FC = () => {
    const { data, isLoading, loadingError } = useMyProfile();

    return <FormWithHandlers isLoading={isLoading} myProfileData={data} hasError={!!loadingError} />;
};
