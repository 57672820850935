import React from 'react';
import { UnpaidAlertNotificationUi } from './ui';

export type UnpaidAlertNotificationProps = {
    amountUnpaidNotPaid: number;
};

export const UnpaidAlertNotification: React.FC<UnpaidAlertNotificationProps> = ({ amountUnpaidNotPaid }) => {
    return <UnpaidAlertNotificationUi amountUnpaidNotPaid={amountUnpaidNotPaid} />;
};
