import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../edit-view/EditView';

export const NotificationForEditStatus: React.FC<{ editStatus: EditStatus }> = ({ editStatus }) => {
    const { t } = useTranslation('my-profile');

    switch (editStatus) {
        case EditStatus.SUCCESS: {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('contact-details.notification.success.title')}
                    text={t('contact-details.notification.success.content')}
                    testId={'editStatus'}
                />
            );
        }
        case EditStatus.ERROR: {
            return (
                <Notification
                    status={NotificationStatus.error}
                    text={t('contact-details.notification.error')}
                    testId={'editStatus'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
