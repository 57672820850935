import { ComboboxDefaultState } from '@cp-shared-8/frontend-ui';
import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { ComboboxOption } from 'types/bronson-react';

export const SelectField: React.FC<{
    testId: string;
    name: string;
    label: string;
    options: ComboboxOption[];
    visible: boolean;
    defaultState?: ComboboxDefaultState;
}> = ({ testId, label, name, options, visible, defaultState }) => {
    const [field, , helpers] = useField(name);

    const onChange = useCallback((values: ComboboxOption[]) => {
        helpers.setValue(values[0]?.key);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const comboDefaultState = defaultState
        ? defaultState
        : {
              value: [
                  {
                      value: options.find((o) => o.key === field.value)?.value || field.value,
                      key: field.value,
                  },
              ],
          };

    if (!visible) return null;

    return (
        <FormField type="select" testId={testId} id={'document-type'} labelText={label}>
            <Combobox name={name} testId={`${testId}-combo`} onChange={onChange} defaultState={comboDefaultState}>
                {options.map((filter) => {
                    return (
                        <Combobox.Item key={filter.key} optionValue={filter.value} optionKey={filter.key}>
                            {filter.value}
                        </Combobox.Item>
                    );
                })}
            </Combobox>
        </FormField>
    );
};
