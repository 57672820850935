import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { EarlyReturnPenalty } from 'components/early-return-penalty/EarlyReturnPenalty';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const EarlyReturnPenaltyPage: React.FC = () => {
    const { contractId } = useParams<{ contractId: string }>();
    const { t } = useTranslation('early-return-penalty');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{t('headline')}</Heading>
            <EarlyReturnPenalty contractId={contractId} />
        </ContentSection>
    );
};
