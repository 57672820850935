import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { FrMonth } from '@cp-fr/common';

type MonthSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const MonthSelection: React.FC<MonthSelectionProps> = ({ label, name, isMandatory = true }) => {
    const months: FrMonth[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    const selectItems: ValidatedSelectItem[] = months.map((month: FrMonth) => {
        return { value: month, label: month };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
