import { getIbanStatusEndpoint } from '@cp-fr/common';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CpDataApi } from '../../../../../../cp-xhr';
import { RootState } from '../../../../../../store/rootReducer';
import { ibanStatusTagFetchStateActions } from './useIbanStatusTagSlice';

let _inProgress = false;

export const useIbanStatusTag = () => {
    const dataValid = useSelector((state: RootState) => state.ibanStatusTagFetchState.dataValid);
    const inProgress = useSelector((state: RootState) => state.ibanStatusTagFetchState.inProgress);
    const [isIbanStatusTag, setIsIbanStatusTag] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (_inProgress || inProgress || dataValid) return;

        dispatch(ibanStatusTagFetchStateActions.setFetchProgress(true));
        _inProgress = true;
        dispatch(ibanStatusTagFetchStateActions.setDataValidity(true));

        CpDataApi.get(getIbanStatusEndpoint())
            .then((response) => {
                if (response.data.requestCustomerStatus === 'IN_PROGRESS') {
                    setIsIbanStatusTag(true);
                }
            })
            .catch(() => {
                setIsIbanStatusTag(false);
            })
            .finally(() => {
                dispatch(ibanStatusTagFetchStateActions.setFetchProgress(false));
                _inProgress = false;
            });
    }, [dispatch, dataValid, inProgress]);

    return isIbanStatusTag;
};
