import { EarlySettlementLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { TotalEarlySettlementUi } from './ui/TotalEarlySettlementUi';

export const TotalEarlySettlement: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data: contract, isLoading, loadingError } = useContract(contractId);

    const TotalEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(TotalEarlySettlementUi);

    return (
        <TotalEarlySettlementWithHandlers
            isLoading={isLoading}
            contract={contract}
            hasError={!!loadingError}
            loadingPlaceholder={<EarlySettlementLoadingPlaceholder withDividedContractHeader summaryElements={1} />}
        />
    );
};
