import React from 'react';
import { ParkingAccountLinking } from '../parking-account-linking';
import { ParkingSessions } from '../parking-sessions';
import { useLocation } from 'react-router-dom';

export const ParkingUi: React.FC<{ handlePbpLogin: () => void; payByPhoneId?: string }> = ({
    handlePbpLogin,
    payByPhoneId,
}) => {
    const location = useLocation();

    return payByPhoneId ? (
        <ParkingSessions />
    ) : (
        <ParkingAccountLinking handlePbpLogin={handlePbpLogin} error={location.state as string} />
    );
};
