import { MarketingSettings } from '@cp-fr/common';
import React from 'react';
import { ConsultView } from './consult-view/ConsultView';

export type EditStatusWithData = {
    marketingSettings?: MarketingSettings;
};

export const MarketingSettingsSection: React.FC<{
    marketingSettings?: MarketingSettings;
}> = ({ marketingSettings }) => {
    return <ConsultView marketingSettings={marketingSettings} />;
};
