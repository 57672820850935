import {
    CustomerChangeName,
    formatAsFileSize,
    Identification,
    ChangeNameDocument,
    getChangeNameSMEEndpoint,
    SmeCustomerData,
    MarketError,
    FileInfo,
    editViewIdentificationSectionSmeFrontendSchema,
} from '@cp-fr/common';
import { FileUpload, preventSubmit, Spinner, ValidatedInput } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { maxFileSize } from 'config';
import { CpDataApi } from 'cp-xhr';
import { SMEFormValues, initialValues } from './initialValues';
import { getSeparatedFilesIds, uploadFilesToMediaStorage } from './helpers';

export enum EditStatus {
    NOT_PERFORMED = 'NOT_PERFORMED',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    ERROR_VIRUS = 'ERROR_VIRUS',
    NO_CHANGE_DETECTED = 'NO_CHANGE_DETECTED',
    WRONG_REASON = 'WRONG_REASON',
    SIREN_IS_MANDATORY_FOR_SME = 'SIREN_IS_MANDATORY_FOR_SME',
    ERROR_NO_EMAIL = 'USER_WITH_NO_EMAIL',
}

export type EditStatusWithData = {
    status: EditStatus;
    identification?: Identification;
    formValues?: CustomerChangeName;
};

export type EditSMEViewProps = {
    identification: Identification;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedIdentification?: Identification) => void;
    editStatus: EditStatus;
};

export const EditViewSME: React.FC<EditSMEViewProps> = ({ identification, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('my-profile');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validationPrefix = 'identification.edit-view-sme';

    const fileUploadTranslationPrefix = 'identification.edit-view-sme.file-upload';
    initialValues.companyName = (identification.data as SmeCustomerData).companyName ?? '';

    const handleSubmit = async (values: SMEFormValues): Promise<void> => {
        setIsSubmitting(true);

        const { companyName: company, kbis, insee, registrationCertificate } = values;
        const { companyName, customerNumber, gender, isEligibleNpaiNotification } =
            identification.data as SmeCustomerData;

        const documentsValues = {
            kbis,
            insee,
            registrationCertificate,
        };

        const mediaStorageFilesIds: Promise<ChangeNameDocument>[] = uploadFilesToMediaStorage(documentsValues);

        const doAfterUploadError = (): void => {
            setIsSubmitting(false);
            finishEditing(EditStatus.ERROR);
        };

        const doAfterSuccessfullUpload = (documentsIds: FileInfo[]): void => {
            const preparedChangeValues: CustomerChangeName = {
                customerType: identification.customerType,
                companyName: companyName === company ? '' : company,
                documents: [...documentsIds],
            };

            CpDataApi.put(getChangeNameSMEEndpoint(), preparedChangeValues)
                .then(() => {
                    const updatedName: Identification = {
                        customerType: identification.customerType,
                        data: {
                            companyName: company,
                            customerNumber,
                            gender,
                            isEligibleNpaiNotification,
                        } as SmeCustomerData,
                    };
                    setIsSubmitting(false);
                    finishEditing(EditStatus.SUCCESS, updatedName);
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    switch (error.response.data.code) {
                        case MarketError.NO_CHANGE_DETECTED:
                            finishEditing(EditStatus.NO_CHANGE_DETECTED);
                            break;
                        case MarketError.MARKET_API_NOT_REACHABLE:
                            finishEditing(EditStatus.ERROR);
                            break;
                        case MarketError.WRONG_REASON:
                            finishEditing(EditStatus.WRONG_REASON);
                            break;
                        case MarketError.VIRUS:
                            finishEditing(EditStatus.ERROR_VIRUS);
                            break;
                        case MarketError.SIREN_IS_MANDATORY_FOR_SME:
                            finishEditing(EditStatus.SIREN_IS_MANDATORY_FOR_SME);
                            break;
                        case MarketError.ERROR_NO_EMAIL:
                            finishEditing(EditStatus.ERROR_NO_EMAIL);
                            break;
                        default:
                            finishEditing(EditStatus.ERROR);
                            break;
                    }
                });
        };
        Promise.all(mediaStorageFilesIds)
            .then((filesId: ChangeNameDocument[]) => {
                const separatedDocumentsIds: FileInfo[] = getSeparatedFilesIds(filesId);
                doAfterSuccessfullUpload(separatedDocumentsIds);
            })
            .catch(() => {
                doAfterUploadError();
            });
    };

    const getFileUploadsList = (): { name: string; suffix: string }[] => {
        return [
            { name: 'kbis', suffix: 'kbis' },
            { name: 'insee', suffix: 'insee' },
        ];
    };

    const errorMessages = {
        companyName: {
            required: t(`${validationPrefix}.validation-error.required-input`),
            matches: t(`${validationPrefix}.validation-error.no-numbers`),
        },
        kbis: {
            required: t(`${validationPrefix}.file-upload.validation.required`),
            max: t(`${validationPrefix}.file-upload.validation.wrong-number-of-files`),
        },
        insee: {
            required: t(`${validationPrefix}.file-upload.validation.required`),
            max: t(`${validationPrefix}.file-upload.validation.wrong-number-of-files`),
        },
    };

    return (
        <DataOverview title={t('identification.edit-view-sme.title')}>
            <Paragraph className={'u-mb'}>{t('identification.edit-view-sme.description')}</Paragraph>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                initialValues={initialValues}
                validationSchema={editViewIdentificationSectionSmeFrontendSchema(errorMessages)}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={(e) => preventSubmit(e)} data-testid="edit-form">
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ValidatedInput
                                            label={t('identification.edit-view-sme.company-name')}
                                            name="companyName"
                                            testId="companyName"
                                            type="text"
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                {getFileUploadsList().map(({ name, suffix }) => {
                                    return (
                                        <Layout.Item key={name}>
                                            <FileUpload
                                                name={name}
                                                descriptionText={t(`${fileUploadTranslationPrefix}.${suffix}`)}
                                                buttonText={t(`${fileUploadTranslationPrefix}.button`)}
                                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                                fileSizeFormatter={(bytes: number): string =>
                                                    formatAsFileSize(bytes, 2)
                                                }
                                                maxFileSize={maxFileSize}
                                                multiple={false}
                                                validFileFormats={['pdf']}
                                                sizeError={t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                                typeError={t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                                sizeAndTypeError={
                                                    <>
                                                        {t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                                        <br />
                                                        {t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                                    </>
                                                }
                                            />
                                        </Layout.Item>
                                    );
                                })}
                            </Layout>
                        </Fieldset.Row>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button secondary onClick={cancelEditing} testId="cancel-button" type="button">
                                        {t('identification.edit-view-sme.button.cancel')}
                                    </Button>
                                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                    <Button onClick={handleSubmit as any} testId="submit-button" type="button">
                                        {t('identification.edit-view-sme.button.confirm')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
