import { ContactDetails, ContactDetailsChange } from '@cp-fr/common';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-8/frontend-ui';
import { changeContactDetailsPath } from 'components/navigation/paths';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ConsultView } from './consult-view/ConsultView';
import { EditStatus, EditView } from './edit-view/EditView';

export type EditStatusWithData = {
    status: EditStatus;
    contactDetails?: ContactDetails;
    formValues?: ContactDetailsChange;
};

export const ContactSection: React.FC<{
    contactDetails?: ContactDetails;
    isContactInformationChangePending: boolean;
}> = ({ contactDetails, isContactInformationChangePending }) => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileContactSubmitFailed',
        confirmSuccess: 'onEditProfileContactSuccess',
    });

    const [editStatus, setEditStatus] = useState<EditStatusWithData>({
        status: EditStatus.NOT_PERFORMED,
        contactDetails,
    });

    const startEditing = (): void => {
        onStart();
        history.push(changeContactDetailsPath());
    };

    const cancelEditing = (): void => {
        onCancel('Contact');
        setEditStatus({
            ...editStatus,
            status: EditStatus.NOT_PERFORMED,
            contactDetails,
        });
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedContact?: ContactDetails): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setEditStatus({
            ...editStatus,
            status: newEditStatus,
            contactDetails: newEditStatus === EditStatus.SUCCESS ? updatedContact : contactDetails,
        });
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeContactDetailsPath()}>
                <EditView contactDetails={contactDetails} cancelEditing={cancelEditing} finishEditing={finishEditing} />
            </Route>
            <Route path={path}>
                <ConsultView
                    changePending={isContactInformationChangePending || isContactInformationChangePending}
                    contactDetails={editStatus.contactDetails}
                    startEditing={startEditing}
                    editStatus={editStatus.status}
                />
            </Route>
        </Switch>
    );
};
