import React from 'react';
import { ValidatedTextarea } from '@cp-shared-8/frontend-ui';

type TextAreaProps = {
    label: string;
    name: string;
    placeholder?: string;
    isMandatory?: boolean;
};

export const TextArea: React.FC<TextAreaProps> = ({ label, name, placeholder, isMandatory = true }) => {
    return (
        <ValidatedTextarea
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            placeholder={placeholder}
            maxLength={1000}
        />
    );
};
