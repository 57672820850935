import React, { useEffect } from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { getUrlSearchParams } from '../../../utils/urlSearchParams';
import { useHistory } from 'react-router-dom';
import { parkingPagePath } from '../../navigation/paths';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from '../../../cp-xhr';
import { getLinkPbpWithCustomerIndexEndpoint, PbpCustomerIndexErrors } from '@cp-fr/common';

export const ParkingLinkingResult: React.FC = () => {
    const history = useHistory();

    const token: string | null = getUrlSearchParams().get('token');
    const pbpError: string | null = getUrlSearchParams().get('error');

    useEffect(() => {
        const redirectToParking = (errorCode?: string) =>
            history.push({
                pathname: parkingPagePath(),
                state: errorCode,
            });
        if (pbpError) redirectToParking(pbpError);
        else if (token) {
            CpDataApi.post(getLinkPbpWithCustomerIndexEndpoint(), { token })
                .then(() => {
                    redirectToParking();
                })
                .catch((e) =>
                    e.response.data.code === PbpCustomerIndexErrors.PBPID_ALREADY_EXISTS
                        ? redirectToParking('409')
                        : redirectToParking('500'),
                );
        } else redirectToParking('500');
    }, [pbpError, token, history]);

    return (
        <ContentSection pageWrapSize="medium">
            <Spinner center />
        </ContentSection>
    );
};
