import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { DateInput, EmailInput, NatureOfClaimSelection, PhonenumberInput } from '../../simple-form-components';
import { useField } from 'formik';
import { NatureOfClaim } from '@cp-fr/common';

export const LossForm: React.FC = () => {
    const { t } = useTranslation('request');
    const [field] = useField('loss.natureOfClaim');
    const selectedNature: NatureOfClaim = field.value;
    const translationPrefix = 'category-selection.loss';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const getNatureInfo = (): string => {
        let key = '';
        switch (selectedNature) {
            case 'Incapacité de travail':
                key = 'incapacity';
                break;
            case 'Perte d’emploi':
                key = 'job-loss';
                break;
            case "Perte Totale et Irréversible d'Autonomie (PTIA)":
                key = 'ptia';
                break;
        }
        return key ? translate(`nature-additional-info.${key}`) : '';
    };

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate('description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'loss.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput label={translate('phonenumber')} name={'loss.phonenumber'} />
            </Layout.Item>
            <Layout.Item>
                <NatureOfClaimSelection label={translate('nature-of-claim')} name={'loss.natureOfClaim'} />
            </Layout.Item>
            {selectedNature && (
                <Layout.Item>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: getNatureInfo(),
                        }}
                    />
                </Layout.Item>
            )}
            <Layout.Item>
                <DateInput label={translate('start-date')} name={'loss.startDate'} />
            </Layout.Item>
        </>
    );
};
