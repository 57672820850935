import { IbanValidatorErrors } from '../types';

export const IbanValidatorError: IbanValidatorErrors = {
    iban: {
        required: 'iban is required',
        validCountryCode: 'countryCode is not valid',
        sameIban: 'provided same iban',
        ibanLength: 'iban length is not valid',
        invalidIban: 'iban value is not valid',
        ibanValidatorUnavailable: 'iban validator is unavailable',
    },
    ibanHolder: {
        required: 'ibanHolder is required',
        maxLength: 'ibanHolder must have max 50 characters',
    },
};
