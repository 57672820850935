import { RequestInitialValues } from '../../../../initialValues';
import { DeadlineRequest, FrMonth } from '@cp-fr/common';

export const deadlineMapper = ({ contractsSelection, deadline }: RequestInitialValues): DeadlineRequest => {
    return {
        requestCategory: 'DEADLINE',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: deadline.email,
        phonenumber: deadline.phonenumber,
        deadline: deadline.deadline as FrMonth,
    };
};
