import { FinancialDetails } from '@cp-fr/common/src/apis/services/types/contracts';
import React, { useState } from 'react';
import {
    Button,
    Heading,
    ButtonContainer,
    Fieldset,
    Layout,
    RadioButton,
    RadioButtonGroup,
} from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';
import { getChangePaymentDayEndpoint, PaymentDayChange } from '@cp-fr/common';
import { currentBrand } from 'config';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { CpDataApi } from 'cp-xhr';
import { EditStatus } from '../enums';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { PaymentDayNotification } from '../payment-day-notification/PaymentDayNotification';

type EditViewProps = {
    details?: FinancialDetails;
    contractId: string;
    finishEditing: (editStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ details, contractId, finishEditing }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editStatus, setEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const { onAction: onEditCancel } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionCancel');
    const { onAction: onEditFail } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSubmitFailed');
    const { onAction: onSuccess } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSuccess');

    const history = useHistory();
    const { t } = useTranslationWithFormatting('financial-details');
    const prefix = `collection-date-section.edit`;

    const radioItems = [1, 5, 15, 20, 25];
    const currentPaymentDay = details?.collectionDate || 0;
    radioItems.includes(currentPaymentDay) && radioItems.splice(radioItems.indexOf(currentPaymentDay), 1);
    const [paymentDay, setPaymentDay] = useState(radioItems[0]);
    const defaultCheckedIndex = radioItems.indexOf(paymentDay);

    const createRadioButton = () => {
        return radioItems.map((value, index) => {
            return (
                <RadioButton
                    key={index}
                    defaultChecked={index === defaultCheckedIndex}
                    name="newPaymentDay"
                    value={value}
                    testId={`test-paymentDay-${value}`}
                    onChange={() => {
                        setPaymentDay(value);
                    }}
                >
                    {value}
                </RadioButton>
            );
        });
    };

    const initialValues: PaymentDayChange = {
        newPaymentDay: paymentDay,
    };

    const handleSubmit = () => {
        setEditStatus(EditStatus.NOT_PERFORMED);
        setIsSubmitting(true);

        const preparedData: PaymentDayChange = {
            newPaymentDay: paymentDay,
        };

        CpDataApi.put(getChangePaymentDayEndpoint(contractId), preparedData)
            .then(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS);
                onSuccess();
            })
            .catch((error) => {
                onEditFail();
                setIsSubmitting(false);
                const errorCode = error.response.data.code;
                setEditStatus(
                    errorCode === EditStatus.USER_WITH_NO_EMAIL_CODE
                        ? EditStatus.USER_WITH_NO_EMAIL_CODE
                        : EditStatus.ERROR,
                );
            });
    };

    return (
        <>
            <div data-theme={currentBrand === 'seat' ? 'alternative' : 'primary'} className={'u-mb'}>
                <Heading className="c-data-overview__header u-pt u-mb-none" level={'6'}>
                    {t(`${prefix}.headline`)}
                    <Button
                        element="a"
                        link
                        className="c-data-overview__button u-mb-small u-ml-xsmall u-mr-small c-icon  c-icon--[semantic-close]"
                        onClick={() => history.push(dashboardPagePath())}
                        testId={'x-back-button'}
                        disabled={!!isSubmitting}
                    >
                        {' '}
                    </Button>
                </Heading>
                {isSubmitting ? (
                    <Spinner center />
                ) : (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ submitForm }) => (
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/1" s="1/1">
                                                <p>{t(`${prefix}.text`)}</p>
                                                <div className="u-pr">
                                                    <RadioButtonGroup>{createRadioButton()}</RadioButtonGroup>
                                                </div>
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <PaymentDayNotification editStatus={editStatus} />
                                        <ButtonContainer className={'u-mt'} center>
                                            <Button
                                                secondary
                                                testId="back-to-dashboard"
                                                onClick={(): void => {
                                                    onEditCancel();
                                                    history.push(dashboardPagePath());
                                                }}
                                            >
                                                {t(`${prefix}.cancel-button`)}
                                            </Button>
                                            <Button onClick={submitForm} testId="submit-paymentDay" type="submit">
                                                {t(`${prefix}.confirm-button`)}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </>
    );
};
