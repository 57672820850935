// The following URLs needs to be whitelisted within IDK (including domain and port)
export const authPaths = {
    loginCallback: () => '/authentication/callback',
    logoutCallback: () => '/authentication/logout',
    silentRenew: () => '/authentication/silent_callback',
};

export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function loginRedirectPagePath(): string {
    return '/login-redirect';
}

export function contactDetailsCheckPagePath(): string {
    return '/contact-details-check';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function forbiddenPagePath(): string {
    return '/forbidden';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function civicToolCookiePolicyPath(): string {
    return '/page/cookies';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function myDocumentsPath(): string {
    return '/my-documents';
}

export function myRequestsPagePath(): string {
    return '/my-requests';
}

export function myRequestsPagePathForContact(): string {
    return '/my-requests-contact';
}

export function earlySettlementPagePath(contractId = ':contractId'): string {
    return `/early-settlement/${contractId}`;
}

export function partialEarlySettlementPagePath(contractId = ':contractId'): string {
    return `/partial-early-settlement/${contractId}`;
}

export function totalEarlySettlementPagePath(contractId = ':contractId'): string {
    return `/total-early-settlement/${contractId}`;
}

export function parkingPagePath(): string {
    return '/my-parking';
}

export function changeIbanPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-iban`;
}

export function contractEndOptionsPath(contractId = ':contractId'): string {
    return `/contract-end-options/${contractId}`;
}

export function changeMarketingSettingsPath(): string {
    return `${myProfilePagePath()}/change-marketing-settings`;
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function changeAddressPath(): string {
    return `${myProfilePagePath()}/change-address`;
}

export function changeBillingAddressPath(): string {
    return `${myProfilePagePath()}/change-billing-address`;
}

export function changeNamePath(): string {
    return `${myProfilePagePath()}/change-name`;
}

export function changeSMENamePath(): string {
    return `${myProfilePagePath()}/change-sme-name`;
}

export function changePaymentDayPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-payment-day`;
}

export function earlyReturnPenaltyPagePath(contractId = ':contractId'): string {
    return `/early-return-penalty/${contractId}`;
}

export function parkingLinkingResultPath(): string {
    return `/parking-linking-result`;
}

export function confirmInformationPath(): string {
    return '/confirm-information';
}
