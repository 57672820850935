import { FinancialDetails } from '@cp-fr/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { Heading } from '@vwfs-bronson/bronson-react';
import { ErrorNotification } from 'components/notifications/error/ErrorNotification';
import { currentBrand } from 'config';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { EditButtonWithStatus } from '../../common/edit-button-with-status';
import { EditStatus } from '../enums';
import { PaymentDayNotification } from '../payment-day-notification/PaymentDayNotification';
import { usePaymentDayStatusTag } from './usePaymentDayStatusTag';

type NonEditViewProps = {
    details?: FinancialDetails;
    isActive?: boolean;
    startEditing: () => void;
    editStatus: EditStatus;
    editButtonDisabled: boolean;
};

export const NonEditView: React.FC<NonEditViewProps> = ({
    details,
    isActive,
    startEditing,
    editStatus,
    editButtonDisabled,
}) => {
    const { t } = useTranslationWithFormatting('financial-details');

    const isPaymentDayStatusTag = usePaymentDayStatusTag();

    if (!details || !isActive) {
        return null;
    }

    const { collectionDate } = details;

    const itemList: DefinitionListItem[] = [
        {
            label: t('collection-date-section.collection-date'),
            value: t('collection-date-section.each-month', { value: collectionDate }),
            testId: 'collection-date',
        },
    ];

    return (
        <>
            <div data-theme={currentBrand === 'seat' ? 'alternative' : 'primary'}>
                <Heading className="c-data-overview__header u-pt u-mb-none" level={'6'}>
                    {t('collection-date-section.title')}
                    {collectionDate && (
                        <EditButtonWithStatus
                            onClick={startEditing}
                            showStatus={isPaymentDayStatusTag}
                            disabled={editButtonDisabled || isPaymentDayStatusTag}
                        />
                    )}
                </Heading>
                {!collectionDate || collectionDate < 1 ? (
                    <ErrorNotification
                        testId={'not-found-error'}
                        headline={t('error.headline')}
                        text={t('error.text')}
                    />
                ) : (
                    <>
                        <DefinitionList split={true} list={itemList} />
                        <PaymentDayNotification editStatus={editStatus} />
                    </>
                )}
            </div>
        </>
    );
};
