import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedDate } from '@cp-shared-8/frontend-ui';

type DateProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
    maxDate?: string;
    minDate?: string;
    value?: string;
};

export const DateInput: React.FC<DateProps> = ({ label, name, isMandatory = true, maxDate, minDate, value }) => {
    const { t, i18n } = useTranslation();
    const language = i18n.languages[0];
    return (
        <ValidatedDate
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            language={language}
            outputDateFormat="d.m.Y"
            placeholder={t('metrics.date-format')}
            maxDate={maxDate}
            minDate={minDate}
            value={value}
        />
    );
};
