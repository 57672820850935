import * as Yup from 'yup';
import { RequestDeadlineValidatorErrors } from '../../types';
import { RequestDeadlineError } from '../../messages';
import { RequestCategory } from '../../../apis/services/types/request';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestDeadlineFormType = {
    email: string;
    phonenumber: string | undefined;
    deadline: string;
};

export const requestDeadlineCoreSchema = (
    errors: RequestDeadlineValidatorErrors = RequestDeadlineError,
): Yup.SchemaOf<RequestDeadlineFormType> =>
    Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phoneNumber.mustBePhoneNumber),
        deadline: Yup.string().trim().required(errors.deadline.required),
    });

export const requestDeadlineValidationSchema = (
    field: string,
    errors: RequestDeadlineValidatorErrors = RequestDeadlineError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestDeadlineFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory): boolean => {
            return requestCategory === 'DEADLINE';
        },
        then: requestDeadlineCoreSchema(errors),
    });
};
