import { EarlyReturnPenaltyValidatorErrors } from '../types';

export const EarlyReturnPenaltyError: EarlyReturnPenaltyValidatorErrors = {
    requestDate: {
        required: 'Please enter your desired replacement date.',
        min: 'Request date must be in the future.',
        deliveryDatePlusNineMonths: 'Returning a car before 9 months of delivery is impossible.',
        afterContractEnds: 'Returning a car is impossible after contract ends.',
    },
    estimatedMileage: {
        required: 'Please enter your estimated mileage upon return.',
        positive: 'Estimated mileage cannot be lower than 0.',
    },
};
