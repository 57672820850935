import { RequestInitialValues } from '../../../../initialValues';
import { Base64File, Civility, DeathRequest, Intentions } from '@cp-fr/common';
import { groupByFileSize, mapFilesToBase64 } from 'utils/file-utils';
import { maxFileSize } from '../../../../../../../config';

export const deathMapper = async ({ contractsSelection, death }: RequestInitialValues): Promise<DeathRequest[]> => {
    if (!Array.isArray(contractsSelection) || !contractsSelection.length) {
        return [];
    }
    const {
        files: { deathCertificate, deedOfInheritance },
    } = death;

    const filesForRequest: Base64File[] = await mapFilesToBase64([
        ...(deathCertificate ?? []),
        ...(deedOfInheritance ?? []),
    ]);

    const mapToRequest = (files: Base64File[]): DeathRequest => ({
        requestCategory: 'DEATH',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        dateOfDeath: death.dateOfDeath,
        nameOfNotary: death.nameOfNotary,
        addressOfNotary: death.addressOfNotary,
        phonenumberOfNotary: death.phonenumberOfNotary,
        emailOfNotary: death.emailOfNotary,
        civilityOfBeneficiary: death.civilityOfBeneficiary as Civility,
        firstNameOfBeneficiary: death.firstNameOfBeneficiary,
        nameOfBeneficiary: death.nameOfBeneficiary,
        addressOfBeneficiary: death.addressOfBeneficiary,
        phonenumberOfBeneficiary: death.phonenumberOfBeneficiary,
        emailOfBeneficiary: death.emailOfBeneficiary,
        intentions: death.intentions as Intentions,
        files: files,
    });

    return groupByFileSize(filesForRequest, maxFileSize).map((group) => mapToRequest(group));
};
