import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import React, { useCallback } from 'react';
import { countryCodeMapping } from '../../../request/form-view/category-selection/category-view/simple-form-components/phone-input/country-code';
import { ComboboxOption } from '../../../../types/bronson-react';
import { useField } from 'formik';

export const optionsFromCountryMapping = (fullCountry?: boolean): JSX.Element[] => {
    return Object.entries(countryCodeMapping).map((value: [string, string[]], index: number) => (
        <Combobox.Item
            key={index}
            optionKey={fullCountry ? value[0] : value[1][1]}
            optionValue={fullCountry ? value[0] : value[1][1]}
        >
            {value[0]}
        </Combobox.Item>
    ));
};

export type CountryCodeComboboxProps = {
    name: string;
    testId: string;
    labelText: string;
    fullCountry?: boolean;
};

export const CountryCodeCombobox: React.FC<CountryCodeComboboxProps> = ({ name, testId, labelText, fullCountry }) => {
    const [field, , helpers] = useField(name);

    const onCountryChange = useCallback((values: ComboboxOption[]) => {
        helpers.setValue(values[0]?.key);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const countryDefaultState = {
        value: [
            {
                value: field.value,
                key: field.value,
            },
        ],
    };

    return (
        <FormField type="select" testId={'country-code-selection'} id={'country-code-selection'} labelText={labelText}>
            <Combobox name={name} testId={testId} defaultState={countryDefaultState} onChange={onCountryChange}>
                {optionsFromCountryMapping(fullCountry)}
            </Combobox>
        </FormField>
    );
};
