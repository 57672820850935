import { LandingPage, getLandingPageEndpoint } from '@cp-fr/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LandingPage>({
    contentName: 'landingPage',
    contentEndpoint: getLandingPageEndpoint,
});

export default reducer;
export const fetchLandingPage = fetchContent;
