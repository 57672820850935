import React from 'react';
import { Greeting as GreetingShared } from '@cp-shared-8/frontend-ui';
import { Greeting } from '@cp-fr/common';
import { useTranslation } from 'react-i18next';

export const GreetingUi: React.FC<{ greeting?: Greeting }> = ({ greeting }) => {
    const { t } = useTranslation('greeting');

    if (!greeting || !greeting.message) {
        return <GreetingShared fullGreetingText={t('message')} />;
    }

    return <GreetingShared fullGreetingText={greeting.message} />;
};
