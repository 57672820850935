import { RequestInitialValues } from '../../../../initialValues';
import { ChangeDurationMileage } from '@cp-fr/common/src/apis/services/types/request';

export const changeDurationMileageMapper = async ({
    contractsSelection,
    changeDurationMileage,
}: RequestInitialValues): Promise<ChangeDurationMileage> => {
    return {
        requestCategory: 'CHANGE_DURATION_MILEAGE',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: changeDurationMileage.email,
        phonenumber: changeDurationMileage.phonenumber,
        totalDuration: changeDurationMileage.totalDuration,
        desiredMileage: changeDurationMileage.desiredMileage,
        desiredTiresSummer: changeDurationMileage.desiredTiresSummer,
        desiredTiresWinter: changeDurationMileage.desiredTiresWinter,
        comment: changeDurationMileage.comment,
    };
};
