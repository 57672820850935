import { RequestCustomerStatus } from '@cp-fr/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiData } from '@cp-shared-8/frontend-integration';
import { ContactInformationChangeStatusSelector } from './ContactInformationChangeStatusSelector';
import { fetchContactInformationChangeStatus } from './ContactInformationChangeStatusSlice';

export function useContactInformationChangeStatus(): AbstractDataState<
    RequestCustomerStatus,
    DefaultBusinessMarketApiErrorCode
> {
    return useGetSimpleApiData(fetchContactInformationChangeStatus, ContactInformationChangeStatusSelector);
}
