export enum EditStatus {
    SUCCESS = 'SUCCESS',
    NOT_PERFORMED = 'NOT_PERFORMED',
    BFF_API_ERROR = 'BFF_API_ERROR',
    CHECK_FAILURE = 'CHECK_FAILURE',
    USER_WITH_NO_EMAIL_CODE = 'USER_WITH_NO_EMAIL',
}

export enum EditViewNames {
    IBAN_VIEW = 'IBAN_VIEW',
    FILE_UPLOAD_VIEW = 'FILE_UPLOAD_VIEW',
}
