import {
    Contract,
    ContractDetails as TContractDetails,
    getContractDetailsEndpoint,
    ProductTypeEN,
} from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { ContractDetailsWithAllValues } from './contract-details/ExampleData';

export const getExpiredWithin1MonthDate = () =>
    formatCpDate(formatCpDate().toMoment().startOf('day').add(1, 'month')).toCpDate();

export const ActiveCreditContractWithAllValues: Contract & TContractDetails = {
    isActive: true,
    carModel: 'VW Golf',
    contractId: 'AX01',
    contractNumber: '19001515LOA01',
    productType: 'ACVN_PR',
    licensePlate: 'CT-978-WW',
    productTypeEN: ProductTypeEN.CREDIT,
    productTypeFR: 'Abrégio Style',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    returnBackVehicleDate: '2021-02-18',
    isEligibleRenewalContractNotificationForLessOfTwelveMonths: true,
    isEligibleRenewalContractNotificationForLessOfFourMonths: true,
    isEligibleRenewalContractNotificationForLessOfThreeMonths: true,
    isEligibleRenewalContractNotificationForLessOfTwoMonths: true,
    isEligibleRenewalContractNotificationForLessOfOneMonth: true,
    isEligibleRenewalContractNotificationForLoiLagarde: true,
    isEligibleRenewalContractNotificationForLessOneMonth: true,
    isEligibleRenewalContractWithNewVehiculeNotificationForLessOneMonth: true,
    _links: { contractDetails: getContractDetailsEndpoint('AX01') },
    ...ContractDetailsWithAllValues,
};

export const ContractWithEligibleReturnDateOnNextPayment: Contract & TContractDetails = {
    ...ActiveCreditContractWithAllValues,
    contractStartDate: '2019-02-18',
    contractEndDate: '2022-12-18',
};

export const ContractWithEligibleReturnDateInAFewMonths: Contract & TContractDetails = {
    ...ActiveCreditContractWithAllValues,
    contractStartDate: '2022-02-18',
    contractEndDate: '2023-12-12',
};

export const ContractWithAmmountNotPaid: Contract & TContractDetails = {
    ...ActiveCreditContractWithAllValues,
    amountUnpaidNotPaid: 999,
};

export const ActiveEndingCreditContractWithAllValues: Contract & TContractDetails = {
    isActive: true,
    carModel: 'VW Golf',
    contractId: 'AX01',
    contractNumber: '19001515LOA01',
    productType: 'ACVN_PR',
    licensePlate: 'CT-978-WW',
    productTypeEN: ProductTypeEN.CREDIT,
    productTypeFR: 'Abrégio Style',
    contractStartDate: '2019-02-18',
    contractEndDate: getExpiredWithin1MonthDate(),
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX01') },
    ...ContractDetailsWithAllValues,
};

export const ActiveFinancialLeaseContractWithAllValues: Contract & TContractDetails = {
    isActive: true,
    carModel: 'VW Golf',
    contractId: 'AX01',
    contractNumber: '19001515LOA01',
    productType: 'CBEQ_FL',
    licensePlate: 'CT-978-WW',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    productTypeEN: ProductTypeEN.FINANCIAL_LEASE,
    productTypeFR: 'Abrégio Style',
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX01') },
    ...ContractDetailsWithAllValues,
};

export const ActiveVDLContractWithAllValues: Contract & TContractDetails = {
    isActive: false,
    carModel: 'VW Golf',
    contractId: 'AX01',
    contractNumber: '19001515LOA01',
    productType: 'LCDN_FL',
    licensePlate: 'CT-978-WW',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    productTypeEN: ProductTypeEN.VDL,
    productTypeFR: 'Abrégio Style',
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX01') },
    ...ContractDetailsWithAllValues,
};

export const ActiveBalloonLeasingContractWithAllValues: Contract & TContractDetails = {
    isActive: true,
    carModel: 'VW Golf',
    contractId: 'AX01',
    contractNumber: '19001515LOA01',
    productType: 'LOAN_FL',
    licensePlate: 'CT-978-WW',
    productTypeEN: ProductTypeEN.BALLON_LEASING,
    productTypeFR: 'Abrégio Style',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX01') },
    ...ContractDetailsWithAllValues,
};

export const ActiveEndingBallonLeasingContractWithAllValues: Contract & TContractDetails = {
    isActive: true,
    carModel: 'VW Golf',
    contractId: 'AX01',
    contractNumber: '19001515LOA01',
    productType: 'LOAN_FL',
    licensePlate: 'CT-978-WW',
    productTypeEN: ProductTypeEN.BALLON_LEASING,
    productTypeFR: 'Abrégio Style',
    contractStartDate: '2019-02-18',
    contractEndDate: getExpiredWithin1MonthDate(),
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX01') },
    ...ContractDetailsWithAllValues,
};

export const ActiveEndingRenewalBallonLeasingContractWithAllValues: Contract & TContractDetails = {
    ...ActiveEndingBallonLeasingContractWithAllValues,
    isEligibleRenewalContractNotificationForLessOfTwelveMonths: true,
    //isEligibleRenewalContractNotification: true,
};

export const ActiveCreditContractWithMissingValues: Contract & TContractDetails = {
    isActive: true,
    carModel: 'Audi A4',
    contractId: 'AX02',
    contractNumber: '19001515LOA02',
    productTypeEN: ProductTypeEN.CREDIT,
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX02') },
    ...ContractDetailsWithAllValues,
};

export const InactiveCreditContractWithAllValues: Contract & TContractDetails = {
    isActive: false,
    carModel: 'Seat Ibiza',
    contractId: 'AX03',
    contractNumber: '19001515LOA03',
    productType: 'ACVN_PR',
    licensePlate: 'CT-777-WW',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    productTypeEN: ProductTypeEN.CREDIT,
    productTypeFR: 'Abrégio Style',
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX03') },
    ...ContractDetailsWithAllValues,
};

export const InactiveFinancialLeaseContractWithAllValues: Contract & TContractDetails = {
    isActive: false,
    carModel: 'Seat Ibiza',
    contractId: 'AX03',
    contractNumber: '19001515LOA03',
    productType: 'CBEQ_FL',
    licensePlate: 'CT-777-WW',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    productTypeEN: ProductTypeEN.FINANCIAL_LEASE,
    productTypeFR: 'Abrégio Style',
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX03') },
    ...ContractDetailsWithAllValues,
};

export const InactiveCreditContractWithMissingValues: Contract & TContractDetails = {
    isActive: false,
    contractId: 'AX04',
    contractNumber: '19001515LOA04',
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
    productTypeEN: ProductTypeEN.CREDIT,
    returnBackVehicleDate: '2021-02-18',
    _links: { contractDetails: getContractDetailsEndpoint('AX04') },
    ...ContractDetailsWithAllValues,
};

export const CreditContractsWithMixedEntries: Array<Contract & TContractDetails> = [
    ActiveCreditContractWithAllValues,
    ActiveCreditContractWithMissingValues,
    InactiveCreditContractWithAllValues,
    InactiveCreditContractWithMissingValues,
];

export const ActiveCreditContracts: Array<Contract & TContractDetails> = [
    ActiveCreditContractWithAllValues,
    ActiveCreditContractWithMissingValues,
];

export const InactiveCreditContracts: Array<Contract & TContractDetails> = [
    InactiveCreditContractWithAllValues,
    InactiveCreditContractWithMissingValues,
];

export const ContractsWithAmmountNotPaid: Array<Contract & TContractDetails> = [ContractWithAmmountNotPaid];

export const NoContractsForAnAccount: Array<Contract & TContractDetails> = [];
