import { Contract, MyProfileData, ContractDetails as TContractDetails } from '@cp-fr/common';
import { TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerHappinessIndex } from '../../customer-happiness-index/CustomerHappinessIndex';
import { ContractsOverview } from '../contracts-overview';

export const ContractsUi: React.FC<{
    contracts?: Array<Contract & TContractDetails>;
    isContactInformationChangePending: boolean;
    myProfile?: MyProfileData;
}> = ({ contracts, isContactInformationChangePending, myProfile }) => {
    const { t } = useTranslation('contracts');
    if (!contracts) {
        return null;
    }

    if (!contracts.length) {
        return <Notification status={NotificationStatus.error} headline={t('error.headline')} text={t('error.text')} />;
    }

    return (
        <>
            <ContractsOverview
                contracts={contracts}
                isContactInformationChangePending={isContactInformationChangePending}
            />
            <CustomerHappinessIndex
                firstName={myProfile?.identification?.data?.name}
                lastName={myProfile?.identification?.data?.surname}
                email={myProfile?.contactDetails?.email}
                customType={TrustedShopsIntegrationCustomType.CUSTOMERPORTAL}
            />
        </>
    );
};
