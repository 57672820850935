import {
    Base64File,
    BaseVehicleClaimRequest,
    NatureOfDamage,
    OriginalRegDocument,
    ProductTypeEN,
    VehicleClaimRequestForBalloon,
    VehicleClaimRequestForCredit,
} from '@cp-fr/common';
import { groupByFileSize, mapFilesToBase64 } from 'utils/file-utils';
import { maxFileSize } from '../../../../../../../config';
import { RequestInitialValues } from '../../../../initialValues';

export const vehicleClaimMapper = async ({
    contractsSelection,
    vehicleClaim,
}: RequestInitialValues): Promise<VehicleClaimRequestForCredit[] | VehicleClaimRequestForBalloon[]> => {
    if (!Array.isArray(contractsSelection) || !contractsSelection.length) {
        return [];
    }
    const { productTypeEN } = contractsSelection[0];
    const {
        files: { invoice, expertReport, transferProposal, paymentDetails, flightReport },
    } = vehicleClaim.nonReparableFields;

    const filesForRequest: Base64File[] = await mapFilesToBase64([
        ...(vehicleClaim.nonReparableFields.originalRegDocument === 'Non' ? flightReport : []),
        ...(productTypeEN === ProductTypeEN.CREDIT && invoice ? invoice : []),
        ...(expertReport ?? []),
        ...(productTypeEN === ProductTypeEN.BALLON_LEASING && transferProposal ? transferProposal : []),
        ...(paymentDetails ?? []),
        ...(productTypeEN === ProductTypeEN.CREDIT ? vehicleClaim.nonReparableFields.registrationFile : []),
    ]);

    const mapToRequest = (files: Base64File[]): BaseVehicleClaimRequest => ({
        requestCategory: 'VEHICLE_CLAIM',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        productTypeEN: vehicleClaim.nonReparableFields.productTypeEN ?? '',
        email: vehicleClaim.nonReparableFields.email,
        reparable: vehicleClaim.reparable,
        phonenumber: vehicleClaim.nonReparableFields.phonenumber,
        natureOfDamage: vehicleClaim.nonReparableFields.natureOfDamage as NatureOfDamage,
        dateOfDamage: vehicleClaim.nonReparableFields.dateOfDamage,
        circumstancesOfTheDisaster: vehicleClaim.nonReparableFields.circumstancesOfTheDisaster,
        causeOfSinistre: vehicleClaim.nonReparableFields.causeOfSinistre,
        nameOfInsurer: vehicleClaim.nonReparableFields.nameOfInsurer,
        addressOfInsurer: vehicleClaim.nonReparableFields.addressOfInsurer,
        postcodeOfInsurer: vehicleClaim.nonReparableFields.postcodeOfInsurer,
        cityOfInsurer: vehicleClaim.nonReparableFields.cityOfInsurer,
        insuranceCustomerNumber: vehicleClaim.nonReparableFields.insuranceCustomerNumber,
        damageNumber: vehicleClaim.nonReparableFields.damageNumber,
        phonenumberOfInsurer: vehicleClaim.nonReparableFields.phonenumberOfInsurer,
        emailOfInsurer: vehicleClaim.nonReparableFields.emailOfInsurer,
        originalRegDocument: (vehicleClaim.nonReparableFields.originalRegDocument as OriginalRegDocument) ?? '',
        files: files,
    });

    /**
     * CR [CO-14774] - make files uploads optional
     */
    const groupedRequests = filesForRequest.length
        ? groupByFileSize(filesForRequest, maxFileSize).map((group) => mapToRequest(group))
        : [mapToRequest([])];

    if (productTypeEN === ProductTypeEN.CREDIT) {
        return groupedRequests;
    }

    const vehicleClaimRequests: VehicleClaimRequestForBalloon[] = groupedRequests.map((base) => ({
        ...base,
        agreeToSend: vehicleClaim.nonReparableFields.agreeToSend,
    }));

    return vehicleClaimRequests;
};
