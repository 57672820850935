import { ParkingSessionSummary } from '@cp-fr/common';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ParkingInfoProps = {
    phoneNumber: string;
    summary: ParkingSessionSummary;
};

export const ParkingInfo: React.FC<ParkingInfoProps> = ({ phoneNumber, summary }) => {
    const { t } = useTranslation('parking');

    return (
        <>
            <Layout.Item default="1/2" s="1/1">
                <Card element="article" title={t('mobile-phone')} className="u-text-left">
                    {phoneNumber} <br /> &nbsp;
                </Card>
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1">
                <Card element="article" title={t('last-year-parking-sessions')} className="u-text-left">
                    {`${t('parking-sessions-number')} ${summary.parkingSessionsCount}`} <br />{' '}
                    {`${t('parking-sessions-amount')} ${summary.parkingSessionsAmount}`}
                </Card>
            </Layout.Item>
        </>
    );
};
