import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';

type RestrictedNumericSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
    min: number;
    max: number;
    step?: number;
    tooltip?: string;
    hint?: string;
    placeholder?: string;
    emptyByDefault?: boolean;
};

export const RestrictedNumericSelection: React.FC<RestrictedNumericSelectionProps> = ({
    label,
    name,
    isMandatory,
    min,
    max,
    step,
    tooltip,
    hint,
    placeholder,
    emptyByDefault,
}) => {
    const range = (min: number, max: number, step?: number) => {
        const diff = Math.abs(max - min);
        const count = Math.trunc(step ? diff / step + 1 : diff + 1);
        return Array.from(Array(count).keys()).map((x) => {
            const increment = Math.trunc(step ? x * step : x * 1);
            const value = min + increment;
            return Math.trunc(value);
        });
    };

    const numberArray = range(min, max, step);
    const selectItems: ValidatedSelectItem[] = numberArray.map((number: number) => {
        return { value: number.toString(), label: number.toString() };
    });

    return (
        <>
            <ValidatedSelect
                label={isMandatory ? `${label} *` : label}
                name={name}
                selectItems={selectItems}
                tooltip={tooltip}
                hint={hint}
                placeholder={placeholder}
                emptyByDefault={emptyByDefault}
            ></ValidatedSelect>
        </>
    );
};
