import { BundledProductWithMarketingInfo, Contract, ContractDetails as TContractDetails } from '@cp-fr/common';

export type BundledProductsForMarketingCards = {
    productsWithMaintenance: BundledProductWithMarketingInfo[];
    otherProducts: BundledProductWithMarketingInfo[];
};

export const MAINTENANCE_PRODUCT_NAMES = ['Entretien', 'Entretien flat', 'Entretien VIP', 'Entretien plus'];
export const YEARS_FOR_UNIFIED_MAINTENANCE_SUBSCRIPTION = 13;

export const useBundledProductsForMarketingCards = (
    contracts?: Array<Contract & TContractDetails>,
): BundledProductsForMarketingCards => {
    if (!contracts)
        return {
            productsWithMaintenance: [],
            otherProducts: [],
        };

    const allBundledProducts: BundledProductWithMarketingInfo[] = contracts
        .map((contract) => {
            return contract.bundledProducts.map((product) => ({
                ...product,
                carCode: contract.vehicle?.carCode,
                firstRegistrationDate: contract.vehicle?.firstRegistrationDate,
                licensePlate: contract.vehicle?.licensePlate,
                vin: contract.vehicle?.vin,
                carModel: contract?.carModel,
                contractStartDate: contract?.contractStartDate,
                contractNumber: contract?.contractNumber,
            }));
        })
        .flat();

    const productsWithMaintenance = allBundledProducts.filter(
        (product) => product.name && MAINTENANCE_PRODUCT_NAMES.includes(product.name.trim()),
    );
    const otherProducts = allBundledProducts.filter(
        (product) => !MAINTENANCE_PRODUCT_NAMES.includes((product.name || '').trim()),
    );

    return {
        productsWithMaintenance,
        otherProducts,
    };
};

export const getMostRecentProductWithBrand = (
    products: BundledProductWithMarketingInfo[] | undefined,
): BundledProductWithMarketingInfo | undefined => {
    const productsWithBrand: BundledProductWithMarketingInfo[] = products?.filter((product) => !!product.carCode) || [];

    if (!productsWithBrand?.length) return undefined;
    return productsWithBrand.sort((a, b) => ((a.contractStartDate || '') > (b.contractStartDate || '') ? -1 : 1))[0];
};
