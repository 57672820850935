import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInput, ReasonSelection, MonthSelection, PhonenumberInput, TextInput } from '../../simple-form-components';
import { ValidatedSelect } from '@cp-shared-8/frontend-ui';
import { DocumentsUpload, TransferTo } from './TenantDocumentUpload';
import { useField } from 'formik';

export const TenantChangeRequestForm: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.tenant';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const [field] = useField('tenantChangeRequest.transferTo');
    const selectedTransferTo: TransferTo = field.value;
    const transferTo: TransferTo[] = ['Personne physique', 'Personne morale'];

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate('description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'tenantChangeRequest.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber')}
                    name={'tenantChangeRequest.phonenumber'}
                    isMandatory={false}
                />
            </Layout.Item>
            <Layout.Item>
                <MonthSelection
                    label={translate('transfer-date')}
                    name={'tenantChangeRequest.transferDate'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <ReasonSelection name={'tenantChangeRequest.reason'} />
            </Layout.Item>
            <Layout.Item>
                <ValidatedSelect
                    isMandatory={true}
                    label={translate('transfer-to')}
                    name={'tenantChangeRequest.transferTo'}
                    selectItems={transferTo.map((transfer) => ({
                        value: transfer,
                        label: transfer,
                    }))}
                    emptyByDefault
                />
            </Layout.Item>
            <DocumentsUpload transferringTo={selectedTransferTo}></DocumentsUpload>
            <Layout.Item>
                <TextInput label={translate('comment')} name={'tenantChangeRequest.comment'} isMandatory={false} />
            </Layout.Item>
        </>
    );
};
