import { ContentSection } from '@vwfs-bronson/bronson-react';
import { PartialEarlySettlement } from 'components/early-settlement/partial-early-settlement';
import React from 'react';
import { useParams } from 'react-router-dom';

export const PartialEarlySettlementPage: React.FC = () => {
    const { contractId } = useParams<{ contractId: string }>();
    return (
        <ContentSection pageWrapSize="medium">
            <PartialEarlySettlement contractId={contractId} />
        </ContentSection>
    );
};
