import { Contract } from '@cp-fr/common';
import { ComboboxDefaultState } from '@cp-shared-8/frontend-ui';
import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from 'components/license-plate/LicensePlate';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { ComboboxOption } from 'types/bronson-react';

export const ContractSelect: React.FC<{
    testId: string;
    name: string;
    label: string;
    options: ComboboxOption[];
    visible: boolean;
    defaultState?: ComboboxDefaultState;
    contracts?: Contract[];
}> = ({ testId, label, name, options, visible, defaultState, contracts }) => {
    const [field, , helpers] = useField(name);

    const onChange = useCallback((values: ComboboxOption[]) => {
        helpers.setValue(values[0]?.key);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const comboDefaultState = defaultState
        ? defaultState
        : {
              value: [
                  {
                      value: options.find((o) => o.key === field.value)?.value || field.value,
                      key: field.value,
                  },
              ],
          };

    if (!visible) return null;

    return (
        <FormField type="select" testId={testId} id={'document-type'} labelText={label}>
            <Combobox name={name} testId={`${testId}-combo`} onChange={onChange} defaultState={comboDefaultState}>
                {options.map((filter) => {
                    const contract =
                        contracts?.length === 1
                            ? contracts[0]
                            : contracts?.find((c) => c.contractNumber === filter.value);
                    return (
                        <Combobox.Item key={filter.key} optionValue={filter.value} optionKey={filter.key}>
                            {filter.key === 'all' || !contract ? (
                                filter.value
                            ) : (
                                <table>
                                    <tr>
                                        <LicensePlate registrationNumber={contract?.licensePlate} />
                                    </tr>
                                    <tr>
                                        <span>{contract?.carModel}</span>
                                    </tr>
                                    <tr>
                                        <span>
                                            <strong>{`${contract?.productTypeFR} ${filter.value}`}</strong>
                                        </span>
                                    </tr>
                                </table>
                            )}
                        </Combobox.Item>
                    );
                })}
            </Combobox>
        </FormField>
    );
};
