import { Contract, getContractsEndpoint } from '@cp-fr/common';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { CpDataApi } from '../../cp-xhr';
import { ContractDetails as TContractDetails } from '@cp-fr/common/src/apis/services/types/contracts';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    Array<Contract & TContractDetails>,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'contracts',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const updateContracts = updateData;
export const fetchContracts = fetchData;
