/* eslint-disable */
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { MyDocumentsUi } from './ui';
import { useMyDocuments } from './useMyDocuments';
import { useContracts } from 'components/contracts';

const MyDocumentsWithHandlers = withLoadingAndNoConnectionHandler(MyDocumentsUi);

export const MyDocuments: React.FC = () => {
    const { data, isLoading, loadingError } = useMyDocuments();
    const { data: contracts, isLoading: contractLoading, loadingError: contractError } = useContracts();
    return <MyDocumentsWithHandlers myProfileData={data} contracts={contracts} isLoading={isLoading || contractLoading} hasError={!!loadingError || !!contractError} />;
};
