import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { JointEntryPointUi } from './ui';

export const JointEntryPoint: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data: contract, isLoading, loadingError } = useContract(contractId);

    const ContractsWithHandlers = withLoadingAndNoConnectionHandler(JointEntryPointUi);

    return <ContractsWithHandlers isLoading={isLoading} contract={contract} hasError={!!loadingError} />;
};
