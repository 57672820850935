import { RequestAuthorizationLeaveCountryValidatorErrors } from '../../types';

export const RequestAuthorizationLeaveCountryError: RequestAuthorizationLeaveCountryValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    exitDate: {
        required: 'exitDate is required',
    },
    returnDate: {
        required: 'returnDate is required',
    },
    reason: {
        required: 'reason is required',
    },
    countryOfDestination: {
        required: 'countryOfDestination is required',
    },
};
