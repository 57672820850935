import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getContractsEndpoint, getMyProfileEndpoint } from '@cp-fr/common';
import { ContractsInfoWithAllValues } from './ExampleData';
import {
    MyProfileRetailWithAllValues,
    MyProfileRetailWithMissingAddress,
    MyProfileRetailWithMissingContact,
    MyProfileRetailWithMissingIdentification,
    MyProfileRetailWithMissingValues,
    MyProfileRetailWithNoData,
} from '../my-profile/examples/ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ContractsInfoWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const RequestMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

const mockMyProfile: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileRetailWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: MyProfileRetailWithMissingValues,
    },
    'With missing identification': {
        status: 200,
        responseBody: MyProfileRetailWithMissingIdentification,
    },
    'With missing contact': {
        status: 200,
        responseBody: MyProfileRetailWithMissingContact,
    },
    'With missing address': {
        status: 200,
        responseBody: MyProfileRetailWithMissingAddress,
    },
    'With no data for all sections': {
        status: 200,
        responseBody: {},
    },
    'With all values missing': {
        status: 200,
        responseBody: MyProfileRetailWithNoData,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'My Profile Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockMyProfile,
    url: getMyProfileEndpoint(),
};

export const RequestResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [RequestMock, MyProfileMock]);

    return <div>{storyFn()}</div>;
};
