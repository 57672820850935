import * as Yup from 'yup';
import { IdentificationSectionEditViewValidatorErrors } from '../../types/myProfileTypes';
import { IdentificationSectionEditViewError } from '../../messages/myProfileMessages';
import { fileValidation } from '../FileValidator';

export type EditViewIdentificationSectionFormTypes = {
    firstName?: string;
    lastName?: string;
};

export type EditViewIdentificationSectionBackendTypes = EditViewIdentificationSectionFormTypes & {
    documents: {
        fileId: string | undefined;
        type: string | undefined;
    }[];
};

export type EditViewContactSectionFrontendTypes = EditViewIdentificationSectionFormTypes & {
    id: File[] | undefined;
    proofOfIdentityChange: File[] | undefined;
};

export const editViewIdentificationSectionCoreSchema = (
    isFrontend: boolean,
    errors: IdentificationSectionEditViewValidatorErrors = IdentificationSectionEditViewError,
): Yup.SchemaOf<EditViewIdentificationSectionFormTypes> => {
    return Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .matches(/^([^0-9]*)$/, errors.firstName.matches)
            .test('isFrontend', errors.firstName.required, (val) => (isFrontend ? !!val : true)),
        lastName: Yup.string()
            .trim()
            .matches(/^([^0-9]*)$/, errors.lastName.matches)
            .test('isFrontend', errors.lastName.required, (val) => (isFrontend ? !!val : true)),
    });
};

export const editViewIdentificationSectionBackendSchema =
    (): Yup.SchemaOf<EditViewIdentificationSectionBackendTypes> => {
        return editViewIdentificationSectionCoreSchema(false).shape({
            documents: Yup.array().of(
                Yup.object().shape({
                    fileId: Yup.string(),
                    type: Yup.string(),
                }),
            ),
        });
    };

export const editViewIdentificationSectionFrontendSchema = (
    errors: IdentificationSectionEditViewValidatorErrors = IdentificationSectionEditViewError,
): Yup.SchemaOf<EditViewContactSectionFrontendTypes> => {
    return editViewIdentificationSectionCoreSchema(true, errors).shape({
        id: fileValidation(errors.id.max, errors.id.required),
        proofOfIdentityChange: fileValidation(errors.proofOfIdentityChange.max, errors.proofOfIdentityChange.required),
    });
};
