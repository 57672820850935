import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const paymentDayStatusTagFetchStateSlice = createSlice({
    name: 'paymentDayStatusTagFetchState',
    initialState: {
        dataValid: false,
        inProgress: false,
    },
    reducers: {
        setDataValidity(state, action: PayloadAction<boolean>) {
            state.dataValid = action.payload;
        },
        setFetchProgress(state, action: PayloadAction<boolean>) {
            state.inProgress = action.payload;
        },
    },
});

export const paymentDayStatusTagFetchStateReducer = paymentDayStatusTagFetchStateSlice.reducer;
export const paymentDayStatusTagFetchStateActions = paymentDayStatusTagFetchStateSlice.actions;
