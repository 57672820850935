import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { NatureOfDamage } from '@cp-fr/common';

type NatureOfDamageSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const NatureOfDamageSelection: React.FC<NatureOfDamageSelectionProps> = ({ label, name, isMandatory }) => {
    const naturesOfDamage: NatureOfDamage[] = ['Vol', 'Incendie', 'Destruction totale', 'Inondation'];

    const selectItems: ValidatedSelectItem[] = naturesOfDamage.map((natureOfDamage: NatureOfDamage) => {
        return { value: natureOfDamage, label: natureOfDamage };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
