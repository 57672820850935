export enum ChangeNameDocumentType {
    IDENTITY_DOCUMENT = 'identity_document',
    PROOF_OF_IDENTITY_CHANGE = 'proof_of_identity_change',
    KBIS = 'kbis',
    INSEE = 'insee',
}

export type ChangeNameDocument = {
    fileId: string[];
    type: ChangeNameDocumentType;
};

export type CustomerChangeName = {
    customerType: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    documents: FileInfo[];
};

export type FileInfo = {
    fileId: string;
    type: ChangeNameDocumentType;
};
