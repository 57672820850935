import {
    Contract,
    ContractDetails as TContractDetails,
    isBalloonLeasing,
    isCredit,
    isLine2Product,
    isLLLProduct,
    isStandalone,
} from '@cp-fr/common';
import { InfoIcon, useAnalyticsDashboardPageTrackerWithoutUnpaid } from '@cp-shared-8/frontend-ui';
import { Button, ExpandableContent, FormSectionGroup, Notification } from '@vwfs-bronson/bronson-react';
import { contractEndOptionsPath } from 'components/navigation/paths';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { ContractComponent } from '../contract';
import moment from 'moment';

export const ContractsOverview: React.FC<{
    contracts: Array<Contract & TContractDetails>;
    isContactInformationChangePending: boolean;
}> = ({ contracts, isContactInformationChangePending }) => {
    const { t } = useTranslation('contracts');
    const { t: mt } = useTranslation('my-profile');
    const history = useHistory();

    const lastLocation = useLastLocation();
    const lastPathname = lastLocation === null ? '/authentication/callback' : lastLocation?.pathname;

    const getVehicleRestitutionGuideBrand = (brand?: string, upper?: boolean) => {
        if (!brand) return null;
        if (brand === 'vwcv' || brand === 'vw') return upper ? 'VW' : 'volkswagen';
        return upper ? brand.toLocaleUpperCase() : brand.toLowerCase();
    };

    useAnalyticsDashboardPageTrackerWithoutUnpaid(!!contracts.length, lastPathname);

    const getListOfContracts = useCallback(
        (isActive: boolean): React.ReactNode | null => {
            const filteredContracts = contracts ? contracts.filter((contract) => contract.isActive === isActive) : [];
            return filteredContracts.length ? (
                <FormSectionGroup>
                    {filteredContracts.map((contract, index) => {
                        const defaultExpanded = isActive && index === 0;
                        return (
                            <ContractComponent
                                contract={contract}
                                key={contract.contractId}
                                defaultExpanded={defaultExpanded}
                                isContactInformationChangePending={isContactInformationChangePending}
                            />
                        );
                    })}
                </FormSectionGroup>
            ) : null;
        },
        [contracts, isContactInformationChangePending],
    );

    const handleContractEndOptionsClicked = (contract: Contract) => {
        history.push(contractEndOptionsPath(contract.contractId));
    };

    const contractsWithNotification = contracts.map((contract, index) => {
        return (
            <React.Fragment key={`contractEndInfoInLessThan12-4-3-2-1Months-${index}`}>
                {/* -------- LINE 1 - LOA (baloon leasing) ---------- */}
                {contract.productType &&
                    isBalloonLeasing(contract.productType) &&
                    contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths && (
                        <Notification
                            visible
                            title={t('contract-end-info-12.headline', {
                                value: contract.contractNumber,
                                date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                            })}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('contract-end-info-12.description.ballon', {
                                        value: contract.contractNumber,
                                        date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                                    }),
                                }}
                            />
                            <div>
                                <Button
                                    onClick={() => handleContractEndOptionsClicked(contract)}
                                    className="u-mt"
                                    disabled={isContactInformationChangePending}
                                >
                                    {t('contract-end-info-12.button')}
                                </Button>
                                {isContactInformationChangePending && (
                                    <InfoIcon text={mt('notifications.contact-information-change-pending')} />
                                )}
                            </div>
                        </Notification>
                    )}

                {contract.productType &&
                    isBalloonLeasing(contract.productType) &&
                    contract.isEligibleRenewalContractNotificationForLessOfFourMonths && (
                        <Notification
                            visible
                            title={t('contract-end-info-4.headline', {
                                value: contract.contractNumber,
                                date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                            })}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('contract-end-info-4.description.ballon'),
                                }}
                            />
                            <div>
                                <Button
                                    onClick={() => handleContractEndOptionsClicked(contract)}
                                    className="u-mt"
                                    disabled={isContactInformationChangePending}
                                >
                                    {t('contract-end-info-4.button')}
                                </Button>
                                {isContactInformationChangePending && (
                                    <InfoIcon text={mt('notifications.contact-information-change-pending')} />
                                )}
                            </div>
                        </Notification>
                    )}

                {/* -------- LINE 2 - Crédit bail, LINE 3 - LLD contracts, LINE 4 - LOUN contracts ----------  (NO BUTTON)*/}
                {contract.productType &&
                    isLine2Product(contract.productType) &&
                    contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths && (
                        <Notification
                            visible
                            title={t('contract-end-info-12.headline', {
                                value: contract.contractNumber,
                                date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                            })}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            {' '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('contract-end-info-12.description.loun'),
                                }}
                            />
                        </Notification>
                    )}

                {/* -------- LINE 5 - Crédit ---------- */}
                {contract.productType &&
                    isCredit(contract.productType) &&
                    contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths && (
                        <Notification
                            visible
                            title={t('contract-end-info-12.headline', {
                                value: contract.contractNumber,
                                date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                            })}
                            testId="credit-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('contract-end-info-12.description.credit'),
                                }}
                            />
                        </Notification>
                    )}

                {/* -------- Stand Alone ---------- NO BUTTON */}
                {contract.productType &&
                    isStandalone(contract.productType) &&
                    contract.isEligibleRenewalContractNotificationForLessOfThreeMonths && (
                        <Notification
                            visible
                            title={t('contract-end-info-3.headline', {
                                value: contract.contractNumber,
                                date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                            })}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            {' '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('contract-end-info-3.description.standalone', {
                                        brand: getVehicleRestitutionGuideBrand(contract.brand),
                                        brandUpper: getVehicleRestitutionGuideBrand(contract.brand, true),
                                    }),
                                }}
                            />
                        </Notification>
                    )}
                {/** -------- LOA LLD LOUN - 1 and 2 months */}
                {contract.productType &&
                    isLLLProduct(contract.productType) &&
                    (contract.isEligibleRenewalContractNotificationForLessOfTwoMonths ||
                        contract.isEligibleRenewalContractNotificationForLessOfOneMonth) && (
                        <Notification
                            visible
                            title={t('contract-end-info-1.headline', {
                                value: contract.contractNumber,
                                date: moment(contract.contractEndDate).format('DD/MM/YYYY'),
                            })}
                            testId="ballon-infobox"
                            showCloseButton={false}
                            status="info"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('contract-end-info-1.description.loun'),
                                }}
                            />
                            <div>
                                <Button
                                    onClick={() => handleContractEndOptionsClicked(contract)}
                                    className="u-mt"
                                    disabled={isContactInformationChangePending}
                                >
                                    {t('contract-end-info-1.button')}
                                </Button>
                                {isContactInformationChangePending && (
                                    <InfoIcon text={mt('notifications.contact-information-change-pending')} />
                                )}
                            </div>
                        </Notification>
                    )}
            </React.Fragment>
        );
    });

    const renderContractEndInfo = () => {
        if (contractsWithNotification) {
            return <div className={'u-mb'}>{contractsWithNotification}</div>;
        }

        return null;
    };

    const memoizedListOfActiveContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);
    const memoizedListOfInactiveContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);
    return (
        <>
            {renderContractEndInfo()}
            {memoizedListOfActiveContracts}
            {!!memoizedListOfInactiveContracts && (
                <div className={'u-mt-large'}>
                    <h4 className="u-text-center">{t('expand.headline')}</h4>
                    <ExpandableContent
                        lazyRender
                        className={'u-mt'}
                        noBackground
                        pageWrapClassName={'u-pl-none u-pr-none'}
                        triggerLabel={t('expand.trigger')}
                    >
                        {memoizedListOfInactiveContracts}
                    </ExpandableContent>
                </div>
            )}
        </>
    );
};
