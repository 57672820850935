import { EarlySettlementLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile';
import React from 'react';
import { EarlyReturnPenaltyUi } from './ui/EarlyReturnPenaltyUi';

export const EarlyReturnPenalty: React.FC<{ contractId: string }> = ({ contractId }) => {
    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: loadingContractError,
    } = useContract(contractId);
    const { data: myProfile, isLoading, loadingError } = useMyProfile();
    const ContractsWithHandlers = withLoadingAndNoConnectionHandler(EarlyReturnPenaltyUi);

    return (
        <ContractsWithHandlers
            isLoading={isLoading || isLoadingContract}
            contract={contract}
            myProfile={myProfile}
            hasError={!!loadingError || !!loadingContractError}
            loadingPlaceholder={<EarlySettlementLoadingPlaceholder />}
        />
    );
};
