import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { CircumstancesOfDisaster } from '@cp-fr/common';

type Props = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const CircumstancesOfDisasterSelection: React.FC<Props> = ({ label, name, isMandatory }) => {
    const list: CircumstancesOfDisaster[] = [
        'Vol du véhicule avec effraction',
        'Vol du véhicule sans effraction',
        'Collision',
        'Incendie',
    ];

    const selectItems: ValidatedSelectItem[] = list.map((item: CircumstancesOfDisaster) => {
        return { value: item, label: item };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
