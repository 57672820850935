import { IconFooter, IconFooterItem } from '@cp-fr/common';
import {
    IconFooter as IconFooterShared,
    IconFooterItem as IconFooterItemShared,
    useAnalyticsActionTracker,
} from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter; isLoggedIn?: boolean }> = ({ iconFooter }) => {
    const { onAction } = useAnalyticsActionTracker('onClickEmailLink');
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];

    if (!iconFooter || !iconFooter.items) {
        return null;
    }

    const items = iconFooter.items.map((item: IconFooterItem): IconFooterItemShared => {
        const isExternalLink = item.linkUrl?.startsWith('http') || false;
        return {
            ...item,
            target: isExternalLink ? 'blank' : undefined,
            ...(isExternalLink ? { element: 'a' } : {}),
            modalContent: item.modalContent ? (
                <div dangerouslySetInnerHTML={{ __html: item.modalContent as string }} />
            ) : null,
        };
    });

    return <IconFooterShared items={items} trackingFunction={onAction} currentPageName={currentPageName} />;
};
