import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { ContactDetails } from '@cp-fr/common';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { NotFoundError } from '../../not-found-error/NotFoundError';
import { EditStatus } from '../edit-view/EditView';
import { ContactList } from './ContactList';
import { NotificationForEditStatus } from './NotificationForEditStatus';
import { ModificationStatus } from '../../modification-status';

export type ConsultViewProps = {
    contactDetails?: ContactDetails;
    startEditing?: () => void;
    editStatus: EditStatus;
    changePending: boolean;
};

export const isEmptyContact = (contactDetails?: ContactDetails): boolean => {
    return !contactDetails || isEmpty(contactDetails);
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    contactDetails,
    startEditing,
    editStatus,
    changePending,
}) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-details.consult-view';

    return (
        <DataOverview
            title={t(`${translationPrefix}.title`)}
            buttonLabel={t('contact-details.consult-view.edit-button')}
            buttonProps={{
                onClick: startEditing,
                testId: 'editButton',
                disabled: changePending,
            }}
        >
            {changePending && <ModificationStatus />}
            {!contactDetails ? (
                <NotFoundError />
            ) : isEmptyContact(contactDetails) ? (
                <NoConnectionNotification />
            ) : (
                <>
                    <Notification
                        status={NotificationStatus.info}
                        text={t(`${translationPrefix}.info-alert`)}
                        testId="contact-datails-info-alert"
                        className="u-mb"
                    />
                    <ContactList contactDetails={contactDetails} />
                    <NotificationForEditStatus editStatus={editStatus} />
                </>
            )}
        </DataOverview>
    );
};
