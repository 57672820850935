import { RequestStatus } from '@cp-fr/common';
import { MyProfileLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { MyProfileUi } from './ui';
import { useContactInformationChangeStatus } from './useContactInformationChangeStatus';
import { useMyProfile } from './useMyProfile';
import { useNameChangeStatus } from './useNameChangeStatus';
import { useAddressChangeStatus } from './useAddressChangeStatus';
import { useContracts } from '../contracts/useContracts';

const MyProfileWithHandlers = withLoadingAndNoConnectionHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data, isLoading, loadingError } = useMyProfile();
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingContractsError } = useContracts();
    const {
        data: contactInformationChange,
        isLoading: isLoadingContactInformationChange,
        loadingError: loadingContactInformationChangeError,
    } = useContactInformationChangeStatus();

    const {
        data: nameChange,
        isLoading: isLoadingNameChange,
        loadingError: loadingNameChangeError,
    } = useNameChangeStatus();

    const {
        data: addressChange,
        isLoading: isLoadingAddressChange,
        loadingError: loadingAddressChangeError,
    } = useAddressChangeStatus();

    useAnalyticsPageViewTracker('profile', !!data);

    return (
        <MyProfileWithHandlers
            isContactInformationChangePending={
                contactInformationChange?.requestCustomerStatus === RequestStatus.IN_PROGRESS
            }
            isNameChangePending={nameChange?.requestCustomerStatus === RequestStatus.IN_PROGRESS}
            isAddressChangePending={addressChange?.requestCustomerStatus === RequestStatus.IN_PROGRESS}
            isLoading={
                isLoading ||
                isLoadingContracts ||
                isLoadingContactInformationChange ||
                isLoadingNameChange ||
                isLoadingAddressChange
            }
            myProfileData={data}
            contracts={contracts}
            hasError={
                !!loadingError ||
                !!loadingContractsError ||
                !!loadingContactInformationChangeError ||
                !!loadingNameChangeError ||
                !!loadingAddressChangeError
            }
            loadingPlaceholder={<MyProfileLoadingPlaceholder />}
        />
    );
};
