import { Contract, ProductTypeEN } from '@cp-fr/common';

export const ContractsInfoWithAllValues: Contract[] = [
    {
        isActive: true,
        contractId: '23456789',
        contractNumber: '234567',
        productType: 'Abrégio Style - credit',
        productTypeEN: ProductTypeEN.CREDIT,
        carModel: 'Golf',
        licensePlate: '12-AB-34',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
    {
        isActive: true,
        contractId: '123456789',
        contractNumber: '1234567',
        productType: 'Abrégio Style - financial lease',
        productTypeEN: ProductTypeEN.FINANCIAL_LEASE,
        carModel: 'Golf',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
    {
        isActive: true,
        contractId: '3456789',
        contractNumber: '34567',
        productType: 'Abrégio Style - balloon',
        productTypeEN: ProductTypeEN.BALLON_LEASING,
        carModel: 'Golf',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
    {
        isActive: true,
        contractId: '456789',
        contractNumber: '4567',
        productType: 'Abrégio Style - operative leasing',
        productTypeEN: ProductTypeEN.OPERATIVE_LEASING,
        carModel: 'Golf',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
    {
        isActive: true,
        contractId: '56789',
        contractNumber: '567',
        productType: 'Abrégio Style - VDL',
        productTypeEN: ProductTypeEN.VDL,
        carModel: 'Golf',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
    {
        isActive: true,
        contractId: '6789',
        contractNumber: '67',
        productType: 'Abrégio Style - stand alone',
        productTypeEN: ProductTypeEN.STAND_ALONE,
        carModel: 'Golf',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
];

export const ContractsInfoWithMissingValues: Contract[] = [
    {
        isActive: true,
        contractId: '23456789',
        contractNumber: '234567',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
    {
        isActive: true,
        contractId: '123456789',
        contractNumber: '1234567',
        productType: 'Abrégio Style',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
];

export const ContractsInfoWithExpiredContract: Contract[] = [
    {
        isActive: false,
        contractId: '23456789',
        contractNumber: '234567',
        productType: 'Abrégio Style',
        productTypeEN: ProductTypeEN.FINANCIAL_LEASE,
        carModel: 'Golf',
        licensePlate: '56-AB-78',
        contractStartDate: '2019-02-18',
        contractEndDate: '2050-02-18',
    } as Contract,
];

export const ContractSelectionWithAllValues = {
    isActive: true,
    contractId: '4562186',
    contractNumber: '234567',
    productTypeEN: ProductTypeEN.CREDIT,
    contractStartDate: '2019-02-18',
    contractEndDate: '2050-02-18',
};
