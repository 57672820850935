import { Contract, getMyDocumentsEndpoint, MyDocumentsData } from '@cp-fr/common';
import {
    Heading,
    Button,
    SectionHeading,
    Table,
    Hr,
    Paragraph,
    NotificationsGroup,
    Notification,
} from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersSection } from '../filters-section/FiltersSection';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import { DocumentFilters } from '../filters-section/DocumentFiltersSlice';

const groupDocuments = (data: MyDocumentsData[]) => {
    const groups: { [key: string]: MyDocumentsData[] } = {};
    data.forEach((document) => {
        const year = new Date(document.date).getFullYear().toString();
        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(document);
    });
    return groups;
};

const dateIncludedInPeriod = (date: string, period: string) => {
    const curDate = new Date();
    const getPrevDate = (months: number) =>
        new Date(curDate.getFullYear(), curDate.getMonth() - months, curDate.getDate());
    switch (period) {
        case 'last3Months':
            return new Date(date) > getPrevDate(3);
        case 'last6Months':
            return new Date(date) > getPrevDate(6);
        case 'last12Months':
            return new Date(date) > getPrevDate(12);
        case 'last2Years':
            return new Date(date) > getPrevDate(24);
        case 'moreThan2Years':
            return new Date(date) < getPrevDate(24);
        default:
            return true;
    }
};

const handleDownload = (path: string, name: string) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = name;
    CpDataApi.get(path).then((res) => {
        link.href = `data:application/pdf;base64,${res.data.content}`;
        link.click();
    });
};

const initialState: DocumentFilters = {
    documentTypeFilter: 'all',
    timePeriodFilter: 'last3Months',
    contractFilter: 'all',
    cleared: false,
};

export const MyDocumentsUi: React.FC<{ myProfileData?: MyDocumentsData[]; contracts?: Contract[] }> = ({
    myProfileData,
    contracts,
}) => {
    const { t } = useTranslation('my-documents');

    const filters = useSelector((state: RootState) => state.documentFilters);
    const filteredDocuments = myProfileData
        ?.filter((doc) => filters.documentTypeFilter === 'all' || doc.documentFamily === filters.documentTypeFilter)
        .filter((doc) => filters.contractFilter === 'all' || doc.contractNumber === filters.contractFilter)
        .filter(
            (doc) => filters.timePeriodFilter === 'all' || dateIncludedInPeriod(doc.date, filters.timePeriodFilter),
        );
    const groups = groupDocuments(filteredDocuments || []);
    const years = Object.keys(groups)
        .map((year) => parseInt(year))
        .sort()
        .reverse();

    return (
        <>
            <Heading level={1}>{t('title')}</Heading>
            <Paragraph>{t('subtitles.1')}</Paragraph>
            <Paragraph>
                {t('subtitles.2')} <strong>{t('subtitles.2b')}</strong>.
            </Paragraph>
            <Paragraph className="u-mb">{t('subtitles.3')}</Paragraph>
            <Formik initialValues={initialState} onSubmit={() => undefined}>
                <>
                    {myProfileData && myProfileData.length === 0 && (
                        <NotificationsGroup>
                            <Notification status="warning" title={t('no-entry')} visible>
                                <React.Fragment key=".0">{t('check-input')}</React.Fragment>
                            </Notification>
                        </NotificationsGroup>
                    )}

                    <FiltersSection documents={myProfileData} contracts={contracts} />

                    {!years.length && <DocumentsTable documents={[]} />}
                    {!!years.length &&
                        years.map((year, index) => (
                            <div key={year.toString()}>
                                <SectionHeading level={4} center>
                                    {year.toString()}
                                </SectionHeading>
                                <DocumentsTable documents={groups[year.toString()]} />
                                {index < years.length - 1 && <Hr />}
                            </div>
                        ))}
                </>
            </Formik>
        </>
    );
};

export const DocumentsTable: React.FC<{ documents?: MyDocumentsData[] }> = ({ documents }) => {
    const { t } = useTranslation('my-documents');

    const getName = (document: MyDocumentsData) => {
        return `${document.description} ${document.documentFamily === 'Contrat' ? document.contractNumber : ''}`;
    };

    return (
        <Table wide>
            <thead>
                <tr>
                    <td style={{ width: '200px' }}>{t('date')}</td>
                    <td>{t('document_name')}</td>
                    <td style={{ width: '60px' }}>{t('download')}</td>
                </tr>
            </thead>
            <tbody>
                {documents?.length === 0 && (
                    <tr>
                        <td></td>
                        <td>{t('no-results')}</td>
                        <td></td>
                    </tr>
                )}
                {documents?.map((document) => (
                    <tr key={document.documentId}>
                        <td>{new Date(document.date).toLocaleDateString()}</td>
                        <td>
                            <Button
                                simple
                                small
                                secondary
                                link
                                onClick={() =>
                                    handleDownload(
                                        `${getMyDocumentsEndpoint()}/${document.documentId}`,
                                        document.contractNumber,
                                    )
                                }
                            >
                                {getName(document)}
                            </Button>
                        </td>
                        <td>
                            <Button
                                icon="semantic-download"
                                iconReversed
                                link
                                secondary
                                small
                                onClick={() =>
                                    handleDownload(
                                        `${getMyDocumentsEndpoint()}/${document.documentId}`,
                                        document.contractNumber,
                                    )
                                }
                                testId={'download-button'}
                            >
                                {` `}
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
