import * as Yup from 'yup';
import { AdressSectionFormBEError, AdressSectionFormViewError } from '../../messages/myProfileMessages';
import { AdressSectionFormViewFEValidatorErrors, AdressSectionFormViewBEValidatorErrors } from '../../types';

export type AdressSectionFormTypes = {
    houseNumber: string;
    street: string | undefined;
    building: string | undefined;
    door: string | undefined;
    postalCode: string;
    city: string;
    country: string;
};

export type AdressSectionBackendTypes = AdressSectionFormTypes & {
    files: {
        base64: string | undefined;
        filename: string | undefined;
        mimeType: string | undefined;
        size: number | undefined;
    }[];
};

export const adressSectionValidationSchema = (
    errors: AdressSectionFormViewFEValidatorErrors = AdressSectionFormViewError,
): Yup.SchemaOf<AdressSectionFormTypes> =>
    Yup.object().shape({
        houseNumber: Yup.string().trim().max(10, errors.houseNumber.max).required(errors.houseNumber.required),
        street: Yup.string().trim().max(60, errors.street.max),
        building: Yup.string().trim().max(10, errors.building.max),
        door: Yup.string().trim().max(5, errors.door.max),
        postalCode: Yup.string().trim().required(errors.postalCode.required).max(5, errors.postalCode.max),
        city: Yup.string().trim().required(errors.city.required).max(40, errors.city.max),
        country: Yup.string().trim().required(errors.country.required).max(40, errors.country.max),
    });

export const adressSectionBackendValidationSchema = (
    errors: AdressSectionFormViewBEValidatorErrors = AdressSectionFormBEError,
): Yup.SchemaOf<AdressSectionBackendTypes> => {
    return adressSectionValidationSchema(errors).shape({
        files: Yup.array()
            .of(
                Yup.object().shape({
                    base64: Yup.string(),
                    filename: Yup.string(),
                    mimeType: Yup.string(),
                    size: Yup.number(),
                }),
            )
            .min(1, errors.files.required),
    });
};
