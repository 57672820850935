import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { Moment } from 'moment';
import { ContractDetails as TContractDetails, Contract } from '@cp-fr/common/src/apis/services/types/contracts';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { getFirstEligibleDateForEarlyReturnPenalty } from 'utils/early-return-penalty-utils';

export const AvailableDateSelector: React.FC<{ contract: Contract & TContractDetails }> = ({ contract }) => {
    const { t } = useTranslation('early-return-penalty');

    const eligibleDates: ValidatedSelectItem[] = [];
    const startDate: Moment | undefined = getFirstEligibleDateForEarlyReturnPenalty(contract);

    if (startDate) {
        const date = startDate.date();
        const selectedDay = date < 10 ? `0${date}` : date;
        const maxDate = formatCpDate(contract.contractEndDate).toMoment().add(-30, 'day');

        while (startDate.isBefore(maxDate)) {
            eligibleDates.push({
                label: startDate.format(`${selectedDay}/MM/YYYY`),
                value: startDate.format(`YYYY-MM-${selectedDay}`),
            });
            startDate.add(1, 'month');
        }
    }

    return (
        <ValidatedSelect
            name={'requestDate'}
            label={t('form.request-date.label')}
            placeholder={t('form.request-date.placeholder')}
            selectItems={eligibleDates.map(({ value, label }) => ({
                value,
                label,
            }))}
        />
    );
};
