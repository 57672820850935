import {
    BundledProduct,
    ContractDetails,
    ContractParty,
    FinancialDetails,
    getChangeIbanEndpoint,
    RequestCustomerStatus,
    RequestStatus,
    VehicleDetails,
} from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';

export const FinancialDetailsWithAllValues: FinancialDetails = {
    amountBorrowed: 151900,
    firstDatePayment: '2018-10-15',
    lastDatePayment: '2021-10-15',
    lastPaymentExclTVA: 12321.76,
    lastPaymentInclTVA: 12321.76,
    monthlyPaymentHT: 453.13,
    monthlyPaymentTTC: 377.6,
    numberOfServices: 0,
    paymentDeadline: '2021-10-15',
    returnBackVehicleDate: '2021-10-15',
    term: 36,
    contractMileage: 15000,
    collectionDate: 7,
    rate: 2,
    iban: 'FR1420041010050500013M02606',
    ibanHolder: 'Nicolas Dupond',
    paymentMethod: 'paiement en espèces',
    _links: { changeIban: getChangeIbanEndpoint('AX01') },
};

export const FinancialDetailsWithMissingValues: FinancialDetails = {
    amountBorrowed: 151900,
    term: 36,
    ibanHolder: 'Nicolas Dupond',
    rate: 2,
};

export const FinancialDetailsWithMissingValuesForCREDIT: FinancialDetails = {
    amountBorrowed: 151900,
    paymentMethod: 'paiement en espèces',
};

export const VehicleDetailsWithAllValues: VehicleDetails = {
    carDescription: 'VOLKSWAGEN Sharan Diesel (7N2)',
    carAddDescription: 'Sharan 2.0 TDi Blue Confortline 150 CV',
    licensePlate: '47-SL-24',
    vehicleType: 'VP',
    co2: 120,
    energy: 'Diesel',
    fiscalPower: 7,
    driver: 'Alexandre Dupont',
    carCode: 'AUDI',
    vin: 'WAUZZZ4G1FN076523',
};

export const VehicleDetailsWithMissingValues: VehicleDetails = {
    carDescription: 'VOLKSWAGEN Sharan Diesel (7N2)',
    carAddDescription: 'Sharan 2.0 TDi Blue Confortline 150 CV',
    vehicleType: 'VP',
    co2: 120,
    driver: 'Alexandre Dupont',
    vin: 'WAUZZZ4G1FN076523',
};

export const PrivateVehicleDetailsWithMissingAllValues: VehicleDetails = {
    carDescription: undefined,
    carAddDescription: undefined,
    licensePlate: undefined,
    vin: undefined,
    vehicleType: 'VP',
    co2: 120,
    energy: 'Diesel',
    fiscalPower: 7,
    driver: 'Alexandre Dupont',
};

export const ContractPartiesWithAllValues: ContractParty[] = [
    {
        name: 'JEAN',
        surname: 'DUPONT',
        role: 'Co-Emprunteur',
        address: {
            street: '105 CRS DE VINCENNES',
            postalCode: '75020',
            city: 'PARIS',
        },
    },
    {
        name: 'ANNA',
        surname: 'DUPONT',
        role: 'Concessionnaire',
        address: {
            street: '105 CRS DE VINCENNES',
            postalCode: '75020',
            city: 'PARIS',
        },
    },
];

export const ContractPartiesWithMissingValues: ContractParty[] = [
    {
        surname: 'DUPONT',
    },
    {
        role: 'Concessionnaire',
        address: {
            postalCode: '75020',
            city: 'PARIS',
        },
    },
];

export const BundledProductsWithAllValues: BundledProduct[] = [
    {
        name: 'Extension de Garantie VO',
        status: 'INACTIVE',
        startDate: formatCpDate('2018-10-11T09:44:57.299').toCpDate(),
        endDate: formatCpDate('2021-12-24T09:44:57.299').toCpDate(),
        supplier: 'EUROP ASSISTANCE',
    },
    {
        name: 'Extension de Garantie VN',
        status: 'ACTIVE',
        startDate: formatCpDate('2017-10-11T09:44:57.299').toCpDate(),
        endDate: formatCpDate('2020-12-24T09:44:57.299').toCpDate(),
        supplier: 'EUROP ASSISTANCE',
    },
    {
        name: 'Extension de TEST',
        status: 'INACTIVE',
        startDate: formatCpDate('2018-07-13T09:44:57.299').toCpDate(),
        endDate: formatCpDate('2020-01-24T09:44:57.299').toCpDate(),
        supplier: 'EUROP ASSISTANCE',
    },
    {
        status: 'ACTIVE',
        startDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
        endDate: formatCpDate('2020-12-24T09:44:57.299').toCpDate(),
        supplier: 'EUROP ASSISTANCE',
    },
    {
        name: 'Entretien',
        status: 'ACTIVE',
        startDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
        endDate: formatCpDate('2020-12-24T09:44:57.299').toCpDate(),
        supplier: 'EUROP ASSISTANCE',
    },
];

export const BundledProductsWithMissingValues: BundledProduct[] = [
    {
        name: 'Extension de Garantie VO',
        status: 'ACTIVE',
        endDate: formatCpDate('2020-12-24T09:44:57.299').toCpDate(),
    },
    {
        name: 'Extension de Garantie VN',
        startDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    },
    {
        name: undefined,
    },
    {
        status: 'INACTIVE',
        startDate: formatCpDate('2020-01-24T09:44:57.299').toCpDate(),
        endDate: formatCpDate('2020-01-24T09:44:57.299').toCpDate(),
    },
    {
        status: 'INACTIVE',
    },
];

export const BundledProductsWithMissingAllValues: BundledProduct[] = [
    {
        name: undefined,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        supplier: undefined,
    },
];

export const ContractDetailsWithAllValues: ContractDetails = {
    financial: FinancialDetailsWithAllValues,
    vehicle: VehicleDetailsWithAllValues,
    contractParties: ContractPartiesWithAllValues,
    bundledProducts: BundledProductsWithAllValues,
};

export const ContractDetailsWithMissingValues: ContractDetails = {
    financial: FinancialDetailsWithMissingValues,
    vehicle: VehicleDetailsWithMissingValues,
    contractParties: ContractPartiesWithMissingValues,
    bundledProducts: BundledProductsWithMissingValues,
};

export const RequestCustomerStatusInProgressResponse: RequestCustomerStatus = {
    requestCustomerStatus: RequestStatus.IN_PROGRESS,
};

export const RequestCustomerStatusDoneResponse: RequestCustomerStatus = {
    requestCustomerStatus: RequestStatus.DONE,
};
