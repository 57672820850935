import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInput, FileUpload, PhonenumberInput, TextArea, TextInput } from '../../simple-form-components';

export const OtherForm: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.other';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate('description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'other.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput label={translate('phonenumber')} name={'other.phonenumber'} isMandatory />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('subject-of-claim')} name={'other.subjectOfClaim'} />
            </Layout.Item>
            <Layout.Item>
                <TextArea label={translate('message')} name={'other.message'} />
            </Layout.Item>
            <Layout.Item>
                <FileUpload name={'other.files.attachment'} label={translate('attachment')} isMandatory={false} />
            </Layout.Item>
        </>
    );
};
