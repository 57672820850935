import { FinancialDetails, ProductTypeEN } from '@cp-fr/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { emptyObjectOrAllValuesAreUndefined } from '../../../../../utils';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import { valueAvailable } from '../../utils';

type GeneralSectionProps = {
    details?: FinancialDetails;
    isActive?: boolean;
    productType?: ProductTypeEN;
    isSMECustomer?: boolean;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ details, isActive, productType, isSMECustomer }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    if (!details) {
        return null;
    }

    const { amountBorrowed, term, contractMileage, monthlyPaymentTTC, monthlyPaymentHT } = details;

    const isCredit = productType === ProductTypeEN.CREDIT;
    const isError = emptyObjectOrAllValuesAreUndefined(details) || (!isCredit && !term && !contractMileage);

    const amountBorrowedLine =
        isCredit && valueAvailable(amountBorrowed) && isActive
            ? [
                  {
                      label: t('general-section.amount-borrowed'),
                      value: f(amountBorrowed, TranslationFormat.CURRENCY),
                      testId: 'amount-borrowed',
                      // TODO: fix issue with tooltip breaking project compilation in tests
                      // tooltip: t('general-section.amount-borrowed-tooltip'),
                  },
              ]
            : [];

    const itemList: DefinitionListItem[] = [...amountBorrowedLine];

    if (valueAvailable(term)) {
        itemList.push({
            label: t('general-section.term'),
            value: t('translation:metrics.month', { value: term }),
            testId: 'term',
            // TODO: fix issue with tooltip breaking project compilation in tests
            // tooltip: t('general-section.term-tooltip'),
        });
    }

    if (valueAvailable(contractMileage)) {
        itemList.push({
            label: t('general-section.contract-mileage'),
            value: f(contractMileage, TranslationFormat.DISTANCE),
            testId: 'contract-mileage',
        });
    }

    if (valueAvailable(monthlyPaymentTTC)) {
        itemList.push({
            label: isCredit ? t('general-section.monthly-payment-credit') : t('general-section.monthly-payment-tva'),
            value: f(monthlyPaymentTTC, TranslationFormat.CURRENCY),
            testId: 'monthly-payment-tva',
        });
    }

    if (isSMECustomer && valueAvailable(monthlyPaymentHT)) {
        itemList.push({
            label: t('general-section.monthly-payment-no-tva'),
            value: f(monthlyPaymentHT, TranslationFormat.CURRENCY),
            testId: 'monthly-payment-no-tva',
        });
    }

    return isError ? (
        <ErrorNotification testId={'not-found-error'} headline={t('error.headline')} text={t('error.text')} />
    ) : (
        <DefinitionList split={true} list={itemList} />
    );
};
