import { useTranslation } from 'react-i18next';
import React from 'react';
import { ValidatedInput } from '@cp-shared-8/frontend-ui';
import { Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { PhoneInput } from '../../../request/form-view/category-selection/category-view/simple-form-components/phone-input/PhoneInput';
import { FormikProps, useFormikContext } from 'formik';
import { CustomerDataFormFields } from './utils';

export const ContactFields: React.FC = () => {
    const { t } = useTranslation(['contact-details-check', 'confirm-information']);
    const formik: FormikProps<CustomerDataFormFields> = useFormikContext();

    return (
        <>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedInput
                                label={t('confirm-information:email')}
                                name="contactDetails.email"
                                testId="email"
                                type="email"
                            />
                        </Layout.Item>
                        <Layout.Item default="1/2" s="1/1">
                            <PhoneInput
                                phoneFieldLabel={t('confirm-information:mobile')}
                                phoneFieldName={'contactDetails.mobilePhone'}
                                phoneFieldTestId={'mobilePhone'}
                                phoneFieldPrefixLabel={t('form.mobile-phone.prefix-label')}
                                phoneFieldPrefixName={'contactDetails.mobilePhonePrefix'}
                                phoneFieldPrefixTestId={'mobilePhonePrefix'}
                                setFormikFieldValue={formik.setFieldValue}
                                hidePrefix={true}
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
};
