import { SocioprofessionalCategories } from '@cp-fr/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import React from 'react';

type SocioprofessionalCategorySelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const SocioprofessionalCategorySelection: React.FC<SocioprofessionalCategorySelectionProps> = ({
    label,
    name,
    isMandatory = true,
}) => {
    const socioprofessionalCategories: SocioprofessionalCategories[] = [
        'Agriculteurs exploitants',
        "Artisans, commerçants et chefs d'entreprise",
        'Cadres et professions intellectuelles supérieures',
        'Professions intermédiaires',
        'Employés',
        'Ouvriers',
        'Retraités',
        'Autres personnes sans activités professionnelle',
        'Autre',
    ];

    const selectItems: ValidatedSelectItem[] = socioprofessionalCategories.map(
        (socioprofessionalCategory: SocioprofessionalCategories) => {
            return { value: socioprofessionalCategory, label: socioprofessionalCategory };
        },
    );

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
