import { PartialEarlySettlementValidatorErrors } from '../types';

export const PartialEarlySettlementError: PartialEarlySettlementValidatorErrors = {
    requestDate: {
        required: 'request date is required',
    },
    reason: {
        required: 'reason is required',
    },
    amount: {
        required: 'amount is required',
    },
    goal: {
        required: 'goal is required',
    },
};
