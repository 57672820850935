import * as Yup from 'yup';
import { TenantChangeRequestValidatorErrors } from '../../types';
import { RequestTenantChangeError } from '../../messages';
import { RequestCategory, TransferTo } from '../../../apis/services/types/request';
import { fileValidation } from '../FileValidator';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type RequestTenantChangeFormType = {
    email: string;
    phonenumber: string;
    transferDate: string;
    reason: string;
    transferTo: string;
};

export type RequestTenantChangeBackendFormType = RequestTenantChangeFormType & {
    files: {
        base64: string | undefined;
        filename: string | undefined;
        mimeType: string | undefined;
        size: number | undefined;
    }[];
};

export type RequestTenantChangeFrontendFormType = RequestTenantChangeFormType & {
    files: {
        dialogueSheet?: string;
        idCopy?: string;
        taxPackage?: string;
        kbisOrInsee?: string;
        newTenantIban?: string;
        vehicleId?: string;
        idCopyManager?: string;
        taxReturn?: string;
    };
};

export const requestTenantChangeCoreSchema = (
    errors: TenantChangeRequestValidatorErrors = RequestTenantChangeError,
): Yup.SchemaOf<RequestTenantChangeBackendFormType> => {
    return Yup.object().shape({
        email: Yup.string().trim().email(errors.email.mustBeEmail).required(errors.email.required),
        transferTo: Yup.string().trim().required(errors.transferTo.required),
        phonenumber: phoneNumberNotRequiredValidation(errors.phonenumber.mustBePhoneNumber),
        transferDate: Yup.string().trim().required(errors.transferDate.required),
        reason: Yup.string().trim().required(errors.reason.required),
        files: Yup.array()
            .of(
                Yup.object().shape({
                    base64: Yup.string(),
                    filename: Yup.string(),
                    mimeType: Yup.string(),
                    size: Yup.number(),
                }),
            )
            .min(1, errors.files.idCopy.required),
        comment: Yup.string(),
    });
};

export const requestTenantChangeFrontendValidationSchema = (
    errors: TenantChangeRequestValidatorErrors = RequestTenantChangeError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestTenantChangeFrontendFormType | {}> => {
    const baseFilesValidation = {
        dialogueSheet: fileValidation(errors.files.dialogueSheet.maxLength, errors.files.dialogueSheet.required),
        vehicleId: fileValidation(errors.files.vehicleId.maxLength, errors.files.vehicleId.required),
    };
    return requestTenantChangeCoreSchema(errors).shape({
        files: Yup.object()
            .when('transferTo', {
                is: (val: TransferTo) => val === 'Personne physique',
                then: Yup.object().shape({
                    ...baseFilesValidation,
                    idCopy: fileValidation(errors.files.idCopy.maxLength, errors.files.idCopy.required),
                    taxPackage: fileValidation(errors.files.taxPackage.maxLength, errors.files.taxPackage.required),
                    kbisOrInsee: fileValidation(errors.files.kbisOrInsee.maxLength),
                    newTenantIban: fileValidation(
                        errors.files.newTenantIban.maxLength,
                        errors.files.newTenantIban.required,
                    ),
                }),
            })
            .when('transferTo', {
                is: (val: TransferTo) => val === 'Personne morale',
                then: Yup.object().shape({
                    ...baseFilesValidation,
                    idCopyManager: fileValidation(
                        errors.files.idCopyManager.maxLength,
                        errors.files.idCopyManager.required,
                    ),
                    taxReturn: fileValidation(errors.files.taxReturn.maxLength, errors.files.taxReturn.required),
                    newTenantIban: fileValidation(
                        errors.files.newTenantIban.maxLength,
                        errors.files.newTenantIban.required,
                    ),
                }),
            }),
    });
};

export const requestTenantChangeValidationSchema = (
    field: string,
    errors: TenantChangeRequestValidatorErrors = RequestTenantChangeError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestTenantChangeFrontendFormType | {}> => {
    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory) => {
            return requestCategory === 'TENANT';
        },
        then: requestTenantChangeFrontendValidationSchema(errors),
    });
};
