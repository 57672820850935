import { MarketingCard, getMarketingCardEndpoint } from '@cp-fr/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<MarketingCard>({
    contentName: 'marketingCard',
    contentEndpoint: getMarketingCardEndpoint,
});

export default reducer;
export const fetchMarketingCard = fetchContent;
