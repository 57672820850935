import { ProductTypeEN } from './contracts';
import { Base64File } from './files';

export type RequestError = 'BAD_CATEGORY' | 'NOT_FOUND';

export type RequestCategory =
    | 'AUTHORIZATION_DEBIT'
    | 'DEADLINE'
    | 'VEHICLE_CLAIM'
    | 'DEATH'
    | 'LOSS'
    | 'REQUEST_CONTRACT_TRANSFER'
    | 'DOCUMENTS'
    | 'REGISTRATION_CERTIFICATE_DUPLICATE'
    | 'AUTHORIZATION_LEAVE_COUNTRY'
    | 'TENANT'
    | 'CHANGE_DURATION_MILEAGE'
    | 'CONFIRM_INFORMATION'
    | 'CONFIRM_INFORMATION_RETAIL'
    | 'CONFIRM_INFORMATION_BUSINESS'
    | 'OTHER';

export type EmploymentStatus = 'Propriétaire' | 'Locataire' | 'Logé à titre gratuit';

export type TransferTo = 'Personne physique' | 'Personne morale';

export type SocioprofessionalCategories =
    | 'Agriculteurs exploitants'
    | "Artisans, commerçants et chefs d'entreprise"
    | 'Cadres et professions intellectuelles supérieures'
    | 'Professions intermédiaires'
    | 'Employés'
    | 'Ouvriers'
    | 'Retraités'
    | 'Autres personnes sans activités professionnelle'
    | 'Autre';

export type SettlementReason =
    | 'Revente du véhicule'
    | 'Rachat de crédit'
    | 'Epargne personnelle'
    | 'Prime'
    | 'Héritage';

export type BalanceReason = SettlementReason;

export type RequestContractTransferReason =
    | 'Projet immobilier'
    | 'Changement de situation familiale'
    | "Acquisition d'un modèle de véhicule plus adapté"
    | 'Difficultés financières'
    | 'Autre, précisez';

export type MonthlyDate = '1' | '5' | '15' | '20' | '25';

export type FrMonth =
    | 'Janvier'
    | 'Février'
    | 'Mars'
    | 'Avril'
    | 'Mai'
    | 'Juin'
    | 'Juillet'
    | 'Août'
    | 'Septembre'
    | 'Octobre'
    | 'Novembre'
    | 'Décembre';

export type NatureOfDamage = 'Vol' | 'Incendie' | 'Destruction totale' | 'Inondation';

export type CircumstancesOfDisaster =
    | 'Vol du véhicule avec effraction'
    | 'Vol du véhicule sans effraction'
    | 'Collision'
    | 'Incendie';

export type Civility = 'Madame' | 'Monsieur';

export type Intentions = 'Restituer le véhicule' | 'Conserver le véhicule';

export type Reasons =
    | "Cession de l'activité"
    | 'Changement de forme juridique'
    | 'Fusion/Absorption'
    | 'Transmission universelle de patrimoine'
    | 'Autre';

export type NatureOfClaim =
    | 'Incapacité de travail'
    | 'Perte d’emploi'
    | "Perte Totale et Irréversible d'Autonomie (PTIA)";

export type OriginalRegDocument = 'Oui' | 'Non';

export type RegistrationCertificateDuplicateReason = 'Vol' | 'Perte';

export type RequestDocumentType =
    | 'Copie de mon contrat'
    | 'Attestation de fin de contrat'
    | 'Autorisation de sortie du territoire'
    | 'Echéancier'
    | 'Documents de cession'
    | 'Duplicata de facture de loyer'
    | 'Etats fiscaux - TVS'
    | 'Etats fiscaux - Amortissements non déductibles/ Avantages en nature';

export interface BaseRequest {
    requestCategory: RequestCategory;
    contractsNumbers: string[];
    natureOfClaim?: string;
    files?: Base64File[];
}

export interface AuthorizationDebitRequest extends BaseRequest {
    requestCategory: 'AUTHORIZATION_DEBIT';
    email: string;
    phonenumber?: string;
    nameSurname: string;
    authorizes: string;
    amount: string;
    balanceReason: BalanceReason;
    otherBalanceReason?: string;
    expirationMonth: string;
}

export interface DeadlineRequest extends BaseRequest {
    requestCategory: 'DEADLINE';
    email: string;
    phonenumber?: string;
    deadline: FrMonth;
}

export interface BaseVehicleClaimRequest extends BaseRequest {
    requestCategory: 'VEHICLE_CLAIM';
    productTypeEN: string;
    email: string;
    reparable: string;
    phonenumber?: string;
    natureOfDamage: NatureOfDamage;
    dateOfDamage: string;
    circumstancesOfTheDisaster: string;
    causeOfSinistre: string[];
    nameOfInsurer: string;
    addressOfInsurer: string;
    postcodeOfInsurer: string;
    cityOfInsurer: string;
    insuranceCustomerNumber: string;
    damageNumber: string;
    phonenumberOfInsurer: string;
    emailOfInsurer: string;
    originalRegDocument: OriginalRegDocument;
    files: Base64File[];
}

export type VehicleClaimRequestForCredit = BaseVehicleClaimRequest;

export interface VehicleClaimRequestForBalloon extends BaseVehicleClaimRequest {
    agreeToSend: boolean;
}

export interface DeathRequest extends BaseRequest {
    requestCategory: 'DEATH';
    dateOfDeath: string;
    nameOfNotary: string;
    addressOfNotary: string;
    phonenumberOfNotary: string;
    emailOfNotary: string;
    civilityOfBeneficiary: Civility;
    firstNameOfBeneficiary: string;
    nameOfBeneficiary: string;
    addressOfBeneficiary: string;
    phonenumberOfBeneficiary: string;
    emailOfBeneficiary: string;
    intentions: Intentions;
    files: Base64File[];
}

export interface LossRequest extends BaseRequest {
    requestCategory: 'LOSS';
    email: string;
    phonenumber?: string;
    natureOfClaim: NatureOfClaim;
    startDate: string;
}
export interface ContractTransferRequest extends BaseRequest {
    requestCategory: 'REQUEST_CONTRACT_TRANSFER';
    email: string;
    phonenumber: string;
    buyerNames: string;
    buyerPhonenumber: string;
    buyerEmail: string;
    requestReason: string;
    requestReasonDescription: string;
    employmentStatus: string;
    socioprofessionalCategory: string;
    files: Base64File[];
}
export interface DocumentsRequest extends BaseRequest {
    requestCategory: 'DOCUMENTS';
    email: string;
    phonenumber: string;
    documentType: RequestDocumentType;
    invoiceMonth?: string;
    invoiceYear?: string;
    desiredYear?: string;
    desiredTaxStatus?: string;
    periodStartDate?: string;
    periodEndDate?: string;
    exitDate?: string;
    returnDate?: string;
    countryOfDestination?: string;
    reason?: string;
    comment?: string;
}
export interface AuthorizationLeaveCountryRequest extends BaseRequest {
    requestCategory: 'AUTHORIZATION_LEAVE_COUNTRY';
    email: string;
    phonenumber: string;
    exitDate: string;
    returnDate: string;
    reason: string;
    countryOfDestination: string;
}

export interface RegistrationCertificateRequest extends BaseRequest {
    requestCategory: 'REGISTRATION_CERTIFICATE_DUPLICATE';
    email: string;
    phonenumber: string;
    postalAddress: string;
    requestReason: RegistrationCertificateDuplicateReason;
    files: Base64File[];
}

export interface TenantChangeRequest extends BaseRequest {
    requestCategory: 'TENANT';
    email: string;
    phonenumber: string;
    transferDate: string;
    reason: string;
    transferTo: string;
    files: Base64File[];
    comment: string;
}

export interface ChangeDurationMileage extends BaseRequest {
    requestCategory: 'CHANGE_DURATION_MILEAGE';
    email: string;
    phonenumber: string;
    totalDuration: string;
    desiredMileage: string;
    desiredTiresSummer?: string;
    desiredTiresWinter?: string;
    comment?: string;
}

export interface ConfirmInformationRequest extends BaseRequest {
    requestCategory: 'CONFIRM_INFORMATION';
    name: string;
    surName: string;
    contractNumber: string;
    customerNumber: string;
    birthDate: string;
    houseNumber: string;
    street: string;
    building: string;
    streetAdd: string;
    postalCode: string;
    city: string;
    country: string;
    email: string;
    mobilePhone: string;
    activityChanged: string;
    personalUsage: string;
}

export interface ConfirmInformationBusinessRequest extends BaseRequest {
    requestCategory: 'CONFIRM_INFORMATION_BUSINESS';
    companyName: string;
    contractNumber: string;
    customerNumber: string;
    birthDate: string;
    houseNumber: string;
    street: string;
    building: string;
    streetAdd: string;
    postalCode: string;
    city: string;
    country: string;
    email: string;
    mobilePhone: string;
    activityChanged: string;
}

export interface ConfirmInformationRetailRequest extends BaseRequest {
    requestCategory: 'CONFIRM_INFORMATION_RETAIL';
    name: string;
    surName: string;
    contractNumber: string;
    customerNumber: string;
    birthDate: string;
    houseNumber: string;
    street: string;
    building: string;
    streetAdd: string;
    postalCode: string;
    city: string;
    country: string;
    email: string;
    mobilePhone: string;
    employerChanged: string;
    employer: string;
    income: string;
}

export interface OtherRequest extends BaseRequest {
    requestCategory: 'OTHER';
    email: string;
    phonenumber: string;
    subjectOfClaim: string;
    message: string;
    files?: Base64File[];
}

export enum RequestStatus {
    DONE = 'DONE',
    IN_PROGRESS = 'IN_PROGRESS',
    NONE = 'NONE',
}

export type RequestCustomerStatus = {
    requestCustomerStatus: RequestStatus;
};

export enum YesNoSelections {
    YES = 'Oui',
    NO = 'Non',
}

export const blueCategories = [
    'Cadres et professions intellectuelles supérieures',
    'Professions intermédiaires',
    'Employés',
    'Ouvriers',
];

export type ContractSelection = {
    isActive: boolean;
    contractId: string;
    contractNumber: string;
    productType?: string;
    productTypeEN?: ProductTypeEN;
    contractStartDate: string;
    contractEndDate: string;
};
