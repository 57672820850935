import { BundledProduct, Contract } from '@cp-fr/common';
import { AccordionItem, Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { emptyObjectOrAllValuesAreUndefined } from '../../../../utils';
import { BundledProductComponent } from './bundled-product/BundledProduct';

type BundledProductsProps = {
    contract?: Contract;
    isSMECustomer?: boolean;
    bundledProductsDetails?: BundledProduct[];
    contractMileage?: number;
};

export const BundledProducts: React.FC<BundledProductsProps> = ({
    contract,
    isSMECustomer,
    bundledProductsDetails,
    contractMileage,
}) => {
    const { t } = useTranslation('bundled-products');

    if (!contract || !bundledProductsDetails) {
        return null;
    }

    const { isActive } = contract;

    const bundledProductsList = bundledProductsDetails.map((bundledProductDetails, index) => (
        <div className={index !== bundledProductsDetails.length - 1 ? 'u-mb' : ''} key={index}>
            {!!index && <Hr className={'u-mv'} />}
            <BundledProductComponent
                isActive={isActive}
                isSMECustomer={isSMECustomer}
                details={bundledProductDetails}
                contractMileage={contractMileage}
            />
        </div>
    ));

    const isError =
        !bundledProductsDetails.length ||
        bundledProductsDetails.every((bundledProduct) => emptyObjectOrAllValuesAreUndefined(bundledProduct));

    return (
        <AccordionItem title={t('title')} contentClassName={'u-pr-none'}>
            {isError ? (
                <Notification status={NotificationStatus.info} testId={'not-found-error'} text={t('info')} />
            ) : (
                <div>{bundledProductsList}</div>
            )}
        </AccordionItem>
    );
};
