import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { searchCustomerIndexEndpoint, SearchCustomerIndexResponse } from '@cp-shared-8/apis';

const { reducer, fetchData } = getSimpleDataFetchSlice<SearchCustomerIndexResponse, DefaultBusinessMarketApiErrorCode>({
    dataName: 'searchCustomerIndex',
    fetchCallback() {
        return CpDataApi.get(searchCustomerIndexEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchSearchCustomerIndex = fetchData;
