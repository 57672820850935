import { AdressSectionFileUploadValidatorErrors } from '../../types';

export const AdressSectionFileUploadError: AdressSectionFileUploadValidatorErrors = {
    proofOfAddress: {
        required: 'Proof of adress is required',
    },
    id: {
        required: 'Id is required',
    },
    accomodationCertificate: {
        required: 'Accomodation certificate is required',
    },
    hostId: {
        required: 'Host id is required',
    },
    kbis: {
        required: 'Kbis is required',
    },
    insee: {
        required: 'Insee is required',
    },
    atLeastOne: 'At least one document is required',
};
