import * as Yup from 'yup';
import { RegistrationLegalPersonValidatorErrors } from '../types';
import { RegistrationLegalPersonError } from '../messages';
import { RegistrationDataLegal } from '../../apis';

export const registrationLegalPersonValidator = (
    errors: RegistrationLegalPersonValidatorErrors = RegistrationLegalPersonError,
): Yup.SchemaOf<RegistrationDataLegal> =>
    Yup.object().shape({
        contractNumber: Yup.string()
            .trim()
            .required(errors.contractNumber.empty)
            .min(11, errors.contractNumber.format)
            .max(12, errors.contractNumber.format)
            .test('valid format-11', errors.contractNumber.format, (contractNumber): boolean => {
                return contractNumber?.length === 11 ? !!contractNumber?.match(RegExp('^\\d{8}[a-zA-Z]{3}$')) : true;
            })
            .test('valid format-12', errors.contractNumber.format, (contractNumber): boolean => {
                return contractNumber?.length === 12
                    ? !!contractNumber?.match(RegExp('^\\d{8}[a-zA-Z]{3}\\d{1}$'))
                    : true;
            }),
        customerNumber: Yup.string()
            .trim()
            .required(errors.customerNumber.empty)
            .min(9, errors.customerNumber.format)
            .max(10, errors.customerNumber.format),
        companyName: Yup.string().trim().required(errors.companyName.empty).max(200, errors.companyName.format),
        siren: Yup.string()
            .trim()
            .required(errors.siren.empty)
            .matches(/^[0-9]*$/, errors.siren.format)
            .min(9, errors.siren.format)
            .max(9, errors.siren.format),
    });
