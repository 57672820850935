import { RequestContractTransferValidatorErrors } from '../../types';

export const RequestContractTransferError: RequestContractTransferValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'email value must be an email',
    },
    phonenumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    buyerNames: {
        required: 'buyerNames is required',
    },
    buyerPhonenumber: {
        required: 'buyerPhoneNumber is required',
        mustBePhoneNumber: 'buyerPhoneNumber must have 10 characters',
    },
    buyerEmail: {
        required: 'buyerEmail is required',
        mustBeEmail: 'buyerEmailvalue must be an email',
    },
    requestReason: {
        required: 'requestReason is required',
    },
    requestReasonDescription: {
        required: 'requestReasonDescription is required',
    },
    employmentStatus: {
        required: 'employmentStatus is required',
    },
    files: {
        signedConfirmation: {
            required: 'signedConfirmation file is required',
            maxLength: 'signedConfirmation must contain 1 file',
        },
        buyerIdentity: {
            required: 'buyerIdentity file is required',
            maxLength: 'buyerIdentity must contain 1 file',
        },
        bankDetails: {
            required: 'bankDetails file is required',
            maxLength: 'bankDetails must contain 1 file',
        },
        payslips: {
            required: 'payslips file is required',
            maxLength: 'payslips must contain 1 file',
        },
        taxNotice: {
            required: 'taxNotice file is required',
            maxLength: 'taxNotice must contain 1 file',
        },
        registrationDoc: {
            required: 'registrationDoc file is required',
            maxLength: 'registrationDoc must contain 1 file',
        },
        proofOfAddress: {
            required: 'proofOfAddress file is required',
            maxLength: 'proofOfAddress must contain 1 file',
        },
        buyerProofOfAddress: {
            required: 'buyerProofOfAddress file is required',
            maxLength: 'buyerProofOfAddress must contain 1 file',
        },
        accomodationCertificate: {
            required: 'accomodationCertificate file is required',
            maxLength: 'accomodationCertificate must contain 1 file',
        },
        indentityDoc: {
            required: 'indentityDoc file is required',
            maxLength: 'indentityDoc must contain 1 file',
        },
    },
};
