export type InitialValues = {
    iban: string;
    currentIban: string;
    ibanHolder: string;
    email: string;
    allContracts: boolean;
    attempt: number;
};

export const getInitialValues = (iban?: string, email?: string, attempt = 1): InitialValues => ({
    iban: '',
    currentIban: iban || '',
    ibanHolder: '',
    email: email || '',
    allContracts: true,
    attempt,
});
