import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedSelect } from '@cp-shared-8/frontend-ui';

type CountrySelectionProps = {
    name: string;
    label: string;
};

export const CountrySelection: React.FC<CountrySelectionProps> = ({ name, label }) => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.country-selection';
    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const countries: string[] = [
        'Allemagne',
        'Autriche',
        'Belgique',
        'Bulgarie',
        'Chypre',
        'Croatie',
        'Danemark',
        'Espagne',
        'Estonie',
        'Finlande',
        'Grèce',
        'Guadeloupe',
        'Guyane Française',
        'Hongrie',
        'Irlande',
        'Islande',
        'Italie',
        'Lettonie',
        'Liechtenstein',
        'Lituanie',
        'Luxembourg',
        'Malte',
        'Martinique',
        'Mayotte',
        'Monaco',
        'Norvège',
        'Pays-Bas',
        'Pologne',
        'Portugal',
        'République Tchèque',
        'Réunion (La)',
        'Roumanie',
        'Royaume-Uni',
        'Saint Pierre et Miquelon',
        'Saint-Siège (Etat de la Cité du Vatican)',
        'Serbie',
        'Slovaquie',
        'Slovénie',
        'Suède',
        'Suisse',
    ];

    return (
        <>
            <ValidatedSelect
                isMandatory={true}
                name={name}
                selectItems={countries.map((country) => ({
                    value: country,
                    label: country,
                }))}
                placeholder={translate('placeholder')}
                label={label}
                emptyByDefault
            />
        </>
    );
};
