import React, { ReactChild, useState } from 'react';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { Button, ButtonContainer, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { InitialValues as FileUploadInitialValues, initialValues } from './initialValues';
import { getValidationSchema } from './validationSchema';
import { InitialValues as IbanInitialValues } from '../iban-view/InitialValues';
import { maxFileSize } from '../../../../../../../config';
import { FileUpload as SharedFileUpload, Spinner } from '@cp-shared-8/frontend-ui';
import { mapFilesToBase64 } from '../../../../../../../utils/file-utils';
import {
    Base64File,
    formatAsFileSize,
    getChangeIbanEndpoint,
    getCheckChangeIbanEndpoint,
    IbanChange,
    IbanChangeProcessingResult,
    IbanChangeResult,
    IbanError,
    stripWhitespaces,
} from '@cp-fr/common';
import { CpDataApi } from '../../../../../../../cp-xhr';
import { EditStatus, EditViewNames } from '../../enums';
import { IbanChangeNotification } from '../../iban-change-notification/IbanChangeNotification';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { Error } from '@cp-shared-8/common-utilities';
import axiosRetry from 'axios-retry';

type FilesUploadViewProps = {
    setCurrentView: (newEditView: EditViewNames) => void;
    onSuccess: (lastEditStatus: EditStatus, effectDate?: string) => void;
    onFail: (lastEditStatus: EditStatus) => void;
    ibanViewValues: IbanInitialValues;
    contractId: string;
};

const NUMBER_OF_RETRIES = 15;
const RETRY_DELAY = 4000;

export const FileUploadView: React.FC<FilesUploadViewProps> = ({
    setCurrentView,
    onSuccess,
    onFail,
    contractId,
    ibanViewValues,
}) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [formValues, setFormValues] = useState<FileUploadInitialValues>(initialValues);
    const CpDataApiWithRetry: AxiosInstance = axios.create(CpDataApi.defaults);

    axiosRetry(CpDataApiWithRetry, {
        retries: NUMBER_OF_RETRIES,
        retryDelay: () => RETRY_DELAY,
        retryCondition: (error) => {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            return (error?.response?.data as any)?.code === 'REQUEST_PROCESSING_IN_PROGRESS';
        },
    });

    const prefix = 'iban-section.edit';
    const fileUploadPrefix = `${prefix}.file-upload`;
    const validationPrefix = `${prefix}.validation.file-upload`;

    const sizeError = t(`${validationPrefix}.wrong-size`);
    const typeError = t(`${validationPrefix}.wrong-type`);
    const sizeAndTypeError = (
        <>
            {sizeError}
            <br />
            {typeError}
        </>
    );

    const setFailState = (
        errorStatus: EditStatus.CHECK_FAILURE | EditStatus.BFF_API_ERROR | EditStatus.USER_WITH_NO_EMAIL_CODE,
    ): void => {
        setLastEditStatus(errorStatus);
        onFail(errorStatus);
    };

    const checkAsyncChangeIbanProcessing: (ibanProcessing: IbanChangeProcessingResult) => Promise<void> = async ({
        requestId,
    }: IbanChangeProcessingResult) => {
        const { data } = await CpDataApiWithRetry.get<IbanChangeResult>(getCheckChangeIbanEndpoint(requestId));
        if (data.message === 'REQUEST_SAVED' || data.message === 'SUCCESSFUL_REQUEST') {
            onSuccess(EditStatus.SUCCESS, data.effectDate);
        } else {
            setFailState(EditStatus.BFF_API_ERROR);
        }
    };

    const handleSubmit = async (values: FileUploadInitialValues): Promise<void> => {
        setFormValues(values);
        setIsSubmitting(true);
        const files: Base64File[] = await mapFilesToBase64(values.file);
        const { iban, currentIban, ibanHolder, email, allContracts, attempt } = ibanViewValues;

        return Promise.all(files).then((files) => {
            const data: IbanChange = {
                iban: stripWhitespaces(iban),
                currentIban,
                ibanHolder,
                email,
                allContracts,
                file: files[0],
                attempt,
            };
            setLastEditStatus(EditStatus.NOT_PERFORMED);
            return CpDataApi.put(getChangeIbanEndpoint(contractId), data)
                .then(async ({ data }: { data: IbanChangeProcessingResult }): Promise<void> => {
                    await checkAsyncChangeIbanProcessing(data);
                })
                .catch(({ response }: AxiosError<Error<IbanError>>): void => {
                    const code: IbanError | undefined = response?.data?.code;

                    const errorStatus =
                        code === 'CHECK_FAILURE'
                            ? EditStatus.CHECK_FAILURE
                            : code === EditStatus.USER_WITH_NO_EMAIL_CODE
                            ? EditStatus.USER_WITH_NO_EMAIL_CODE
                            : EditStatus.BFF_API_ERROR;

                    setFailState(errorStatus);
                })
                .finally(() => setIsSubmitting(false));
        });
    };

    return isSubmitting ? (
        <Spinner center />
    ) : (
        <Formik
            initialValues={formValues}
            validationSchema={getValidationSchema(t)}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ handleSubmit }): ReactChild => (
                <Form onSubmit={(e): void => e.preventDefault()} data-testid="iban-form">
                    <Fieldset>
                        <Fieldset.Row>
                            <Layout>
                                <Layout.Item>
                                    <p>{t(`${fileUploadPrefix}.description`)}</p>
                                    <SharedFileUpload
                                        name={'file'}
                                        descriptionText={t(`${fileUploadPrefix}.headline`)}
                                        descriptionSupplementaryText={t(`${fileUploadPrefix}.text`)}
                                        buttonText={t(`${fileUploadPrefix}.button`)}
                                        cancelLabel={t('translation:editable-section-nav.cancel')}
                                        fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                        maxFileSize={maxFileSize}
                                        validFileFormats={['pdf']}
                                        sizeError={sizeError}
                                        typeError={typeError}
                                        sizeAndTypeError={sizeAndTypeError}
                                    />
                                </Layout.Item>
                            </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <IbanChangeNotification lastEditStatus={lastEditStatus} />
                            <ButtonContainer className={'u-mt'} center>
                                <Button
                                    secondary
                                    onClick={(): void => setCurrentView(EditViewNames.IBAN_VIEW)}
                                    testId="back-to-iban-view"
                                >
                                    {t('translation:editable-section-nav.back')}
                                </Button>

                                {/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */}
                                <Button onClick={handleSubmit as any} type="submit" testId="submit">
                                    {t('translation:editable-section-nav.submit')}
                                </Button>
                            </ButtonContainer>
                        </Fieldset.Row>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
