import { formatAsFileSize } from '@cp-fr/common';
import { FileUpload as SharedFileUpload } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { maxFileSize } from '../../../../../../../config';

export type FileUploadProps = { name: string; label?: string; isMandatory?: boolean };

export const FileUpload: React.FC<FileUploadProps> = ({ name, label, isMandatory = true }) => {
    const { t } = useTranslation('request');
    const sizeError = t('file-upload.size-error');
    const typeError = t('file-upload.type-error');
    const sizeAndTypeError = (
        <>
            {sizeError}
            <br />
            {typeError}
        </>
    );
    return (
        <>
            {label && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: isMandatory ? `${label} *` : label,
                    }}
                    className={'u-mb-xsmall'}
                />
            )}
            <SharedFileUpload
                name={name}
                descriptionText={t('file-upload.headline')}
                descriptionSupplementaryText={t('file-upload.text')}
                buttonText={t('file-upload.button')}
                cancelLabel={t('translation:editable-section-nav.cancel')}
                fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                maxFileSize={maxFileSize}
                validFileFormats={['pdf']}
                sizeError={sizeError}
                typeError={typeError}
                sizeAndTypeError={sizeAndTypeError}
            />
        </>
    );
};
