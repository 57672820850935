import {
    getIbanStatusEndpoint,
    isCredit,
    isLine2Product,
    isLLD,
    isLLLProduct,
    isBalloonLeasing,
    isLoun,
    isStandalone,
} from '@cp-fr/common';
import { Contract } from '@cp-fr/common/src/apis/services/types/contracts';
import { NotificationCenter } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

type NotificationCenterProps = {
    contractNotificatioForLessOneMonth?: Contract;
    myProfileNotification?: boolean;
    renuewalContractNotificationForLessTwelveMonths?: Contract;
    renuewalContractNotificationForLessFourMonths?: Contract;
    renuewalContractNotificationForLessThreeMonths?: Contract;
    renuewalContractNotificationForLessTwoMonths?: Contract;
    renuewalContractNotificationForLessOneMonth?: Contract;
    renuewalContractNotificationForLoiLagarde?: Contract;
};

type NotificationItemProps = {
    id: string;
    title: string;
    content?: string;
    link?: string;
    externalLink?: string;
    read: boolean;
};

export const NotificationsCenterUi: React.FC<NotificationCenterProps> = ({
    myProfileNotification,
    contractNotificatioForLessOneMonth,

    renuewalContractNotificationForLessTwelveMonths,
    renuewalContractNotificationForLessFourMonths,
    renuewalContractNotificationForLessThreeMonths,
    renuewalContractNotificationForLessTwoMonths,
    renuewalContractNotificationForLessOneMonth,
    renuewalContractNotificationForLoiLagarde,
}) => {
    const { t } = useTranslation('notification-center');
    const history = useHistory();
    const [ibanStatus, setIbanStatus] = useState<boolean>(false);

    useEffect(() => {
        CpDataApi.get(getIbanStatusEndpoint()).then((response) => {
            if (response.data.requestCustomerStatus === 'IN_PROGRESS') {
                setIbanStatus(true);
            }
        });
    }, []);

    const staticNotifications: NotificationItemProps[] = [
        ...(myProfileNotification
            ? [
                  {
                      id: '1',
                      title: t('incorrectAddress.title'),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      content: (<span dangerouslySetInnerHTML={{ __html: t(`incorrectAddress.content`) }} />) as any,
                      link: t('incorrectAddress.link'),
                      read: false,
                  },
              ]
            : []),

        ...(ibanStatus
            ? [
                  {
                      id: '3',
                      title: t('ibanStatus.title'),
                      content: t('ibanStatus.content'),
                      link: t('ibanStatus.link'),
                      read: false,
                  },
              ]
            : []),
        ...(isBalloonLeasing(renuewalContractNotificationForLessTwelveMonths?.productType)
            ? [
                  {
                      id: '8',
                      title: t('LOA_12Months.title'),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      content: (<span dangerouslySetInnerHTML={{ __html: t(`LOA_12Months.content`) }} />) as any,
                      read: false,
                  },
              ]
            : []),

        ...(isCredit(renuewalContractNotificationForLessTwelveMonths?.productType)
            ? [
                  {
                      id: '9',
                      title: t('Credit_12Months.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t(`Credit_12Months.content`) }} />,
                      read: false,
                  },
              ]
            : []),

        ...(isLine2Product(renuewalContractNotificationForLessTwelveMonths?.productType)
            ? [
                  {
                      id: '10',
                      title: t('LLD_12Months.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t(`LLD_12Months.content`) }} />,
                      read: false,
                  },
              ]
            : []),

        ...(isBalloonLeasing(renuewalContractNotificationForLessFourMonths?.productType)
            ? [
                  {
                      id: '11',
                      title: t('4Months.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t(`4Months.content`) }} />,
                      read: false,
                  },
              ]
            : []),

        ...(isStandalone(renuewalContractNotificationForLessThreeMonths?.productType)
            ? [
                  {
                      id: '12',
                      title: t('3Months.title'),
                      read: false,
                  },
              ]
            : []),

        ...(isLLLProduct(renuewalContractNotificationForLessTwoMonths?.productType)
            ? [
                  {
                      id: '13',
                      title: t('2Months.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t(`2Months.content`) }} />,
                      read: false,
                  },
              ]
            : []),
        ...(isLLLProduct(renuewalContractNotificationForLessOneMonth?.productType)
            ? [
                  {
                      id: '14',
                      title: t('1Month.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t(`1Month.content`) }} />,
                      read: false,
                  },
              ]
            : []),
        ...(isCredit(renuewalContractNotificationForLoiLagarde?.productType)
            ? [
                  {
                      id: '15',
                      title: t('LoiLagarde.title'),
                      content: <span dangerouslySetInnerHTML={{ __html: t('LoiLagarde.content') }} />,
                      read: false,
                  },
              ]
            : []),
        ...(isLLD(contractNotificatioForLessOneMonth?.productType) && contractNotificatioForLessOneMonth
            ? [
                  {
                      id: '16',
                      title: t('contractEndsForLessOneMonth.title'),
                      content: (
                          <span dangerouslySetInnerHTML={{ __html: t('contractEndsForLessOneMonth.contentLLD') }} />
                      ),
                      read: false,
                  },
              ]
            : []),
        ...(isLoun(contractNotificatioForLessOneMonth?.productType) && contractNotificatioForLessOneMonth
            ? [
                  {
                      id: '17',
                      title: t('contractEndsForLessOneMonth.title'),
                      content: (
                          <span dangerouslySetInnerHTML={{ __html: t('contractEndsForLessOneMonth.contentLoun') }} />
                      ),
                      read: false,
                  },
              ]
            : []),
    ];

    return (
        <NotificationCenter notifications={staticNotifications}>
            {(notification: NotificationItemProps): JSX.Element => (
                <NotificationCenter.Item
                    statusReadLabel=""
                    markAsReadLabel={t('read')}
                    markAsUnreadLabel={t('unread')}
                    onClick={(): void => {
                        if (notification.link) {
                            history.push(notification.link);
                        }
                        if (notification.externalLink) {
                            window.open(notification.externalLink, '_blank');
                        }
                    }}
                >
                    {notification.content && <p>{notification.content}</p>}
                </NotificationCenter.Item>
            )}
        </NotificationCenter>
    );
};
