import { CPDate } from '@cp-shared-8/common-utilities';

export type RegistrationDataPhysical = {
    contractNumber: string;
    firstName: string;
    surname: string;
    siren?: string;
    dateOfBirth: CPDate;
};

export type RegistrationDataPhysicalValidationSchema = {
    contractNumber: string;
    firstName: string;
    surname: string;
    siren?: string;
};

export type RegistrationDataLegal = {
    contractNumber: string;
    customerNumber: string;
    companyName: string;
    siren: string;
};

export type RegistrationData = RegistrationDataPhysical | RegistrationDataLegal;

export type RegistrationResult = {
    customerNumber: string;
};

export enum RegistrationIdentifierType {
    CONTRACT_NUMBER = 'CONTRACT_NUMBER',
    CUSTOMER_NUMBER = 'CUSTOMER_NUMBER',
}

export enum RegistrationError {
    CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
    CONTRACT_NOT_YET_IN_SCOPE = 'CONTRACT_NOT_YET_IN_SCOPE',
    CONTRACT_TERMINATED = 'CONTRACT_TERMINATED',
    BAD_KYC_TYPE = 'BAD_KYC_TYPE',
    CUSTOMER_FLEET_NOT_IN_SCOPE = 'CUSTOMER_FLEET_NOT_IN_SCOPE',
    FLEET_CONTRACT_NOT_YET_IN_SCOPE = 'FLEET_CONTRACT_NOT_YET_IN_SCOPE',
    SIREN_IS_MANDATORY_FOR_SME = 'SIREN_IS_MANDATORY_FOR_SME',
    CUSTOMER_ALREADY_REGISTRED = 'CUSTOMER_ALREADY_REGISTRED',
    UNABLE_TO_PROCESS_DIGITAL_COMMUNICATION_REFERENCE = 'UNABLE_TO_PROCESS_DIGITAL_COMMUNICATION_REFERENCE',
}
