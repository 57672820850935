import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const getValidationSchema = (t: TFunction) => {
    const prefix = 'iban-section.edit.validation.file-upload';
    return Yup.object().shape({
        file: Yup.array()
            .min(1, t(`${prefix}.required`))
            .max(1, t(`${prefix}.wrong-number-of-files`)),
    });
};
