import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Paragraph, Checkbox, Button, Layout } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { currentBrand } from 'config';

export const ParkingAccountLinking: React.FC<{ handlePbpLogin: () => void; error?: string }> = ({
    handlePbpLogin,
    error,
}) => {
    const { t } = useTranslation('parking');
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('isUnlinkingSuccessful')) {
            localStorage.setItem('isUnlinkingSuccessful', '');
        }
    }, []);

    const getErrorTexts = (error: string): { headline: string; text: string } => {
        switch (error) {
            case '403':
                return {
                    headline: '',
                    text: t('error-linking-notification.consent-denied.text'),
                };
            case '409':
                return {
                    headline: '',
                    text: t('error-linking-notification.already-linked.text'),
                };
            default:
                return {
                    headline: t('error-linking-notification.default.title'),
                    text: t('error-linking-notification.default.text'),
                };
        }
    };

    return (
        <>
            {error && (
                <Notification
                    status={NotificationStatus.error}
                    testId={'error-linking-notification'}
                    className="u-mb"
                    headline={getErrorTexts(error).headline}
                    text={getErrorTexts(error).text}
                />
            )}
            {localStorage.getItem('isUnlinkingSuccessful') && (
                <Notification
                    status={NotificationStatus.success}
                    testId={'success-unlinking-notification'}
                    className="u-mb"
                    headline={t('success-unlinking-notification.title')}
                    text={t('success-unlinking-notification.text')}
                />
            )}
            <Heading level={1}>{t('account-linking.headline')}</Heading>
            <Layout>
                <Layout.Item>
                    <Paragraph>{t('account-linking.description')}</Paragraph>
                </Layout.Item>
                <Layout.Item className={'u-mb-small'}>
                    <img src={`contents/${currentBrand}/logo_paybyphone.svg`} alt={'logo paybyphone'} />
                </Layout.Item>
                <Layout.Item>
                    <Checkbox
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAccepted(event.target.checked)}
                        testId={'accept-account-linking-checkbox'}
                    >
                        {t('account-linking.checkbox')}
                    </Checkbox>
                </Layout.Item>
                <Layout.Item>
                    <Button type={'button'} disabled={!accepted} onClick={() => handlePbpLogin()}>
                        {t('account-linking.button')}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
