import * as Yup from 'yup';
import { EarlyReturnPenaltyValidatorErrors } from '../types';
import { EarlyReturnPenaltyError } from '../messages';
import { CPDate, formatCpDate } from '@cp-shared-8/common-utilities';

export type EarlyReturnFormikInitialValues = {
    requestDate: string;
    estimatedMileage: number;
};

export const earlyReturnPenaltyValidationSchema = (
    errors: EarlyReturnPenaltyValidatorErrors = EarlyReturnPenaltyError,
    contractStartDate?: CPDate,
    contractEndDate?: CPDate,
): Yup.SchemaOf<EarlyReturnFormikInitialValues> =>
    Yup.object().shape({
        requestDate: Yup.string()
            .required(errors.requestDate.required)
            .test('min', errors.requestDate.min, (value): boolean =>
                formatCpDate()
                    .toMoment()
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    .add(1, 'day')
                    .isBefore(formatCpDate(value).toMoment()),
            )
            .test(
                'delivery-date-plus-min-nine-months',
                errors.requestDate.deliveryDatePlusNineMonths,
                (value): boolean =>
                    formatCpDate(contractStartDate).toMoment().add(9, 'month').isBefore(formatCpDate(value).toMoment()),
            )
            .test('after-contract-ends', errors.requestDate.afterContractEnds, (value): boolean =>
                formatCpDate(value).toMoment().isBefore(contractEndDate),
            ),
        estimatedMileage: Yup.number()
            .required(errors.estimatedMileage.required)
            .typeError(errors.estimatedMileage.required)
            .positive(errors.estimatedMileage.positive),
    });

export const frontEndEarlyReturnPenaltyValidationSchema = (
    errors: EarlyReturnPenaltyValidatorErrors = EarlyReturnPenaltyError,
): Yup.SchemaOf<EarlyReturnFormikInitialValues> =>
    earlyReturnPenaltyValidationSchema(errors)
        .omit(['requestDate'])
        .shape({
            requestDate: Yup.string().required(errors.requestDate.required),
        });
