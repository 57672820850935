import { MyProfileData, RegistrationCertificateDuplicateReason } from '@cp-fr/common';
import { Layout } from '@vwfs-bronson/bronson-react';
import { AddressSection } from 'components/my-profile/address-section/AddressSection';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInput, PhonenumberInput } from '../../simple-form-components';
import { RequestReasonSelection } from '../../simple-form-components/request-reason-selection/RequestReasonSelection';
import { DocumentsUpload } from './DocumentsUpload';

export const RegistrationCertificateDuplicateForm: React.FC<{ myProfileData?: MyProfileData }> = ({
    myProfileData,
}) => {
    const { t } = useTranslation('request');
    const [field] = useField('registrationCertificateDuplicate.requestReason');

    const selectedReason: RegistrationCertificateDuplicateReason = field.value;
    const translationPrefix = 'category-selection.registration-certificate-duplicate';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            <Layout.Item>
                <p>{translate('description')}</p>
            </Layout.Item>
            <Layout.Item>
                <EmailInput
                    label={translate('email')}
                    name={'registrationCertificateDuplicate.email'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber')}
                    name={'registrationCertificateDuplicate.phonenumber'}
                />
            </Layout.Item>
            <Layout.Item>
                <AddressSection
                    address={myProfileData?.address}
                    customerType={{
                        isPrivateCustomer: myProfileData?.identification?.customerType === 'Retail',
                        isPMCustomer: myProfileData?.identification?.customerType === 'PM',
                    }}
                    isAddressChangePending={false}
                    isContactInformationChangePending={false}
                />
            </Layout.Item>
            <Layout.Item>
                <RequestReasonSelection
                    label={translate('request-reason')}
                    name={'registrationCertificateDuplicate.requestReason'}
                    isMandatory={true}
                />
            </Layout.Item>
            <Layout.Item>
                <DocumentsUpload requestReason={selectedReason} />
            </Layout.Item>
        </>
    );
};
