import React from 'react';
import { Brand } from '@cp-fr/common';
import { ContentSection, Layout, PageWrap, Tabs } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { RegistrationPhysical } from './physical-person';
import { RegistrationLegal } from './legal-person';
import { tabs } from './availableTabs';
import { currentBrand } from 'config';

type RegistrationUiProps = {
    privacyPolicy?: string;
    usageAgreement?: string;
};

export const RegistrationUi: React.FC<RegistrationUiProps> = ({ privacyPolicy, usageAgreement }) => {
    const { t } = useTranslation('registration');

    const isFS = currentBrand === Brand?.FSVG;

    if (!privacyPolicy || !usageAgreement) {
        return null;
    }

    return (
        <>
            <ContentSection>
                <PageWrap size={'xsmall'}>
                    <Layout className={'u-mt-large'}>
                        <Layout.Item className={'u-text-center'}>
                            <img
                                src={`/${currentBrand}/img/logo.svg`}
                                className={'u-1/4 u-1/3@s u-1/2@xs'}
                                alt="logo"
                            />
                        </Layout.Item>
                        <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                            <h1>{t('registration-form.head.title')}</h1>
                            <p>{t('registration-form.head.info-identification')}</p>
                            <p>{t('registration-form.head.info-legal-identification')}</p>
                            {isFS && (
                                <Notification
                                    className="u-text-left"
                                    status={NotificationStatus.info}
                                    testId="fs-infobox"
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t('registration-form.person.fs-infobox.text'),
                                        }}
                                    />
                                </Notification>
                            )}
                        </Layout.Item>
                        <Layout.Item default={'1/1'}>
                            <Tabs defaultSelected={tabs.physical} testId={'registrationTabs'}>
                                <Tabs.Header>
                                    <Tabs.HeaderItem tabId={tabs.physical} className={`cp-tab-button-${tabs.physical}`}>
                                        {t('registration-form.head.tabs.physical.label')}
                                    </Tabs.HeaderItem>

                                    <Tabs.HeaderItem tabId={tabs.legal} className={`cp-tab-button-${tabs.legal}`}>
                                        {t('registration-form.head.tabs.legal.label')}
                                    </Tabs.HeaderItem>
                                </Tabs.Header>
                                <Tabs.Content>
                                    <Tabs.ContentItem tabId={tabs.physical} testId={`tab-content-${tabs.physical}`}>
                                        <RegistrationPhysical
                                            isFS={isFS}
                                            privacyPolicy={privacyPolicy}
                                            usageAgreement={usageAgreement}
                                        />
                                    </Tabs.ContentItem>

                                    <Tabs.ContentItem tabId={tabs.legal} testId={`tab-content-${tabs.legal}`}>
                                        <RegistrationLegal
                                            privacyPolicy={privacyPolicy}
                                            usageAgreement={usageAgreement}
                                        />
                                    </Tabs.ContentItem>
                                </Tabs.Content>
                            </Tabs>
                        </Layout.Item>
                    </Layout>
                </PageWrap>
            </ContentSection>
        </>
    );
};
