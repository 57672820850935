import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getParkingSessionsEndpoint } from '@cp-fr/common';
import { ParkingSessionsDataWithAllValues, ParkingSessionsDataWithNoValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ParkingSessionsDataWithAllValues,
    },
    'With no values': {
        status: 200,
        responseBody: ParkingSessionsDataWithNoValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ParkingSessionsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Parking Sessions Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getParkingSessionsEndpoint(),
};

export const ParkingSessionsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ParkingSessionsMock]);

    return <div>{storyFn()}</div>;
};
