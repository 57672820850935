import * as Yup from 'yup';
import { IdentificationSectionEditViewSmeValidatorErrors } from '../../types/myProfileTypes';
import { IdentificationSectionSmeEditViewError } from '../../messages/myProfileMessages';

export type idenfiticationSectionEditViewSmeFormTypes = {
    companyName: string;
};

export type idenfiticationSectionEditViewSmeBackendTypes = idenfiticationSectionEditViewSmeFormTypes & {
    documents: {
        fileId: string | undefined;
        type: string | undefined;
    }[];
};

export type idenfiticationSectionEditViewSmeFrontendTypes = idenfiticationSectionEditViewSmeFormTypes & {
    kbis: File[] | undefined;
    insee: File[] | undefined;
};

const isAtLeastOneForSME = (
    files: idenfiticationSectionEditViewSmeFrontendTypes,
    testContext: Yup.TestContext,
    errorMessage: string,
): Yup.ValidationError | boolean => {
    return files.kbis?.length === 0 && files.insee?.length === 0
        ? testContext.createError({
              message: errorMessage,
              path: 'kbis',
          })
        : true;
};

export const editViewIdentificationSectionSmeCoreSchema = (
    errors: IdentificationSectionEditViewSmeValidatorErrors = IdentificationSectionSmeEditViewError,
): Yup.SchemaOf<idenfiticationSectionEditViewSmeFormTypes> => {
    return Yup.object().shape({
        companyName: Yup.string()
            .trim()
            .required(errors.companyName.required)
            .matches(/^([^0-9]*)$/, errors.companyName.matches),
    });
};

export const editViewIdentificationSectionSmeBackendSchema =
    (): Yup.SchemaOf<idenfiticationSectionEditViewSmeBackendTypes> => {
        return editViewIdentificationSectionSmeCoreSchema().shape({
            documents: Yup.array().of(
                Yup.object().shape({
                    fileId: Yup.string(),
                    type: Yup.string(),
                }),
            ),
        });
    };

export const editViewIdentificationSectionSmeFrontendSchema = (
    errors: IdentificationSectionEditViewSmeValidatorErrors = IdentificationSectionSmeEditViewError,
): Yup.SchemaOf<idenfiticationSectionEditViewSmeFrontendTypes> => {
    return editViewIdentificationSectionSmeCoreSchema(errors).shape({
        kbis: Yup.array()
            .test('is-optional', '', function () {
                return isAtLeastOneForSME(this.parent, this, errors.kbis.required);
            })
            .max(1, errors.kbis.max),
        insee: Yup.array()
            .test('is-optional', '', function () {
                return isAtLeastOneForSME(this.parent, this, errors.insee.required);
            })
            .max(1, errors.insee.max),
    });
};
