import { YesNoSelections } from '@cp-fr/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { FormikProps, useField, useFormikContext } from 'formik';
import { RequestInitialValues } from '../../../../initialValues';

type ReparableSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const ReparableSelection: React.FC<ReparableSelectionProps> = ({ label, name, isMandatory }) => {
    const [, , reparableHelpers] = useField('vehicleClaim.reparable');
    const [categorySelection, , categorySelectionHelpers] = useField('categorySelection');
    const { initialValues }: FormikProps<RequestInitialValues> = useFormikContext();

    const selectItems: ValidatedSelectItem[] = [
        {
            label: YesNoSelections.YES,
            value: YesNoSelections.YES,
        },
        {
            label: YesNoSelections.NO,
            value: YesNoSelections.NO,
        },
    ];

    const setValueChange = (selectedReparable: string) => {
        categorySelectionHelpers.setValue(categorySelection.value || initialValues.categorySelection);
        reparableHelpers.setValue(selectedReparable || initialValues.vehicleClaim.reparable);
    };

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
            onChange={setValueChange}
        />
    );
};
