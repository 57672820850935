import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-8/frontend-ui';
import { NotificationsCenter } from 'components/notification-center/NotificationsCenter';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { currentBrand, idpHint, payByPhoneEnabled } from '../../config';
import {
    contactDetailsCheckPagePath,
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    forbiddenPagePath,
    landingPagePath,
    legalNoticePath,
    myDocumentsPath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    parkingPagePath,
    privacyPolicyPath,
    registrationPagePath,
} from './paths';

const pagesWithoutNavbar = [registrationPagePath(), contactDetailsCheckPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    privacyPolicyPath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    registrationPagePath(),
    forbiddenPagePath(),
    contactDetailsCheckPagePath(),
];

function publicNavigationLinks(t: TFunction, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            label: t('navigation.login'),
            onClick: login,
        },
    ];
}

function privateNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        ...(payByPhoneEnabled
            ? [
                  {
                      url: parkingPagePath(),
                      label: t('navigation.parking'),
                  },
              ]
            : []),
        {
            url: myDocumentsPath(),
            label: t('navigation.documents'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: myRequestsPagePath(),
            label: t('navigation.requests'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC = () => {
    const { t, i18n } = useTranslation('navigation');
    const { isAuthenticated, logout, login } = useAuthentication();
    const currentPathName = useLocation().pathname;
    const language = i18n.languages[0];
    const history = useHistory();
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];
    const { onAction: onLogin } = useAnalyticsActionTracker('login');
    const { onAction: onLogout } = useAnalyticsActionTracker('logout');

    const navigationItems = (
        isAuthenticated
            ? privateNavigationLinks(t, () => {
                  onLogout(currentPageName);
                  return logout({ redirectUri: window.location.origin + landingPagePath() });
              })
            : publicNavigationLinks(t, () => {
                  onLogin(currentPageName);
                  return login({
                      redirectUri: window.location.origin + dashboardPagePath(),
                      locale: language,
                      idpHint,
                      prompt: 'login',
                  });
              })
    ).map((navItem) => ({
        ...{ isActive: history.location.pathname === navItem.url },
        ...navItem,
    }));

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };

    const onLogoClick = useCallback(
        () =>
            isAuthenticated
                ? (): void => history.push(dashboardPagePath())
                : (): void => history.push(landingPagePath()),
        [isAuthenticated, history],
    );

    const NavigationBarSharedWithLogo = (
        <NavigationBarShared
            onLogoClick={onLogoClick()}
            logoSource={`/${currentBrand}/img/logo.svg`}
            logoAltText={t(`navigation.${isAuthenticated ? 'dashboard' : 'home'}`)}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
            notificationsCenter={isAuthenticated ? <NotificationsCenter /> : <></>}
        />
    );

    return NavigationBarSharedWithLogo;
};
