import { ValidatedInput } from '@cp-shared-8/frontend-ui';
import React from 'react';

type TextProps = {
    label: string;
    name: string;
    placeholder?: string;
    isMandatory?: boolean;
    defaultValue?: string;
    disabled?: boolean;
};

export const TextInput: React.FC<TextProps> = ({
    label,
    name,
    placeholder,
    isMandatory = true,
    defaultValue,
    disabled,
}) => {
    return (
        <ValidatedInput
            label={isMandatory ? `${label} *` : label}
            name={name}
            inputMode={'text'}
            testId={`test-${name}`}
            placeholder={placeholder}
            maxLength={40}
            defaultValue={defaultValue}
            disabled={disabled}
        />
    );
};
