import { Contract, ContractDetails as TContractDetails } from '@cp-fr/common';
import { Accordion, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BundledProducts } from '../bundled-products';
import { ContractPartiesComponent } from '../contract-parties';
import { FinancialDetailsComponent } from '../financial-details';
import { VehicleDetailsComponent } from '../vehicle-details';

export const ContractDetailsUi: React.FC<{
    contract?: Contract & TContractDetails;
    isSMECustomer?: boolean;
    isContactInformationChangePending: boolean;
}> = ({ contract, isSMECustomer, isContactInformationChangePending }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');

    if (!contract) {
        return null;
    }

    const { financial, vehicle, contractParties, bundledProducts } = contract;

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('financial-details:title.private'):
            case t('financial-details:title.sme'):
                return 'Financial details';
            case t('vehicle-details:title'):
                return 'Vehicle details';
            case t('contract-parties:title'):
                return 'Contract parties';
            case t('bundled-products:title'):
                return 'Products / Services included';
        }
    };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    return (
        <Accordion onChange={handleOnChange}>
            <FinancialDetailsComponent
                contract={contract}
                financialDetails={financial}
                isContactInformationChangePending={isContactInformationChangePending}
            />
            <VehicleDetailsComponent contract={contract} vehicleDetails={vehicle} isSMECustomer={isSMECustomer} />
            <ContractPartiesComponent contractParties={contractParties} />
            <BundledProducts
                contract={contract}
                isSMECustomer={isSMECustomer}
                bundledProductsDetails={bundledProducts}
                contractMileage={financial?.contractMileage}
            />
        </Accordion>
    );
};
