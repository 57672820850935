import { DocumentsRequest, RequestDocumentType } from '@cp-fr/common';
import { RequestInitialValues } from '../../../../initialValues';

export const documentsMapper = ({ documents, contractsSelection }: RequestInitialValues): DocumentsRequest => {
    return {
        requestCategory: 'DOCUMENTS',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: documents.email,
        phonenumber: documents.phonenumber,
        documentType: documents.documentType as RequestDocumentType,
        invoiceMonth: documents.invoiceMonth,
        invoiceYear: documents.invoiceYear,
        desiredYear: documents.desiredYear,
        desiredTaxStatus: documents.desiredTaxStatus,
        periodStartDate: documents.periodStartDate,
        periodEndDate: documents.periodEndDate,
        exitDate: documents.exitDate,
        returnDate: documents.returnDate,
        countryOfDestination: documents.countryOfDestination,
        reason: documents.reason,
        comment: documents.comment,
    };
};
