import { ContactSectionEditViewBEValidatorErrors, ContactSectionEditViewFEValidatorErrors } from '../../types';

export const ContactSectionEditViewFEError: ContactSectionEditViewFEValidatorErrors = {
    emailAddress: {
        required: 'Email adress is required',
        email: 'Email form is required',
    },
    mobilePhone: {
        required: 'Mobile phone is required',
        matches: 'Mobile phone does not match',
    },
    otherPhone: {
        matches: 'Other phone does not match',
    },
};

export const ContactSectionEditViewBEError: ContactSectionEditViewBEValidatorErrors = {
    ...ContactSectionEditViewFEError,
    mobilePhoneCountryCode: {
        required: 'Mobile phone country code is required',
    },
    otherPhoneCountryCode: {},
};
