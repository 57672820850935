import { TotalEarlySettlementValidatorErrors } from '../types';

export const TotalEarlySettlementError: TotalEarlySettlementValidatorErrors = {
    requestDate: {
        required: 'request date is required',
    },
    reason: {
        required: 'reason is required',
    },
};
