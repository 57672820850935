import { getContractsEndpoint, getMyProfileEndpoint } from '@cp-fr/common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { ContactInformationChangeStatusMock } from 'components/my-profile/ContactInformationChangeStatusResponseMocks';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContractDetailsMock } from './contract-details/ContractDetailsResponseMocks';
import {
    ActiveCreditContracts,
    ActiveEndingRenewalBallonLeasingContractWithAllValues,
    CreditContractsWithMixedEntries,
    InactiveCreditContracts,
    NoContractsForAnAccount,
} from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 201,
        responseBody: CreditContractsWithMixedEntries,
        delay: 1000,
    },
    'Only active contracts': {
        status: 201,
        responseBody: ActiveCreditContracts,
        delay: 1000,
    },
    'Ending Ballon Leasing with Renewal notification': {
        status: 201,
        responseBody: [ActiveEndingRenewalBallonLeasingContractWithAllValues],
        delay: 1000,
    },
    'Only inactive contracts': {
        status: 201,
        responseBody: InactiveCreditContracts,
        delay: 1000,
    },
    'No contracts for an account': {
        status: 201,
        responseBody: NoContractsForAnAccount,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const MyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'MyProfile Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getMyProfileEndpoint(),
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [ContractsMock, ContractDetailsMock(CreditContractsWithMixedEntries[0])]);

    return <div>{storyFn()}</div>;
};

export const ContractsWithContactStatusChangeResponseResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [
        ContractsMock,
        MyProfileMock,
        ContactInformationChangeStatusMock,
        ContractDetailsMock(CreditContractsWithMixedEntries[0]),
        ContractDetailsMock(CreditContractsWithMixedEntries[1]),
        ContractDetailsMock(CreditContractsWithMixedEntries[2]),
        ContractDetailsMock(CreditContractsWithMixedEntries[3]),
    ]);

    return <div>{storyFn()}</div>;
};
