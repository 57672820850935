import React from 'react';
import { Request } from '../../components/request';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const RequestPage: React.FC = () => {
    const { t } = useTranslation('request');
    const title = t('headline');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <Request />
        </ContentSection>
    );
};
