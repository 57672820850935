import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CountrySelection, DateInput, EmailInput, PhonenumberInput, TextInput } from '../../simple-form-components';
import { useField } from 'formik';
import { toInteger } from 'lodash';
import { ValidatedSelect } from '@cp-shared-8/frontend-ui';

export const AuthorizationLeaveCountryForm: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.authorization-leave-country';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    const [selectedStartDate] = useField('authorizationLeaveCountry.exitDate');

    const parseDate = (dateToParse: string, offsetD?: number, offsetM?: number, offsetY?: number): Date => {
        const parts = dateToParse.match(/(\d+)/g);
        return parts
            ? new Date(
                  toInteger(parts[2]) + (offsetY ?? 0),
                  toInteger(parts[1]) + (offsetM ?? 0) - 1,
                  toInteger(parts[0]) + (offsetD ?? 0),
              )
            : new Date();
    };

    const minEndDate = selectedStartDate.value ? parseDate(selectedStartDate.value, 1).toISOString() : '';
    const maxEndDate = selectedStartDate.value ? parseDate(selectedStartDate.value, 30).toISOString() : '';
    const reasonToLeave = ['Motif professionnel', 'Motif personnel'];

    return (
        <>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'authorizationLeaveCountry.email'} isMandatory={true} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber')}
                    name={'authorizationLeaveCountry.phonenumber'}
                    isMandatory={false}
                />
            </Layout.Item>
            <Layout.Item>
                <DateInput label={translate('exit-date')} name={'authorizationLeaveCountry.exitDate'} />
            </Layout.Item>
            <Layout.Item>
                <DateInput
                    label={translate('return-date')}
                    name={'authorizationLeaveCountry.returnDate'}
                    minDate={minEndDate}
                    maxDate={maxEndDate}
                />
            </Layout.Item>
            <Layout.Item>
                <ValidatedSelect
                    isMandatory={true}
                    name={'authorizationLeaveCountry.reason'}
                    selectItems={reasonToLeave.map((reason) => ({
                        value: reason,
                        label: reason,
                    }))}
                    label={translate('reason')}
                    emptyByDefault
                />
            </Layout.Item>
            <Layout.Item>
                <CountrySelection
                    name={'authorizationLeaveCountry.countryOfDestination'}
                    label={translate('country-of-destination')}
                ></CountrySelection>
            </Layout.Item>
            <Layout.Item>
                <TextInput
                    label={translate('comment')}
                    name={'authorizationLeaveCountry.comment'}
                    isMandatory={false}
                />
            </Layout.Item>
        </>
    );
};
