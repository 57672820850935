import { registrationLegalPersonValidator, RegistrationLegalPersonValidatorErrors } from '@cp-fr/common';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { RegistrationLegalFields } from '../RegistrationFormDataTypes';

const translationPrefix = 'registration-form.legal.validation-errors';

export const errorMessageIds: RegistrationLegalPersonValidatorErrors = {
    contractNumber: {
        empty: `${translationPrefix}.contract-number.empty`,
        format: `${translationPrefix}.contract-number.format`,
        format11: `${translationPrefix}.contract-number.format-11`,
        format12: `${translationPrefix}.contract-number.format-12`,
    },
    customerNumber: {
        empty: `${translationPrefix}.customer-number.empty`,
        format: `${translationPrefix}.customer-number.format`,
    },
    companyName: {
        empty: `${translationPrefix}.company-name.empty`,
        format: `${translationPrefix}.company-name.format`,
    },
    siren: {
        empty: `${translationPrefix}.siren.empty`,
        format: `${translationPrefix}.siren.format`,
    },
    privacyPolicy: `${translationPrefix}.privacy-policy`,
    usageAgreement: `${translationPrefix}.terms-and-conditions`,
};

export const validationSchema = (t: TFunction): Yup.SchemaOf<RegistrationLegalFields> => {
    const errors: RegistrationLegalPersonValidatorErrors = {
        contractNumber: {
            empty: t(errorMessageIds.contractNumber.empty),
            format: t(errorMessageIds.contractNumber.format),
            format11: t(errorMessageIds.contractNumber.format11),
            format12: t(errorMessageIds.contractNumber.format12),
        },
        customerNumber: {
            empty: t(errorMessageIds.customerNumber.empty),
            format: t(errorMessageIds.customerNumber.format),
        },
        companyName: {
            empty: t(errorMessageIds.companyName.empty),
            format: t(errorMessageIds.companyName.format),
        },
        siren: {
            empty: t(errorMessageIds.siren.empty),
            format: t(errorMessageIds.siren.format),
        },
        privacyPolicy: t(errorMessageIds.privacyPolicy),
        usageAgreement: t(errorMessageIds.usageAgreement),
    };

    return registrationLegalPersonValidator(errors).shape({
        confirmTermsAndConditions: Yup.bool().oneOf([true], errors.usageAgreement),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], errors.privacyPolicy),
    });
};
