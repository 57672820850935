import { DefaultStaticPageContentLoadingPlaceholder } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { ParkingSessionsUi } from './ui/ParkingSessionsUi';
import { useParkingSessions } from './useParkingSessions';

const ParkingSessionsWithHandlers = withLoadingAndNoConnectionHandler(ParkingSessionsUi);

export const ParkingSessions: React.FC = () => {
    const { data, isLoading, loadingError } = useParkingSessions();

    return (
        <ParkingSessionsWithHandlers
            parkingSessions={data}
            isLoading={isLoading}
            hasError={!!loadingError}
            loadingPlaceholder={<DefaultStaticPageContentLoadingPlaceholder />}
        />
    );
};
