import { TenantChangeRequestValidatorErrors } from '../../types';

export const RequestTenantChangeError: TenantChangeRequestValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'email value must be an email',
    },
    phonenumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    transferDate: {
        required: 'transferDate is required',
    },
    reason: {
        required: 'reason is required',
    },
    transferTo: {
        required: 'transferTo is required',
    },
    files: {
        dialogueSheet: {
            required: 'dialogueSheet file is required',
            maxLength: 'dialogueSheet must contain 1 file',
        },
        idCopy: {
            required: 'idCopy file is required',
            maxLength: 'idCopy must contain 1 file',
        },
        taxPackage: {
            required: 'taxPackage file is required',
            maxLength: 'taxPackage must contain 1 file',
        },
        kbisOrInsee: {
            required: 'kbisOrInsee file is required',
            maxLength: 'kbisOrInsee must contain 1 file',
        },
        newTenantIban: {
            required: 'newTenantIban file is required',
            maxLength: 'newTenantIban must contain 1 file',
        },
        vehicleId: {
            required: 'vehicleId file is required',
            maxLength: 'vehicleId must contain 1 file',
        },
        idCopyManager: {
            required: 'idCopyManager file is required',
            maxLength: 'idCopyManager must contain 1 file',
        },
        taxReturn: {
            required: 'taxReturn file is required',
            maxLength: 'taxReturn must contain 1 file',
        },
    },
};
