import { Address, Identification, MyProfileData } from '@cp-fr/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';

export type ContactDetailsFormFields = {
    mobilePhone: string;
    mobilePhonePrefix: string;
    email: string;
};

export type ProfessionalDetailsFields = {
    employer: string;
    employerChanged?: boolean;
    income?: number;
    activityChanged?: boolean;
    authorizationChanged?: boolean;
};

export type CustomerDataFormFields = {
    identification: Identification;
    address: Address;
    contactDetails: ContactDetailsFormFields;
    professional: ProfessionalDetailsFields;
    confirmation?: boolean;
    personalUsage?: boolean;
    proofOfIdentityChange?: File[];
    proofOfAddressChange?: File[];
    proofOfProfessionChange?: File[];
    proofOfActivityChange?: File[];
    proofOfAuthChangeId?: File[];
    proofOfAuthChangeSign?: File[];
};

export type CustomerType = 'Retail' | 'SME' | 'PM' | '';
export const getFormCustomerType = (customerType: string): CustomerType => {
    switch (customerType) {
        case 'Retail':
            return 'Retail';
        case 'BIC':
        case 'BNC':
        case 'BA':
            return 'SME';
        case 'PM':
            return 'PM';
        default:
            return '';
    }
};

export const confirmInformationInitialValues = (
    initPrefix: string,
    profileData?: MyProfileData,
): CustomerDataFormFields => ({
    identification: {
        data: {
            ...profileData?.identification?.data,
            birthDate: profileData?.identification?.data.birthDate
                ? formatCpDate(profileData?.identification?.data.birthDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
                : '',
        },
        customerType: profileData?.identification?.customerType || 'Retail',
    },
    personalUsage: undefined,
    address: {
        houseNumber: profileData?.address?.houseNumber || '',
        street: profileData?.address?.street || '',
        streetAdd: profileData?.address?.streetAdd || '',
        building: profileData?.address?.building || '',
        door: profileData?.address?.door || '',
        postalCode: profileData?.address?.postalCode || '',
        city: profileData?.address?.city || '',
        country: profileData?.address?.country || 'France',
    },
    professional: {
        employer: '',
        income: undefined,
        employerChanged: false,
        activityChanged: false,
    },
    contactDetails: {
        email: profileData?.contactDetails.email || '',
        mobilePhone: profileData?.contactDetails.mobilePhone || '',
        mobilePhonePrefix: initPrefix || '33',
    },
    confirmation: false,
    proofOfIdentityChange: [],
    proofOfAddressChange: [],
    proofOfProfessionChange: [],
    proofOfActivityChange: [],
    proofOfAuthChangeId: [],
    proofOfAuthChangeSign: [],
});
