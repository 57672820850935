import React, { useState } from 'react';
import { ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Greeting } from '../../components/greeting';
import { Contracts } from '../../components/contracts';
import { MarketingCard } from 'components/marketing-card/MarketingCard';

export const DashboardPage: React.FC = () => {
    const [hasMarketingCard, setHasMarketingCard] = useState(false);
    const { t } = useTranslation('dashboard');
    const title = t('headline');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <Greeting />
            <Layout>
                <Layout.Item default={hasMarketingCard ? '2/3' : '1/1'} m={'1/1'}>
                    <Contracts />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <MarketingCard setShouldRender={setHasMarketingCard} shouldRender={hasMarketingCard} />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
