import { Identification } from '@cp-fr/common';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-8/frontend-ui';
import { changeNamePath, changeSMENamePath } from 'components/navigation/paths';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { NoConnectionNotification } from '../../notifications/no-connection/NoConnectionNotification';
import { NotFoundError } from '../not-found-error/NotFoundError';
import { ConsultView } from './consult-view/ConsultView';
import { EditViewSME } from './edit-view-sme/EditViewSME';
import { EditStatus, EditStatusWithData, EditView } from './edit-view/EditView';

export const isEmptyIdentification = (identification?: Identification): boolean => {
    return !identification || isEmpty(identification) || isEmpty(identification.data);
};

export const IdentificationSection: React.FC<{
    identification?: Identification;
    isNameChangePending: boolean;
    isContactInformationChangePending: boolean;
}> = ({ identification, isNameChangePending, isContactInformationChangePending }) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { onAction: onEditFail } = useAnalyticsActionTracker('onEditProfileIdentificationSubmitFailed');
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileIdentification');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onSuccess } = useAnalyticsFormTracker({
        confirmSuccess: 'onEditProfileIdentificationSuccess',
    });
    const [editStatus, setEditStatus] = useState<EditStatusWithData>({
        status: EditStatus.NOT_PERFORMED,
        identification,
    });

    const startEditing = (): void => {
        onStart();
        if (identification?.customerType === 'PM') {
            history.push(changeSMENamePath());
        } else {
            history.push(changeNamePath());
        }
    };

    const cancelEditing = (): void => {
        onCancel('Identification');
        setEditStatus({
            ...editStatus,
            status: EditStatus.NOT_PERFORMED,
            identification,
        });
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedIdentification?: Identification): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onEditFail();
        setEditStatus({
            ...editStatus,
            status: newEditStatus,
            identification: newEditStatus === EditStatus.SUCCESS ? updatedIdentification : identification,
        });
        history.push(path);
    };

    return (
        <>
            {!identification ? (
                <NotFoundError />
            ) : isEmptyIdentification(identification) ? (
                <NoConnectionNotification />
            ) : (
                <Switch>
                    <Route exact path={changeNamePath()}>
                        <EditView
                            identification={identification}
                            cancelEditing={cancelEditing}
                            finishEditing={finishEditing}
                            editStatus={editStatus.status}
                        />
                    </Route>
                    <Route exact path={changeSMENamePath()}>
                        <EditViewSME
                            identification={identification}
                            cancelEditing={cancelEditing}
                            finishEditing={finishEditing}
                            editStatus={editStatus.status}
                        />
                    </Route>
                    <Route path={path}>
                        <ConsultView
                            isNameChangePending={isNameChangePending}
                            isContactInformationChangePending={isContactInformationChangePending}
                            identification={editStatus.identification}
                            startEditing={startEditing}
                            editStatus={editStatus.status}
                        />
                    </Route>
                </Switch>
            )}
        </>
    );
};
