import * as Yup from 'yup';
import {
    ContactSectionEditViewBEValidatorErrors,
    ContactSectionEditViewFEValidatorErrors,
} from '../../types/myProfileTypes';
import { ContactSectionEditViewBEError, ContactSectionEditViewFEError } from '../../messages/myProfileMessages';
import { phoneNumberNotRequiredValidation } from '../PhoneNumberValidator';

export type EditViewContactSectionFormikValues = {
    emailAddress: string;
    mobilePhone: string;
    otherPhone: string | undefined;
};

export type EditViewContactSectionBackendFormikValues = {
    mobilePhoneCountryCode: string;
    otherPhoneCountryCode: string | undefined;
};

export const editViewContactSectionValidationSchema = (
    errors: ContactSectionEditViewFEValidatorErrors = ContactSectionEditViewFEError,
): Yup.SchemaOf<EditViewContactSectionFormikValues> =>
    Yup.object().shape({
        emailAddress: Yup.string().trim().required(errors.emailAddress.required).email(errors.emailAddress.email),
        mobilePhone: phoneNumberNotRequiredValidation(errors.mobilePhone.required, errors.mobilePhone.matches),
        otherPhone: Yup.string()
            .trim()
            .test('matches', errors.otherPhone.matches, (value): boolean =>
                value
                    ? value === '' || /^0[1-5|9]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/.test(value)
                    : true,
            ),
    });

export const editViewContactSectionBackendValidationSchema = (
    errors: ContactSectionEditViewBEValidatorErrors = ContactSectionEditViewBEError,
): Yup.SchemaOf<EditViewContactSectionFormikValues> =>
    editViewContactSectionValidationSchema(errors).shape({
        mobilePhoneCountryCode: Yup.string().trim().required(errors.mobilePhoneCountryCode.required),
        otherPhoneCountryCode: Yup.string().trim(),
    });
