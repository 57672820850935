import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import contractDetailsReducer from 'components/contracts/contract-details/ContractDetailsSlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import forbiddenErrorPageReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import notFoundErrorPageReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorPageReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import faqReducer from 'components/faq/FaqSlice';
import iconFooterReducer from 'components/footer/icon-footer/IconFooterSlice';
import legalFooterReducer from 'components/footer/legal-footer/LegalFooterSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import landingPageReducer from 'components/landing-page/LandingPageSlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import marketingCardReducer from 'components/marketing-card/MarketingCardSlice';
import bundledProductContactInfoReducer from 'components/contracts/contract-details/bundled-products/bundled-product/BundledProductSlice';
import contactInformationChangeStatusReducer from 'components/my-profile/ContactInformationChangeStatusSlice';
import nameChangeStatusReducer from 'components/my-profile/NameChangeStatusSlice';
import addressChangeStatusReducer from 'components/my-profile/AddressChangeStatusSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import myDocumentsReducer from 'components/my-documents/MyDocumentsSlice';
import privacyPolicyReducer from 'components/privacy-policy/PrivacyPolicySlice';
import productRoutingReducer from 'components/marketing-card/ProductRoutingSlice';
import requestReducer from 'components/request/RequestSlice';
import parkingSessionsReducer from 'components/parking/parking-sessions/ParkingSessionsSlice';
import searchCustomerIndexReducer from 'components/parking/SearchCustomerIndexSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { paymentDayStatusTagFetchStateReducer } from '../components/contracts/contract-details/financial-details/collection-date-section/non-edit-view/usePaymentDayStatusTagSlice';
import { ibanStatusTagFetchStateReducer } from '../components/contracts/contract-details/financial-details/iban-section/consult-view/useIbanStatusTagSlice';
import { documentFiltersStateReducer } from 'components/my-documents/filters-section/DocumentFiltersSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    myProfile: myProfileReducer,
    greeting: greetingReducer,
    contracts: contractsReducer,
    request: requestReducer,
    contractDetails: contractDetailsReducer,
    contactInformationChangeStatus: contactInformationChangeStatusReducer,
    nameChangeStatus: nameChangeStatusReducer,
    addressChangeStatus: addressChangeStatusReducer,
    productRouting: productRoutingReducer,
    parkingSessions: parkingSessionsReducer,
    searchCustomerIndex: searchCustomerIndexReducer,
    paymentDayStatusTagFetchState: paymentDayStatusTagFetchStateReducer,
    myDocuments: myDocumentsReducer,
    documentFilters: documentFiltersStateReducer,
    ibanStatusTagFetchState: ibanStatusTagFetchStateReducer,
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        legalNotice: legalNoticeReducer,
        notFoundErrorPage: notFoundErrorPageReducer,
        forbiddenErrorPage: forbiddenErrorPageReducer,
        unauthorizedErrorPage: unauthorizedErrorPageReducer,
        cookiePolicy: cookiePolicyReducer,
        landingPage: landingPageReducer,
        faq: faqReducer,
        privacyPolicy: privacyPolicyReducer,
        marketingCard: marketingCardReducer,
        bundledProductsContactInfo: bundledProductContactInfoReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
