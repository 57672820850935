import { Contract, Identification, ProductTypeEN, RequestCategory } from '@cp-fr/common';
import React from 'react';
import {
    AuthorizationDebitForm,
    DeadlineForm,
    DeathForm,
    DocumentsForm,
    LossForm,
    OtherForm,
    RequestContractTransferForm,
    TenantChangeRequestForm,
    ChangeDurationMileageForm,
    VehicleClaimForm,
} from './category-views';
import { AuthorizationLeaveCountryForm } from './category-views/authorization-leave-country';
import { RegistrationCertificateDuplicateFormWithHandlers } from './category-views/registration-certificate-duplicate/RegistrationCertificateDuplicateFormWithHandlers';

export type CategoryViewProps = {
    category: RequestCategory;
    productTypeEn?: ProductTypeEN;
    identification?: Identification;
    isSMECustomer?: boolean;
    contractsSelected?: Contract[];
};

export const CategoryView: React.FC<CategoryViewProps> = ({
    contractsSelected,
    category,
    productTypeEn,
    identification,
    isSMECustomer,
}) => {
    switch (category) {
        case 'AUTHORIZATION_DEBIT':
            return <AuthorizationDebitForm identification={identification} />;
        case 'DEADLINE':
            return <DeadlineForm productTypeEn={productTypeEn} />;
        case 'VEHICLE_CLAIM':
            return <VehicleClaimForm productTypeEn={productTypeEn} />;
        case 'DEATH':
            return <DeathForm />;
        case 'LOSS':
            return <LossForm />;
        case 'REQUEST_CONTRACT_TRANSFER':
            return <RequestContractTransferForm />;
        case 'AUTHORIZATION_LEAVE_COUNTRY':
            return <AuthorizationLeaveCountryForm />;
        case 'DOCUMENTS':
            return <DocumentsForm isSMECustomer={isSMECustomer} contractsSelected={contractsSelected} />;
        case 'REGISTRATION_CERTIFICATE_DUPLICATE':
            return <RegistrationCertificateDuplicateFormWithHandlers />;
        case 'TENANT':
            return <TenantChangeRequestForm />;
        case 'CHANGE_DURATION_MILEAGE':
            return <ChangeDurationMileageForm />;
        case 'OTHER':
            return <OtherForm />;
        default:
            return null;
    }
};
