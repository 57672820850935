export type SMEFormValues = {
    companyName: string;
    kbis: File[];
    insee: File[];
    registrationCertificate: File[];
};

export const initialValues: SMEFormValues = {
    companyName: '',
    kbis: [],
    insee: [],
    registrationCertificate: [],
};
