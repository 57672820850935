import { Address, ChangeAddress, AddressType, HousingOccupancyStatus } from '@cp-fr/common';
import { isCustomerType } from '@cp-fr/common/src/apis/services/types/contracts';
import { Notification, NotificationStatus, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { changeBillingAddressPath } from 'components/navigation/paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { emptyObjectOrAllValuesAreUndefined } from 'utils';
import { EditStatus, EditViewName } from '../enums';
import { FileUploadView } from './file-upload-view/FileUploadView';
import { FormView } from './form-view/FormView';

export type EditViewProps = {
    address?: Address;
    customerType: isCustomerType;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedAddressDetails?: Address) => void;
    hasBallonContracts?: boolean;
};

export const EditView: React.FC<EditViewProps> = ({
    cancelEditing,
    address,
    customerType,
    finishEditing,
    hasBallonContracts,
}) => {
    const { t } = useTranslation('my-profile');
    const currentPathName = useLocation().pathname;
    const [currentView, setCurrentView] = useState(EditViewName.FORM_VIEW);

    const trackingSection = 'Address';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);

    const isBillingAddress = (): boolean => {
        return currentPathName === changeBillingAddressPath();
    };

    const initialValues: ChangeAddress = {
        houseNumber: address?.houseNumber ? address?.houseNumber : '',
        street: address?.street ? address?.street : '',
        streetAdd: address?.streetAdd ? address?.streetAdd : '',
        building: address?.building ? address?.building : '',
        door: address?.door ? address?.door : '',
        postalCode: address?.postalCode ? address?.postalCode : '',
        city: address?.city ? address?.city : '',
        country: address?.country ? address.country : 'France',
        documents: [],
        addressType: isBillingAddress() ? AddressType.INVOICE : AddressType.POSTAL,
        housingOccupancyStatus: HousingOccupancyStatus.HOSTED_FREE,
    };

    const [addressChangeValues, setAddressChangeValues] = useState<ChangeAddress>(initialValues);

    const closeButtonProps = {
        onClick: cancelEditing,
        className: 'c-icon  c-icon--[semantic-close]',
        testId: 'x-button',
    };

    const getEditViewTitle = (): string => {
        return isBillingAddress()
            ? // eslint-disable-next-line @typescript-eslint/ban-types
              emptyObjectOrAllValuesAreUndefined(address as object)
                ? t('address.edit-view.title-sme-add-address')
                : t('address.edit-view.title-sme')
            : t('address.edit-view.title');
    };

    return (
        <DataOverview title={getEditViewTitle()} buttonLabel={' '} buttonProps={closeButtonProps}>
            <>
                <Notification
                    status={NotificationStatus.warning}
                    text={t('address.edit-view.info')}
                    className={'u-mb'}
                />
                {currentView === EditViewName.FILE_UPLOAD_VIEW && (
                    <FileUploadView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        setCurrentView={setCurrentView}
                        addressChangeValues={addressChangeValues}
                        hasBallonContracts={hasBallonContracts}
                        customerType={customerType}
                        isBillingAddress={isBillingAddress()}
                    />
                )}
                {currentView === EditViewName.FORM_VIEW && (
                    <FormView
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        setCurrentView={setCurrentView}
                        setAddressChangeValues={setAddressChangeValues}
                        addressChangeValues={addressChangeValues}
                    />
                )}
            </>
        </DataOverview>
    );
};
