import React from 'react';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-8/frontend-ui';
import { BalanceReason } from '@cp-fr/common';

type BalanceReasonSelectionProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const BalanceReasonSelection: React.FC<BalanceReasonSelectionProps> = ({ label, name, isMandatory = true }) => {
    const balanceReasons: BalanceReason[] = [
        'Revente du véhicule',
        'Rachat de crédit',
        'Epargne personnelle',
        'Prime',
        'Héritage',
    ];

    const selectItems: ValidatedSelectItem[] = balanceReasons.map((balanceReason: BalanceReason) => {
        return { value: balanceReason, label: balanceReason };
    });

    return (
        <ValidatedSelect
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
            selectItems={selectItems}
            emptyByDefault
        />
    );
};
