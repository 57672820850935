import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const ibanStatusTagFetchStateSlice = createSlice({
    name: 'ibanStatusTagFetchState',
    initialState: {
        dataValid: false,
        inProgress: false,
    },
    reducers: {
        setDataValidity(state, action: PayloadAction<boolean>) {
            state.dataValid = action.payload;
        },
        setFetchProgress(state, action: PayloadAction<boolean>) {
            state.inProgress = action.payload;
        },
    },
});

export const ibanStatusTagFetchStateReducer = ibanStatusTagFetchStateSlice.reducer;
export const ibanStatusTagFetchStateActions = ibanStatusTagFetchStateSlice.actions;
