import { AdressSectionFormViewBEValidatorErrors, AdressSectionFormViewFEValidatorErrors } from '../../types';

export const AdressSectionFormViewError: AdressSectionFormViewFEValidatorErrors = {
    houseNumber: {
        max: 'Number of max letters for house number reached',
        required: 'House number is required',
    },
    street: {
        max: 'Number of max letters for street reached',
    },
    building: {
        max: 'Number of max letters for building reached',
    },
    door: {
        max: 'Number of max letters for door reached',
    },
    postalCode: {
        required: 'Postal code is required',
        max: 'Number of max letters for postal code reached',
    },
    city: {
        required: 'City is required',
        max: 'Number of max letters for city reached',
    },
    country: {
        required: 'Country is required',
        max: 'Number of max letters for country reached',
    },
};

export const AdressSectionFormBEError: AdressSectionFormViewBEValidatorErrors = {
    ...AdressSectionFormViewError,
    files: {
        required: 'At least one file is required',
    },
};
