import React from 'react';
import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../enums';

type AddressChangeNotificationProps = {
    lastEditStatus: EditStatus;
    effectDate?: string;
};

type NotificationProps = {
    testId: string;
    status: NotificationStatus;
    headline?: string;
    text: string | React.ReactNode;
};

export const AddressChangeNotification: React.FC<AddressChangeNotificationProps> = ({ lastEditStatus }) => {
    const { t } = useTranslation('my-profile');

    if (lastEditStatus === EditStatus.NOT_PERFORMED) {
        return null;
    }

    const getNotificationProps = (): NotificationProps => {
        switch (lastEditStatus) {
            case EditStatus.CHECK_FAILURE:
                return {
                    testId: 'check-failure',
                    status: NotificationStatus.error,
                    headline: t('address.notification.check-failure.text'),
                    text: (
                        <>
                            {t('address.notification.check-failure.option1')}
                            <br />
                            {t('address.notification.check-failure.option2')}
                        </>
                    ),
                };
            case EditStatus.ERROR:
                return {
                    testId: 'no-connection-error',
                    status: NotificationStatus.error,
                    text: t('address.notification.error'),
                };
            case EditStatus.ERROR_DOCUMENTS:
                return {
                    testId: 'documents-error',
                    status: NotificationStatus.error,
                    text: t('address.notification.error-documents'),
                };
            case EditStatus.NO_EMAIL_ERROR:
                return {
                    testId: 'no-email-error',
                    status: NotificationStatus.error,
                    text: t('address.notification.error-no-email'),
                };
            case EditStatus.SUCCESS:
                return {
                    testId: 'success',
                    status: NotificationStatus.success,
                    headline: t('address.notification.success.title'),
                    text: t('address.notification.success.text'),
                };
        }
    };

    const { text, ...notificationProps } = getNotificationProps();

    return (
        <Notification {...notificationProps} className={'u-mb'}>
            {text}
        </Notification>
    );
};
