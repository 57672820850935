import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { Parking } from '../../components/parking';

export const ParkingPage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="medium">
            <Parking />
        </ContentSection>
    );
};
