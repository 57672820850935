import { RequestCustomerStatus, getContactDetailsStatusEndpoint } from '@cp-fr/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    RequestCustomerStatus,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'ContactInformationChangeStatus',
    fetchCallback() {
        return CpDataApi.get<RequestCustomerStatus>(getContactDetailsStatusEndpoint()).then(
            (response) => response.data,
        );
    },
});

export default reducer;
export const fetchContactInformationChangeStatus = fetchData;
export const updateContactInformationChangeStatus = updateData;
