import { OtherRequest } from '@cp-fr/common';
import { mapFilesToBase64 } from 'utils/file-utils';
import { RequestInitialValues } from '../../../../initialValues';

export const otherMapper = async ({ contractsSelection, other }: RequestInitialValues): Promise<OtherRequest> => {
    return {
        requestCategory: 'OTHER',
        contractsNumbers: contractsSelection.map((contract) => contract.contractNumber),
        email: other.email,
        phonenumber: other.phonenumber,
        subjectOfClaim: other.subjectOfClaim,
        message: other.message,
        files: other.files.attachment.length ? await mapFilesToBase64(other.files.attachment) : undefined,
    };
};
