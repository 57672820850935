import {
    FinancialDetails,
    isBalloonLeasing,
    isCredit,
    isFinancialLease,
    isLine2Product,
    isLongTermOrOperatingLeasing,
} from '@cp-fr/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { Hr } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import { valueAvailable } from '../../utils';

type PaymentSectionProps = {
    details?: FinancialDetails;
    isActive?: boolean;
    productType?: string;
    isSMECustomer?: boolean;
};

export const PaymentSection: React.FC<PaymentSectionProps> = ({ details, isActive, productType, isSMECustomer }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    if (!details || !isActive) {
        return null;
    }

    const {
        rate,
        paymentMethod,
        firstDatePayment,
        lastDatePayment,
        lastPaymentInclTVA,
        lastPaymentExclTVA,
        returnBackVehicleDate,
        paymentDeadline,
        numberOfServices,
    } = details;

    const isCreditType = isCredit(productType);
    const isBalloonType = isBalloonLeasing(productType);
    const isFinancialLeaseType = isFinancialLease(productType);
    const isOperatingLeaseType = isLongTermOrOperatingLeasing(productType);
    const isLine2ProductType = isLine2Product(productType);

    const isError = (!rate && !paymentMethod) || (!isCreditType && !paymentMethod);

    const itemList: DefinitionListItem[] = [];

    const getReturnBackVehicleLabel = () => {
        if (isBalloonType) {
            return t('payment-section.return-back-vehicle-date-balloon');
        } else if (isFinancialLeaseType) {
            return t('payment-section.return-back-vehicle-date-financial');
        } else {
            return t('payment-section.return-back-vehicle-date-operating');
        }
    };

    const getFirstDatePaymentLabel = () => {
        if (isCreditType) {
            return t('payment-section.first-date-payment-credit');
        } else if (isLine2ProductType) {
            return t('payment-section.first-date-payment-long-term-leasing');
        } else {
            return t('payment-section.first-date-payment');
        }
    };

    if (!!firstDatePayment) {
        itemList.push({
            label: getFirstDatePaymentLabel(),
            value: f(firstDatePayment, TranslationFormat.DATE),
            testId: 'first-date-payment',
        });
    }

    if (!!lastDatePayment) {
        itemList.push({
            label: isCreditType
                ? t('payment-section.last-date-payment-credit')
                : t('payment-section.last-date-payment-balloon'),
            value: f(lastDatePayment, TranslationFormat.DATE),
            testId: 'last-date-payment',
        });
    }

    if (valueAvailable(lastPaymentInclTVA) && (isBalloonType || isFinancialLeaseType) && isActive) {
        itemList.push({
            label: t('payment-section.last-payment-amount-ttc'),
            value: f(lastPaymentInclTVA, TranslationFormat.CURRENCY),
            testId: 'last-payment-amount-ttc',
        });
    }

    if (isSMECustomer && valueAvailable(lastPaymentExclTVA) && (isBalloonType || isFinancialLeaseType) && isActive) {
        itemList.push({
            label: t('payment-section.last-payment-amount-ht'),
            value: f(lastPaymentExclTVA, TranslationFormat.CURRENCY),
            testId: 'last-payment-amount-ht',
        });
    }

    if (!!returnBackVehicleDate && (isBalloonType || isOperatingLeaseType || isFinancialLeaseType)) {
        itemList.push({
            label: getReturnBackVehicleLabel(),
            value: f(returnBackVehicleDate, TranslationFormat.DATE),
            testId: 'return-back-vehicle-date',
        });
    }

    if (!!rate && !isSMECustomer && isCreditType && isActive) {
        itemList.push({
            label: t('payment-section.rate'),
            value: f(rate, TranslationFormat.PERCENTAGE),
            testId: 'rate',
        });
    }

    if (isSMECustomer && (isBalloonType || isFinancialLeaseType || isOperatingLeaseType) && isActive) {
        itemList.push({
            label: t('payment-section.number-of-services'),
            value: f(numberOfServices, TranslationFormat.NUMBER),
            testId: 'number-of-services',
        });

        if (!!paymentMethod) {
            itemList.push({
                label: t('payment-section.payment-method'),
                value: paymentMethod,
                testId: 'payment-method',
            });
        }

        if (!!paymentDeadline) {
            itemList.push({
                label: t('payment-section.payment-deadline'),
                value: f(paymentDeadline, TranslationFormat.DATE),
                testId: 'payment-deadline',
            });
        }
    }

    return (
        <>
            <Hr className={'u-mb'} />
            {isError ? (
                <ErrorNotification testId={'not-found-error'} headline={t('error.headline')} text={t('error.text')} />
            ) : (
                <DefinitionList split={true} list={itemList} />
            )}
        </>
    );
};
