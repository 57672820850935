import { Notification, NotificationStatus } from '@cp-shared-8/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../enums';

type PaymentDayChangeNotificationProps = {
    editStatus: EditStatus;
};

type NotificationProps = {
    testId: string;
    status: NotificationStatus;
    headline?: string;
    text: string;
};

export const PaymentDayNotification: React.FC<PaymentDayChangeNotificationProps> = ({ editStatus }) => {
    const { t } = useTranslation('financial-details');
    const prefix = 'collection-date-section.edit.notification';

    if (editStatus === EditStatus.NOT_PERFORMED) {
        return null;
    }

    const getNotificationProps = (): NotificationProps => {
        switch (editStatus) {
            case EditStatus.SUCCESS:
                return {
                    testId: 'change-success',
                    status: NotificationStatus.warning,
                    headline: t(`${prefix}.success.title`),
                    text: t(`${prefix}.success.text`),
                };
            case EditStatus.ERROR:
                return {
                    testId: 'api-error',
                    status: NotificationStatus.error,
                    text: t(`${prefix}.error.text`),
                };
            case EditStatus.USER_WITH_NO_EMAIL_CODE:
                return {
                    testId: 'missing-data-error',
                    status: NotificationStatus.error,
                    text: t(`${prefix}.missing-email-error.text`),
                };
        }
    };

    return <Notification {...getNotificationProps()} />;
};
