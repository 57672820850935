import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const contactDetailsCheckValidation = (t: TFunction) => {
    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(t('form.validation.required-field'))
            .email(t('form.validation.e-mail-invalid')),
        mobilePhone: Yup.string()
            .trim()
            .required(t('form.validation.required-field'))
            .matches(/^[06|07]{2}[0-9]{8}$|^$/, t('form.validation.phone-invalid')),
        mobilePhonePrefix: Yup.string().required(t('form.validation.required-field')),
    });
};
