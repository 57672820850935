import { ContentSection, Layout, PageWrap } from '@vwfs-bronson/bronson-react';
import { ContactDetailsCheck } from 'components/kyc/contact-details-check';
import { currentBrand } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ContactDetailsCheckPage: React.FC = () => {
    const { t } = useTranslation('contact-details-check');

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                <Layout className={'u-mt-large'}>
                    <Layout.Item className={'u-text-center'}>
                        <img src={`/${currentBrand}/img/logo.svg`} className={'u-1/4 u-1/3@s u-1/2@xs'} alt="logo" />
                    </Layout.Item>
                    <Layout.Item default={'1/1'} className={'u-mb u-text-center'}>
                        <h1>{t('headline')}</h1>
                        <p>{t('description')}</p>
                    </Layout.Item>
                </Layout>
                <ContactDetailsCheck />
            </PageWrap>
        </ContentSection>
    );
};
