import { SimplePage } from '@cp-fr/common';
import { CookiePolicy as CookiePolicyShared, CookieTable, HeroImage } from '@cp-shared-8/frontend-ui';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { CookieDescriptionCMS } from '@cp-shared-8/apis';

export const CookiePolicyUi: React.FC<{ cookiePolicy?: SimplePage }> = ({ cookiePolicy }) => {
    if (!cookiePolicy) {
        return null;
    }

    const { teaser, content, table } = cookiePolicy;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={false} inverted={!!teaser.isInverted} />
    ) : null;

    const columnTitles: CookieDescriptionCMS = {
        type: table.columnsTitles.type,
        name: table.columnsTitles.name,
        description: table.columnsTitles.description,
        expirationDuration: table.columnsTitles.expirationDuration,
        service: table.columnsTitles.service,
        creationTime: table.columnsTitles.creationTime,
        source: table.columnsTitles.source,
    };

    return (
        <>
            {teaserComponent}
            <ContentSection>
                <PageWrap size={'medium'}>
                    <CookiePolicyShared
                        textAboveTable={content.textAboveTable}
                        textBelowTable={content.textBelowTable}
                        title={content.title}
                        cookieTable={<CookieTable cookies={table.values} columnTitles={columnTitles} />}
                    />
                </PageWrap>
            </ContentSection>
        </>
    );
};
