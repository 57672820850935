import React from 'react';
import { Redirect } from 'react-router-dom';

import {
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-8/frontend-ui';

import { landingPagePath, registrationPagePath } from '../components/navigation/paths';
import { getUserRegistryStatusEndpoint, UserRegistryStatus } from '@cp-fr/common';
import { CpDataApi } from '../cp-xhr';

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const authDataProvider = async (): Promise<{ isAuthorized: boolean }> => {
    try {
        const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
        return { isAuthorized: registrationData.isRegistered };
    } catch (e) {
        return { isAuthorized: false };
    }
};

const { Authorization: UserRegistrationAuthorization } = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: authDataProvider,
    onMissingAuthorization: <Redirect to={registrationPagePath()} />,
    onLoading: <Spinner center />,
    onError: <Redirect to={landingPagePath()} />,
});

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistrationAuthorization);
