import { Base64File, Contract, getMailSendingEndpoint, SmeCustomerData } from '@cp-fr/common';
import { Spinner } from '@cp-shared-8/frontend-ui';
import { withLoadingHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile';
import { dashboardPagePath, myProfilePagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomerDataForm } from './ui';
import { Content, Options } from '@cp-shared-8/integration-library';
import { Modal } from '@vwfs-bronson/bronson-react';
import { mapFileToBase64 } from 'utils/file-utils';
import { CustomerDataFormFields, getFormCustomerType } from './ui/utils';
import { useContracts } from 'components/contracts';

const ConfirmInformationWithHandlers = withLoadingHandler(CustomerDataForm);

export const ConfirmInformation: React.FC = () => {
    const { t } = useTranslation('confirm-information');
    const { data: profile, isLoading } = useMyProfile();
    const { data: contracts, isLoading: isLoadingContracts } = useContracts();
    const [isSubmiting, setIsSubmitting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [data, setData] = useState<CustomerDataFormFields>();
    const history = useHistory();

    const handleModalClose = (): void => {
        setIsError(false);
        setIsSubmitting(false);
        setIsSuccess(false);
    };

    const onSuccessConfirm = () => {
        history.push(dashboardPagePath());
    };

    const getContractNumbers = (contracts?: Contract[]) => {
        if (!contracts) return 'N/A';
        if (contracts.length === 1) return contracts[0].contractNumber;
        else {
            return contracts.map((c) => c.contractNumber).join(',');
        }
    };

    const customerType = getFormCustomerType(profile?.identification?.customerType || '');

    const handleSubmit = async (data: CustomerDataFormFields): Promise<void> => {
        setIsSubmitting(true);
        setData(data);
        const template = {
            templateName:
                customerType === 'Retail'
                    ? 'ConfirmInformationRetail'
                    : customerType === 'PM'
                    ? 'ConfirmInformationBusiness'
                    : 'ConfirmInformation',
            templateParams: {
                name: data.identification.data.name,
                surName: data.identification.data.surname,
                companyName: (data.identification.data as SmeCustomerData).companyName,
                contractNumber: getContractNumbers(contracts),
                customerNumber: profile?.identification?.data.customerNumber,
                birthDate: data.identification.data.birthDate,
                houseNumber: data.address.houseNumber,
                street: data.address.street,
                building: data.address.building,
                streetAdd: data.address.streetAdd,
                postalCode: data.address.postalCode,
                city: data.address.city,
                country: data.address.country,
                email: data.contactDetails.email,
                mobilePhone: `${data.contactDetails.mobilePhonePrefix} ${data.contactDetails.mobilePhone}`,
                employerChanged: data.professional.employerChanged ? t('yes') : t('no'),
                employer: data.professional.employer,
                income: data.professional.income,
                activityChanged: data.professional.activityChanged ? t('yes') : t('no'),
                identityChanged: data.proofOfIdentityChange?.length ? t('yes') : t('no'),
                addressChanged: data.proofOfAddressChange?.length ? t('yes') : t('no'),
                professionChanged: data.proofOfProfessionChange?.length ? t('yes') : t('no'),
                personalUsage: data.personalUsage ? t('personal-usage') : t('proffesional-usage'),
                authorizationChanged: data.professional.authorizationChanged ? t('yes') : t('no'),
            },
        };

        const filesArr = [
            ...(data.proofOfAddressChange || []),
            ...(data.proofOfIdentityChange || []),
            ...(data.proofOfProfessionChange || []),
            ...(data.proofOfAuthChangeId || []),
            ...(data.proofOfAuthChangeSign || []),
            ...(data.proofOfActivityChange || []),
        ].filter((f) => f !== undefined);
        const resultFiles: Array<Base64File> = await Promise.all(
            filesArr.map(async (value: File | undefined) => await mapFileToBase64([value as File])),
        );
        const body: { content: Content; files: Base64File[]; mailOptions?: Options } = {
            content: { subject: 'KYC', template: { plain: 'Content test' }, data: template },
            files: resultFiles,
        };

        CpDataApi.post(getMailSendingEndpoint(), body)
            .then(() => {
                setIsSubmitting(false);
                setIsSuccess(true);
            })
            .catch(() => {
                setIsSubmitting(false);
                setIsError(true);
                setIsSuccess(false);
            });
    };

    const tryAgain = () => {
        if (data) {
            handleSubmit(data);
        }
    };

    return (
        <>
            {isSubmiting && <Spinner fullPage={true} />}
            <Modal
                shown={isError}
                status="error"
                onClose={handleModalClose}
                onCancel={tryAgain}
                onClickOutside={handleModalClose}
                buttonCancelText={t('try-again')}
                testId={'error-modal'}
                title={t('error-title')}
            >
                {t('error-text')}
            </Modal>
            <Modal
                shown={isSuccess}
                status="success"
                title={t('success-title')}
                onClose={onSuccessConfirm}
                onConfirm={onSuccessConfirm}
                onClickOutside={onSuccessConfirm}
                buttonConfirmText={t('dashboard')}
                testId={'success-modal'}
            >
                {t('success-text')}
            </Modal>
            <ConfirmInformationWithHandlers
                isLoading={isLoading || isLoadingContracts}
                profileData={profile}
                initPrefix={'33'}
                handleSubmit={handleSubmit}
                onCancel={() => history.push(myProfilePagePath())}
            />
        </>
    );
};
