import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type DocumentFilters = {
    documentTypeFilter: string;
    timePeriodFilter: string;
    contractFilter: string;
    cleared: boolean;
};

const initialState: DocumentFilters = {
    documentTypeFilter: 'all',
    timePeriodFilter: 'last3Months',
    contractFilter: 'all',
    cleared: false,
};

const documentFiltersSlice = createSlice({
    name: 'documentFiltersSlice',
    initialState: initialState,
    reducers: {
        setDocumentTypeFilter(state, action: PayloadAction<string>) {
            state.documentTypeFilter = action.payload;
            state.cleared = false;
        },
        setTimePeriodFilter(state, action: PayloadAction<string>) {
            state.timePeriodFilter = action.payload;
            state.cleared = false;
        },
        setIdDocumentFilter(state, action: PayloadAction<string>) {
            state.contractFilter = action.payload;
            state.cleared = false;
        },
        clearFilters(state) {
            state.documentTypeFilter = 'Tous';
            state.timePeriodFilter = 'all';
            state.contractFilter = 'all';
            state.cleared = true;
        },
    },
});

export const documentFiltersStateReducer = documentFiltersSlice.reducer;
export const documentFiltersStateActions = documentFiltersSlice.actions;
