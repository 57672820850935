import { RequestOtherValidatorErrors } from '../../types';

export const RequestOtherError: RequestOtherValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'value must be an email',
    },
    phoneNumber: {
        required: 'phoneNumber is required',
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    subjectOfClaim: {
        required: 'subjectOfClaim is required',
        maxLength: 'subjectOfClaim must have max 40 characters',
    },
    message: {
        required: 'message is required',
        maxLength: 'message must have max 1000 characters',
    },
};
