import { Faq, MyProfileData } from '@cp-fr/common';
import { FaqCMS, TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { FaqLoadingPlaceholder, HeroImage } from '@cp-shared-8/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile';
import React from 'react';
import { FaqUi } from '../ui';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';
import { ContentSection } from '@vwfs-bronson/bronson-react';

type FaqPrivateProps = {
    faq?: Faq;
    myProfile?: MyProfileData;
};

export const FaqPrivate: React.FC<FaqPrivateProps> = ({ faq, myProfile }) => {
    const { data: myProfileData, isLoading, loadingError } = useMyProfile();
    const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

    if (!faq?.content) return null;

    const isSMECustomer = myProfileData?.identification?.customerType
        ? myProfileData?.identification?.customerType !== 'Retail'
        : false;

    const faqContent: FaqCMS = isSMECustomer ? faq.content.privateSme : faq.content.privateRetail;

    const mappedFaqContentItems =
        faqContent.itemGroups?.map(({ category, items }) => ({
            header: category,
            items,
        })) || [];

    return (
        <>
            {faq.teaser && (
                <>
                    <HeroImage
                        title={faq.teaser.title}
                        imageUrl={faq.teaser.imageUrl}
                        shallow={true}
                        inverted={faq.teaser.isInverted}
                    />
                    <ContentSection pageWrapSize="medium">
                        <>
                            <h2>{faqContent.header}</h2>
                            <div>
                                <p>{faqContent.descriptionText}</p>
                            </div>
                        </>
                    </ContentSection>
                </>
            )}
            {mappedFaqContentItems.map((faqSection, idx) => (
                <FaqWithHandlers
                    key={`FAQ-section-${idx}`}
                    isLoading={isLoading}
                    faqContent={faqSection}
                    hasError={!!loadingError}
                    loadingPlaceholder={<FaqLoadingPlaceholder />}
                />
            ))}
            <ContentSection pageWrapSize="medium">
                <CustomerHappinessIndex
                    firstName={myProfile?.identification?.data?.name}
                    lastName={myProfile?.identification?.data?.surname}
                    email={myProfile?.contactDetails?.email}
                    customType={TrustedShopsIntegrationCustomType.CUSTOMERPORTAL_FAQ}
                />
            </ContentSection>
        </>
    );
};
