import { RequestCustomerStatus, getAddressStatusEndpoint } from '@cp-fr/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    RequestCustomerStatus,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'AddressChangeStatus',
    fetchCallback() {
        return CpDataApi.get<RequestCustomerStatus>(getAddressStatusEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchAddressChangeStatus = fetchData;
export const updateAddressChangeStatus = updateData;
