import { RequestRegistrationCertificateDuplicateValidatorErrors } from '../../types';

export const RequestRegistrationCertificateDuplicateError: RequestRegistrationCertificateDuplicateValidatorErrors = {
    email: {
        required: 'email is required',
        mustBeEmail: 'email must be an email',
    },
    phoneNumber: {
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    requestReason: {
        required: 'requestReason is required',
    },
    files: {
        id: {
            required: 'id file is required',
            maxLength: 'id must contain 1 file',
        },
        proofOfAddress: {
            required: 'proofOfAddress file is required',
            maxLength: 'proofOfAddress must contain 1 file',
        },
        registrationCertificate: {
            maxLength: 'registrationCertificate must contain 1 file',
        },
        lossDeclaration: {
            maxLength: 'lossDeclaration must contain 1 file',
        },
        theftDeclaration: {
            maxLength: 'theftDeclaration must contain 1 file',
        },
    },
};
