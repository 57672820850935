import { preventSubmit } from '@cp-shared-8/frontend-ui';
import { Button, ButtonContainer, Checkbox, Fieldset, Form, FormHeading, Paragraph } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { customerDataValidation } from './validationSchema';
import { MyProfileData } from '@cp-fr/common';
import { AddressFields } from './AddressFields';
import { ContactFields } from './ContactFields';
import { ProfessionalsFields } from './ProfessionalsFields';
import IdentityFields from './IdentityFields';
import { confirmInformationInitialValues, CustomerDataFormFields, getFormCustomerType } from './utils';

export type CustomerDataFormProps = {
    profileData?: MyProfileData;
    initPrefix?: string;
    handleSubmit: (formValues: CustomerDataFormFields) => void;
    onCancel?: () => void;
};

export const CustomerDataForm: React.FC<CustomerDataFormProps> = ({
    profileData,
    initPrefix,
    handleSubmit,
    onCancel,
}) => {
    const { t } = useTranslation('confirm-information');
    const { t: tp } = useTranslation('my-profile');
    const [requiredFiles, setRequiredFiles] = useState([false, false, false, false, false]);
    const customerDataValues: CustomerDataFormFields = confirmInformationInitialValues(initPrefix || '33', profileData);
    return (
        <Formik
            initialValues={customerDataValues}
            validationSchema={customerDataValidation(
                tp,
                requiredFiles,
                getFormCustomerType(profileData?.identification?.customerType || ''),
            )}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <Form onSubmit={(e) => preventSubmit(e)} data-testid="edit-form">
                    <FormHeading>{t('personal-details')}</FormHeading>
                    <IdentityFields setRequiredFiles={setRequiredFiles} />
                    <FormHeading>{t('postal-address')}</FormHeading>
                    <Paragraph>{t('address-intro')}</Paragraph>
                    <AddressFields setRequiredFiles={setRequiredFiles} />
                    <FormHeading>{t('contact-details-title')}</FormHeading>
                    <ContactFields />
                    <FormHeading>{t('professional-details')}</FormHeading>
                    <ProfessionalsFields setRequiredFiles={setRequiredFiles} />
                    <Fieldset>
                        <Fieldset.Row>
                            <Checkbox onChange={(e) => formik.setFieldValue('confirmation', e.currentTarget.checked)}>
                                {t('confirmation-label')}
                            </Checkbox>
                        </Fieldset.Row>
                        <Fieldset.Row>
                            <ButtonContainer center>
                                <Button
                                    secondary
                                    onClick={() => {
                                        onCancel && onCancel();
                                    }}
                                    testId="back-button"
                                    type="button"
                                >
                                    {t('my-profile:contact-details.edit-view.back-button')}
                                </Button>
                                <Button
                                    disabled={!formik.values.confirmation}
                                    testId="next-button"
                                    type="submit"
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                >
                                    {t('my-profile:contact-details.edit-view.submit-button')}
                                </Button>
                            </ButtonContainer>
                        </Fieldset.Row>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
