import * as Yup from 'yup';
import { RequestVehicleClaimValidatorErrors } from '../../types';
import { RequestVehicleClaimError } from '../../messages';
import {
    RequestCategory,
    YesNoSelections,
    OriginalRegDocument,
    ContractSelection,
} from '../../../apis/services/types/request';
import { fileValidation } from '../FileValidator';
import { ProductTypeEN } from '../../../apis/services/types/contracts';

export type RequestVehicleClaimFormType = {
    reparable: string;
    nonReparableFields: RequestVehicleClaimNonReparableFields;
    files: {
        flightReport: string;
        invoice: string;
        expertReport: string;
        paymentDetails: string;
        transferProposal: string;
    };
};

export type RequestVehicleClaimNonReparableFields = {
    email: string;
    phonenumber: string;
    natureOfDamage: string;
    dateOfDamage: string;
    circumstancesOfTheDisaster: string;
    causeOfSinistre: string[];
    nameOfInsurer: string;
    addressOfInsurer: string;
    postcodeOfInsurer: string;
    cityOfInsurer: string;
    insuranceCustomerNumber: string;
    damageNumber: string;
    phonenumberOfInsurer: string;
    emailOfInsurer: string;
    originalRegDocument: OriginalRegDocument;
    agreeToSend: string;
    registrationFile: string;
};

export const requestVehicleClaimNonReparableFieldsSchema = (
    productTypeEN: ProductTypeEN | undefined,
    errors: RequestVehicleClaimValidatorErrors = RequestVehicleClaimError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<{}> => {
    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(errors.nonReparableFields.email.required)
            .email(errors.nonReparableFields.email.mustBeEmail),
        phonenumber: Yup.string().trim().min(10, errors.nonReparableFields.phoneNumber.mustBePhoneNumber),
        natureOfDamage: Yup.string().trim().required(errors.nonReparableFields.natureOfDamage.required),
        dateOfDamage: Yup.string().required(errors.nonReparableFields.dateOfDamage.required),
        circumstancesOfTheDisaster: Yup.string().required(
            errors.nonReparableFields.circumstancesOfTheDisaster.required,
        ),
        causeOfSinistre: Yup.array(),
        nameOfInsurer: Yup.string().trim().required(errors.nonReparableFields.nameOfInsurer.required),
        addressOfInsurer: Yup.string().trim().required(errors.nonReparableFields.addressOfInsurer.required),
        postcodeOfInsurer: Yup.string()
            .required(errors.nonReparableFields.postcodeOfInsurer.required)
            .min(5, errors.nonReparableFields.postcodeOfInsurer.minLength),
        cityOfInsurer: Yup.string().trim().required(errors.nonReparableFields.cityOfInsurer.required),
        insuranceCustomerNumber: Yup.string()
            .trim()
            .required(errors.nonReparableFields.insuranceCustomerNumber.required),
        damageNumber: Yup.string().trim().required(errors.nonReparableFields.damageNumber.required),
        phonenumberOfInsurer: Yup.string()
            .trim()
            .required(errors.nonReparableFields.phonenumberOfInsurer.required)
            .min(10, errors.nonReparableFields.phonenumberOfInsurer.mustBePhoneNumber),
        emailOfInsurer: Yup.string()
            .trim()
            .required(errors.nonReparableFields.emailOfInsurer.required)
            .email(errors.nonReparableFields.emailOfInsurer.mustBeEmail),
        originalRegDocument: Yup.string()
            .trim()
            .when('_', {
                is: () =>
                    productTypeEN === ProductTypeEN.BALLON_LEASING ||
                    productTypeEN === ProductTypeEN.OPERATIVE_LEASING ||
                    productTypeEN === ProductTypeEN.VDL ||
                    productTypeEN === ProductTypeEN.FINANCIAL_LEASE,
                then: Yup.string().trim().required(errors.nonReparableFields.originalRegDocument.required),
            }),
        agreeToSend: Yup.bool().when('originalRegDocument', {
            is: (originalRegDocument: OriginalRegDocument) =>
                originalRegDocument === YesNoSelections.YES &&
                (productTypeEN === ProductTypeEN.BALLON_LEASING ||
                    productTypeEN === ProductTypeEN.OPERATIVE_LEASING ||
                    productTypeEN === ProductTypeEN.VDL ||
                    productTypeEN === ProductTypeEN.FINANCIAL_LEASE),
            then: Yup.bool().oneOf([true], errors.nonReparableFields.agreeToSend.required),
        }),
        registrationFile: Yup.array().when('originalRegDocument', {
            is: (value: OriginalRegDocument) => value === YesNoSelections.NO && productTypeEN === ProductTypeEN.CREDIT,
            then: fileValidation(
                errors.nonReparableFields.registrationFile.maxLength,
                errors.nonReparableFields.registrationFile.required,
            ),
        }),
    });
};

export const requestVehicleClaimCoreSchema = (
    errors: RequestVehicleClaimValidatorErrors = RequestVehicleClaimError,
): Yup.SchemaOf<{ reparable: string }> => {
    return Yup.object().shape({
        reparable: Yup.string().trim().required(errors.reparable.required),
    });
};

export const requestVehicleClaimIntegrationSchema = (
    productTypeEN: ProductTypeEN | undefined,
    nonReparableFields = false,
    errors: RequestVehicleClaimValidatorErrors = RequestVehicleClaimError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<{}> => {
    const reparableFields = requestVehicleClaimCoreSchema(errors).shape({
        files: Yup.array().of(
            Yup.object().shape({
                base64: Yup.string(),
                filename: Yup.string(),
                mimeType: Yup.string(),
                size: Yup.number(),
            }),
        ),
    });

    if (nonReparableFields) {
        reparableFields.shape({
            ...requestVehicleClaimNonReparableFieldsSchema(productTypeEN, errors).fields,
        });
    }
    return reparableFields;
};

export const requestVehicleClaimFrontendValidationSchema = (
    productTypeEN: ProductTypeEN | undefined,
    errors: RequestVehicleClaimValidatorErrors = RequestVehicleClaimError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestVehicleClaimFormType | {}> => {
    return requestVehicleClaimCoreSchema(errors).shape({
        nonReparableFields: requestVehicleClaimNonReparableFieldsSchema(productTypeEN, errors).shape({
            files: Yup.object().when('_', {
                is: () => productTypeEN === ProductTypeEN.CREDIT,
                then: Yup.object().shape({
                    invoice: fileValidation(
                        errors.nonReparableFields.files.invoice.maxLength,
                        errors.nonReparableFields.files.invoice.required,
                    ),
                    expertReport: fileValidation(
                        errors.nonReparableFields.files.expertReport.maxLength,
                        errors.nonReparableFields.files.expertReport.required,
                    ),
                    paymentDetails: fileValidation(errors.nonReparableFields.files.paymentDetails.maxLength),
                }),
            }),
        }),
    });
};

export const requestVehicleClaimValidationSchema = (
    field: string[],
    errors: RequestVehicleClaimValidatorErrors = RequestVehicleClaimError,
    // eslint-disable-next-line @typescript-eslint/ban-types
): Yup.SchemaOf<RequestVehicleClaimFormType | {}> => {
    let productTypeEN: ProductTypeEN | undefined;

    return Yup.object().when(field, {
        is: (requestCategory: RequestCategory, contractsSelection: ContractSelection[]) => {
            if (Array.isArray(contractsSelection) && contractsSelection.length === 1) {
                productTypeEN = contractsSelection[0].productTypeEN;
            } else {
                productTypeEN = ProductTypeEN.OTHER;
            }
            return requestCategory === 'VEHICLE_CLAIM';
        },
        then: Yup.lazy(() => requestVehicleClaimFrontendValidationSchema(productTypeEN, errors)),
    });
};
