export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractDetailsEndpoint(contractId: string): string {
    return `${getContractsEndpoint()}/${contractId}`;
}

export function getChangeIbanEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/iban`;
}

export function getCheckChangeIbanEndpoint(requestId: string): string {
    return `/check-iban/${requestId}`;
}

export function getChangePaymentDayEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/payment-day`;
}

export function getEndOptionEndpoint(contractId: string): string {
    return `${getContractDetailsEndpoint(contractId)}/end-option`;
}
