import { Address, Contract, MyProfileData, ProductTypeEN } from '@cp-fr/common';
import { isCustomerType } from '@cp-fr/common/src/apis/services/types/contracts';
import React from 'react';
import { NoConnectionNotification } from '../../notifications/no-connection/NoConnectionNotification';
import { AddressSection, isEmptyAddress } from '../address-section/AddressSection';
import { ContactDetailsCheckPendingNotification } from '../address-section/contact-details-change-pending-notification/ContactDetailsCheckPendingNotification';
import { ContactSection } from '../contact-section';
import { isEmptyContact } from '../contact-section/consult-view/ConsultView';
import { IdentificationSection, isEmptyIdentification } from '../identification-section';
import { MarketingSettingsSection } from '../marketing-settings-section/MarketingSettingsSection';
import { TrustedShopsIntegrationCustomType } from '@cp-shared-8/apis';
import { CustomerHappinessIndex } from '../../customer-happiness-index/CustomerHappinessIndex';

export const MyProfileUi: React.FC<{
    myProfileData?: MyProfileData;
    contracts?: Contract[];
    isContactInformationChangePending: boolean;
    isNameChangePending: boolean;
    isAddressChangePending: boolean;
}> = ({ myProfileData, contracts, isContactInformationChangePending, isNameChangePending, isAddressChangePending }) => {
    if (!myProfileData) {
        return null;
    }

    const { identification, contactDetails, address, billingAddress, marketingSettings } = myProfileData;

    if (isEmptyIdentification(identification) && isEmptyContact(contactDetails) && isEmptyAddress(address))
        return <NoConnectionNotification />;

    const customerType: isCustomerType = {
        isPrivateCustomer: identification?.customerType === 'Retail',
        isPMCustomer: identification?.customerType === 'PM',
    };

    const getBillingAddress = (): Address | undefined => {
        const isBillingAddressEqualToPostal = JSON.stringify(billingAddress) === JSON.stringify(address);
        return customerType.isPrivateCustomer || isBillingAddressEqualToPostal ? {} : billingAddress;
    };

    const hasBallonContracts = (): boolean => {
        const ballonContracts = contracts
            ? contracts.filter(
                  (contract) => contract.isActive && contract.productTypeEN === ProductTypeEN.BALLON_LEASING,
              )
            : [];
        return ballonContracts.length > 0;
    };

    return (
        <>
            <div className={'u-mb'}>
                {isContactInformationChangePending && <ContactDetailsCheckPendingNotification />}
                <IdentificationSection
                    identification={identification}
                    isNameChangePending={isNameChangePending}
                    isContactInformationChangePending={isContactInformationChangePending}
                />
                <AddressSection
                    address={address}
                    billingAddress={getBillingAddress()}
                    customerType={customerType}
                    hasBallonContracts={hasBallonContracts()}
                    isAddressChangePending={isAddressChangePending}
                    isContactInformationChangePending={isContactInformationChangePending}
                    isEligibleNpaiNotification={identification?.data.isEligibleNpaiNotification}
                />
                <ContactSection
                    contactDetails={contactDetails}
                    isContactInformationChangePending={isContactInformationChangePending}
                />
                <MarketingSettingsSection marketingSettings={marketingSettings} />
            </div>
            <div className={'u-mb'}>
                <CustomerHappinessIndex
                    firstName={myProfileData?.identification?.data?.name}
                    lastName={myProfileData?.identification?.data?.surname}
                    email={myProfileData?.contactDetails?.email}
                    customType={TrustedShopsIntegrationCustomType.CUSTOMERPORTAL_PROFILE}
                />
            </div>
        </>
    );
};
