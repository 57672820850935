import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { PartialEarlySettlementUi } from './ui';

export const PartialEarlySettlement: React.FC<{ contractId: string }> = ({ contractId }) => {
    const { data: contract, isLoading, loadingError } = useContract(contractId);

    const PartialEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(PartialEarlySettlementUi);

    return <PartialEarlySettlementWithHandlers isLoading={isLoading} contract={contract} hasError={!!loadingError} />;
};
