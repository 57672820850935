import axios, { AxiosInstance } from 'axios';

import { currentBrand, integrationApiBaseUrl } from '../config';

export const CpDataApi: AxiosInstance = axios.create({
    baseURL: integrationApiBaseUrl,
});

export const CpContentApi: AxiosInstance = axios.create({
    baseURL: '/contents',
    headers: {
        'X-CP-Brand': currentBrand,
    },
});
