import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails } from '@cp-fr/common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const ContactList: React.FC<{ contactDetails: ContactDetails }> = ({ contactDetails }) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-details.consult-view';
    const { mobilePhone, otherPhone, email } = contactDetails;

    const contactList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.email.label`),
            value: email || '-',
            testId: 'contact-details-email',
        },
        {
            label: t(`${translationPrefix}.mobile-phone.label`),
            value: mobilePhone || '-',
            testId: 'contact-details-mobile-phone',
        },
        {
            label: t(`${translationPrefix}.other-phone.label`),
            value: otherPhone || '-',
            testId: 'contact-details-other-phone',
        },
    ];

    return <DefinitionList split={true} list={contactList} />;
};
