import { ContentSection } from '@vwfs-bronson/bronson-react';
import { TotalEarlySettlement } from 'components/early-settlement/total-early-settlement/TotalEarlySettlement';
import React from 'react';
import { useParams } from 'react-router-dom';

export const TotalEarlySettlementPage: React.FC = () => {
    const { contractId } = useParams<{ contractId: string }>();
    return (
        <ContentSection pageWrapSize="medium">
            <TotalEarlySettlement contractId={contractId} />
        </ContentSection>
    );
};
