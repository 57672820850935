import React from 'react';
import { CleaveInput } from '@cp-shared-8/frontend-ui';

type YearInputProps = {
    label: string;
    name: string;
    isMandatory?: boolean;
};

export const YearInput: React.FC<YearInputProps> = ({ label, name, isMandatory }) => {
    return (
        <CleaveInput
            cleaveOptions={{
                delimiter: '',
                blocks: [4],
                numericOnly: true,
            }}
            inputMode={'numeric'}
            label={isMandatory ? `${label} *` : label}
            name={name}
            testId={`test-${name}`}
        />
    );
};
