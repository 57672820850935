import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ContractEndOptions } from 'components/contracts/contract-end-options';
import { useParams } from 'react-router-dom';

export const ContractEndOptionsPage: React.FC = () => {
    const { t } = useTranslation('contract-end-options');
    const { contractId } = useParams<{ contractId: string }>();

    const title = t('headline');

    return (
        <ContentSection pageWrapSize="medium">
            <Heading level={1}>{title}</Heading>
            <ContractEndOptions contractId={contractId} />
        </ContentSection>
    );
};
