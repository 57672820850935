import { ContentSection } from '@vwfs-bronson/bronson-react';
import { JointEntryPoint } from 'components/early-settlement/joint-entry-point';
import React from 'react';
import { useParams } from 'react-router-dom';

export const EarlySettlementPage: React.FC = () => {
    const { contractId } = useParams<{ contractId: string }>();
    return (
        <ContentSection pageWrapSize="medium">
            <JointEntryPoint contractId={contractId} />
        </ContentSection>
    );
};
