import { CleaveInput, UiBlockingSpinner } from '@cp-shared-8/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useEffect, useState } from 'react';
import { IbanStateHandler } from '../types';
import { FormikProps, useFormikContext } from 'formik';
import { InitialValues } from '../InitialValues';

export type ValidatedIbanInputProps = {
    isValidating: boolean;
    ibanStateHandler: IbanStateHandler;
};

const translationPrefix = 'iban-section.edit.iban';
const cleaveOptionsIban = {
    delimiter: ' ',
    blocks: [4, 4, 4, 4, 4, 4, 4, 4, 4],
    numericOnly: false,
};

export const ValidatedIbanInput: React.FC<ValidatedIbanInputProps> = ({ isValidating, ibanStateHandler }) => {
    const { t } = useTranslationWithFormatting('financial-details');
    const { signedBankData } = ibanStateHandler;
    const { setFieldTouched }: FormikProps<InitialValues> = useFormikContext();
    const [shouldValidate, setShouldValidate] = useState<boolean>(true);

    useEffect(() => {
        if (ibanStateHandler.savedIban.iban && shouldValidate) {
            setFieldTouched('iban', true, shouldValidate);
            setShouldValidate(false);
        }
    }, [shouldValidate, setFieldTouched, ibanStateHandler.savedIban]);

    return (
        <UiBlockingSpinner isBlocking={isValidating}>
            <CleaveInput
                cleaveOptions={cleaveOptionsIban}
                label={t(`${translationPrefix}.label`)}
                name="iban"
                testId="iban"
                stateIcon
                tooltip={t(`${translationPrefix}.tooltip`)}
            />
            {signedBankData?.data.isValid && (
                <p className="u-text-success">{signedBankData.data.bankDetails?.bankName}</p>
            )}
        </UiBlockingSpinner>
    );
};
