import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailInput, PhonenumberInput, TextInput } from '../../simple-form-components';
import { RestrictedNumericSelection } from '../../simple-form-components/restricted-numeric-selection/RestrictedNumericSelection';

export const ChangeDurationMileageForm: React.FC = () => {
    const { t } = useTranslation('request');
    const translationPrefix = 'category-selection.change-duration-mileage';

    const translate = (key: string): string => {
        return t(`${translationPrefix}.${key}`);
    };

    return (
        <>
            <Layout.Item>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate('description'),
                    }}
                />
            </Layout.Item>
            <Layout.Item>
                <EmailInput label={translate('email')} name={'changeDurationMileage.email'} />
            </Layout.Item>
            <Layout.Item>
                <PhonenumberInput
                    label={translate('phonenumber')}
                    name={'changeDurationMileage.phonenumber'}
                    isMandatory={false}
                />
            </Layout.Item>
            <Layout.Item>
                <RestrictedNumericSelection
                    label={translate('desired-duration.description')}
                    name={'changeDurationMileage.totalDuration'}
                    min={12}
                    max={60}
                    emptyByDefault={true}
                />
            </Layout.Item>
            <Layout.Item>
                <RestrictedNumericSelection
                    label={translate('desired-mileage.description')}
                    name={'changeDurationMileage.desiredMileage'}
                    min={10000}
                    max={300000}
                    step={5000}
                    hint={translate('desired-mileage.hint')}
                    placeholder={translate('desired-mileage.placeholder')}
                    tooltip={translate('desired-mileage.tooltip')}
                    emptyByDefault={true}
                />
            </Layout.Item>
            <Layout.Item>
                <RestrictedNumericSelection
                    label={translate('desired-tires-summer')}
                    name={'changeDurationMileage.desiredTiresSummer'}
                    tooltip={translate('desired-tires-tooltip')}
                    min={2}
                    max={8}
                    step={2}
                    emptyByDefault={true}
                />
            </Layout.Item>
            <Layout.Item>
                <RestrictedNumericSelection
                    label={translate('desired-tires-winter')}
                    name={'changeDurationMileage.desiredTiresWinter'}
                    tooltip={translate('desired-tires-tooltip')}
                    min={2}
                    max={8}
                    step={2}
                    emptyByDefault={true}
                />
            </Layout.Item>
            <Layout.Item>
                <TextInput label={translate('comment')} name={'changeDurationMileage.comment'} isMandatory={false} />
            </Layout.Item>
        </>
    );
};
