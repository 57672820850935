import { formatAsFileSize, SmeCustomerData } from '@cp-fr/common';
import { FileUpload, ValidatedInput } from '@cp-shared-8/frontend-ui';
import {
    Fieldset,
    FormFieldLabel,
    Layout,
    DatePicker,
    ErrorMessage,
    RadioButtonGroup,
    RadioButton,
} from '@vwfs-bronson/bronson-react';
import React, { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps, useFormikContext } from 'formik';
import { maxFileSize } from 'config';
import { CustomerType, getFormCustomerType, CustomerDataFormFields } from './utils';

export enum EditStatus {
    NOT_PERFORMED = 'NOT_PERFORMED',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    ERROR_VIRUS = 'ERROR_VIRUS',
    NO_CHANGE_DETECTED = 'NO_CHANGE_DETECTED',
    WRONG_REASON = 'WRONG_REASON',
    SIREN_IS_MANDATORY_FOR_SME = 'SIREN_IS_MANDATORY_FOR_SME',
    ERROR_NO_EMAIL = 'USER_WITH_NO_EMAIL',
}

export const IdentityFields: React.FC<{ setRequiredFiles: Dispatch<React.SetStateAction<boolean[]>> }> = ({
    setRequiredFiles,
}) => {
    const { t } = useTranslation(['my-profile', 'confirm-information']);
    const formik: FormikProps<CustomerDataFormFields> = useFormikContext();
    const [isDirty, setIsDirty] = useState(false);
    const [initial] = useState(formik.initialValues.identification.data);
    const fileUploadTranslationPrefix = 'identification.edit-view.file-upload';
    const [customerType, setCustomerType] = useState<CustomerType>('');

    useEffect(() => {
        setCustomerType(getFormCustomerType(formik.initialValues.identification.customerType));
        const dirty =
            initial &&
            (formik.values.identification.data.name !== initial.name ||
                formik.values.identification.data.surname !== initial.surname ||
                (formik.values.identification.data as SmeCustomerData).companyName !==
                    (initial as SmeCustomerData).companyName);
        setRequiredFiles((files) => {
            const updated = [...files];
            updated[0] = dirty;
            return updated;
        });
        setIsDirty(dirty);
    }, [initial, formik.values]);

    return (
        <>
            <Fieldset>
                {customerType === 'SME' && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/2" s="1/1">
                                <FormFieldLabel>{t('confirm-information:vehicle-usage')}</FormFieldLabel>
                                <RadioButtonGroup>
                                    <RadioButton
                                        name="personalUsage"
                                        checked={formik.values.personalUsage}
                                        onChange={() => formik.setFieldValue('personalUsage', true)}
                                        error={!!formik.errors.personalUsage}
                                    >
                                        {t('confirm-information:personal-usage')}
                                    </RadioButton>
                                    <RadioButton
                                        name="personalUsage"
                                        checked={formik.values.personalUsage === false}
                                        onChange={() => formik.setFieldValue('personalUsage', false)}
                                        error={!!formik.errors.personalUsage}
                                    >
                                        {t('confirm-information:proffesional-usage')}
                                    </RadioButton>
                                </RadioButtonGroup>
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
                {customerType === 'PM' ? (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/1" s="1/1">
                                <ValidatedInput
                                    label={t('confirm-information:company-name')}
                                    name="identification.data.companyName"
                                    testId="name"
                                    type="text"
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                ) : (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item default="1/2" s="1/1">
                                <ValidatedInput
                                    label={t('confirm-information:first-name')}
                                    name="identification.data.name"
                                    testId="name"
                                    type="text"
                                />
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/1">
                                <ValidatedInput
                                    label={t('confirm-information:last-name')}
                                    name="identification.data.surname"
                                    testId="surname"
                                    type="text"
                                />
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
                {customerType === 'Retail' && (
                    <Fieldset.Row>
                        <Layout>
                            <Layout.Item key="birth-date" default="1/2" s="1/1">
                                <FormFieldLabel>{t('confirm-information:birth-date')}</FormFieldLabel>
                                <DatePicker
                                    name="birthDate"
                                    dateFormat="d.m.Y"
                                    disabled={!!initial.birthDate}
                                    error={!!formik.errors.identification?.data?.birthDate}
                                    value={formik.values.identification.data.birthDate}
                                    onChange={(e) => formik.setFieldValue('identification.data.birthDate', e)}
                                />
                                {!!formik.errors.identification?.data?.birthDate && (
                                    <ErrorMessage>{formik.errors.identification?.data?.birthDate}</ErrorMessage>
                                )}
                            </Layout.Item>
                        </Layout>
                    </Fieldset.Row>
                )}
            </Fieldset>
            {isDirty && (
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item>
                            <FileUpload
                                name={'proofOfIdentityChange'}
                                descriptionText={t(
                                    `${fileUploadTranslationPrefix}.${
                                        customerType === 'Retail'
                                            ? 'proof-of-identity-change-risky'
                                            : customerType === 'PM'
                                            ? 'evidence-for-change-m'
                                            : formik.values.personalUsage
                                            ? 'proof-of-identity-change-risky'
                                            : 'evidence-for-change-m'
                                    }`,
                                )}
                                descriptionSupplementaryText={<></>}
                                buttonText={t(`${fileUploadTranslationPrefix}.button`)}
                                cancelLabel={t('translation:editable-section-nav.cancel')}
                                fileSizeFormatter={(bytes: number): string => formatAsFileSize(bytes, 2)}
                                maxFileSize={maxFileSize}
                                multiple={false}
                                validFileFormats={['pdf']}
                                sizeError={t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                typeError={t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                sizeAndTypeError={
                                    <>
                                        {t(`${fileUploadTranslationPrefix}.validation.wrong-size`)}
                                        <br />
                                        {t(`${fileUploadTranslationPrefix}.validation.wrong-type`)}
                                    </>
                                }
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            )}
        </>
    );
};

export default IdentityFields;
