import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-8/frontend-ui';
import { IconFooter } from 'components/footer/icon-footer';
import { LegalFooter } from 'components/footer/legal-footer';
import { NavigationBar } from 'components/navigation/NavigationBar';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { registrationPagePath } from '../../components/navigation/paths';
import { currentBrand } from 'config/config';
import { useMyProfile } from 'components/my-profile';

// eslint-disable-next-line @typescript-eslint/ban-types
export type MasterPageProps = {};

export const MasterPage: React.FC<MasterPageProps> = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};

const MasterPageComponent: React.FC<{ isAuthenticated: boolean }> = memo(({ isAuthenticated, children }) => {
    const { t } = useTranslation();
    const { data } = useMyProfile();
    const customerType = data?.identification?.customerType;
    const userGroup = isAuthenticated && !!customerType ? customerType : undefined;

    return (
        <AnalyticsProvider
            market={'fr'}
            isAuthenticated={isAuthenticated}
            version={'1'}
            releaseDate={'2020-12-01'}
            language={'fr'}
            userGroup={userGroup}
            brand={currentBrand}
            registrationPath={registrationPagePath()}
            loginPath={'/authentication/callback'}
        >
            <MasterPageShared
                navigationBar={<NavigationBar />}
                iconFooter={<IconFooter />}
                legalFooter={<LegalFooter />}
                label={t('back-to-top')}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});

MasterPageComponent.displayName = 'MasterPageComponent';
