import { RequestFormDeathValidatorErrors } from '../../types';

export const RequestFormDeathError: RequestFormDeathValidatorErrors = {
    dateOfDeath: {
        required: 'dateOfDeath is required',
    },
    nameOfNotary: {
        maxLength: 'nameOfNotary must have max 40 characters',
    },
    addressOfNotary: {},
    phonenumberOfNotary: {
        mustBePhoneNumber: 'phoneNumberOfNotary must have 10 characters',
    },
    emailOfNotary: {
        mustBeEmail: 'value must be an email',
    },
    civilityOfBeneficiary: {
        required: 'civilityOfBeneficiary is required',
    },
    firstNameOfBeneficiary: {
        required: 'firstNameOfBeneficiary is required',
        maxLength: 'firstNameOfBeneficiary must have max 40 characters',
    },
    nameOfBeneficiary: {
        required: 'nameOfBeneficiary is required',
        maxLength: 'nameOfBeneficiary must have max 40 characters',
    },
    addressOfBeneficiary: {
        required: 'addressOfBeneficiary is required',
    },
    phonenumberOfBeneficiary: {
        required: 'phonenumberOfBeneficiary is required',
        mustBePhoneNumber: 'phoneNumber must have 10 characters',
    },
    emailOfBeneficiary: {
        required: 'emailOfBeneficiary is required',
        mustBeEmail: 'value must be an email',
    },
    intentions: {
        required: 'intentions is required',
    },
    files: {
        deathCertificate: {
            required: 'deathCertificate file is required',
            maxLength: 'deathCertificate must contain 1 file',
        },
        deedOfInheritance: {
            required: 'deathCertificate file is required',
            maxLength: 'deedOfInheritance must contain 1 file',
        },
    },
};
