/* eslint-disable */
import React from 'react';
import { ContentSection, Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { MyDocuments } from 'components/my-documents';

export const MyDocumentsPage: React.FC = () => {

    return (
        <ContentSection pageWrapSize="medium">
            <MyDocuments />
        </ContentSection>
    );
};
