import React from 'react';
import { getDisplayName } from '@cp-shared-8/frontend-ui';
import { withLoadingHandler, withLoadingHandlerProps } from './withLoadingHandler';
import { withNoConnectionHandler, withNoConnectionHandlerProps } from './withNoConnectionHandler';

type combinedProps<TProps> = TProps & withLoadingHandlerProps & withNoConnectionHandlerProps;

// eslint-disable-next-line @typescript-eslint/ban-types
export const withLoadingAndNoConnectionHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<combinedProps<TProps>> => {
    const ComponentWithHocs = withLoadingHandler(withNoConnectionHandler(WrappedComponent));
    const Wrapper = (props: combinedProps<TProps>) => {
        return <ComponentWithHocs {...props} />;
    };

    Wrapper.displayName = `withLoadingAndNoConnectionHandler(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
