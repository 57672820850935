import React, { useState } from 'react';
import {
    Button,
    ButtonContainer,
    Card,
    Icon,
    Layout,
    Modal,
    Paragraph,
    TileSelect,
    TileSelectGroup,
} from '@vwfs-bronson/bronson-react';
import {
    Contract,
    ContractDetails,
    ContractEndOptionsDetails,
    ContractEndOptionsEN,
    getEndOptionEndpoint,
    MarketError,
    MyProfileData,
} from '@cp-fr/common';
import { ContractHeader, Notification, NotificationStatus, Spinner } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { LicensePlate } from 'components/license-plate/LicensePlate';
import { dashboardPagePath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from 'cp-xhr';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { useUpdateContractsAll } from '../../useUpdateContractsAll';
import { TFunction } from 'i18next';

type ContractEndOptionsRequestProps = {
    isError: boolean;
    isSuccess: boolean;
    optionValue: ContractEndOptionsEN | undefined;
};

enum OptionsView {
    MAIN = 'MAIN',
    BUY_A_CAR = 'BUY_A_CAR',
    REFINANCE_ADDITIONAL = 'REFINANCE_ADDITIONAL',
}

export const ContractEndOptionsUi: React.FC<{ contract?: Contract & ContractDetails; myProfile?: MyProfileData }> = ({
    contract,
    myProfile,
}) => {
    const { t } = useTranslation('contract-end-options');
    const history = useHistory();
    const [currentOptions, setCurrentOptions] = useState<OptionsView>(OptionsView.MAIN);
    const [contractEndOptionsRequest, setContractEndOptionsRequest] = useState<ContractEndOptionsRequestProps>({
        isError: false,
        isSuccess: false,
        optionValue: undefined,
    });
    const [errorCode, setErrorCode] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { updateContracts, contractsData } = useUpdateContractsAll();

    if (!contract) {
        return <Notification status={NotificationStatus.error} text={t('error.text')} />;
    }

    const { contractNumber, carModel = ' ', licensePlate = '', contractId, productTypeFR = '' } = contract;

    const { brand: contractBrand } = contract;
    const brand = contractBrand ? contractBrand.toLowerCase() : null;
    const brandLabel = brand === 'vwcv' ? 'Volkswagen Véhicules Utilitaires' : contractBrand;
    const isRetail = myProfile?.identification?.customerType === 'Retail';

    let vehicleRestitutionGuideBrand: null | string = null;
    switch (brand) {
        case 'vwfs':
            vehicleRestitutionGuideBrand = 'vw';
            break;
        case 'vwcv':
            vehicleRestitutionGuideBrand = null;
            break;
        default:
            vehicleRestitutionGuideBrand = brand;
            break;
    }

    const showSubmitButton = (contract: Contract & ContractDetails) => {
        if (contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths) return false;
        return true;
    };

    const getSuccessText = (
        t: TFunction,
        contractEndOptionsRequest: ContractEndOptionsRequestProps,
        contract: Contract & ContractDetails,
    ) => {
        // Option 1 (renew) - 4 / 2 / 1 months
        if (
            contractEndOptionsRequest.optionValue === ContractEndOptionsEN.RENEWAL &&
            (contract.isEligibleRenewalContractNotificationForLessOfFourMonths ||
                contract.isEligibleRenewalContractNotificationForLessOfOneMonth ||
                contract.isEligibleRenewalContractNotificationForLessOfTwoMonths)
        ) {
            return (
                <>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('success.text-renewal-4-2-1months'),
                        }}
                    />
                    <br />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('success.text2-renewal-4-2-1months'),
                        }}
                    />
                </>
            );
        }
        // Option 2 (buy) - 4 months
        if (
            (contractEndOptionsRequest.optionValue === ContractEndOptionsEN.PAYMENT ||
                contractEndOptionsRequest.optionValue === ContractEndOptionsEN.REFINANCING) &&
            contract.isEligibleRenewalContractNotificationForLessOfFourMonths
        ) {
            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('success.text-buy-a-car-4months', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            );
        }
        if (
            contractEndOptionsRequest.optionValue === ContractEndOptionsEN.REFINANCING &&
            (contract.isEligibleRenewalContractNotificationForLessOfOneMonth ||
                contract.isEligibleRenewalContractNotificationForLessOfTwoMonths)
        ) {
            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('success.refinancing', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            );
        }

        if (
            contractEndOptionsRequest.optionValue === ContractEndOptionsEN.PAYMENT &&
            (contract.isEligibleRenewalContractNotificationForLessOfOneMonth ||
                contract.isEligibleRenewalContractNotificationForLessOfTwoMonths)
        ) {
            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('success.payment', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            );
        }
        // Option 3 (return)- 4 / 2 / 1 months
        if (
            contractEndOptionsRequest.optionValue === ContractEndOptionsEN.RETURN &&
            (contract.isEligibleRenewalContractNotificationForLessOfFourMonths ||
                contract.isEligibleRenewalContractNotificationForLessOfOneMonth ||
                contract.isEligibleRenewalContractNotificationForLessOfTwoMonths)
        ) {
            return (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('success.text-return-4-2-1months', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            );
        }

        return t('success.text');
    };

    const updateIsEligibleRenewalFlag = (): void => {
        const updatedContractsData = [...contractsData].map((contract) => {
            if (contract.contractId === contractId) {
                const updatedContract = { ...contract };
                updatedContract.isEligibleRenewalContractNotificationForLessOfFourMonths = false;
                updatedContract.isEligibleRenewalContractNotificationForLessOfOneMonth = false;
                updatedContract.isEligibleRenewalContractNotificationForLessOfThreeMonths = false;
                updatedContract.isEligibleRenewalContractNotificationForLessOfTwelveMonths = false;
                updatedContract.isEligibleRenewalContractNotificationForLessOfTwoMonths = false;
                updatedContract.isEligibleRenewalContractNotificationForLessOneMonth = false;
                updatedContract.isEligibleRenewalContractNotificationForLoiLagarde = false;
                return updatedContract;
            }
            return contract;
        });
        updateContracts(updatedContractsData);
    };

    const handleModalClose = () => {
        setContractEndOptionsRequest({
            isError: false,
            isSuccess: false,
            optionValue: undefined,
        });
    };

    const redirectToDashboard = () => {
        updateIsEligibleRenewalFlag();
        history.push(dashboardPagePath());
    };

    const handleChange = (option: ContractEndOptionsEN) => {
        setContractEndOptionsRequest({
            ...contractEndOptionsRequest,
            optionValue: option,
        });
    };

    const clearChosenOption = () => {
        setContractEndOptionsRequest({
            ...contractEndOptionsRequest,
            optionValue: undefined,
        });
    };

    const handleSubmit = () => {
        if (contractEndOptionsRequest.optionValue === undefined) {
            return;
        }

        const preparedData: ContractEndOptionsDetails = {
            email: myProfile?.contactDetails?.email ? myProfile?.contactDetails?.email : '',
            endOption: contractEndOptionsRequest.optionValue,
        };
        setIsSubmitting(true);
        CpDataApi.put(getEndOptionEndpoint(contract.contractId), preparedData)
            .then(() => {
                setContractEndOptionsRequest({ ...contractEndOptionsRequest, isSuccess: true });
            })
            .catch(({ error }) => {
                setContractEndOptionsRequest({ ...contractEndOptionsRequest, isError: true });
                setErrorCode(error.response.data.code);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const refinancingOption = formatCpDate(contract.returnBackVehicleDate)
        .toMoment()
        .subtract(1, 'months')
        .isBefore(new Date()) ? (
        <TileSelect
            icon="semantic-calculator"
            title={t('options.refinancing.headline')}
            inputType="radio"
            radioGroupName="buyACarOptions"
            onClick={() => handleChange(ContractEndOptionsEN.REFINANCING)}
            testId={'tile-select-refinancing'}
        >
            {t('options.refinancing.description')}
        </TileSelect>
    ) : undefined;

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}
            <Layout>
                <Layout.Item>
                    <Card element="article" title="">
                        <ContractHeader
                            contractIdentifier={<LicensePlate registrationNumber={licensePlate} />}
                            carInformation={carModel}
                            contractCategory={productTypeFR}
                            contractNumber={contractNumber}
                        />
                    </Card>
                </Layout.Item>
                {/* contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths */}
                <Layout.Item>
                    {currentOptions === OptionsView.MAIN &&
                        contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths && (
                            <RenewEndOption12Months brand={brandLabel || ''} />
                        )}
                    {/* 4 2 1 Months */}
                    {currentOptions === OptionsView.MAIN && (
                        <>
                            {contract.isEligibleRenewalContractNotificationForLessOfFourMonths ||
                            contract.isEligibleRenewalContractNotificationForLessOfTwoMonths ||
                            contract.isEligibleRenewalContractNotificationForLessOfOneMonth ? (
                                <TileSelectGroup
                                    key={OptionsView.MAIN}
                                    layoutItemClassName="u-1/3 u-1/2@l u-1/1@s"
                                    className={'u-text-center'}
                                    testId={'tile-select-group-main'}
                                >
                                    <RenewEndOption421Months onClick={handleChange} brand={brandLabel || ''} />
                                    <BuyEndOption4m2m1m
                                        contract={contract}
                                        onClick={
                                            contract.isEligibleRenewalContractNotificationForLessOfFourMonths
                                                ? () => {
                                                      handleChange(ContractEndOptionsEN.PAYMENT);
                                                  }
                                                : () => {
                                                      clearChosenOption();
                                                      setCurrentOptions(OptionsView.BUY_A_CAR);
                                                  }
                                        }
                                    />
                                    <ReturnEndOption4m2m1m
                                        onClick={() => handleChange(ContractEndOptionsEN.RETURN)}
                                        one={
                                            !!(
                                                contract.isEligibleRenewalContractNotificationForLessOfOneMonth ||
                                                contract.isEligibleRenewalContractNotificationForLessOfTwoMonths
                                            )
                                        }
                                    />
                                </TileSelectGroup>
                            ) : (
                                <>
                                    {!contract.isEligibleRenewalContractNotificationForLessOfTwelveMonths && (
                                        <TileSelectGroup
                                            key={OptionsView.MAIN}
                                            layoutItemClassName="u-1/3 u-1/2@l u-1/1@s"
                                            className={'u-text-center'}
                                            testId={'tile-select-group-main'}
                                        >
                                            <TileSelect
                                                icon="semantic-car"
                                                title={t('options.renewal.headline')}
                                                inputType="radio"
                                                radioGroupName="contractEndMainOptions"
                                                onClick={() => handleChange(ContractEndOptionsEN.RENEWAL)}
                                            >
                                                {t('options.renewal.description')}
                                                {isRetail && vehicleRestitutionGuideBrand && (
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t('options.renewal.vehicle-restitution-guide', {
                                                                brand: vehicleRestitutionGuideBrand,
                                                            }),
                                                        }}
                                                    />
                                                )}
                                            </TileSelect>
                                            <TileSelect
                                                icon="semantic-accessoires"
                                                title={t('options.buy-a-car.headline')}
                                                inputType="radio"
                                                radioGroupName="contractEndMainOptions"
                                                testId={'tile-select-buy-a-car'}
                                                onClick={() => {
                                                    clearChosenOption();
                                                    setCurrentOptions(OptionsView.BUY_A_CAR);
                                                }}
                                            >
                                                {t('options.buy-a-car.description')}
                                            </TileSelect>
                                            <TileSelect
                                                icon="semantic-reload"
                                                title={t('options.return.headline')}
                                                inputType="radio"
                                                radioGroupName="contractEndMainOptions"
                                                onClick={() => handleChange(ContractEndOptionsEN.RETURN)}
                                            >
                                                {isRetail && vehicleRestitutionGuideBrand ? (
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t('options.return.vehicle-restitution-guide', {
                                                                brand: vehicleRestitutionGuideBrand,
                                                            }),
                                                        }}
                                                    />
                                                ) : (
                                                    t('options.return.description')
                                                )}
                                            </TileSelect>
                                        </TileSelectGroup>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {currentOptions === OptionsView.BUY_A_CAR && (
                        <>
                            {contract.isEligibleRenewalContractNotificationForLessOfTwoMonths ||
                            contract.isEligibleRenewalContractNotificationForLessOfOneMonth ? (
                                <PaymentRefinance2m1m
                                    contract={contract}
                                    onClickRefin={() => setCurrentOptions(OptionsView.REFINANCE_ADDITIONAL)}
                                    onClickPay={handleChange}
                                />
                            ) : (
                                <TileSelectGroup
                                    key={OptionsView.BUY_A_CAR}
                                    layoutItemClassName={`u-1/${refinancingOption ? 2 : 1} u-1/2@l u-1/1@s`}
                                    className={'u-text-center'}
                                    testId={'tile-select-group-buy'}
                                >
                                    {refinancingOption}
                                    <TileSelect
                                        icon="semantic-fs-payment"
                                        title={t('options.payment.headline')}
                                        inputType="radio"
                                        radioGroupName="buyACarOptions"
                                        onClick={() => handleChange(ContractEndOptionsEN.PAYMENT)}
                                    >
                                        {t('options.payment.description')}
                                    </TileSelect>
                                </TileSelectGroup>
                            )}
                        </>
                    )}
                    {currentOptions === OptionsView.REFINANCE_ADDITIONAL && (
                        <RefinanceAdditional2m1m onClick={handleChange} contract={contract} />
                    )}
                </Layout.Item>
                <Layout.Item>
                    <ButtonContainer center>
                        <Button
                            secondary
                            testId="backButton"
                            onClick={() => {
                                if (currentOptions === OptionsView.MAIN) redirectToDashboard();
                                else if (currentOptions === OptionsView.BUY_A_CAR) {
                                    clearChosenOption();
                                    setCurrentOptions(OptionsView.MAIN);
                                } else if (currentOptions === OptionsView.REFINANCE_ADDITIONAL) {
                                    clearChosenOption();
                                    setCurrentOptions(OptionsView.BUY_A_CAR);
                                }
                            }}
                        >
                            {t('buttons.back')}
                        </Button>
                        {showSubmitButton(contract) && (
                            <Button
                                testId="submitButton"
                                disabled={!contractEndOptionsRequest.optionValue}
                                onClick={handleSubmit}
                            >
                                {t('buttons.submit')}
                            </Button>
                        )}
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <Modal
                shown={contractEndOptionsRequest.isSuccess}
                status="success"
                onClose={redirectToDashboard}
                onCancel={redirectToDashboard}
                buttonCancelText={t('success.button')}
                testId={'success-modal'}
                title={t('success.headline')}
            >
                {getSuccessText(t, contractEndOptionsRequest, contract)}
            </Modal>
            <Modal
                shown={contractEndOptionsRequest.isError}
                status="error"
                onClose={handleModalClose}
                onCancel={handleModalClose}
                testId={'error-modal'}
            >
                {errorCode && errorCode === MarketError.ERROR_NO_EMAIL ? t('no-email-error.text') : t('error.text')}
            </Modal>
        </>
    );
};

/// Card Components and Utils ////////////////////////////////////

const showLink = (brand: string) => ['vw', 'audi', 'seat', 'skoda', 'vmcv'].includes(brand?.toLowerCase() || '');
const getLink = (brand: string): string => {
    switch (brand.toLowerCase()) {
        case 'vw':
            return 'https://www.volkswagen.fr/fr.html';
        case 'audi':
            return 'https://www.audi.fr/fr/web/fr.html';
        case 'skoda':
            return 'https://www.skoda.fr/';
        case 'vwcv':
            return 'https://www.volkswagen-utilitaires.fr/fr.html';
        default:
            return '';
    }
};

export const RenewEndOption12Months: React.FC<{ onClick?: (option: ContractEndOptionsEN) => void; brand?: string }> = ({
    brand,
}) => {
    const { t } = useTranslation('contract-end-options');

    return (
        <div className="u-text-center">
            <Icon name="semantic-car" size="large" />
            <h4>{t('options.renewal-12months.headline')}</h4>
            <Paragraph>{t('options.renewal-12months.description')}</Paragraph>
            <br />
            {showLink(brand || '') && (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('options.renewal-12months.vehicle-restitution-guide', {
                            brand: brand,
                            link: getLink(brand || ''),
                        }),
                    }}
                />
            )}
            <br />
            <span
                dangerouslySetInnerHTML={{
                    __html: t('options.renewal-12months.description2'),
                }}
            />
        </div>
    );
};

export const RenewEndOption421Months: React.FC<{ onClick: (option: ContractEndOptionsEN) => void; brand?: string }> = ({
    onClick,
    brand,
}) => {
    const { t } = useTranslation('contract-end-options');

    return (
        <TileSelect
            icon="semantic-car"
            title={t('options.renewal-4-2-1months.headline')}
            inputType="radio"
            radioGroupName="contractEndMainOptions"
            onClick={() => onClick(ContractEndOptionsEN.RENEWAL)}
        >
            <span>{t('options.renewal-4-2-1months.description')}</span>
            <br />
            {showLink(brand || '') && (
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('options.renewal-4-2-1months.vehicle-restitution-guide', {
                            brand: brand,
                            link: getLink(brand || ''),
                        }),
                    }}
                />
            )}
            <br />
            <span
                dangerouslySetInnerHTML={{
                    __html: t('options.renewal-4-2-1months.description2'),
                }}
            />
        </TileSelect>
    );
};

export const BuyEndOption4m2m1m: React.FC<{ onClick: () => void; contract: Contract & ContractDetails }> = ({
    onClick,
    contract,
}) => {
    const { t } = useTranslation('contract-end-options');
    return (
        <TileSelect
            icon="semantic-accessoires"
            title={t('options.buy-a-car-4months.headline')}
            inputType="radio"
            radioGroupName="contractEndMainOptions"
            testId={'tile-select-buy-a-car'}
            onClick={onClick}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: t(
                        contract.isEligibleRenewalContractNotificationForLessOfFourMonths
                            ? 'options.buy-a-car-4months.description'
                            : 'options.buy-a-car-2-1months.description',
                        {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        },
                    ),
                }}
            />
        </TileSelect>
    );
};

export const ReturnEndOption4m2m1m: React.FC<{ onClick: (option: ContractEndOptionsEN) => void; one: boolean }> = ({
    onClick,
    one,
}) => {
    const { t } = useTranslation('contract-end-options');
    return (
        <TileSelect
            icon="semantic-reload"
            title={t('options.return-4-2-1months.headline')}
            inputType="radio"
            radioGroupName="contractEndMainOptions"
            onClick={() => onClick(ContractEndOptionsEN.RETURN)}
        >
            <span
                dangerouslySetInnerHTML={{
                    __html: one
                        ? t('options.return-4-2-1months.description1')
                        : t('options.return-4-2-1months.description'),
                }}
            />
            <span
                dangerouslySetInnerHTML={{
                    __html: t('options.return-4-2-1months.vehicle-restitution-guide'),
                }}
            />
        </TileSelect>
    );
};

// Option A / B for Buy option
export const PaymentRefinance2m1m: React.FC<{
    onClickRefin: () => void;
    onClickPay: (option: ContractEndOptionsEN) => void;
    contract: Contract & ContractDetails;
}> = ({ onClickRefin, onClickPay, contract }) => {
    const { t } = useTranslation('contract-end-options');
    return (
        <TileSelectGroup
            key={OptionsView.BUY_A_CAR}
            layoutItemClassName={`u-1/2 u-1/2@l u-1/1@s`}
            className={'u-text-center'}
            testId={'tile-select-group-buy'}
        >
            <TileSelect
                icon="semantic-calculator"
                title={t('options.refinancing-2-1months.headline')}
                inputType="radio"
                radioGroupName="buyACarOptions"
                onClick={onClickRefin}
                testId={'tile-select-refinancing'}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('options.refinancing-2-1months.description', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            </TileSelect>
            <TileSelect
                icon="semantic-fs-payment"
                title={t('options.payment-2-1months.headline')}
                inputType="radio"
                radioGroupName="buyACarOptions"
                onClick={() => onClickPay(ContractEndOptionsEN.PAYMENT)}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('options.payment-2-1months.description', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            </TileSelect>
        </TileSelectGroup>
    );
};

export const RefinanceAdditional2m1m: React.FC<{
    onClick: (option: ContractEndOptionsEN) => void;
    contract: Contract & ContractDetails;
}> = ({ onClick, contract }) => {
    const { t } = useTranslation('contract-end-options');
    return (
        <TileSelectGroup
            key={OptionsView.BUY_A_CAR}
            layoutItemClassName={`u-1/1 u-1/2@l u-1/1@s`}
            className={'u-text-center'}
            testId={'tile-select-group-buy'}
        >
            <TileSelect
                icon="semantic-calculator"
                title={t('options.refinancing-2-1months.headline')}
                inputType="radio"
                radioGroupName="buyACarOptions"
                onClick={() => onClick(ContractEndOptionsEN.REFINANCING)}
                testId={'tile-select-refinancing'}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('options.refinancing-2-1months.additional', {
                            lastPaymentInclTVA: contract.financial.lastPaymentInclTVA?.toLocaleString('fr-FR'),
                        }),
                    }}
                />
            </TileSelect>
        </TileSelectGroup>
    );
};
